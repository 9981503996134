/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC, useState} from 'react';

import style from './UsefulLinkButton.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import ConfirmButton from 'components/common/ConfirmButton';
import {useNavigate} from 'react-router-dom';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

interface Props {
  text: string;
  infotxt: string;
  link: string;
  onlyModal?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exitCallback?: any;
  navigateLink?: string;
  modalNeeded?: boolean;
  mailTo?: boolean;
}

const LinkButton: FC<Props> = ({text, infotxt, link, onlyModal, exitCallback, navigateLink, modalNeeded, mailTo}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [toggleModal, setToggleModal] = useState<boolean>(onlyModal || false);
  const navigate = useNavigate();

  const handleClose = () => {
    setToggleModal(false);
    exitCallback && exitCallback(false);
    navigateLink && navigate(navigateLink);
  };

  return (
    <>
      {!modalNeeded ? (
        <a href={link} target="_blank" rel="noreferrer" aria-label={text}>
          <button tabIndex={-1} className={`${style.link_button}`} aria-label="Przycisk otwierający link">
            {text}
          </button>
        </a>
      ) : (
        <>
          <button
            className={`${style.link_button}`}
            onClick={() => setToggleModal(true)}
            style={{display: onlyModal ? 'none' : ''}}
          >
            {text}
          </button>
          {modalNeeded && toggleModal && (
            <div className={style.link_modal}>
              <div className={style.link_modal__box}>
                <header className={style.link_modal__box_header}>
                  <h2>{text}</h2>
                  <img src={isContrastTheme ? ContrastX : OrangeX} alt="zamknij" onClick={handleClose} />
                </header>
                <p>{infotxt}</p>
                {mailTo ? (
                  <a href={`mailto:${link}`}>{link}</a>
                ) : (
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                    <span className="sr-only">{t('13024')}</span>
                  </a>
                )}
                <div className={style.link_modal__btn_box}>
                  <ConfirmButton text={t('29002')} execute={handleClose} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LinkButton;
