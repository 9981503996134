import React from 'react';

export const useMemoPagination = (pages: number | undefined) => {
  const [allPages, setAllPages] = React.useState(pages);

  React.useEffect(() => {
    if (pages !== undefined) {
      setAllPages(pages);
    }
  }, [pages]);

  return {allPages};
};
