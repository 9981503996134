import {FC} from 'react';

import style from './SearchHeader.module.scss';

interface SearchHeaderDataProps {
  title: string;
  isMainHeader?: boolean;
}
const SearchHeader: FC<SearchHeaderDataProps> = ({title, isMainHeader}) => {
  return isMainHeader ? (
    <h1 className={style.Search__header}>{title}</h1>
  ) : (
    <h2 className={style.Search__header}>{title}</h2>
  );
};
export default SearchHeader;
