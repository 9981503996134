import {Dispatch, createContext, useContext, useReducer} from 'react';
import grmContextReducer from './grmContextReducer';
import GrmContextState from './GrmContextState';
import {GrmContextActions} from './GrmContextActions.enum';
import {GRMAvailability} from 'interfaces/Grm';

export const GrmInitialData: GrmContextState = {
  currentCarriage: null,
  placesToTake: [],
  train: undefined,
  carriages: [],
  klasa1: [],
  klasa2: [],
  klasa0: [],
  active: false,
  placesToTakeIndex: 0,
  pojazdTyp: undefined,
  wagonySchemat: {},
  confirmPopup: null,
  mode: GRMAvailability.UNAVAILABLE,
  zmieniaKierunek: false,
};

export const GrmContext = createContext<GrmContextState>(GrmInitialData);
export const GrmDispatchContext = createContext<{dispatch: Dispatch<GrmContextActions>} | null>(null);

const GRMContextProvider = ({children}: {children: React.ReactNode}) => {
  const [state, dispatch] = useReducer(grmContextReducer, GrmInitialData);

  return (
    <GrmContext.Provider value={state}>
      <GrmDispatchContext.Provider value={{dispatch}}>{children}</GrmDispatchContext.Provider>
    </GrmContext.Provider>
  );
};

export {GRMContextProvider};

export const useGrmState = () => {
  const c = useContext(GrmContext);

  if (!c) {
    throw new Error('Brak providera');
  }

  return c;
};

export const useGrmDispatch = () => {
  const c = useContext(GrmDispatchContext);

  if (!c) {
    throw new Error('Brak providera');
  }

  return c.dispatch;
};
