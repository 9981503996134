import {StateContext, assertIsStyledTheme} from '../types';
import {initialStateContext, storageName} from '../consts';

export const hydrate = (): StateContext => {
  try {
    const store = localStorage.getItem(storageName);
    if (typeof store === 'string') {
      const parsedStore = JSON.parse(store);
      assertIsStyledTheme(parsedStore);
      return new StateContext(parsedStore.theme, parsedStore.font);
    } else {
      return initialStateContext;
    }
  } catch {
    return initialStateContext;
  }
};
