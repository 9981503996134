import {styled} from '@mui/material';
import {Link} from 'react-router-dom';
import {MOBILE_BREAKPOINT} from 'common/consts';
import {mediaQueries} from 'common/theme/consts';

export const MyTicketsListContainer = styled('section')`
  background-color: ${({theme}) => theme.color.background.primary};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
    top: 0;
    left: 0;
    padding: 32px 8px 72px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
    z-index: 1;
  }
`;

export const HeaderContainer = styled('div')`
  position: relative;
  padding: 0 30px;

  @media (${mediaQueries.sm}) {
    padding: 0;
  }
`;

export const BackButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  width: 24px;
  height: 24px;

  @media (${mediaQueries.sm}) {
    display: none;
  }
`;

export const Title = styled('h2')`
  font-size: 1.9rem;
  font-weight: ${({theme}) => theme.text.weight[700]};
  line-height: 2.3rem;
  color: ${({theme}) => theme.color.text.primary};
  text-align: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0;
  }

  @media (${mediaQueries.sm}) {
    font-size: ${({theme}) => theme.text.size[20]};
    line-height: 2.4rem;
    text-align: left;
  }
`;

export const AddTicketContainer = styled('div')`
  overflow: auto;

  @media (${mediaQueries.sm}) {
    margin-top: 20px;
  }
`;
