import React, {useState, createContext, FC} from 'react';
import {IIdResponseList, FullTicket, IDownloadTicketResponse, ISingleId} from 'api/sprzedaz/interfaces';

export const UserTicketsContext = createContext<IUserTicketsContext>({
  openedDesktopTab: 1,
  setOpenedDesktopTab: null,
  actualTicketsIdent: null,
  setActualTicketsIdent: null,
  historyTicketsIdent: null,
  setHistoryTicketsIdent: null,
  returnedTicketIdent: null,
  setReturnedTicketIdent: null,
  actualTicketsFull: null,
  setActualTicketsFull: null,
  historyTicketsFull: null,
  setHistoryTicketsFull: null,
  returnedTicketFull: null,
  setReturnedTicketFull: null,
  returnedTicketData: null,
  setReturnedTicketData: null,
  seazonTicketsIdent: null,
  setSeazonTicketsIdent: null,
  seazonTicketData: null,
  setSeazonTicketData: null,
});

type DataState = {
  children: React.ReactNode;
};

interface IUserTicketsContext {
  openedDesktopTab: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOpenedDesktopTab: any;
  actualTicketsIdent: IIdResponseList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActualTicketsIdent: any;
  historyTicketsIdent: IIdResponseList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHistoryTicketsIdent: any;
  returnedTicketIdent: IIdResponseList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReturnedTicketIdent: any;
  actualTicketsFull: FullTicket[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActualTicketsFull: any;
  historyTicketsFull: FullTicket[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHistoryTicketsFull: any;
  returnedTicketFull: FullTicket[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReturnedTicketFull: any;
  returnedTicketData: {
    ticketSr: string;
    ticket: IDownloadTicketResponse;
    mainTicketIdentifier: ISingleId;
    seazonalTicket?: boolean;
  } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReturnedTicketData: any;
  seazonTicketsIdent: IIdResponseList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSeazonTicketsIdent: any;
  seazonTicketData: FullTicket[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSeazonTicketData: any;
}

const UserTicketProvider: FC<DataState> = ({children}) => {
  const [openedDesktopTab, setOpenedDesktopTab] = useState(1);
  const [actualTicketsIdent, setActualTicketsIdent] = useState(null);
  const [historyTicketsIdent, setHistoryTicketsIdent] = useState(null);
  const [returnedTicketIdent, setReturnedTicketIdent] = useState(null);
  const [actualTicketsFull, setActualTicketsFull] = useState(null);
  const [historyTicketsFull, setHistoryTicketsFull] = useState(null);
  const [returnedTicketFull, setReturnedTicketFull] = useState(null);
  const [returnedTicketData, setReturnedTicketData] = useState(null);

  const [seazonTicketsIdent, setSeazonTicketsIdent] = useState(null);
  const [seazonTicketData, setSeazonTicketData] = useState(null);

  return (
    <UserTicketsContext.Provider
      value={{
        openedDesktopTab,
        setOpenedDesktopTab,
        actualTicketsIdent,
        setActualTicketsIdent,
        historyTicketsIdent,
        setHistoryTicketsIdent,
        returnedTicketIdent,
        setReturnedTicketIdent,
        actualTicketsFull,
        setActualTicketsFull,
        historyTicketsFull,
        setHistoryTicketsFull,
        returnedTicketFull,
        setReturnedTicketFull,
        returnedTicketData,
        setReturnedTicketData,
        seazonTicketsIdent,
        setSeazonTicketsIdent,
        seazonTicketData,
        setSeazonTicketData,
      }}
    >
      {children}
    </UserTicketsContext.Provider>
  );
};

export default UserTicketProvider;
