/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {FC, ChangeEvent, useState, KeyboardEvent} from 'react';
import {getMonthName} from 'Utils/commonFunctions';

import style from './DiscountsAgePicker.module.scss';
import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';
import ArrowBottomContrast from 'assets/contrastArrowBottomDesktop.svg';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

interface Props {
  min: number;
  max: number;
  placeholder: string;
  callback: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback2: any;
  type: string;
  autoComplete?: string;
  id: string;
  label: string;
  pattern?: string;
}

const DiscountsAgePicker: FC<Props> = ({
  min,
  max,
  placeholder,
  callback,
  value,
  callback2,
  type,
  autoComplete,
  id,
  label,
  pattern,
}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [toggleList, setToggleList] = useState<boolean>(false);

  const dateArray = [];
  for (let i = min; i <= max; i++) {
    if (i < 10) {
      dateArray.push(`0${i}`);
    } else {
      dateArray.push(i.toString());
    }
  }

  if (placeholder === 'Rok') dateArray.reverse();

  const FormatInputDate = (numb: number) => {
    if (numb === 0) return '';
    if (numb < 10) return `0${numb}`;
    return numb.toString();
  };
  const CheckValue = (val: string) => {
    if (type === 'text') {
      if (Number(val) > 12) return t('13046');
      switch (val) {
        case '01':
          return '13035';
        case '02':
        case '2':
          return '13036';
        case '03':
        case '3':
          return '13037';
        case '04':
        case '4':
          return '13038';
        case '05':
        case '5':
          return '13039';
        case '06':
        case '6':
          return '13040';
        case '07':
        case '7':
          return '13041';
        case '08':
        case '8':
          return '13042';
        case '09':
        case '9':
          return '13043';
        case '10':
          return '13044';
        case '11':
          return '13045';
        case '12':
          return '13046';
        default:
          return val;
      }
    }
    const valToNumb = Number(val);
    if (valToNumb > max) callback2(max);
    if (valToNumb < min && placeholder !== 'Rok') return '';
    return FormatInputDate(valToNumb);
  };

  const firstLiElementFocus = () => {
    const firstLiElement = document.querySelector(`#${placeholder}`)?.firstChild;
    (firstLiElement as HTMLElement).focus();
  };

  const checkLastLiElement = (element: string, max: number, min: number) => {
    if (id === 'birthdayYear' && Number(element) === min) {
      setToggleList(false);
    }
    if (id !== 'birthdayYear' && Number(element) === max) {
      setToggleList(false);
    }
  };

  const moveFocusByArrowKey = (directionTop: boolean) => {
    const activeElement = document.activeElement;
    if (directionTop) {
      (activeElement?.nextElementSibling as HTMLElement)?.focus();
    } else {
      (activeElement?.previousElementSibling as HTMLElement)?.focus();
    }
  };

  const handleKey = (e: KeyboardEvent<HTMLLIElement>, date: string) => {
    if (e.key === 'Enter') {
      callback2(date);
      setToggleList(false);
    }
    if (e.key === 'Escape') {
      setToggleList(false);
    }
    if (e.key === 'ArrowUp') {
      moveFocusByArrowKey(false);
    }
    if (e.key === 'ArrowDown') {
      moveFocusByArrowKey(true);
    }
    if (e.key === 'Tab') {
      checkLastLiElement(date, max, min);
    }
  };

  return (
    <div className={style.disAgeInput__box}>
      <label className="hiddenLabel" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        type={type}
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={callback}
        value={t(CheckValue(value))}
        autoComplete={autoComplete}
        pattern={pattern}
      />
      <img
        src={isContrastTheme ? ArrowBottomContrast : ArrowBottom}
        alt={`${toggleList ? t('13048') : t('13049')}`}
        tabIndex={0}
        onClick={() => setToggleList(!toggleList)}
        onKeyDown={(e) => (e.key === 'Enter' ? setToggleList(!toggleList) : null)}
        onKeyUp={(e) => (e.key === 'Enter' ? firstLiElementFocus() : null)}
        className={`${toggleList ? style.disAgeInput__icon_rotate : null}`}
      />
      <ul className={`${style.disAgeInput__list} ${toggleList ? style.disAgeInput__list_open : null}`} id={placeholder}>
        {dateArray.map((date) => {
          return (
            <li
              key={date}
              onClick={() => {
                callback2(date);
                setToggleList(false);
              }}
              onKeyDown={(e) => handleKey(e, date)}
              tabIndex={toggleList ? 0 : undefined}
              value={date}
            >
              {placeholder === t('13050') ? t(getMonthName(Number(date))) : date}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DiscountsAgePicker;
