import {FC, useEffect} from 'react';
import s from './SafeLogin.module.scss';
import ConfirmButton from 'components/common/ConfirmButton';
import {ssoUrlBuilderWithPKCEParams} from 'Utils/ssoUrlBuilder';
import {useStyledThemeState} from 'common/theme';
import userData from '../../userData';
import {useTranslation} from 'react-i18next';
const SafeLogin: FC = () => {
  const {login} = userData();
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();

  const handleLogin = async () => {
    ssoUrlBuilderWithPKCEParams(isContrastTheme).then((ssoUrl) => window.location.replace(ssoUrl));
  };

  useEffect(() => {
    login && window.location.replace('/profil/mojebilety');
  }, [login]);

  return (
    <section className={s.main}>
      <h2 className={`col-12 ${s.header_style}`}>{t('10011')}</h2>
      <div className={`col-12 col-sm-12 ${s.info_text}`}>{t('29096')}</div>
      <ConfirmButton text={t('10011')} execute={handleLogin} />
    </section>
  );
};

export default SafeLogin;
