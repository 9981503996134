import api from '../axios-middleware';
import {env} from 'env';

import {ICheckToken, IGetTokenResponse, IGuestTicket} from './interfaces';

const methods = {
  getJwtToken: async (code: string) => {
    const codeVerifier = sessionStorage.getItem('eic2Uuid');
    const codeVerifierContent = codeVerifier ? `?code_verifier=${codeVerifier}` : '';
    return await api.get<IGetTokenResponse>(
      `${env.REACT_APP_SSO_SERVER}/oauth/token/code/${code}${codeVerifierContent}`,
    );
  },
  refreshJwtToken: async (refreshToken: string) => {
    return await api.postExt<IGetTokenResponse>(
      `${env.REACT_APP_SSO_SERVER}/oauth/token`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ZWljXzI6QGRtaW4hU3pjeg==',
      },
    );
  },
  renewJwtToken: async (refreshToken: string, ticket: IGuestTicket) => {
    return await api.postExt<IGetTokenResponse>(
      `${env.REACT_APP_SSO_SERVER}/oauth/renew_token`,
      {
        refreshToken: refreshToken,
        ticket: ticket,
      },
      {
        'Content-Type': 'application/json',
      },
    );
  },
  checkJwtToken: async (token: string) => {
    return await api.postExt<ICheckToken>(
      `${env.REACT_APP_SSO_SERVER}/oauth/check_token`,
      {
        token,
      },
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    );
  },
};

export default methods;
