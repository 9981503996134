import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalErrorBoundary from './components/errors/GlobalErrorBoundary';
import 'react-tooltip/dist/react-tooltip.css';
import './styles/globals.scss';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <GlobalErrorBoundary>
    <App />
  </GlobalErrorBoundary>,
  // </React.StrictMode>
);
