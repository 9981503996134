import {FC, useState} from 'react';

import style from './SetDiscounts.module.scss';
import ExpandIcon from 'assets/expandIcon.svg';
import ExpandIconContrast from 'assets/contrastExpandIcon.svg';

import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';
interface Props {
  description: string;
  ariaLabel: string;
}

const DiscountToggleDetail: FC<Props> = ({description, ariaLabel}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [toggle, setToggle] = useState(false);

  return (
    <div className={style.singleDiscountDetails}>
      <button
        onClick={(e) => `${setToggle(!toggle)} ${e.stopPropagation()}`}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === 'Enter') {
            e.preventDefault();
            setToggle(!toggle);
          }
        }}
        aria-expanded={toggle}
        aria-controls={ariaLabel}
      >
        <p> {t('11004')}</p>
        <img
          src={isContrastTheme ? ExpandIconContrast : ExpandIcon}
          alt=""
          className={style.detailsArrow}
          style={{
            transform: toggle ? 'rotate(180deg)' : '',
          }}
        />
      </button>
      <article
        className={`${style.singleDiscountDetails_accordion} ${toggle ? style.singleDiscountDetails_accordionOpen : ''}`}
        id={ariaLabel}
      >
        {description}
      </article>
    </div>
  );
};

export default DiscountToggleDetail;
