import {FC} from 'react';

import {useStyledThemeState} from 'common/theme';

import style from './ProfilePagination.module.scss';
import ArrowOrange from 'assets/orangeArrowRightNarrow.svg';
import ArrowContrast from 'assets/contrastArrowRightNarrow.svg';
import ArrowGray from 'assets/grayArrowLeft.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  toDisplay: number;
  perPage: number;
  page: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPage: any;
}

const ProfilePagination: FC<Props> = ({page, setPage, toDisplay, perPage}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();

  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(toDisplay / perPage); i++) {
    pageNumbers.push(i);
  }

  const checkContrastArrow = isContrastTheme ? ArrowContrast : ArrowOrange;

  return (
    <div
      className={`col-sm-12 col-12 ${style.pagination_container}`}
      style={{display: pageNumbers.length < 2 ? 'none' : ''}}
    >
      <button onClick={() => setPage(page - 1)} disabled={page === 1} aria-label={t('29034')}>
        <img
          src={page > 1 ? checkContrastArrow : ArrowGray}
          alt={t('11026')}
          style={{transform: page > 1 ? 'rotate(180deg)' : ''}}
        />
      </button>
      {pageNumbers.map((item) => {
        return (
          <button
            key={item}
            onClick={() => setPage(item)}
            className={page === item ? style.active_page : ''}
            aria-label={`strona ${item}`}
          >
            {item}
          </button>
        );
      })}
      <button onClick={() => setPage(page + 1)} disabled={page === pageNumbers.length} aria-label={t('29035')}>
        <img
          src={page < pageNumbers.length ? checkContrastArrow : ArrowGray}
          alt={t('11027')}
          style={{transform: page < pageNumbers.length ? '' : 'rotate(180deg)'}}
        />
      </button>
    </div>
  );
};

export default ProfilePagination;
