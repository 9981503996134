import {FC} from 'react';

import {TrasaPrzejazdu} from 'api/pociagi/interfaces';
import {getComparableDateFormat} from 'Utils/commonFunctions';

import MyTicketsRouteModalStations from './MyTicketsRouteModalStations';
import MyTicketsRouteModalTimes from './MyTicketsRouteModalTimes';
import MyTicketsRouteModalPlatforms from './MyTicketsRouteModalPlatforms';

import S from './MyTicketsRouteModal.module.scss';

interface Props {
  route: TrasaPrzejazdu[] | [];
  departureStationIndex: number;
  arrivalStationIndex: number;
  isFullRouteVisible: boolean;
}

const MyTicketsRouteModalGridContent: FC<Props> = ({
  route,
  departureStationIndex,
  arrivalStationIndex,
  isFullRouteVisible,
}) => {
  const currentDate = new Date();

  const checkIsNextStation = (index: number) =>
    index > 0 &&
    currentDate.getTime() >
      getComparableDateFormat(route[index - 1].dataPrzyjazduRzeczywista || route[index - 1].dataPrzyjazdu).getTime() &&
    currentDate.getTime() >
      getComparableDateFormat(route[index - 1].dataWyjazduRzeczywista || route[index - 1].dataWyjazdu).getTime() &&
    currentDate.getTime() <
      getComparableDateFormat(route[index].dataPrzyjazduRzeczywista || route[index].dataPrzyjazdu).getTime();

  return (
    <>
      {route.map((item, index) => (
        <div className={S.grid} key={`${item.kodStacji}-${item.nazwaStacji}`}>
          <MyTicketsRouteModalStations
            routeSection={item}
            isFirstStation={index === 0}
            isEndStation={index === route.length - 1}
            isNextStation={checkIsNextStation(index)}
          />
          <MyTicketsRouteModalTimes
            id={index}
            route={route}
            routeSection={item}
            departureStationIndex={departureStationIndex}
            arrivalStationIndex={arrivalStationIndex}
            isFullRouteVisible={isFullRouteVisible}
          />
          <MyTicketsRouteModalPlatforms
            routeSection={item}
            isDepartureStation={index === (isFullRouteVisible ? departureStationIndex : 0)}
            isArrivalStation={index === (isFullRouteVisible ? arrivalStationIndex : route.length - 1)}
          />
        </div>
      ))}
    </>
  );
};

export default MyTicketsRouteModalGridContent;
