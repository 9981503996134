import {FC} from 'react';

import ArrowDown from 'assets/orangeArrowBottomDesktop.svg';
import ArrowDownContrast from 'assets/contrastArrowBottomDesktop.svg';
import {useStyledThemeState} from 'common/theme';

import S from './MyTicketsRouteModal.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  isFullRouteVisible: boolean;
  toggleRoute: () => void;
}

const MyTicketsRouteModalGridHeader: FC<Props> = ({isFullRouteVisible, toggleRoute}) => {
  const {t} = useTranslation();

  const {isContrastTheme} = useStyledThemeState();
  return (
    <div className={S.grid}>
      <div className={S.flexContainer}>
        <p className={S.headerText}>stacja</p>
        <button className={S.showFullRouteButton} aria-label="Rozwiń informacje o trasie" onClick={toggleRoute}>
          <p className={S.underlinedText}>Pokaż całą trasę</p>
          <img
            src={isContrastTheme ? ArrowDownContrast : ArrowDown}
            alt={`Strzałka w ${isFullRouteVisible ? 'górę' : 'dół'}`}
            className={`${S.arrowDown} ${isFullRouteVisible && S.toggled}`}
          />
        </button>
      </div>
      <p className={S.headerText}>{t('11061')}</p>
      <p className={S.headerText}>{t('11060')}</p>
      <p className={S.headerText}>peron</p>
      <p className={S.headerText}>{t('17046')}</p>
    </div>
  );
};

export default MyTicketsRouteModalGridHeader;
