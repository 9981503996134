import {useTranslation} from 'react-i18next';
import Accordion from '../Accordion/Accordion';

import S from './Rodo.module.scss';

const Rodo = () => {
  const {t} = useTranslation();
  return (
    <Accordion header={t('10050')} isHeaderUnderlined={false} headerAltStyle>
      <p className={S.rules} dangerouslySetInnerHTML={{__html: t('10051')}} />
      <p className={S.rules}>{t('10052')}</p>
      <p className={S.rules}>{t('10053')}</p>
    </Accordion>
  );
};
export default Rodo;
