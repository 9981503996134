import {IPaymentForm, IPaymentInvoiceData} from 'interfaces/forms';
import {IUserAddress} from '../api/user/interfaces';

export const getPaymentFormInitialData = (name: string): IPaymentForm => {
  return {
    name: name || '',
    invoiceData: null,
    rules: false,
    paymentMethod: '',
  };
};

export const invoiceFormInitialData: IPaymentInvoiceData = {
  invoice: false,
  invoiceEmailRules: false,
  invoiceType: 'person',
  personName: '',
  personSurname: '',
  personStreet: '',
  personHouseNumber: '',
  personLocalNumber: '',
  personZipCode: '',
  personCity: '',
  personCountry: 'Polska',
  isNotPolishNIP: false,
  companyNip: '',
  companyName: '',
  companyStreet: '',
  companyHouseNumber: '',
  companyLocalNumber: '',
  companyZipCode: '',
  companyCity: '',
  companyCountry: '',
};

export const initialPersonInvoiceData = {
  personName: '',
  personSurname: '',
  personStreet: '',
  personHouseNumber: '',
  personLocalNumber: '',
  personZipCode: '',
  personCity: '',
  personCountry: 'Polska',
};

export const initialCompanyInvoiceData = {
  isNotPolishNIP: false,
  companyNip: '',
  companyName: '',
  companyStreet: '',
  companyHouseNumber: '',
  companyLocalNumber: '',
  companyZipCode: '',
  companyCity: '',
  companyCountry: '',
};

export const getInvoiceFormFromUserData = (address: IUserAddress): IPaymentInvoiceData => {
  const isCompany = address.addressType.id === 2 || address.addressType.id === 3;
  return {
    invoice: true,
    invoiceEmailRules: true,
    invoiceType: isCompany ? 'company' : 'person',
    personName: !isCompany ? address.firstName : '',
    personSurname: !isCompany ? address.lastName : '',
    personStreet: !isCompany ? address.street : '',
    personHouseNumber: !isCompany ? address.houseNumber : '',
    personLocalNumber: !isCompany ? address.apartmentNumber : '',
    personZipCode: !isCompany ? address.zipCode : '',
    personCity: !isCompany ? address.city : '',
    personCountry: !isCompany ? address.country.name : 'Polska',
    isNotPolishNIP: address.foreignNip,
    companyNip: isCompany ? address.nip : '',
    companyName: isCompany ? address.name : '',
    companyStreet: isCompany ? address.street : '',
    companyHouseNumber: isCompany ? address.houseNumber : '',
    companyLocalNumber: isCompany ? address.apartmentNumber : '',
    companyZipCode: isCompany ? address.zipCode : '',
    companyCity: isCompany ? address.city : '',
    companyCountry: isCompany ? address.country.name : '',
  };
};
