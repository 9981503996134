import React from 'react';
import {Response} from 'common/api';
import {useMyTicketState, useMyTicketDispatch} from './useTicket';
import {useMyTicketsState} from './useTickets';
import {MyTicket, MyTicketIdentifier} from 'common/api/myTickets';

/* 
  The hook was created to refresh the ticket details on the mobile version because the ticket details are taken from the context,
  which is updated when the ticket tile is clicked, so when the ticket details are open, it was not possible to refresh the data.
*/

export const useRefreshTicketDetails = (identifier: MyTicketIdentifier, ticket: Response<'bilety', MyTicket>) => {
  const {identifier: staleIdentifier, ticket: staleTicket} = useMyTicketState();
  const {setTicketDetails} = useMyTicketDispatch();
  const {isMobileVersion} = useMyTicketsState();

  React.useEffect(() => {
    if (isMobileVersion && staleIdentifier?.transakcjaNr === identifier.transakcjaNr && staleTicket) {
      setTicketDetails(identifier, ticket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileVersion, identifier, ticket]);
};
