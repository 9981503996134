import {useTranslation} from 'react-i18next';
import packageJson from '../../../../package.json';
import S from './VersionInfo.module.scss';

const VersionInfo = () => {
  const {t} = useTranslation();
  return (
    <div className={`${S.versionInfo_container}`}>
      {t('29286')} {packageJson.version}
    </div>
  );
};

export default VersionInfo;
