import {
  Header,
  Paragraph,
  TextBox,
  RemovalConfirmationModal,
  Confirmation,
  ButtonRow,
  CloseButtonWrapper,
  ContentRow,
} from '../RemoveTicketModal/RemoveTicketModal.style';
import {Text} from 'common/components/base/Text';
import {Box, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ModalContainer} from 'common/components/base/Modal';
import Icon from 'common/components/base/Icon';
import OutlineButton from 'components/common/OutlineButton';
import ConfirmButton from 'components/common/ConfirmButton';
import {useWindowResize} from 'common/hooks/screen';
import {useState} from 'react';
import {format} from 'date-fns';
import {IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import {ChangeTransportOperation, DocumentType} from 'api/dokumenty/interfaces';
import documentsApi from 'api/dokumenty/repository';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';

type Props = {
  ticket: IDownloadTicketResponseSingleTicket;
  onClose: () => void;
  ticketType: string;
  exitFromDeletedTicketModal?: () => void;
};

const RemoveTicketModal = ({ticket, onClose, ticketType, exitFromDeletedTicketModal}: Props) => {
  const {t} = useTranslation();
  const {invalidateSeasonalTickets, invalidateMyTicket} = useInvalidateMyTicketsQueries();
  const {isMobile} = useWindowResize();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const isNoState = !success && !error;

  const {biletSeria: ticketSeries, biletNr: ticketNumber, sprzedazData: saleDate, ofertaKod: offerCode} = ticket;
  const date = format(new Date(), 'dd.MM.yyyy HH:mm:ss');

  const formattedDate = saleDate.slice(0, 10);
  const request = {
    operacja: ChangeTransportOperation.Delete,
    typDokumentu: DocumentType.NationalDocumentManual,
    seriaDokumentu: ticketSeries,
    numerDokumentu: ticketNumber,
    dataSprzedazy: formattedDate,
    numerOferty: offerCode,
  };
  const removeManuallyAddedTicket = async () => {
    try {
      const response = await documentsApi.deleteTransportDocument(request);
      if (response && response?.bledy && response.bledy.length === 0) {
        setSuccess(true);
        invalidateMyTicket(null, ticketSeries, ticketNumber);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };
  const handleSeasonTicketsReload = () => (success ? invalidateSeasonalTickets() : null);

  const handleConfirmButton = () => {
    if (isNoState) {
      removeManuallyAddedTicket();
    } else {
      exitFromDeletedTicketModal && exitFromDeletedTicketModal();
      onClose();
    }
    handleSeasonTicketsReload();
  };
  const handleXButton = () => {
    if (isNoState) {
      onClose();
    } else {
      onClose();
      handleSeasonTicketsReload();
      exitFromDeletedTicketModal && exitFromDeletedTicketModal();
    }
  };
  const handleConfirmButtonStyle = () => {
    if (!isMobile) {
      return isNoState ? '100%' : '38.5rem';
    } else {
      return '100%';
    }
  };
  return (
    <ModalContainer>
      <RemovalConfirmationModal>
        <ContentRow>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Header>
                <Text variant={isMobile ? 'H4' : 'H2'}>{t('13137')}</Text>
              </Header>
            </Grid>
            <Grid item>
              <CloseButtonWrapper onClick={handleXButton}>
                <Icon icon="X" />
              </CloseButtonWrapper>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" gap={isMobile ? '1.6rem' : '3.2rem'} marginBottom="3.2rem">
            <Grid item>
              <Icon icon="QR" variant={isMobile ? 'huge' : undefined} />
            </Grid>

            <Grid item>
              <Paragraph>
                <Text variant={isMobile ? 'P' : 'H4'}>{ticketType}</Text>
              </Paragraph>
              <Paragraph>
                <Text palette="text.secondary" variant={isMobile ? 'P' : 'H4'}>
                  {ticketSeries}
                  {ticketNumber}
                </Text>
              </Paragraph>
            </Grid>
          </Grid>

          {success ? (
            <Grid container gap="4px" marginBottom="4rem">
              <Grid item xs={12}>
                <Paragraph>
                  <Text variant={isMobile ? 'P' : 'H2'}>Wpis został usunięty z Twojego konta</Text>
                </Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>
                  <Text variant={isMobile ? 'P' : 'H2'} weight={600}>
                    {date}
                  </Text>
                </Paragraph>
              </Grid>
            </Grid>
          ) : null}

          {error ? (
            <Box marginBottom="3.2rem">
              <Paragraph>
                <Text variant={isMobile ? 'P' : 'H2'} palette="error.default">
                  {t('29168')}
                </Text>
              </Paragraph>
            </Box>
          ) : null}

          {isNoState ? (
            <>
              <Paragraph>
                <Text palette="text.secondary" variant={isMobile ? 'P' : 'H4'}>
                  {t('29353')}
                </Text>
              </Paragraph>
              <Paragraph>
                <TextBox>
                  <Text variant={isMobile ? 'P' : 'H4'}>{t('13138')}</Text>
                </TextBox>
              </Paragraph>
              <Box marginTop="3.2rem">
                <Confirmation>
                  <Text variant={isMobile ? 'P3' : 'H3'} weight={700}>
                    {t('29146')}
                  </Text>
                </Confirmation>
              </Box>
            </>
          ) : null}
        </ContentRow>

        <ButtonRow>
          <Box width={handleConfirmButtonStyle()}>
            <ConfirmButton execute={handleConfirmButton} text={isNoState ? t('29355') : t('29002')} />
          </Box>
          {isNoState ? (
            <OutlineButton path={onClose} text={t('29354')} variant={isMobile ? 'smaller' : undefined} />
          ) : null}
        </ButtonRow>
      </RemovalConfirmationModal>
    </ModalContainer>
  );
};

export default RemoveTicketModal;
