export interface InvoiceCountryOption {
  namePL: string;
  nameEN: string;
  descriptionPL: string;
  descriptionEN: string;
  code: string;
  isEU: boolean;
}

export const invoiceCountriesList: InvoiceCountryOption[] = [
  {
    namePL: 'Andorra',
    descriptionPL: 'Księstwo Andory',
    nameEN: 'Andorra',
    descriptionEN: 'Principality of Andorra',
    code: 'AD',
    isEU: false,
  },
  {
    namePL: 'Zjednoczone Emiraty Arabskie',
    descriptionPL: 'Zjednoczone Emiraty Arabskie',
    nameEN: 'United Arab Emirates',
    descriptionEN: 'United Arab Emirates',
    code: 'AE',
    isEU: false,
  },
  {
    namePL: 'Afganistan',
    descriptionPL: 'Islamska Republika Afganistanu',
    nameEN: 'Afghanistan',
    descriptionEN: 'The Transitional Islamic State of Afghanistan',
    code: 'AF',
    isEU: false,
  },
  {
    namePL: 'Antigua i Barbuda',
    descriptionPL: 'Antigua i Barbuda (z Wyspą Redonda)',
    nameEN: 'Antigua and Barbuda',
    descriptionEN: 'Antigua and Barbuda (includes Redonda Island)',
    code: 'AG',
    isEU: false,
  },
  {
    namePL: 'Anguilla',
    descriptionPL: 'Anguilla',
    nameEN: 'Anguilla',
    descriptionEN: 'Anguilla',
    code: 'AI',
    isEU: false,
  },
  {
    namePL: 'Albania',
    descriptionPL: 'Republika Albanii',
    nameEN: 'Albania',
    descriptionEN: 'Republic of Albania',
    code: 'AL',
    isEU: false,
  },
  {
    namePL: 'Armenia',
    descriptionPL: 'Republika Armenii',
    nameEN: 'Armenia',
    descriptionEN: 'Republic of Armenia',
    code: 'AM',
    isEU: false,
  },
  {
    namePL: 'Antyle Holenderskie',
    descriptionPL: 'Antyle Holenderskie (z wyspami Bonaire, Curacao, Saba, St. Eustatius i Południowy St. Martin)',
    nameEN: 'Netherlands Antilles',
    descriptionEN: 'Netherlands Antilles (includes Bonaire, Curacao, Saba, St. Eustatius, and Southern St. Martin)',
    code: 'AN',
    isEU: false,
  },
  {
    namePL: 'Angola',
    descriptionPL: 'Republika Angoli (z Cabinda)',
    nameEN: 'Angola',
    descriptionEN: 'Republic of Angola (includes Cabinda)',
    code: 'AO',
    isEU: false,
  },
  {
    namePL: 'Antarktyda',
    descriptionPL: 'Obszary południowe poniżej 60 równoleżnika',
    nameEN: 'Antarctica',
    descriptionEN: 'Territory south of 60 degrees south latitude',
    code: 'AQ',
    isEU: false,
  },
  {
    namePL: 'Argentyna',
    descriptionPL: 'Republika Argentyny',
    nameEN: 'Argentina',
    descriptionEN: 'Argentine Republic',
    code: 'AR',
    isEU: false,
  },
  {
    namePL: 'Samoa Amerykańskie',
    descriptionPL: 'Samoa Amerykańskie (główna wyspa - Tutuila, z Wyspą Swaina)',
    nameEN: 'American Samoa',
    descriptionEN: "America Samoa (principal island Tutuila and includes Swain's Island)",
    code: 'AS',
    isEU: false,
  },
  {
    namePL: 'Austria',
    descriptionPL: 'Republika Austrii',
    nameEN: 'Austria',
    descriptionEN: 'Republic of Austria',
    code: 'AT',
    isEU: true,
  },
  {
    namePL: 'Australia',
    descriptionPL:
      'Australia (z Wyspą Lord Howe, Wyspami Macquire, Wyspami Ashmore, Wyspą Cartier i Wyspami Morza Koralowego, które tworzą australijskie obszary zewnętrzne)',
    nameEN: 'Australia',
    descriptionEN:
      'Australia (includes Lord Howe Island, Macquarie Islands, Ashmore Islands and Cartier Island, and Coral Sea Islands are Australian external territories)',
    code: 'AU',
    isEU: false,
  },
  {
    namePL: 'Aruba',
    descriptionPL: 'Aruba',
    nameEN: 'Aruba',
    descriptionEN: 'Aruba',
    code: 'AW',
    isEU: false,
  },
  {
    namePL: 'Wyspy Alandzkie',
    descriptionPL: 'Wyspy Alandzkie',
    nameEN: 'Aland Islands',
    descriptionEN: 'Aland Islands',
    code: 'AX',
    isEU: false,
  },
  {
    namePL: 'Azerbejdżan',
    descriptionPL: 'Republika Azerbejdżanu',
    nameEN: 'Azerbaijan',
    descriptionEN: 'Republic of Azerbaijan',
    code: 'AZ',
    isEU: false,
  },
  {
    namePL: 'Bośnia i Hercegowina',
    descriptionPL: 'Bośnia i Hercegowina',
    nameEN: 'Bosnia and Herzegovina',
    descriptionEN: 'Bosnia and Herzegovina',
    code: 'BA',
    isEU: false,
  },
  {
    namePL: 'Barbados',
    descriptionPL: 'Barbados',
    nameEN: 'Barbados',
    descriptionEN: 'Barbados',
    code: 'BB',
    isEU: false,
  },
  {
    namePL: 'Bangladesz',
    descriptionPL: 'Republika Ludowa Bangladeszu',
    nameEN: 'Bangladesh',
    descriptionEN: "People's Republic of Bangladesh",
    code: 'BD',
    isEU: false,
  },
  {
    namePL: 'Belgia',
    descriptionPL: 'Królestwo Belgii',
    nameEN: 'Belgium',
    descriptionEN: 'Kingdom of Belgium',
    code: 'BE',
    isEU: true,
  },
  {
    namePL: 'Burkina Faso',
    descriptionPL: 'Burkina Faso',
    nameEN: 'Burkina Faso',
    descriptionEN: 'Burkina Faso',
    code: 'BF',
    isEU: false,
  },
  {
    namePL: 'Bułgaria',
    descriptionPL: 'Republika Bułgarii',
    nameEN: 'Bulgaria',
    descriptionEN: 'Republic of Bulgaria',
    code: 'BG',
    isEU: true,
  },
  {
    namePL: 'Bahrajn',
    descriptionPL: 'Królestwo Bahrajnu',
    nameEN: 'Bahrain',
    descriptionEN: 'Kingdom of Bahrain',
    code: 'BH',
    isEU: false,
  },
  {
    namePL: 'Burundi',
    descriptionPL: 'Republika Burundii',
    nameEN: 'Burundi',
    descriptionEN: 'Republic of Burundi',
    code: 'BI',
    isEU: false,
  },
  {
    namePL: 'Benin',
    descriptionPL: 'Republika Beninu',
    nameEN: 'Benin',
    descriptionEN: 'Republic of Benin',
    code: 'BJ',
    isEU: false,
  },
  {
    namePL: 'Saint Barthelemy',
    descriptionPL: 'Saint Barthelemy',
    nameEN: 'Saint Barthelemy',
    descriptionEN: 'Saint Barthelemy',
    code: 'BL',
    isEU: false,
  },
  {
    namePL: 'Bermudy',
    descriptionPL: 'Bermudy',
    nameEN: 'Bermuda',
    descriptionEN: 'Bermuda',
    code: 'BM',
    isEU: false,
  },
  {
    namePL: 'Brunei Darussalam',
    descriptionPL: 'Brunei Darussalam',
    nameEN: 'Brunei Darussalam',
    descriptionEN: 'Brunei Darussalam',
    code: 'BN',
    isEU: false,
  },
  {
    namePL: 'Boliwia',
    descriptionPL: 'Boliwia',
    nameEN: 'Bolivia, Plurinational State of',
    descriptionEN: 'Plurinational State of Bolivia',
    code: 'BO',
    isEU: false,
  },
  {
    namePL: 'Brazylia',
    descriptionPL: 'Federacyjna Republika Brazylii (z wyspami Fernando de Noronha, Martim Vaz i Trindade)',
    nameEN: 'Brazil',
    descriptionEN:
      'Federative Republic of Brazil (includes Fernando de Noronha Island, Martim Vaz Islands,  and Trindade Island)',
    code: 'BR',
    isEU: false,
  },
  {
    namePL: 'Wyspy Bahama',
    descriptionPL: 'Wyspy Bahama',
    nameEN: 'Bahamas',
    descriptionEN: 'Commonwealth of the Bahamas',
    code: 'BS',
    isEU: false,
  },
  {
    namePL: 'Bhutan',
    descriptionPL: 'Królestwo Bhutan',
    nameEN: 'Bhutan',
    descriptionEN: 'Kingdom of Bhutan',
    code: 'BT',
    isEU: false,
  },
  {
    namePL: 'Wyspa Bouveta',
    descriptionPL: 'Wyspa Bouveta',
    nameEN: 'Bouvet Island',
    descriptionEN: 'Bouvet Island',
    code: 'BV',
    isEU: false,
  },
  {
    namePL: 'Botswana',
    descriptionPL: 'Republika Botswany',
    nameEN: 'Botswana',
    descriptionEN: 'Republic of Botswana',
    code: 'BW',
    isEU: false,
  },
  {
    namePL: 'Białoruś',
    descriptionPL: 'Republika Białorusi',
    nameEN: 'Belarus',
    descriptionEN: 'Republic of Belarus',
    code: 'BY',
    isEU: false,
  },
  {
    namePL: 'Belize',
    descriptionPL: 'Belize',
    nameEN: 'Belize',
    descriptionEN: 'Belize',
    code: 'BZ',
    isEU: false,
  },
  {
    namePL: 'Kanada',
    descriptionPL: 'Kanada',
    nameEN: 'Canada',
    descriptionEN: 'Canada',
    code: 'CA',
    isEU: false,
  },
  {
    namePL: 'Wyspy Kokosowe',
    descriptionPL: 'Wyspy Kokosowe',
    nameEN: 'Cocos (Keeling) Islands',
    descriptionEN: 'Cocos (Keeling) Islands',
    code: 'CC',
    isEU: false,
  },
  {
    namePL: 'Demokratyczna Republika Konga',
    descriptionPL: 'Republika Demokratyczna Konga (poprzedni zapis - Republika Zairu)',
    nameEN: 'Congo, The Democratic Republic of the',
    descriptionEN: 'The Democratic Republic of the Congo (previous entry was Republic of Zaire)',
    code: 'CD',
    isEU: false,
  },
  {
    namePL: 'Republika Środkowej Afryki',
    descriptionPL: 'Republika Środkowej Afryki',
    nameEN: 'Central African Republic',
    descriptionEN: 'Central African Republic',
    code: 'CF',
    isEU: false,
  },
  {
    namePL: 'Kongo',
    descriptionPL: 'Republika Konga',
    nameEN: 'Congo',
    descriptionEN: 'Republic of the Congo',
    code: 'CG',
    isEU: false,
  },
  {
    namePL: 'Szwajcaria',
    descriptionPL: 'Konfederacja Szwajcarii',
    nameEN: 'Switzerland',
    descriptionEN: 'Swiss Confederation',
    code: 'CH',
    isEU: false,
  },
  {
    namePL: 'Wybrzeże Kości Słoniowej',
    descriptionPL: 'Republika Wybrzeża Kości Słoniowej',
    nameEN: "Cote d'Ivoire",
    descriptionEN: "Republic of Cote d'Ivoire",
    code: 'CI',
    isEU: false,
  },
  {
    namePL: 'Wyspy Cooka',
    descriptionPL: 'Wyspy Cooka (główna wyspa Rarotonga)',
    nameEN: 'Cook Islands',
    descriptionEN: 'Cook Islands (principal island Rarotonga)',
    code: 'CK',
    isEU: false,
  },
  {
    namePL: 'Chile',
    descriptionPL:
      'Republika Chile (z Wyspą Wielkanocną oraz wyspami Juan Fernandez, Sala y Gomez, San Ambrosio i San Felix)',
    nameEN: 'Chile',
    descriptionEN:
      'Republic of Chile (includes Easter Island, Juan Fernandez Islands, Sala y Gomez Island, San Ambrosio Island, and San Felix Island)',
    code: 'CL',
    isEU: false,
  },
  {
    namePL: 'Kamerun',
    descriptionPL: 'Republika Kamerunu',
    nameEN: 'Cameroon',
    descriptionEN: 'Republic of Cameroon',
    code: 'CM',
    isEU: false,
  },
  {
    namePL: 'Chiny',
    descriptionPL: 'Chińska Republika Ludowa (patrz również: Tajwan, prowincja Chin)',
    nameEN: 'China',
    descriptionEN: "People's Republic of China (see also TAIWAN, PROVINCE OF CHINA)",
    code: 'CN',
    isEU: false,
  },
  {
    namePL: 'Kolumbia',
    descriptionPL: 'Republika Kolumbii (z Wyspą Malpelo i wyspami San Andres y Providencia)',
    nameEN: 'Colombia',
    descriptionEN: 'Republic of Colombia (includes Malpelo Island, San Andres y Providencia Islands)',
    code: 'CO',
    isEU: false,
  },
  {
    namePL: 'Kostaryka',
    descriptionPL: 'Republika Kostaryki (z Wyspami Kokosowymi)',
    nameEN: 'Costa Rica',
    descriptionEN: 'Republic of Costa Rica (includes Coco Island)',
    code: 'CR',
    isEU: false,
  },
  {
    namePL: 'Kuba',
    descriptionPL: 'Republika Kuby',
    nameEN: 'Cuba',
    descriptionEN: 'Republic of Cuba',
    code: 'CU',
    isEU: false,
  },
  {
    namePL: 'Zielony Przylądek',
    descriptionPL: 'Republika Zielonego Przylądka (główne wyspy - Sao Tiago i Sao Vincente)',
    nameEN: 'Cape Verde',
    descriptionEN: 'Republic of Cape Verde (principal islands Sao Tiago and Sao Vincente)',
    code: 'CV',
    isEU: false,
  },
  {
    namePL: 'Wyspa Bożego Narodzenia',
    descriptionPL: 'Wyspa Bożego Narodzenia',
    nameEN: 'Christmas Island',
    descriptionEN: 'Christmas Island',
    code: 'CX',
    isEU: false,
  },
  {
    namePL: 'Cypr',
    descriptionPL: 'Republika Cypru',
    nameEN: 'Cyprus',
    descriptionEN: 'Republic of Cyprus',
    code: 'CY',
    isEU: true,
  },
  {
    namePL: 'Republika Czeska',
    descriptionPL: 'Republika Czeska',
    nameEN: 'Czech Republic',
    descriptionEN: 'Czech Republic',
    code: 'CZ',
    isEU: true,
  },
  {
    namePL: 'Niemcy',
    descriptionPL: 'Republika Federalna Niemiec',
    nameEN: 'Germany',
    descriptionEN: 'Federal Republic of Germany',
    code: 'DE',
    isEU: true,
  },
  {
    namePL: 'Dżibuti',
    descriptionPL: 'Republika Dżibutti',
    nameEN: 'Djibouti',
    descriptionEN: 'Republic of Djibouti',
    code: 'DJ',
    isEU: false,
  },
  {
    namePL: 'Dania',
    descriptionPL: 'Królestwo Danii',
    nameEN: 'Denmark',
    descriptionEN: 'Kingdom of Denmark',
    code: 'DK',
    isEU: true,
  },
  {
    namePL: 'Dominikana',
    descriptionPL: 'Dominikana',
    nameEN: 'Dominica',
    descriptionEN: 'Commonwealth of Dominica',
    code: 'DM',
    isEU: false,
  },
  {
    namePL: 'Republika Dominikany',
    descriptionPL: 'Republika Dominikany',
    nameEN: 'Dominican Republic',
    descriptionEN: 'Dominican Republic',
    code: 'DO',
    isEU: false,
  },
  {
    namePL: 'Algieria',
    descriptionPL: 'Ludowo-Demokratyczna Republika Algierii',
    nameEN: 'Algeria',
    descriptionEN: "People's Democratic Republic of Algeria",
    code: 'DZ',
    isEU: false,
  },
  {
    namePL: 'Ekwador',
    descriptionPL: 'Republika Ekwadoru (z Wyspami Galapagos (Archipelag Colon))',
    nameEN: 'Ecuador',
    descriptionEN: 'Republic of Ecuador (includes Galapagos Islands (Archipelago de Colon))',
    code: 'EC',
    isEU: false,
  },
  {
    namePL: 'Estonia',
    descriptionPL: 'Republika Estonii',
    nameEN: 'Estonia',
    descriptionEN: 'Republic of Estonia',
    code: 'EE',
    isEU: true,
  },
  {
    namePL: 'Egipt',
    descriptionPL: 'Arabska Republika Egiptu',
    nameEN: 'Egypt',
    descriptionEN: 'Arab Republic of Egypt',
    code: 'EG',
    isEU: false,
  },
  {
    namePL: 'Sahara Zachodnia',
    descriptionPL: 'Sahara Zachodnia',
    nameEN: 'Western Sahara',
    descriptionEN: 'Western Sahara',
    code: 'EH',
    isEU: false,
  },
  {
    namePL: 'Erytrea',
    descriptionPL: 'Erytrea',
    nameEN: 'Eritrea',
    descriptionEN: 'Eritrea',
    code: 'ER',
    isEU: false,
  },
  {
    namePL: 'Hiszpania',
    descriptionPL: 'Królestwo Hiszpanii',
    nameEN: 'Spain',
    descriptionEN: 'Kingdom of Spain',
    code: 'ES',
    isEU: true,
  },
  {
    namePL: 'Etiopia',
    descriptionPL: 'Federalno-Demokratyczna Republika Etiopii',
    nameEN: 'Ethiopia',
    descriptionEN: 'Federal Democratic Republic of Ethiopia',
    code: 'ET',
    isEU: false,
  },
  {
    namePL: 'Finlandia',
    descriptionPL: 'Republika Finlandii (z Aland)',
    nameEN: 'Finland',
    descriptionEN: 'Republic of Finland (includes Aland)',
    code: 'FI',
    isEU: true,
  },
  {
    namePL: 'Fidżi',
    descriptionPL: 'Republika Wysp Fidżi (główne wyspy: Vanua Levu i Viti Levu, w tym wyspa Rotuma)',
    nameEN: 'Fiji',
    descriptionEN: 'Republic of the Fiji Islands (principal islands Vanua Levu and Viti Levu, Includes Rotuma Island)',
    code: 'FJ',
    isEU: false,
  },
  {
    namePL: 'Wyspy Falklandzkie (Malwiny)',
    descriptionPL: 'Wyspy Falklandzkie (Malwiny)',
    nameEN: 'Falkland Islands (Malvinas)',
    descriptionEN: 'Falkland Islands (Malvinas)',
    code: 'FK',
    isEU: false,
  },
  {
    namePL: 'Federacja Mikronezji',
    descriptionPL:
      'Mikronezja z Wyspami Karoliny (bez Palau, patrz osobny zapis), główne wyspy - Chuuk, Kosrae, Pohnpei i Yap',
    nameEN: 'Micronesia, Federated States of',
    descriptionEN:
      'Micronesia, Federated States of (includes Caroline Islands (except Palau, see seperate entry), principal islands Chuuk, Kosrae, Pohnpei, and Yap)',
    code: 'FM',
    isEU: false,
  },
  {
    namePL: 'Wyspy Owcze',
    descriptionPL: 'Wyspy Owcze',
    nameEN: 'Faroe Islands',
    descriptionEN: 'Faroe Islands',
    code: 'FO',
    isEU: false,
  },
  {
    namePL: 'Francja',
    descriptionPL:
      'Republika Francji (wraz z Gujaną Fr., Gwadelupą, Martyniką, Reunion, Mayotte, St Barthelemy, St Martin, St Pierre, Miquelon, Polinezją Fr., Francuskimi Terytoriami Południowymi, Nową Kaledonią, Wyspami Wallis i futura i Clipperton)',
    nameEN: 'France',
    descriptionEN:
      'French Republic (includes French Guiana, Guadeloupe, Martinique, Reunion, Mayotte, St. Barthelemy, St. Martin, St. Pierre, Miquelon, French Polynesia, French Southern Territories, New Caledonia, Wallis and Futuna, Clipperton Island)',
    code: 'FR',
    isEU: true,
  },
  {
    namePL: 'Gabon',
    descriptionPL: 'Republika Gabonu',
    nameEN: 'Gabon',
    descriptionEN: 'Gabonese Republic',
    code: 'GA',
    isEU: false,
  },
  {
    namePL: 'Wielka Brytania',
    descriptionPL: 'Zjednoczone Królestwo Wielkiej Brytanii i Irlandii Północnej',
    nameEN: 'United Kingdom',
    descriptionEN: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
    isEU: false,
  },
  {
    namePL: 'Grenada',
    descriptionPL: 'Grenada (z Południowymi Wyspami Grenady(główna wyspa Carriacou))',
    nameEN: 'Grenada',
    descriptionEN: 'Grenada (includes Southen Grenadine Islands (principal island Carriacou))',
    code: 'GD',
    isEU: false,
  },
  {
    namePL: 'Gruzja',
    descriptionPL: 'Gruzja',
    nameEN: 'Georgia',
    descriptionEN: 'Georgia',
    code: 'GE',
    isEU: false,
  },
  {
    namePL: 'Gujana Francuska',
    descriptionPL: 'Gujana Francuska',
    nameEN: 'French Guiana',
    descriptionEN: 'French Guiana',
    code: 'GF',
    isEU: false,
  },
  {
    namePL: 'Wyspa Guernsey',
    descriptionPL: 'Wyspa Guernsey',
    nameEN: 'Guernsey',
    descriptionEN: 'Guernsey',
    code: 'GG',
    isEU: false,
  },
  {
    namePL: 'Ghana',
    descriptionPL: 'Republika Ghany',
    nameEN: 'Ghana',
    descriptionEN: 'Republic of Ghana',
    code: 'GH',
    isEU: false,
  },
  {
    namePL: 'Gibraltar',
    descriptionPL: 'Gibraltar',
    nameEN: 'Gibraltar',
    descriptionEN: 'Gibraltar',
    code: 'GI',
    isEU: false,
  },
  {
    namePL: 'Grenlandia',
    descriptionPL: 'Grenlandia',
    nameEN: 'Greenland',
    descriptionEN: 'Greenland',
    code: 'GL',
    isEU: false,
  },
  {
    namePL: 'Gambia',
    descriptionPL: 'Republika Gambii',
    nameEN: 'Gambia',
    descriptionEN: 'Republic of the Gambia',
    code: 'GM',
    isEU: false,
  },
  {
    namePL: 'Gwinea',
    descriptionPL: 'Republika Gwinei',
    nameEN: 'Guinea',
    descriptionEN: 'Republic of Guinea',
    code: 'GN',
    isEU: false,
  },
  {
    namePL: 'Gwadelupa',
    descriptionPL: 'Gwadelupa (wraz z la Desirade, Marie-Galante, les Saintes)',
    nameEN: 'Guadeloupe',
    descriptionEN: 'Guadeloupe (includes la Desirade, Marie-Galante, les Saintes)',
    code: 'GP',
    isEU: false,
  },
  {
    namePL: 'Gwinea Równikowa',
    descriptionPL:
      'Republika Gwinei Równikowej (złożona z Wyspy Annobon, Wyspy Bioko i Obszaru Kontynentalnego (Rio Muni))',
    nameEN: 'Equatorial Guinea',
    descriptionEN:
      'Republic of Equatorial Guinea (comprises Annobon Island, Bioko Island, and the Continental Region (Rio Muni))',
    code: 'GQ',
    isEU: false,
  },
  {
    namePL: 'Grecja',
    descriptionPL: 'Republika Grecka (z autonomiczną strefą Góry Athos)',
    nameEN: 'Greece',
    descriptionEN: 'Hellenic Republic (includes Mount Athos autonomous area)',
    code: 'EL',
    isEU: true,
  },
  {
    namePL: 'Georgia Południowa i Wyspy South Sandwich',
    descriptionPL: 'Georgia Południowa i Wyspy South Sandwich',
    nameEN: 'South Georgia and the South Sandwich Islands',
    descriptionEN: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    isEU: false,
  },
  {
    namePL: 'Gwatemala',
    descriptionPL: 'Republika Gwatemali',
    nameEN: 'Guatemala',
    descriptionEN: 'Republic of Guatemala',
    code: 'GT',
    isEU: false,
  },
  {
    namePL: 'Guam',
    descriptionPL: 'Guam',
    nameEN: 'Guam',
    descriptionEN: 'Guam',
    code: 'GU',
    isEU: false,
  },
  {
    namePL: 'Gwinea-Bissau',
    descriptionPL: 'Republika Gwinea-Bissau',
    nameEN: 'Guinea-Bissau',
    descriptionEN: 'Republic of Guinea-Bissau',
    code: 'GW',
    isEU: false,
  },
  {
    namePL: 'Gujana',
    descriptionPL: 'Republika Gujany',
    nameEN: 'Guyana',
    descriptionEN: 'Republic of Guyana',
    code: 'GY',
    isEU: false,
  },
  {
    namePL: 'Hongkong',
    descriptionPL: 'Hongkong - specjalny region administracyjny Chin',
    nameEN: 'Hong Kong',
    descriptionEN: 'Hong Kong Special Administrative Region of China',
    code: 'HK',
    isEU: false,
  },
  {
    namePL: 'Wyspa Hearda i Wyspy McDonalda',
    descriptionPL: 'Wyspa Hearda i Wyspy McDonalda',
    nameEN: 'Heard Island and McDonald Islands',
    descriptionEN: 'Heard Island and McDonald Islands',
    code: 'HM',
    isEU: false,
  },
  {
    namePL: 'Honduras',
    descriptionPL: 'Republika Hondurasu (z Wyspami Łabędzimi)',
    nameEN: 'Honduras',
    descriptionEN: 'Republic of Honduras (includes Swan Islands)',
    code: 'HN',
    isEU: false,
  },
  {
    namePL: 'Chorwacja',
    descriptionPL: 'Republika Chorwacji',
    nameEN: 'Croatia',
    descriptionEN: 'Republic of Croatia',
    code: 'HR',
    isEU: true,
  },
  {
    namePL: 'Haiti',
    descriptionPL: 'Republika Haiti',
    nameEN: 'Haiti',
    descriptionEN: 'Republic of Haiti',
    code: 'HT',
    isEU: false,
  },
  {
    namePL: 'Węgry',
    descriptionPL: 'Republika Węgier',
    nameEN: 'Hungary',
    descriptionEN: 'Republic of Hungary',
    code: 'HU',
    isEU: true,
  },
  {
    namePL: 'Indonezja',
    descriptionPL: 'Republika Indonezji',
    nameEN: 'Indonesia',
    descriptionEN: 'Republic of Indonesia',
    code: 'ID',
    isEU: false,
  },
  {
    namePL: 'Irlandia',
    descriptionPL: 'Irlandia',
    nameEN: 'Ireland',
    descriptionEN: 'Ireland',
    code: 'IE',
    isEU: true,
  },
  {
    namePL: 'Izrael',
    descriptionPL: 'Izrael',
    nameEN: 'Israel',
    descriptionEN: 'State of Israel',
    code: 'IL',
    isEU: false,
  },
  {
    namePL: 'Wyspa Man',
    descriptionPL: 'Wyspa Man',
    nameEN: 'Isle of Man',
    descriptionEN: 'Isle of Man',
    code: 'IM',
    isEU: false,
  },
  {
    namePL: 'Indie',
    descriptionPL:
      'Republika Indii (z Wyspami Amindivi, Wyspami Andaman, Wyspami Laccadive, Wyspą Minicoy i Wyspami Nicobar)',
    nameEN: 'India',
    descriptionEN:
      'Republic of India (includes Amindivi Islands, Andaman Islands, Laccadive Islands, Minicoy Island, and Nicobar Islands)',
    code: 'IN',
    isEU: false,
  },
  {
    namePL: 'Terytorium brytyjskie Oceanu Indyjskiego',
    descriptionPL: 'Terytorium brytyjskie Oceanu Indyjskiego (główna wyspa - Diego Garcia, z archipelagiem Czagos)',
    nameEN: 'British Indian Ocean Territory',
    descriptionEN: 'British Indian Ocean Territory (principal island Diego Garcia and includes Chagos Archipelago)',
    code: 'IO',
    isEU: false,
  },
  {
    namePL: 'Irak',
    descriptionPL: 'Republika Iraku',
    nameEN: 'Iraq',
    descriptionEN: 'Republic of Iraq',
    code: 'IQ',
    isEU: false,
  },
  {
    namePL: 'Islamska Republika Iranu',
    descriptionPL: 'Islamska Republika Iranu',
    nameEN: 'Iran, Islamic Republic of',
    descriptionEN: 'Islamic Republic of Iran',
    code: 'IR',
    isEU: false,
  },
  {
    namePL: 'Islandia',
    descriptionPL: 'Republika Islandii',
    nameEN: 'Iceland',
    descriptionEN: 'Republic of Iceland',
    code: 'IS',
    isEU: false,
  },
  {
    namePL: 'Włochy',
    descriptionPL: 'Republika Włoska',
    nameEN: 'Italy',
    descriptionEN: 'Italian Republic',
    code: 'IT',
    isEU: true,
  },
  {
    namePL: 'Wyspa Jersey',
    descriptionPL: 'Wyspa Jersey',
    nameEN: 'Jersey',
    descriptionEN: 'Jersey',
    code: 'JE',
    isEU: false,
  },
  {
    namePL: 'Jamajka',
    descriptionPL: 'Jamajka',
    nameEN: 'Jamaica',
    descriptionEN: 'Jamaica',
    code: 'JM',
    isEU: false,
  },
  {
    namePL: 'Jordania',
    descriptionPL: 'Haszymickie Królestwo Jordanii',
    nameEN: 'Jordan',
    descriptionEN: 'Hashemite Kingdom of Jordan',
    code: 'JO',
    isEU: false,
  },
  {
    namePL: 'Japonia',
    descriptionPL: 'Japonia',
    nameEN: 'Japan',
    descriptionEN: 'Japan',
    code: 'JP',
    isEU: false,
  },
  {
    namePL: 'Kenia',
    descriptionPL: 'Republika Kenii',
    nameEN: 'Kenya',
    descriptionEN: 'Republic of Kenya',
    code: 'KE',
    isEU: false,
  },
  {
    namePL: 'Kirgizja',
    descriptionPL: 'Republika Kirgizji',
    nameEN: 'Kyrgyzstan',
    descriptionEN: 'Kyrgyz Republic',
    code: 'KG',
    isEU: false,
  },
  {
    namePL: 'Kambodża',
    descriptionPL: 'Królestwo Kambodży',
    nameEN: 'Cambodia',
    descriptionEN: 'Kingdom of Cambodia',
    code: 'KH',
    isEU: false,
  },
  {
    namePL: 'Kiribati',
    descriptionPL: 'Republika Kiribati (Wyspy Gilberta, część archipelagu Wysp Line, Wyspy Feniks)',
    nameEN: 'Kiribati',
    descriptionEN: 'Republic of Kiribati (includes Gilbert Islands, Part of Line Islands, Phoenix Islands)',
    code: 'KI',
    isEU: false,
  },
  {
    namePL: 'Komory',
    descriptionPL: 'Unia Komorów (w tym wyspy Anjouan, Grande Comore, Moheli i inne)',
    nameEN: 'Comoros',
    descriptionEN: 'Union of the Comoros (includes Anjouan, Grande Comore, Moheli, and other islands)',
    code: 'KM',
    isEU: false,
  },
  {
    namePL: 'Saint Kitts i Nevis',
    descriptionPL: 'Saint Kitts i Nevis',
    nameEN: 'Saint Kitts and Nevis',
    descriptionEN: 'Saint Kitts and Nevis',
    code: 'KN',
    isEU: false,
  },
  {
    namePL: 'Koreańska Republika Ludowo-Demokratyczna',
    descriptionPL: 'Koreańska Republika Ludowo-Demokratyczna (często występująca jako Korea Północna)',
    nameEN: "Korea, Democratic People's Republic of",
    descriptionEN: "Korea, Democratic People's Republic of (often referred to as North Korea)",
    code: 'KP',
    isEU: false,
  },
  {
    namePL: 'Korea Południowa',
    descriptionPL: 'Republika Korei (często występująca jako Korea Południowa)',
    nameEN: 'Korea, Republic of',
    descriptionEN: 'Korea, Republic of (often referred to as South Korea)',
    code: 'KR',
    isEU: false,
  },
  {
    namePL: 'Kuwejt',
    descriptionPL: 'Kuwejt',
    nameEN: 'Kuwait',
    descriptionEN: 'State of Kuwait',
    code: 'KW',
    isEU: false,
  },
  {
    namePL: 'Kajmany',
    descriptionPL: 'Kajmany (główna wyspa Grand Cayman)',
    nameEN: 'Cayman Islands',
    descriptionEN: 'Cayman Islands (principal island Grand Cayman)',
    code: 'KY',
    isEU: false,
  },
  {
    namePL: 'Kazachstan',
    descriptionPL: 'Republika Kazachstanu',
    nameEN: 'Kazakhstan',
    descriptionEN: 'Republic of Kazakhstan',
    code: 'KZ',
    isEU: false,
  },
  {
    namePL: 'Ludowo-Demokratyczna Republika Laosu',
    descriptionPL: 'Ludowo-Demokratyczna Republika Laosu',
    nameEN: "Lao People's Democratic Republic",
    descriptionEN: "Lao People's Democratic Republic",
    code: 'LA',
    isEU: false,
  },
  {
    namePL: 'Liban',
    descriptionPL: 'Republika Libanu',
    nameEN: 'Lebanon',
    descriptionEN: 'Lebanese Republic',
    code: 'LB',
    isEU: false,
  },
  {
    namePL: 'Saint Lucia',
    descriptionPL: 'Saint Lucia',
    nameEN: 'Saint Lucia',
    descriptionEN: 'Saint Lucia',
    code: 'LC',
    isEU: false,
  },
  {
    namePL: 'Liechtenstein',
    descriptionPL: 'Księstwo Liechtenstein',
    nameEN: 'Liechtenstein',
    descriptionEN: 'Principality of Liechtenstein',
    code: 'LI',
    isEU: false,
  },
  {
    namePL: 'Sri Lanka',
    descriptionPL: 'Socjalistyczno-Demokratyczna Republika Sri Lanki',
    nameEN: 'Sri Lanka',
    descriptionEN: 'Democratic Socialist Republic of Sri Lanka',
    code: 'LK',
    isEU: false,
  },
  {
    namePL: 'Liberia',
    descriptionPL: 'Republika Liberii',
    nameEN: 'Liberia',
    descriptionEN: 'Republic of Liberia',
    code: 'LR',
    isEU: false,
  },
  {
    namePL: 'Lesoto',
    descriptionPL: 'Królestwo Lesotho',
    nameEN: 'Lesotho',
    descriptionEN: 'Kingdom of Lesotho',
    code: 'LS',
    isEU: false,
  },
  {
    namePL: 'Litwa',
    descriptionPL: 'Republika Litwy',
    nameEN: 'Lithuania',
    descriptionEN: 'Republic of Lithuania',
    code: 'LT',
    isEU: true,
  },
  {
    namePL: 'Luksemburg',
    descriptionPL: 'Wielkie Księstwo Luksemburga',
    nameEN: 'Luxembourg',
    descriptionEN: 'Grand Duchy of Luxembourg',
    code: 'LU',
    isEU: true,
  },
  {
    namePL: 'Łotwa',
    descriptionPL: 'Republika Łotwy',
    nameEN: 'Latvia',
    descriptionEN: 'Republic of Latvia',
    code: 'LV',
    isEU: true,
  },
  {
    namePL: 'Libijska Republika Arabska',
    descriptionPL: 'Liban',
    nameEN: 'Libyan Arab Jamahiriya',
    descriptionEN: "Socialist People's Libyan Arab Jamahiriya",
    code: 'LY',
    isEU: false,
  },
  {
    namePL: 'Maroko',
    descriptionPL: 'Królestwo Maroko',
    nameEN: 'Morocco',
    descriptionEN: 'Kingdom of Morocco',
    code: 'MA',
    isEU: false,
  },
  {
    namePL: 'Monako',
    descriptionPL: 'Księstwo Monako',
    nameEN: 'Monaco',
    descriptionEN: 'Principality of Monaco',
    code: 'MC',
    isEU: false,
  },
  {
    namePL: 'Mołdawia',
    descriptionPL: 'Republika Mołdawii',
    nameEN: 'Moldova',
    descriptionEN: 'Republic of Moldova',
    code: 'MD',
    isEU: false,
  },
  {
    namePL: 'Czarnogóra',
    descriptionPL: 'Czarnogóra',
    nameEN: 'Montenegro',
    descriptionEN: 'Montenegro',
    code: 'ME',
    isEU: false,
  },
  {
    namePL: 'Saint Martin (część francuska)',
    descriptionPL:
      'Saint Martin (część francuska) (Saint Martin jest podzielona na północną część należącą do Francji i południową należącą do Holandii)',
    nameEN: 'Saint Martin (French part)',
    descriptionEN:
      'Saint Martin (French part) (Saint Martin is divided into a northern French part and a southern Dutch part)',
    code: 'MF',
    isEU: false,
  },
  {
    namePL: 'Madagaskar',
    descriptionPL: 'Republika Madagaskaru',
    nameEN: 'Madagascar',
    descriptionEN: 'Republic of Madagascar',
    code: 'MG',
    isEU: false,
  },
  {
    namePL: 'Wyspy Marshalla',
    descriptionPL: 'Republika Wysp Marshalla (główne atole to Jaluit, Kwajalein, i Majuro)',
    nameEN: 'Marshall Islands',
    descriptionEN: 'Republic of the Marshall Islands (principal atolls are Jaluit, Kwajalein, and Majuro)',
    code: 'MH',
    isEU: false,
  },
  {
    namePL: 'Macedonia',
    descriptionPL: 'Macedonia',
    nameEN: 'Macedonia, The Former Yugoslav Republic of',
    descriptionEN: 'Macedonia, the Former Yugoslav Republic of',
    code: 'MK',
    isEU: false,
  },
  {
    namePL: 'Mali',
    descriptionPL: 'Republika Mali',
    nameEN: 'Mali',
    descriptionEN: 'Republic of Mali',
    code: 'ML',
    isEU: false,
  },
  {
    namePL: 'Myanmar',
    descriptionPL: 'Związek Myanmar',
    nameEN: 'Myanmar',
    descriptionEN: 'Union of Myanmar',
    code: 'MM',
    isEU: false,
  },
  {
    namePL: 'Mongolia',
    descriptionPL: 'Mongolia',
    nameEN: 'Mongolia',
    descriptionEN: 'Mongolia',
    code: 'MN',
    isEU: false,
  },
  {
    namePL: 'Makao',
    descriptionPL: 'Makao - specjalny region administracyjny Chin',
    nameEN: 'Macao',
    descriptionEN: 'Macao Special Administrative Region of China',
    code: 'MO',
    isEU: false,
  },
  {
    namePL: 'Mariany Północne',
    descriptionPL: 'Mariany Północne bez Guam (główna wyspa - Saipan)',
    nameEN: 'Northern Mariana Islands',
    descriptionEN: 'Commonwealth of the Northern Mariana Islands except Guam (principle Island is Saipan)',
    code: 'MP',
    isEU: false,
  },
  {
    namePL: 'Martynika',
    descriptionPL: 'Martynika',
    nameEN: 'Martinique',
    descriptionEN: 'Martinique',
    code: 'MQ',
    isEU: false,
  },
  {
    namePL: 'Mauretania',
    descriptionPL: 'Islamska Republika Mauretanii',
    nameEN: 'Mauritania',
    descriptionEN: 'Islamic Republic of Mauritania',
    code: 'MR',
    isEU: false,
  },
  {
    namePL: 'Montserrat',
    descriptionPL: 'Montserrat',
    nameEN: 'Montserrat',
    descriptionEN: 'Montserrat',
    code: 'MS',
    isEU: false,
  },
  {
    namePL: 'Malta',
    descriptionPL: 'Republika Malty',
    nameEN: 'Malta',
    descriptionEN: 'Republic of Malta',
    code: 'MT',
    isEU: true,
  },
  {
    namePL: 'Mauritius',
    descriptionPL: 'Republika Mauritius (z Wyspami Agalega, Ławicami Cargados Carajos i Wyspą Rodrigues)',
    nameEN: 'Mauritius',
    descriptionEN: 'Republic of Mauritius (includes Agalega Islands, Cargados Carajos Shoals, and Rodrigues Island)',
    code: 'MU',
    isEU: false,
  },
  {
    namePL: 'Malediwy',
    descriptionPL: 'Republika Malediwy',
    nameEN: 'Maldives',
    descriptionEN: 'Republic of Maldives',
    code: 'MV',
    isEU: false,
  },
  {
    namePL: 'Malawi',
    descriptionPL: 'Republika Malawi',
    nameEN: 'Malawi',
    descriptionEN: 'Republic of Malawi',
    code: 'MW',
    isEU: false,
  },
  {
    namePL: 'Meksyk',
    descriptionPL: 'Stany Zjednoczone Meksyku',
    nameEN: 'Mexico',
    descriptionEN: 'United Mexican States',
    code: 'MX',
    isEU: false,
  },
  {
    namePL: 'Malezja',
    descriptionPL: 'Malezja (z Półwyspem Malezja, Sabah i Sarawak)',
    nameEN: 'Malaysia',
    descriptionEN: 'Malaysia (includes Peninsular Malaysia, Sabah, and Sarawak)',
    code: 'MY',
    isEU: false,
  },
  {
    namePL: 'Mozambik',
    descriptionPL: 'Republika Mozambiku',
    nameEN: 'Mozambique',
    descriptionEN: 'Republic of Mozambique',
    code: 'MZ',
    isEU: false,
  },
  {
    namePL: 'Namibia',
    descriptionPL: 'Republika Namibii',
    nameEN: 'Namibia',
    descriptionEN: 'Republic of Namibia',
    code: 'NA',
    isEU: false,
  },
  {
    namePL: 'Nowa Kaledonia',
    descriptionPL: 'Nowa Kaledonia (z Wyspami Loyalty)',
    nameEN: 'New Caledonia',
    descriptionEN: 'New Caledonia (includes Loyalty Islands)',
    code: 'NC',
    isEU: false,
  },
  {
    namePL: 'Niger',
    descriptionPL: 'Republika Nigeru',
    nameEN: 'Niger',
    descriptionEN: 'Republic of the Niger',
    code: 'NE',
    isEU: false,
  },
  {
    namePL: 'Wyspa Norfolk',
    descriptionPL: 'Wyspa Norfolk',
    nameEN: 'Norfolk Island',
    descriptionEN: 'Norfolk Island',
    code: 'NF',
    isEU: false,
  },
  {
    namePL: 'Nigeria',
    descriptionPL: 'Republika Federalna Nigerii',
    nameEN: 'Nigeria',
    descriptionEN: 'Federal Republic of Nigeria',
    code: 'NG',
    isEU: false,
  },
  {
    namePL: 'Nikaragua',
    descriptionPL: 'Republika Nikaragui',
    nameEN: 'Nicaragua',
    descriptionEN: 'Republic of Nicaragua',
    code: 'NI',
    isEU: false,
  },
  {
    namePL: 'Holandia',
    descriptionPL: 'Królestwo Holandii',
    nameEN: 'Netherlands',
    descriptionEN: 'Kingdom of the Netherlands',
    code: 'NL',
    isEU: true,
  },
  {
    namePL: 'Norwegia',
    descriptionPL: 'Królestwo Norwegii',
    nameEN: 'Norway',
    descriptionEN: 'Kingdom of Norway',
    code: 'NO',
    isEU: false,
  },
  {
    namePL: 'Nepal',
    descriptionPL: 'Królestwo Nepalu',
    nameEN: 'Nepal',
    descriptionEN: 'Kingdom of Nepal',
    code: 'NP',
    isEU: false,
  },
  {
    namePL: 'Nauru',
    descriptionPL: 'Republika Nauru',
    nameEN: 'Nauru',
    descriptionEN: 'Republic of Nauru',
    code: 'NR',
    isEU: false,
  },
  {
    namePL: 'Niue',
    descriptionPL: 'Republika Niue',
    nameEN: 'Niue',
    descriptionEN: 'Republic of Niue',
    code: 'NU',
    isEU: false,
  },
  {
    namePL: 'Nowa Zelandia',
    descriptionPL: 'Nowa Zelandia (z Wyspami Antipodes, Auckland, Campbell, Chatham i Kermadec)',
    nameEN: 'New Zealand',
    descriptionEN:
      'New Zealand (includes Antipodes Islands, Auckland Islands, Campbell Islands, Chatham Islands, and Kermadec Islands)',
    code: 'NZ',
    isEU: false,
  },
  {
    namePL: 'Oman',
    descriptionPL: 'Sułtanat Omanu (wraz z częścią półwyspu Musandam)',
    nameEN: 'Oman',
    descriptionEN: 'Sultanate of Oman (includes part of the Musandam Peninsula)',
    code: 'OM',
    isEU: false,
  },
  {
    namePL: 'Panama',
    descriptionPL: 'Republika Panamy',
    nameEN: 'Panama',
    descriptionEN: 'Republic of Panama',
    code: 'PA',
    isEU: false,
  },
  {
    namePL: 'Peru',
    descriptionPL: 'Republika Peru',
    nameEN: 'Peru',
    descriptionEN: 'Republic of Peru',
    code: 'PE',
    isEU: false,
  },
  {
    namePL: 'Polinezja Francuska',
    descriptionPL:
      'Polinezja Francuska (złożona z Wysp Austral, Gambier, Marquesas, Archipelagu Society (główna wyspa Taiti), Wysp Tuamotu)',
    nameEN: 'French Polynesia',
    descriptionEN:
      'French Polynesia (comprises Austral Islands, Gambier Islands, Marquesas Islands, Society Archipelago (principal island Tahiti), Tuamotu Islands)',
    code: 'PF',
    isEU: false,
  },
  {
    namePL: 'Papua Nowa Gwinea',
    descriptionPL:
      'Papua Nowa Gwinea (z Archipelagiem Bismarka i Północnymi Wyspami Salomona (główna wyspa to Bougainville))',
    nameEN: 'Papua New Guinea',
    descriptionEN:
      'Papua New Guinea (includes Bismarck Archipelago and Northern Solomon Islands (principal island is Bougainville))',
    code: 'PG',
    isEU: false,
  },
  {
    namePL: 'Filipiny',
    descriptionPL: 'Republika Filipin',
    nameEN: 'Philippines',
    descriptionEN: 'Republic of the Philippines',
    code: 'PH',
    isEU: false,
  },
  {
    namePL: 'Pakistan',
    descriptionPL: 'Islamska Republika Pakistanu',
    nameEN: 'Pakistan',
    descriptionEN: 'Islamic Republic of Pakistan',
    code: 'PK',
    isEU: false,
  },
  {
    namePL: 'Polska',
    descriptionPL: 'Rzeczpospolita Polska',
    nameEN: 'Poland',
    descriptionEN: ' Republic of Poland',
    code: 'PL',
    isEU: true,
  },
  {
    namePL: 'Saint Pierre i Miquelon',
    descriptionPL: 'Saint Pierre i Miquelon',
    nameEN: 'Saint Pierre and Miquelon',
    descriptionEN: 'Saint Pierre and Miquelon',
    code: 'PM',
    isEU: false,
  },
  {
    namePL: 'Pitcairn',
    descriptionPL: 'Pitcairn (z Henderson, Ducie i Oeno)',
    nameEN: 'Pitcairn',
    descriptionEN: 'Pitcairn (includes Henderson, Ducie, and Oeno)',
    code: 'PN',
    isEU: false,
  },
  {
    namePL: 'Puerto Rico',
    descriptionPL: 'Puerto Rico',
    nameEN: 'Puerto Rico',
    descriptionEN: 'Puerto Rico',
    code: 'PR',
    isEU: false,
  },
  {
    namePL: 'Okupowane terytorium Palestyny',
    descriptionPL: 'Okupowane terytorium Palestyny',
    nameEN: 'Palestinian Territory,Occupied',
    descriptionEN: 'Occupied Palestinian Territory',
    code: 'PS',
    isEU: false,
  },
  {
    namePL: 'Portugalia',
    descriptionPL: 'Republika Portugalii',
    nameEN: 'Portugal',
    descriptionEN: 'Portuguese Republic',
    code: 'PT',
    isEU: true,
  },
  {
    namePL: 'Palau',
    descriptionPL: 'Republika Palau (z zachodnia częścią Wysp Karoliny (główna wyspa to Babelthuap))',
    nameEN: 'Palau',
    descriptionEN:
      'Republic of Palau (includes the west part of the Caroline Islands (principal island is Babelthuap))',
    code: 'PW',
    isEU: false,
  },
  {
    namePL: 'Paragwaj',
    descriptionPL: 'Republika Paragwaju',
    nameEN: 'Paraguay',
    descriptionEN: 'Republic of Paraguay',
    code: 'PY',
    isEU: false,
  },
  {
    namePL: 'Katar',
    descriptionPL: 'Katar',
    nameEN: 'Qatar',
    descriptionEN: 'State of Qatar',
    code: 'QA',
    isEU: false,
  },
  {
    namePL: 'Reunion',
    descriptionPL: 'Reunion',
    nameEN: 'Reunion',
    descriptionEN: 'Reunion',
    code: 'RE',
    isEU: false,
  },
  {
    namePL: 'Rumunia',
    descriptionPL: 'Rumunia',
    nameEN: 'Romania',
    descriptionEN: 'Romania',
    code: 'RO',
    isEU: true,
  },
  {
    namePL: 'Serbia',
    descriptionPL: 'Republika Serbska',
    nameEN: 'Serbia',
    descriptionEN: 'Republic of Serbia',
    code: 'RS',
    isEU: false,
  },
  {
    namePL: 'Federacja Rosyjska',
    descriptionPL: 'Federacja Rosyjska (wraz z Okręgiem Kaliningradzkim)',
    nameEN: 'Russian Federation',
    descriptionEN: 'Russian Federation (includes the Kaliningrad Region)',
    code: 'RU',
    isEU: false,
  },
  {
    namePL: 'Ruanda',
    descriptionPL: 'Republika Rwandy',
    nameEN: 'Rwanda',
    descriptionEN: 'Rwandese Republic',
    code: 'RW',
    isEU: false,
  },
  {
    namePL: 'Arabia Saudyjska',
    descriptionPL: 'Królestwo Arabii Saudyjskiej',
    nameEN: 'Saudi Arabia',
    descriptionEN: 'Kingdom of Saudi Arabia',
    code: 'SA',
    isEU: false,
  },
  {
    namePL: 'Wyspy Salomona',
    descriptionPL: 'Wyspy Salomona (z Wyspami Santa Cruz i Południowymi Wyspami Salomona (główna wyspa - Guadalcanal))',
    nameEN: 'Solomon Islands',
    descriptionEN:
      'Solomon Islands (includes Santa Cruz Islands and Southern Solomon Islands (principal island is Guadalcanal))',
    code: 'SB',
    isEU: false,
  },
  {
    namePL: 'Seszele',
    descriptionPL:
      'Republika Seszeli (główna wyspa to Mahe, z Wyspami Aldabra, Wyspami Amirante, Wyspami Cosmoledo i Wyspami Farquhar)',
    nameEN: 'Seychelles',
    descriptionEN:
      'Republic of Seychelles (principle island is Mahe and includes Aldabra Islands, Amirante Islands, Cosmoledo Islands, and Farquhar Islands)',
    code: 'SC',
    isEU: false,
  },
  {
    namePL: 'Sudan',
    descriptionPL: 'Republika Sudanu',
    nameEN: 'Sudan',
    descriptionEN: 'Republic of the Sudan',
    code: 'SD',
    isEU: false,
  },
  {
    namePL: 'Szwecja',
    descriptionPL: 'Królestwo Szwecji',
    nameEN: 'Sweden',
    descriptionEN: 'Kingdom of Sweden',
    code: 'SE',
    isEU: true,
  },
  {
    namePL: 'Singapur',
    descriptionPL: 'Republika Singapuru',
    nameEN: 'Singapore',
    descriptionEN: 'Republic of Singapore',
    code: 'SG',
    isEU: false,
  },
  {
    namePL: 'Święta Helena',
    descriptionPL: 'Święta Helena (z Wyspą Ascension, Wyspą Gough i Archipelagiem Tristan da Cunha)',
    nameEN: 'Saint Helena',
    descriptionEN: 'Saint Helena (includes Ascension Island, Gough Island, Tristan da Cunha Archipelago)',
    code: 'SH',
    isEU: false,
  },
  {
    namePL: 'Słowenia',
    descriptionPL: 'Republika Słowenii',
    nameEN: 'Slovenia',
    descriptionEN: 'Republic of Slovenia',
    code: 'SI',
    isEU: true,
  },
  {
    namePL: 'Wyspy Svalbard i Jan Mayen',
    descriptionPL: 'Wyspy Svalbard i Jan Mayen (z Wyspą Niedźwiedzią)',
    nameEN: 'Svalbard and Jan Mayen',
    descriptionEN: 'Svalbard and Jan Mayen (includes Bear Island)',
    code: 'SJ',
    isEU: false,
  },
  {
    namePL: 'Słowacja',
    descriptionPL: 'Republika Słowacji',
    nameEN: 'Slovakia',
    descriptionEN: 'Slovak Republic',
    code: 'SK',
    isEU: true,
  },
  {
    namePL: 'Sierra Leone',
    descriptionPL: 'Republika Sierra Leone',
    nameEN: 'Sierra Leone',
    descriptionEN: 'Republic of Sierra Leone',
    code: 'SL',
    isEU: false,
  },
  {
    namePL: 'San Marino',
    descriptionPL: 'Republika San Marino',
    nameEN: 'San Marino',
    descriptionEN: 'Republic of San Marino',
    code: 'SM',
    isEU: false,
  },
  {
    namePL: 'Senegal',
    descriptionPL: 'Republika Senegalu',
    nameEN: 'Senegal',
    descriptionEN: 'Republic of Senegal',
    code: 'SN',
    isEU: false,
  },
  {
    namePL: 'Somalia',
    descriptionPL: 'Republika Somali',
    nameEN: 'Somalia',
    descriptionEN: 'Somali Republic',
    code: 'SO',
    isEU: false,
  },
  {
    namePL: 'Surinam',
    descriptionPL: 'Republika Suriname',
    nameEN: 'Suriname',
    descriptionEN: 'Republic of Suriname',
    code: 'SR',
    isEU: false,
  },
  {
    namePL: 'Wyspy Świętego Tomasza i Książęca',
    descriptionPL: 'Demokratyczna Republika Wysp Św. Tomasza i Książęcej',
    nameEN: 'Sao Tome and Principe',
    descriptionEN: 'Democratic Republic of Sao Tome and Principe',
    code: 'ST',
    isEU: false,
  },
  {
    namePL: 'Salwador',
    descriptionPL: 'Republika Salwadoru',
    nameEN: 'El Salvador',
    descriptionEN: 'Republic of El Salvador',
    code: 'SV',
    isEU: false,
  },
  {
    namePL: 'Syryjska Republika Arabska',
    descriptionPL: 'Syryjska Republika Arabska',
    nameEN: 'Syrian Arab Republic',
    descriptionEN: 'Syrian Arab Republic',
    code: 'SY',
    isEU: false,
  },
  {
    namePL: 'Suazi',
    descriptionPL: 'Królestwo Suazi',
    nameEN: 'Swaziland',
    descriptionEN: 'Kingdom of Swaziland',
    code: 'SZ',
    isEU: false,
  },
  {
    namePL: 'Wyspy Turks i Caicos',
    descriptionPL: 'Wyspy Turks i Caicos',
    nameEN: 'Turks and Caicos Islands',
    descriptionEN: 'Turks and Caicos Islands',
    code: 'TC',
    isEU: false,
  },
  {
    namePL: 'Czad',
    descriptionPL: 'Republika Czadu',
    nameEN: 'Chad',
    descriptionEN: 'Republic of Chad',
    code: 'TD',
    isEU: false,
  },
  {
    namePL: 'Obszary Francji Południowej',
    descriptionPL:
      'Francuskie Terytoria Południowe (Wyspa Amsterdam, Archipelag Crozet, Wyspy Kerguelen, Wyspa Saint Paul oraz wyspy na Oceanie Indyjskim - Bassas da India, Wyspa Europa, Wyspy Glorioso, Wyspa Juan de Nova i Wyspa Tromelin)',
    nameEN: 'French Southern Territories',
    descriptionEN:
      'French Southern Territories (comprises Amsterdam Island, Crozet Archipelago, Kerguelen Islands, St. Paul Island, Indian Ocean Islands formed by Bassas da India, Europa Island, Glorioso Islands, Juan de Nova Island, Tromelin Island)',
    code: 'TF',
    isEU: false,
  },
  {
    namePL: 'Togo',
    descriptionPL: 'Republika Togolese',
    nameEN: 'Togo',
    descriptionEN: 'Togolese Republic',
    code: 'TG',
    isEU: false,
  },
  {
    namePL: 'Tajlandia',
    descriptionPL: 'Królestwo Tajlandii',
    nameEN: 'Thailand',
    descriptionEN: 'Kingdom of Thailand',
    code: 'TH',
    isEU: false,
  },
  {
    namePL: 'Tadżykistan',
    descriptionPL: 'Republika Tadżykistanu',
    nameEN: 'Tajikistan',
    descriptionEN: 'Republic of Tajikistan',
    code: 'TJ',
    isEU: false,
  },
  {
    namePL: 'Tokelau',
    descriptionPL: 'Tokelau',
    nameEN: 'Tokelau',
    descriptionEN: 'Tokelau',
    code: 'TK',
    isEU: false,
  },
  {
    namePL: 'Timor-Leste',
    descriptionPL: 'Timor-Leste (w tym enklawa Oecussi)',
    nameEN: 'Timor-Leste',
    descriptionEN: 'Timor-Leste (Includes the exclave of Oecussi)',
    code: 'TL',
    isEU: false,
  },
  {
    namePL: 'Turkmenia',
    descriptionPL: 'Turkmenia',
    nameEN: 'Turkmenistan',
    descriptionEN: 'Turkmenistan',
    code: 'TM',
    isEU: false,
  },
  {
    namePL: 'Tunezja',
    descriptionPL: 'Republika Tunezji',
    nameEN: 'Tunisia',
    descriptionEN: 'Republic of Tunisia',
    code: 'TN',
    isEU: false,
  },
  {
    namePL: 'Tonga',
    descriptionPL: 'Królestwo Tonga (główna wyspa to Tongatapu)',
    nameEN: 'Tonga',
    descriptionEN: 'Kingdom of Tonga (principal island is Tongatapu)',
    code: 'TO',
    isEU: false,
  },
  {
    namePL: 'Turcja',
    descriptionPL: 'Republika Turcji',
    nameEN: 'Turkey',
    descriptionEN: 'Republic of Turkey',
    code: 'TR',
    isEU: false,
  },
  {
    namePL: 'Trynidad i Tobago',
    descriptionPL: 'Republika Trynidadu i Tobago',
    nameEN: 'Trinidad and Tobago',
    descriptionEN: 'Republic of Trinidad and Tobago',
    code: 'TT',
    isEU: false,
  },
  {
    namePL: 'Tuvalu',
    descriptionPL: 'Tuvalu (główny atol to Funafuti)',
    nameEN: 'Tuvalu',
    descriptionEN: 'Tuvalu (principal atoll is Funafuti)',
    code: 'TV',
    isEU: false,
  },
  {
    namePL: 'Tajwan',
    descriptionPL: 'Tajwan',
    nameEN: 'Taiwan',
    descriptionEN: 'Taiwan',
    code: 'TW',
    isEU: false,
  },
  {
    namePL: 'Zjednoczona Republika Tanzanii',
    descriptionPL: 'Zjednoczona Republika Tanzanii',
    nameEN: 'Tanzania, United Republic of',
    descriptionEN: 'United Republic of Tanzania',
    code: 'TZ',
    isEU: false,
  },
  {
    namePL: 'Ukraina',
    descriptionPL: 'Ukraina',
    nameEN: 'Ukraine',
    descriptionEN: 'Ukraine',
    code: 'UA',
    isEU: false,
  },
  {
    namePL: 'Uganda',
    descriptionPL: 'Republika Ugandy',
    nameEN: 'Uganda',
    descriptionEN: 'Republic of Uganda',
    code: 'UG',
    isEU: false,
  },
  {
    namePL: 'Mniejsze Wyspy Zewnętrzne  USA',
    descriptionPL:
      'Mniejsze Wyspy Zewnętrzne USA (z Wyspą Baker, Wyspą Howland, Wyspą Jarvis, Atolem Johnston, Rafą Kingman, Wyspami Midway, Wyspą Navassa, Atolem Palmyra i Wyspą Wake)',
    nameEN: 'United States Minor Outlying Islands',
    descriptionEN:
      'United States Minor Outlying Islands (includes Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Islands, Navassa Island, Palmyra Atoll, and Wake Island)',
    code: 'UM',
    isEU: false,
  },
  {
    namePL: 'Stany Zjednoczone',
    descriptionPL: 'Stany Zjednoczone Ameryki',
    nameEN: 'United States',
    descriptionEN: 'United States of America',
    code: 'US',
    isEU: false,
  },
  {
    namePL: 'Urugwaj',
    descriptionPL: 'Wschodnia Republika Urugwaju',
    nameEN: 'Uruguay',
    descriptionEN: 'Eastern Republic of Uruguay',
    code: 'UY',
    isEU: false,
  },
  {
    namePL: 'Uzbekistan',
    descriptionPL: 'Republika Uzbekistanu',
    nameEN: 'Uzbekistan',
    descriptionEN: 'Republic of Uzbekistan',
    code: 'UZ',
    isEU: false,
  },
  {
    namePL: 'Państwo Watykan',
    descriptionPL: 'Państwo Watykan',
    nameEN: 'Holy See (Vatican City State)',
    descriptionEN: 'Holy See (Vatican City State) previous entry Vatican City State (Holy See)',
    code: 'VA',
    isEU: false,
  },
  {
    namePL: 'Saint Vincent i Grenadiny',
    descriptionPL:
      'Saint Vincent i Grenadiny (z Wyspami Północnej Grenadiny (główna wyspa Bequia) i Wyspą Saint Vincent)',
    nameEN: 'Saint Vincent and the Grenadines',
    descriptionEN:
      'Saint Vincent and the Grenadines (includes Northern Grenadine Islands (principal island Bequia) and Saint Vincent Island)',
    code: 'VC',
    isEU: false,
  },
  {
    namePL: 'Republika Wenezueli',
    descriptionPL: 'Republika Wenezueli (w tym wyspa Bird)',
    nameEN: 'Venezuela, Bolivarian Republic of',
    descriptionEN: 'Bolivarian Republic of Venezuela (includes Bird Island)',
    code: 'VE',
    isEU: false,
  },
  {
    namePL: 'Brytyjskie Wyspy Dziewicze',
    descriptionPL: 'Brytyjskie Wyspy Dziewicze (wyspy: Anegada, Jost Van Dyke, Tortola i Virgin Gorda)',
    nameEN: 'Virgin Islands, British',
    descriptionEN: 'British Virgin Islands (includes Anegada, Jost Van Dyke, Tortola, and Virgin Gorda)',
    code: 'VG',
    isEU: false,
  },
  {
    namePL: 'Wyspy Dziewicze, USA',
    descriptionPL: 'Wyspy Dziewicze (USA) (z Saint Croix, Saint John i Saint Thomas)',
    nameEN: 'Virgin Islands, U.S.',
    descriptionEN: 'Virgin Islands of the United States (includes Saint Croix, Saint John, and Saint Thomas)',
    code: 'VI',
    isEU: false,
  },
  {
    namePL: 'Wietnam',
    descriptionPL: 'Socjalistyczna Republika Wietnamu',
    nameEN: 'Viet Nam',
    descriptionEN: 'Socialist Republic of Viet Nam',
    code: 'VN',
    isEU: false,
  },
  {
    namePL: 'Vanuatu',
    descriptionPL: 'Republika Vanuatu (główne wyspy to Efate i Espiritu Santo)',
    nameEN: 'Vanuatu',
    descriptionEN: 'Republic of Vanuatu (principal islands are Efate and Espiritu Santo)',
    code: 'VU',
    isEU: false,
  },
  {
    namePL: 'Wallis i Futuna',
    descriptionPL: 'Wallis i Futuna (z Wyspami Hoorn (główna wyspa - Futuna) i Wyspami Wallis (główna wyspa - Uvea))',
    nameEN: 'Wallis and Futuna',
    descriptionEN:
      'Wallis and Futuna (includes Hoorn Islands (principal island is Futuna) and Wallis Islands (principal island is Uvea))',
    code: 'WF',
    isEU: false,
  },
  {
    namePL: 'Samoa',
    descriptionPL: 'Samoa (główne wyspy: Savii i Upolu)',
    nameEN: 'Samoa',
    descriptionEN: 'Independent State of Samoa (principal islands are Savii and Upolu)',
    code: 'WS',
    isEU: false,
  },
  {
    namePL: 'Jemen',
    descriptionPL: 'Republika Jemenu (z Wyspą Socotra)',
    nameEN: 'Yemen',
    descriptionEN: 'Republic of Yemen (includes Socotra Island)',
    code: 'YE',
    isEU: false,
  },
  {
    namePL: 'Mayotte',
    descriptionPL: 'Mayotte',
    nameEN: 'Mayotte',
    descriptionEN: 'Mayotte',
    code: 'YT',
    isEU: false,
  },
  {
    namePL: 'Afryka Południowa',
    descriptionPL: 'Republika Południowej Afryki (z Wyspą Marion i Wyspą Prince Edward)',
    nameEN: 'South Africa',
    descriptionEN: 'Republic of South Africa (includes Marion Island and Prince Edward Island)',
    code: 'ZA',
    isEU: false,
  },
  {
    namePL: 'Zambia',
    descriptionPL: 'Republika Zambii',
    nameEN: 'Zambia',
    descriptionEN: 'Republic of Zambia',
    code: 'ZM',
    isEU: false,
  },
  {
    namePL: 'Zimbabwe',
    descriptionPL: 'Republika Zimbabwe',
    nameEN: 'Zimbabwe',
    descriptionEN: 'Republic of Zimbabwe',
    code: 'ZW',
    isEU: false,
  },
];
