import {ChangeEvent, FC, FocusEvent} from 'react';
import MaskedInput from 'react-text-mask';

import S from './BlueInputText.module.scss';

interface Props {
  label: string;
  name: string;
  id: string;
  value: string;
  maxLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regExValidation?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  withMaskedInput?: boolean;
  mask?: Array<string | RegExp> | false;
  inputMode?: 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';
  guide?: boolean;
  autoFocus?: boolean;
  isFullWidth?: boolean;
  inputType?: 'text' | 'number';
}

const BlueInputText: FC<Props> = ({
  label,
  name,
  id,
  value,
  maxLength,
  regExValidation,
  onChange,
  onBlur,
  onFocus,
  disabled,
  withMaskedInput,
  mask,
  inputMode,
  guide = false,
  autoFocus,
  isFullWidth = true,
  inputType = 'text',
}) => {
  return (
    <div className={`${S.blueInputWrapper} ${isFullWidth && S.fullWidth}`}>
      {withMaskedInput ? (
        <MaskedInput
          className={`${S.blueInput}`}
          mask={mask || false}
          guide={guide}
          type={inputType}
          inputMode={inputMode || 'text'}
          placeholder=" "
          maxLength={maxLength}
          name={name}
          id={id}
          value={value}
          onKeyDown={(e) => regExValidation && (regExValidation(e.key) ? null : e.preventDefault())}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
      ) : (
        <input
          type={inputType}
          inputMode={inputMode || 'text'}
          name={name}
          id={id}
          placeholder=" "
          maxLength={maxLength}
          className={`${S.blueInput} ${isFullWidth && S.fullWidth}`}
          onKeyDown={(e) => regExValidation && (regExValidation(e.key) ? null : e.preventDefault())}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
      )}
      <label htmlFor={id} className={S.blueInputLabel}>
        {label}
      </label>
    </div>
  );
};

export default BlueInputText;
