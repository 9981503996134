/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {IGoogleTagManager} from './googleTagManager.type';

const GoogleTagManager: IGoogleTagManager = Object.freeze({
  initialize: (gtmId: string): void => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', gtmId);
  },
  pushDataLayer: (eventName: string, dataLayer?: object): void => {
    window.dataLayer.push({event: eventName, ...dataLayer});
  },
});

export default GoogleTagManager;
