import {useState, useEffect} from 'react';
import useMyTickets from '../MyTickets/useMyTickets';
import api from 'api/faktury/repository';
import {IDownloadTicketResponse} from 'api/sprzedaz/interfaces';
import {PobierzIdentyfikatoryFakturFakturaOdp, IIdInvList} from 'api/faktury/interfaces';
import {getFullDate} from 'Utils/commonFunctions';
import {isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';

export interface FullTicket {
  ticketInfo: PobierzIdentyfikatoryFakturFakturaOdp;
  ticketDetail: IDownloadTicketResponse;
  searchDate: string;
}

const useMyInvoice = () => {
  const [tickets, setTickets] = useState<FullTicket[] | null>(null);
  const [pending, setPending] = useState(true);

  const {makeDetailTicketArr} = useMyTickets();

  const getInvoices = async (data: IIdInvList) => {
    try {
      const getIndexes = await api.getInvoiceIdent(data);

      return getIndexes;
    } catch (error) {
      console.error(error);
    }
  };

  const getAllidentificators = async () => {
    let allInvoices = [] as PobierzIdentyfikatoryFakturFakturaOdp[];

    const getInvoiceIndex = async (num: number) => {
      const firstInvoice = await getInvoices({
        fakturaTyp: num,
        wynikowNaStronie: 1,
        sortowanie: 1,
        numerStrony: 1,
      });

      if (firstInvoice && firstInvoice?.paginacja?.wszystkichWynikow > 1) {
        const moreInvoice = await getInvoices({
          fakturaTyp: num,
          wynikowNaStronie: firstInvoice?.paginacja?.wszystkichWynikow,
          sortowanie: 1,
          numerStrony: 1,
        });
        if (moreInvoice?.identyfikatory)
          allInvoices = [
            ...allInvoices,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...moreInvoice?.identyfikatory.map((el) => {
              return {...el, typeOfNote: num};
            }),
          ];
      } else {
        if (firstInvoice?.identyfikatory)
          allInvoices = [
            ...allInvoices,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...firstInvoice?.identyfikatory.map((el) => {
              return {...el, typeOfNote: num};
            }),
          ];
      }
    };

    await getInvoiceIndex(1);
    await getInvoiceIndex(2);
    await getInvoiceIndex(3);

    return allInvoices;
  };

  const startData = async () => {
    const allInvoices = await getAllidentificators();

    const det = await makeDetailTicketArr({
      identyfikatory: allInvoices,
      bledy: [],
      paginacja: {obecnaStrona: 1, wszystkichStron: 1, wszystkichWynikow: 1, wynikowNaStrone: 1},
    });
    if (allInvoices?.length) {
      const moreInvoices = allInvoices.map((el) => {
        return {
          ticketInfo: el,
          ticketDetail: det?.filter(
            (el2) => el2.bilety[0]?.biletNr === el?.biletNr && el2.bilety[0]?.transakcjaNr === el.transakcjaNr,
          )[0],
          searchDate: getFullDate(el.waznoscBiletuOd),
          nrSeriaDoWyszukiwania: `${el.biletSeria}${el.biletNr}`,
          searchInvoice: el.typeOfNote && el.typeOfNote === 1 ? `${el.biletSeria}${el?.fakturaNr}` : '',
          searchNote: el.typeOfNote && el.typeOfNote === 3 ? `${el.biletSeria}${el?.notaKorygujacaNr}` : '',
          searchCorrectInvoce: el.typeOfNote && el.typeOfNote === 2 ? `${el.biletSeria}${el?.fakturaKorektaNr}` : '',
        };
      });
      const allMainTickets = moreInvoices.filter((el) => el.ticketDetail?.bilety.length > 1);

      const mappedInvoices = moreInvoices.map((el) => {
        const returnProperPieces = () => {
          return (
            el.ticketDetail?.bilety.length,
            allMainTickets.filter((el2) => {
              let check = false;
              el2.ticketDetail?.bilety.forEach((ticket) => {
                if (ticket.transakcjaNr === el.ticketDetail?.bilety[0].transakcjaNr) {
                  check = true;
                }
              });
              if (check) return el2;
            })[0]?.ticketDetail?.bilety[0]?.odcinki
          );
        };
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!el?.ticketDetail?.bilety[0]?.odcinki[0]?.stacjaOdKod) {
          return el;
        }
        return {
          ...el,
          ticketDetail: {
            ...el.ticketDetail,
            bilety: [
              {
                ...el.ticketDetail?.bilety[0],
                odcinki: returnProperPieces(),
              },
            ],
          },
        };
      });

      const isAdded = (type: number) => {
        if (type === 20 || type === 21 || type === 22 || type === 23) return true;
        return false;
      };

      setTickets(
        mappedInvoices.map((el) => {
          return {
            ...el,
            ticketDetail: {
              ...el.ticketDetail,
              bilety: isAdded(el.ticketDetail?.bilety[0].biletRodzaj)
                ? [el.ticketDetail?.bilety[0]]
                : el.ticketDetail?.bilety.filter((el2) => isPrimaryTicketKind(el2.biletRodzaj)),
            },
          };
        }),
      );
    }
    setPending(false);
  };

  useEffect(() => {
    const getData = async () => {
      await startData();
    };
    getData();
  }, []);

  return {
    tickets,
    pending,
  };
};

export default useMyInvoice;
