import React, {Dispatch, useState, createContext, SetStateAction} from 'react';

import {IDownloadTicketResponseSingleTicket, IGenerateTicketResponse} from 'api/sprzedaz/interfaces';

interface ReminderModalSearchData {
  startStation: number;
  endStation: number;
  travelDate: string;
  travelTime: string;
}

interface IReservationContext {
  downloadedTicketData: IDownloadTicketResponseSingleTicket | null;
  setDownloadedTicketData: Dispatch<SetStateAction<IDownloadTicketResponseSingleTicket | null>>;
  saveDownloadedTicketDataToLocalStorage: () => void;
  getDownloadedTicketDataFromLocalStorage: () => void;
  reservationDates: {type: 'forth' | 'back'; dates: string[]}[] | null;
  setReservationDates: Dispatch<SetStateAction<{type: 'forth' | 'back'; dates: string[]}[] | null>>;
  downloadedReservationsTicketData: {type: 'forth' | 'back'; tickets: IGenerateTicketResponse[]}[] | null;
  setDownloadedReservationsTicketData: Dispatch<
    SetStateAction<{type: 'forth' | 'back'; tickets: IGenerateTicketResponse[]}[] | null>
  >;
  reminderModalSearchData: null | ReminderModalSearchData;
  setReminderModalSearchData: Dispatch<SetStateAction<ReminderModalSearchData | null>>;
}

export const ReservationContext = createContext<IReservationContext>({
  downloadedTicketData: null,
  setDownloadedTicketData: () => {},
  saveDownloadedTicketDataToLocalStorage: () => {},
  getDownloadedTicketDataFromLocalStorage: () => {},
  reservationDates: null,
  setReservationDates: () => {},
  downloadedReservationsTicketData: null,
  setDownloadedReservationsTicketData: () => {},
  reminderModalSearchData: null,
  setReminderModalSearchData: () => {},
});

const ReservationProvider = ({children}: {children: React.ReactNode}) => {
  const [downloadedTicketData, setDownloadedTicketData] = useState<IDownloadTicketResponseSingleTicket | null>(null);
  const [reservationDates, setReservationDates] = useState<{type: 'forth' | 'back'; dates: string[]}[] | null>(null);
  const [downloadedReservationsTicketData, setDownloadedReservationsTicketData] = useState<
    {type: 'forth' | 'back'; tickets: IGenerateTicketResponse[]}[] | null
  >(null);
  const [reminderModalSearchData, setReminderModalSearchData] = useState<null | ReminderModalSearchData>(null);

  const saveDownloadedTicketDataToLocalStorage = () => {
    window.localStorage.setItem('reservationData', JSON.stringify(downloadedTicketData));
  };

  const getDownloadedTicketDataFromLocalStorage = () => {
    const data = window.localStorage.getItem('reservationData');
    if (data) {
      setDownloadedTicketData(JSON.parse(data));
    }
  };

  return (
    <ReservationContext.Provider
      value={{
        downloadedTicketData,
        setDownloadedTicketData,
        saveDownloadedTicketDataToLocalStorage,
        getDownloadedTicketDataFromLocalStorage,
        reservationDates,
        setReservationDates,
        downloadedReservationsTicketData,
        setDownloadedReservationsTicketData,
        reminderModalSearchData,
        setReminderModalSearchData,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
