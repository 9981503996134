import {getFullTimeFromDate, getFullDate} from 'Utils/commonFunctions';
import i18n from 'i18n';
const t = i18n.t;

export const addTripToCalendar = (
  startPoint: string | number,
  endPoint: string | number,
  startDate: string,
  endDate: string,
  ticketNumber: string | number,
) => {
  const googleCalendarUrl = 'https://www.google.com/calendar/render?action=TEMPLATE&';

  const event = {
    summary: `${t('29007')} ${startPoint} ${t('29008')} ${endPoint}`,
    location: `${startPoint}`,
    description: `${t('29007')} ${startPoint} ${t('29008')} ${endPoint}, ${getFullDate(
      startDate,
    )} ${getFullTimeFromDate(startDate)} - ${getFullDate(endDate)} ${getFullTimeFromDate(endDate)}, ${t(
      '29009',
    )} ${ticketNumber}`,
    start: {
      dateTime: startDate.replace(' ', 'T').replaceAll('-', '').replaceAll(':', ''),
      timeZone: 'Europe/Warsaw',
    },
    end: {
      dateTime: endDate.replace(' ', 'T').replaceAll('-', '').replaceAll(':', ''),
      timeZone: 'Europe/Warsaw',
    },
    reminders: {
      useDefault: false,
      overrides: [
        {method: 'email', minutes: 60},
        {method: 'popup', minutes: 10},
      ],
    },
  };

  const params =
    'text=' +
    encodeURIComponent(event.summary) +
    '&dates=' +
    encodeURIComponent(event.start.dateTime) +
    '/' +
    encodeURIComponent(event.end.dateTime) +
    '&location=' +
    encodeURIComponent(event.location) +
    '&details=' +
    encodeURIComponent(event.description) +
    '&ctz=' +
    encodeURIComponent(event.start.timeZone) +
    '&remind=' +
    encodeURIComponent(
      event.reminders.overrides
        .map(function (reminder) {
          return reminder.minutes + 'm';
        })
        .join(','),
    );
  window.open(googleCalendarUrl + params, '_blank');
};

export default addTripToCalendar;
