import {ReactNode} from 'react';

import S from './IconHeader.module.scss';

interface IconHeaderDataProps {
  title: string;
  description: string;
  icon: ReactNode;
}
const IconHeader = ({title, description, icon}: IconHeaderDataProps) => {
  return (
    <div className={S.wrap}>
      <div className={S.main}>
        <div className={S.main_icon}>{icon}</div>
        <h2 className={S.title}>{title}</h2>
      </div>
      <p className={S.description}>{description}</p>
    </div>
  );
};
export default IconHeader;
