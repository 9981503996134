import {ComponentProps, FC} from 'react';
import {useTranslation} from 'react-i18next';

import Icon from '../../base/Icon';

import {XButtonWrapper} from './Buttons.styles';

type XButtonProps = ComponentProps<typeof XButtonWrapper>;

export const XButton: FC<XButtonProps> = (props) => {
  const {t} = useTranslation();
  return (
    <XButtonWrapper aria-label={t('29002')} {...props}>
      <Icon icon="X" />
    </XButtonWrapper>
  );
};
