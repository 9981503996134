import {FC} from 'react';

import {TrasaPrzejazdu} from 'api/pociagi/interfaces';
import {convertRomanNumerals} from 'Utils/commonFunctions';

import S from './MyTicketsRouteModal.module.scss';

interface Props {
  routeSection: TrasaPrzejazdu;
  isDepartureStation: boolean;
  isArrivalStation: boolean;
}

const MyTicketsRouteModalPlatforms: FC<Props> = ({routeSection, isDepartureStation, isArrivalStation}) => (
  <>
    {(isDepartureStation || isArrivalStation) && (
      <>
        <p className={S.detailsText}>{routeSection.peron?.length ? convertRomanNumerals(routeSection.peron) : null}</p>
        <p className={S.detailsText}>{routeSection.tor}</p>
      </>
    )}
  </>
);

export default MyTicketsRouteModalPlatforms;
