import React, {useState, useReducer, FC, createContext, Dispatch, SetStateAction} from 'react';

import {IConnectionSearchResultsList, IAmenities, IConnectionSearchTrainDetails} from 'api/pociagi/interfaces';
import {
  INewTicket,
  IPkpOffers,
  IPriceCheckOffer,
  IPriceCheckResponse,
  IPriceCheckTripSectionExtended,
  TicketType,
} from 'api/sprzedaz/interfaces';
import {ExpandableError, ticketsActions, ticketsAndDisscountInitial, ticketsReducer} from './TicketsReducer';
import {useData} from 'Utils/DataContex';
import {
  durationInDaysBetweenTwoDates,
  getTZBRequiredPreSaleDaysFromEnv,
  returnOfferNameFromCode,
  returnOfferStringFromCode,
} from './commonFunctions';
import noop from 'lodash/noop';
import {TicketTypeValue} from 'api/sprzedaz/interfaces';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {PRICE_CODE_OFFERS, SHOP_DISCOUNT_CODES} from 'common/consts';

export const TicketContext = createContext<TicketInterface>({
  ticketType: 1,
  setTicketType: noop,
  contextAmenities: null,
  setContextAmenities: noop,
  from: null,
  setFrom: null,
  to: null,
  setTo: null,
  addTicketContextFrom: {
    dogsTickets: 0,
    guideDogsTickets: false,
    bikesTickets: 0,
    luggageTickets: 0,
  },
  setAddTicketContextFrom: null,
  addTicketContextTo: {
    dogsTickets: 0,
    guideDogsTickets: false,
    bikesTickets: 0,
    luggageTickets: 0,
  },
  setAddTicketContextTo: null,
  placesFromContext: null,
  setPlaceFromContext: null,
  placesToContext: null,
  setPlaceToContext: null,
  ticketQQ: {
    classFrom: null,
    classTo: null,
    ticket100: 0,
    ticket50: 0,
    gender: 0,
  },
  setTicketQQ: null,
  adultTicketsList: [],
  adultTicketsNoOfferList: [],
  setAdultTicketsList: null,
  setAdultTicketsListWithOffer: null,
  tripBackAdultTicketsList: [],
  setTripBackAdultTicketsList: null,
  childrenTicketsList: [],
  setChildrenTicketsList: null,
  pkpOffers: {
    familyTicket: false,
    bigFamily: false,
    cheaperWithRelatives: false,
  },
  setPkpOffers: null,
  disableOffer: null,
  checkOffersConditions: null,
  canApplyOffer: null,
  canApplyOfferExtended: null,
  offersModalInfo: null,
  offersModalError: null,
  offersExpandableError: null,
  setOfferModalInfo: null,
  wcgaInfo: null,
  setWcgaInfo: null,
  resetOfferModal: null,
  resetErrorModal: null,
  resetExpandableErrorsModal: null,
  preferedPlacesFrom: null,
  setPreferedPlacesFrom: noop,
  preferedPlacesTo: null,
  verifyDiscountCodesWithOffers: null,
  setPreferedPlacesTo: noop,
  verifyOfferAndTrainCat: null,
  verifyTicketsAndApplyOffer: null,
  choiceOfPlaceMobileData: {
    connections: [],
    prices: [],
    itemOffer: [],
    returnFn: () => {},
    exchangeMode: false,
    stations: [],
  },
  setChoiceOfPlaceMobileData: noop,
  checkTicketInfo: null,
  setCheckTicketInfo: noop,
  samePlaceWithChildFrom: false,
  setSamePlaceWithChildFrom: noop,
  samePlaceWithChildTo: false,
  setSamePlaceWithChildTo: noop,
  connectionSearchListSelectedIndex: -1,
  setConnectionSearchListSelectedIndex: noop,
});

interface Places {
  miejsceTypKod: number;
  usytowanieKod: number;
  wyborMiejscaRodzaj: number;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
}

export interface AddTicket {
  dogsTickets: number;
  guideDogsTickets: boolean;
  bikesTickets: number;
  luggageTickets: number;
}

export interface TicketQuantity {
  classFrom: IPriceCheckOffer | null;
  classTo: IPriceCheckOffer | null;
  ticket100: number;
  ticket50: number;
  gender: number;
}

interface Places {
  miejsceTypKod: number;
  usytowanieKod: number;
  wyborMiejscaRodzaj: number;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
}

export interface WygenerujBiletOdcinekZad {
  wyjazdData: string;
  stacjaOdKod: number;
  stacjaDoKod: number;
  pociagNr: number;
  klasa: number;
  miejsca: Places[];
}

interface TicketInterface {
  ticketType: TicketTypeValue;
  setTicketType: Dispatch<SetStateAction<TicketTypeValue>>;
  contextAmenities: IAmenities | null;
  setContextAmenities: Dispatch<SetStateAction<IAmenities | null>>;
  from: IConnectionSearchResultsList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFrom: any;
  to: IConnectionSearchResultsList | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTo: any;
  addTicketContextFrom: AddTicket;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddTicketContextFrom: any;
  addTicketContextTo: AddTicket;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddTicketContextTo: any;
  placesFromContext: Places[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPlaceFromContext: any;
  placesToContext: Places[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPlaceToContext: any;
  ticketQQ: TicketQuantity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTicketQQ: any;
  adultTicketsList: INewTicket[];
  adultTicketsNoOfferList: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAdultTicketsList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAdultTicketsListWithOffer: any;
  tripBackAdultTicketsList: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTripBackAdultTicketsList: any;
  childrenTicketsList: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setChildrenTicketsList: any;
  pkpOffers: IPkpOffers;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPkpOffers: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disableOffer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkOffersConditions: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canApplyOffer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canApplyOfferExtended: any;
  offersModalInfo: string[] | null;
  offersModalError: string[] | null;
  offersExpandableError: ExpandableError[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOfferModalInfo: any;
  wcgaInfo: {car: string; ticket: string} | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWcgaInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetOfferModal: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetErrorModal: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetExpandableErrorsModal: any;
  preferedPlacesFrom: WygenerujBiletOdcinekZad[] | null;
  setPreferedPlacesFrom: Dispatch<SetStateAction<WygenerujBiletOdcinekZad[] | null>>;
  preferedPlacesTo: WygenerujBiletOdcinekZad[] | null;
  setPreferedPlacesTo: Dispatch<SetStateAction<WygenerujBiletOdcinekZad[] | null>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyOfferAndTrainCat: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyDiscountCodesWithOffers: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyTicketsAndApplyOffer: any;
  choiceOfPlaceMobileData: SelectedMobileItemData;
  setChoiceOfPlaceMobileData: Dispatch<SetStateAction<SelectedMobileItemData>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkTicketInfo: any;
  setCheckTicketInfo: Dispatch<SetStateAction<boolean | null>>;
  samePlaceWithChildFrom: boolean;
  setSamePlaceWithChildFrom: Dispatch<SetStateAction<boolean>>;
  samePlaceWithChildTo: boolean;
  setSamePlaceWithChildTo: Dispatch<SetStateAction<boolean>>;
  connectionSearchListSelectedIndex: number;
  setConnectionSearchListSelectedIndex: Dispatch<SetStateAction<number>>;
}

type DataState = {
  children: React.ReactNode;
};

type OfferConditions = {
  adultsGt0: boolean;
  adultsGt1: boolean;
  adultsGe2: boolean;
  adultsKdr: boolean;
  childrenGt0: boolean;
  childrenGe3: boolean;
  children77: boolean;
  ticketsSumTzB: boolean;
  ticketsSumGt5: boolean;
  ticketsSumLe5: boolean;
  ticketsSumLe6: boolean;
  dateDiffGe1Day: boolean;
  autoOffersDisabled: IPkpOffers;
};

export type SelectedMobileItemData = {
  connections: IConnectionSearchResultsList[];
  connectionsBack?: IConnectionSearchResultsList[];
  prices: IPriceCheckResponse[] | undefined;
  itemOffer?: number[];
  returnFn: (e: boolean) => void;
  exchangeMode?: boolean;
  stations?: IConnectionSearchTrainDetails[][];
  metastationCheck?: (odc: IPriceCheckTripSectionExtended[]) => void;
};

const TicketProvider: FC<DataState> = ({children}) => {
  const {t} = useTranslation();
  const {offers, shopDiscounts} = useData();
  const [ticketType, setTicketType] = useState<TicketTypeValue>(TicketType.SingleTicket);
  const [contextAmenities, setContextAmenities] = useState<IAmenities | null>(null);
  const [from, setFrom] = useState<IConnectionSearchResultsList | null>(null);
  const [to, setTo] = useState<IConnectionSearchResultsList | null>(null);
  const [addTicketContextFrom, setAddTicketContextFrom] = useState<AddTicket>({
    dogsTickets: 0,
    guideDogsTickets: false,
    bikesTickets: 0,
    luggageTickets: 0,
  });
  const [addTicketContextTo, setAddTicketContextTo] = useState<AddTicket>({
    dogsTickets: 0,
    guideDogsTickets: false,
    bikesTickets: 0,
    luggageTickets: 0,
  });
  const [placesFromContext, setPlaceFromContext] = useState(null);
  const [placesToContext, setPlaceToContext] = useState(null);
  const [ticketQQ, setTicketQQ] = useState({
    classFrom: null,
    classTo: null,
    ticket100: 1,
    ticket50: 0,
    gender: 0,
  });

  const [preferedPlacesFrom, setPreferedPlacesFrom] = useState<WygenerujBiletOdcinekZad[] | null>(null);
  const [preferedPlacesTo, setPreferedPlacesTo] = useState<WygenerujBiletOdcinekZad[] | null>(null);

  const [samePlaceWithChildFrom, setSamePlaceWithChildFrom] = useState(false);
  const [samePlaceWithChildTo, setSamePlaceWithChildTo] = useState(false);
  const [connectionSearchListSelectedIndex, setConnectionSearchListSelectedIndex] = useState(-1);

  const [ticketsState, dispatch] = useReducer(ticketsReducer, ticketsAndDisscountInitial);

  const [choiceOfPlaceMobileData, setChoiceOfPlaceMobileData] = useState<SelectedMobileItemData>({
    connections: [],
    connectionsBack: [],
    prices: [],
    itemOffer: [],
    returnFn: () => {},
    exchangeMode: false,
    stations: [],
  });

  const [checkTicketInfo, setCheckTicketInfo] = useState<boolean | null>(null);

  const adultTicketsList = ticketsState.adultTicketsList;

  const tripBackAdultTicketsList = ticketsState.tripBackAdultTicketsList;
  const adultTicketsNoOfferList = [...ticketsState.adultTicketsList].map((x) => {
    if (x.discountCode === SHOP_DISCOUNT_CODES.FAMILY) return {...x, discountCode: SHOP_DISCOUNT_CODES.NORMAL};
    return x;
  });
  const childrenTicketsList = ticketsState.childrenTicketsList;
  const pkpOffers = ticketsState.pkpOffers;
  const offersModalInfo = ticketsState.offerModalInfo;
  const offersModalError = ticketsState.offerModalError;
  const offersExpandableError = ticketsState.offerExpandableError;
  const wcgaInfo = ticketsState.wcgaInfo;

  const setAdultTicketsList = (list: INewTicket[]) => {
    dispatch({
      type: ticketsActions.UPDATE_ADULT_TICKETS,
      payload: list,
    });
  };

  const setAdultTicketsListWithOffer = (list: INewTicket[]) => {
    let adultsList = list;

    if (
      !pkpOffers.bigFamily &&
      pkpOffers.familyTicket &&
      adultsList.some((x) => x.discountCode === SHOP_DISCOUNT_CODES.NORMAL)
    ) {
      adultsList = [...list].map((x) => {
        if (x.discountCode === SHOP_DISCOUNT_CODES.NORMAL) return {...x, discountCode: SHOP_DISCOUNT_CODES.FAMILY};
        return x;
      });
    }

    dispatch({
      type: ticketsActions.UPDATE_ADULT_TICKETS,
      payload: adultsList,
    });
  };

  const disableOffer = (name: string) => {
    dispatch({
      type: ticketsActions.DISABLE_OFFER,
      payload: name,
    });
  };

  const setTripBackAdultTicketsList = (list: INewTicket[]) => {
    dispatch({
      type: ticketsActions.UPDATE_TRIP_BACK_ADULT_TICKETS,
      payload: list,
    });
  };

  const setChildrenTicketsList = (list: INewTicket[]) => {
    dispatch({
      type: ticketsActions.UPDATE_CHILDREN_TICKETS,
      payload: list,
    });
  };

  const setOfferModalInfo = (info: string) => {
    dispatch({
      type: ticketsActions.SET_OFFER_MODAL_INFO,
      payload: info,
    });
  };

  const setOfferModalError = (error: string) => {
    dispatch({
      type: ticketsActions.SET_OFFER_MODAL_ERROR,
      payload: error,
    });
  };

  const setExpandableModalError = (error: ExpandableError) => {
    dispatch({
      type: ticketsActions.SET_OFFER_EXPANDABLE_ERROR,
      payload: error,
    });
  };

  const setWcgaInfo = (message: string) => {
    dispatch({
      type: ticketsActions.SET_WCGA_INFO,
      payload: message,
    });
  };

  const resetOfferModal = () => {
    dispatch({
      type: ticketsActions.RESET_OFFER_MODAL,
      payload: null,
    });
  };

  const resetErrorModal = () => {
    dispatch({
      type: ticketsActions.RESET_ERRORS_MODAL,
      payload: null,
    });
  };

  const removeExpandableError = (offerName: string) => {
    dispatch({
      type: ticketsActions.REMOVE_EXPANDABLE_ERROR,
      payload: offerName,
    });
  };

  const resetExpandableErrorsModal = () => {
    dispatch({
      type: ticketsActions.RESET_EXPANDABLE_ERRORS_MODAL,
      payload: null,
    });
  };

  const setPkpOffers = (offers: string) => {
    resetErrorModal();

    if (offers === 'bigFamily') {
      dispatch({
        type: ticketsActions.SET_OFFER_BIG_FAMILY,
        payload: null,
      });
      return;
    }

    if (offers === 'familyTicket') {
      dispatch({
        type: ticketsActions.SET_OFFER_FAMILY,
        payload: null,
      });
      return;
    }

    if (offers === 'cheaperWithRelatives') {
      dispatch({
        type: ticketsActions.SET_OFFER_CHEAPER_WITH_RELATIVES,
        payload: null,
      });
      return;
    }

    if (offers.startsWith('autoOffersDisabled')) {
      dispatch({
        type: ticketsActions.SET_AUTO_OFFERS_DISABLED,
        payload: offers.split(':')[1],
      });
      return;
    }

    dispatch({
      type: ticketsActions.RESET_OFFERS,
      payload: null,
    });
  };

  const hasMinimumPreSaleDaysBeforeDeparture = (departureDate: string) => {
    const departure = new Date(departureDate).toDateString();
    const dateNow = new Date().toDateString();

    if (departure === 'Invalid Date' || !departure) {
      return false;
    }

    const requiredPreSaleDays = getTZBRequiredPreSaleDaysFromEnv();

    if (requiredPreSaleDays === null) return false;

    const daysUntilDeparture = durationInDaysBetweenTwoDates(departure, dateNow);

    return daysUntilDeparture >= requiredPreSaleDays;
  };

  const decodeAndFloorDiscountCode = (code: number | null): number => {
    if (code === null) return -1;

    return Math.floor((code - 1000) / 10);
  };

  const verifyDiscountCodesWithOffer = (offerCode: number, ticketsArray: INewTicket[], setExpModalError: boolean) => {
    const offerData = offers && offers.length > 0 ? offers.find((x) => x.kod === offerCode)?.znizki : undefined;

    if (!offerData) return false;

    if (offerCode === 4 || offerCode === 14) {
      offerData.filter((x) => x === 1).length === 0 && offerData.push(1);
      offerData.filter((x) => x === 65).length === 0 && offerData.push(65);
    }

    if ((pkpOffers.bigFamily || pkpOffers.familyTicket) && offerData.filter((x) => x === 65).length === 0) {
      offerData.push(65);
    }

    const offerName = returnOfferStringFromCode(offerCode);
    const notSupportedDiscountCodes = ticketsArray.filter(
      (x) =>
        x.discountCode !== null &&
        x.discountCode !== 0 &&
        !offerData?.find((t) => t === decodeAndFloorDiscountCode(x.discountCode)),
    );

    if (notSupportedDiscountCodes && notSupportedDiscountCodes.length > 0) {
      const offerActive =
        (offerCode === 4 && pkpOffers.familyTicket) ||
        (offerCode === 15 && pkpOffers.bigFamily) ||
        (offerCode === 14 && pkpOffers.cheaperWithRelatives);

      const discountsList = shopDiscounts
        .filter((x) => notSupportedDiscountCodes.find((f) => f.discountCode === x.kodZakupowy))
        .map((m) => {
          const description = m.opisy.find((l) => l.jezyk === i18n.language.toUpperCase())?.nazwa;
          if (description) return ' ' + description;
          return false;
        });

      const discountsLengthGt1 = discountsList.length > 1;
      const modalText = `${discountsLengthGt1 ? t('14001') : t('14002')} ${discountsList} nie ${discountsLengthGt1 ? 'obowiązują' : 'obowiązuje'} ${t('14005')} ${returnOfferNameFromCode(t, offerCode)}.`;

      if (offerActive) {
        setOfferModalError(`${modalText} ${t('14006')}`);
      } else if (setExpModalError) {
        const expandableError = `${modalText} ${t('14007')}`;
        const expErrorExists = offersExpandableError?.find((x) => x.offerName === offerName);

        if (expErrorExists && expErrorExists.offerError === expandableError) {
          return false;
        }
        setExpandableModalError({offerName: offerName, offerError: expandableError});
      }

      return false;
    } else if (offersExpandableError && offersExpandableError.length > 0) {
      removeExpandableError(offerName);
    }

    return true;
  };

  const verifyDiscountCodesWithOffers = (ticketsArray: INewTicket[]) => {
    if (!ticketsArray) return;

    let noErrors = true;
    const offerNumbers = [4, 14, 15];
    offerNumbers.forEach((x) => {
      const result = verifyDiscountCodesWithOffer(x, ticketsArray, false);
      if (!result) noErrors = false;
    });

    return noErrors;
  };

  const returnConditionsObj = (date: string): OfferConditions => {
    const ticketsSum = adultTicketsList.length + childrenTicketsList.length;
    const chTickets = childrenTicketsList.filter((x) => x.discountCode === 1770).length;

    if (!date) date = new Date().toDateString();

    return {
      adultsGt0: adultTicketsList.length > 0,
      adultsGt1: adultTicketsList.length > 1,
      adultsGe2: adultTicketsList.length >= 2,
      adultsKdr: adultTicketsList.filter((x) => x.discountCode === 1570).length > 0,
      childrenGt0: childrenTicketsList.length > 0,
      childrenGe3: childrenTicketsList.length >= 3,
      children77: chTickets > 0 && chTickets <= 3 ? true : false,
      ticketsSumTzB: ticketsSum >= 2,
      ticketsSumGt5: ticketsSum > 5,
      ticketsSumLe5: ticketsSum <= 5,
      ticketsSumLe6: ticketsSum <= 6,
      dateDiffGe1Day: hasMinimumPreSaleDaysBeforeDeparture(date),
      autoOffersDisabled: ticketsState.autoOffersDisabled,
    };
  };

  const canApplyOffer = (date: string, offerName: string): boolean => {
    const conditionsObj = returnConditionsObj(date);

    if (offerName === 'familyTicket' && !conditionsObj.ticketsSumGt5) {
      return verifyDiscountCodesWithOffer(4, [...adultTicketsList, ...childrenTicketsList], true);
    }

    if (offerName === 'bigFamily' && (conditionsObj.childrenGt0 || conditionsObj.adultsGt0)) {
      return verifyDiscountCodesWithOffer(15, [...adultTicketsList, ...childrenTicketsList], true);
    }

    if (offerName === 'cheaperWithRelatives' && conditionsObj.dateDiffGe1Day) {
      return verifyDiscountCodesWithOffer(14, [...adultTicketsList, ...childrenTicketsList], true);
    }

    return false;
  };

  const canApplyOfferExtended = (date: string, offerName: string): boolean => {
    const conditionsObj = returnConditionsObj(date);

    if (offerName === 'cheaperWithRelatives') {
      return (
        conditionsObj.ticketsSumTzB &&
        conditionsObj.adultsGt0 &&
        conditionsObj.dateDiffGe1Day &&
        !pkpOffers.bigFamily &&
        conditionsObj.ticketsSumLe6 &&
        verifyDiscountCodesWithOffer(14, [...adultTicketsList, ...childrenTicketsList], true)
      );
    }

    if (offerName === 'familyTicket') {
      return (
        conditionsObj.adultsGt0 &&
        conditionsObj.children77 &&
        !pkpOffers.bigFamily &&
        !conditionsObj.ticketsSumGt5 &&
        verifyDiscountCodesWithOffer(4, [...adultTicketsList, ...childrenTicketsList], true)
      );
    }

    if (offerName === 'bigFamily') {
      return (
        conditionsObj.adultsKdr &&
        conditionsObj.adultsGt0 &&
        conditionsObj.childrenGe3 &&
        conditionsObj.ticketsSumLe5 &&
        !pkpOffers.familyTicket &&
        verifyDiscountCodesWithOffer(15, [...adultTicketsList, ...childrenTicketsList], true)
      );
    }

    return false;
  };

  const checkOffersConditions = (date: string) => {
    if (pkpOffers.cheaperWithRelatives && !canApplyOffer(date, 'cheaperWithRelatives')) {
      disableOffer('cheaperWithRelatives');
    }

    if (pkpOffers.familyTicket && !canApplyOffer(date, 'familyTicket')) {
      disableOffer('familyTicket');
    }

    if (pkpOffers.bigFamily && !canApplyOffer(date, 'bigFamily')) {
      disableOffer('bigFamily');
    }
  };

  const verifyOfferAndTrainCat = (trains: IConnectionSearchTrainDetails[], departureDate: string, offer: number) => {
    const departure = new Date(departureDate).toDateString();
    const duration = hasMinimumPreSaleDaysBeforeDeparture(departure);

    if (offer === PRICE_CODE_OFFERS.BETTER_WITH_FAMILY && !duration) return 1;
    if (!verifyDiscountCodesWithOffer(offer, [...adultTicketsList, ...childrenTicketsList], false)) return 1;

    const offerData = offers.find((x) => x.kod === offer) ?? undefined;

    if (offerData) {
      if (!offerData.kategoriePociagow.find((x) => x === 'ZKA')) {
        offerData.kategoriePociagow.push('ZKA');
      }

      if (offer === PRICE_CODE_OFFERS.BETTER_WITH_FAMILY && !offerData.kategoriePociagow.find((x) => x === 'EN')) {
        offerData.kategoriePociagow.push('EN');
      }

      const checkTrainsCategories = trains.every((x) => offerData?.kategoriePociagow.includes(x.kategoriaPociagu));

      if (!checkTrainsCategories) {
        if (
          offer === PRICE_CODE_OFFERS.FAMILY_TICKET_OR_SUPER_PROMO_INTERNATIONAL &&
          duration &&
          !trains.some((x) => x.kategoriaPociagu === 'EIC' || x.kategoriaPociagu === 'EIP')
        ) {
          return PRICE_CODE_OFFERS.BETTER_WITH_FAMILY;
        }

        return 1;
      }
    }

    return offer;
  };

  const verifyTicketsAndApplyOffer = (date: string) => {
    const autoOfferDisabledFromStorage = window.sessionStorage.getItem('autoOffersDisabled')?.split(';') ?? [];

    if (
      canApplyOfferExtended(date, 'cheaperWithRelatives') &&
      !autoOfferDisabledFromStorage.some((x) => x === 'cheaperWithRelatives')
    ) {
      pkpOffers.bigFamily && disableOffer('bigFamily');
      setPkpOffers('cheaperWithRelatives');
    } else if (pkpOffers.cheaperWithRelatives && !canApplyOfferExtended(date, 'cheaperWithRelatives')) {
      disableOffer('cheaperWithRelatives');
    }

    if (
      canApplyOfferExtended(date, 'familyTicket') &&
      !pkpOffers.familyTicket &&
      !autoOfferDisabledFromStorage.some((x) => x === 'familyTicket')
    ) {
      pkpOffers.bigFamily && disableOffer('bigFamily');
      setPkpOffers('familyTicket');
    } else if (pkpOffers.familyTicket && (pkpOffers.bigFamily || !canApplyOfferExtended(date, 'familyTicket'))) {
      disableOffer('familyTicket');
    }

    if (
      canApplyOfferExtended(date, 'bigFamily') &&
      !ticketsState.autoOffersDisabled.bigFamily &&
      !pkpOffers.bigFamily &&
      !autoOfferDisabledFromStorage.some((x) => x === 'bigFamily')
    ) {
      pkpOffers.familyTicket && disableOffer('familyTicket');
      pkpOffers.cheaperWithRelatives && disableOffer('cheaperWithRelatives');
      setPkpOffers('bigFamily');
    } else if (pkpOffers.bigFamily && !canApplyOffer(date, 'bigFamily')) {
      disableOffer('bigFamily');
    }
  };

  return (
    <TicketContext.Provider
      value={{
        ticketType,
        setTicketType,
        contextAmenities,
        setContextAmenities,
        from,
        setFrom,
        to,
        setTo,
        addTicketContextFrom,
        setAddTicketContextFrom,
        addTicketContextTo,
        setAddTicketContextTo,
        placesFromContext,
        setPlaceFromContext,
        placesToContext,
        setPlaceToContext,
        ticketQQ,
        setTicketQQ,
        adultTicketsList,
        adultTicketsNoOfferList,
        setAdultTicketsList,
        setAdultTicketsListWithOffer,
        tripBackAdultTicketsList,
        setTripBackAdultTicketsList,
        childrenTicketsList,
        setChildrenTicketsList,
        pkpOffers,
        setPkpOffers,
        disableOffer,
        checkOffersConditions,
        canApplyOffer,
        canApplyOfferExtended,
        offersModalInfo,
        offersModalError,
        offersExpandableError,
        setOfferModalInfo,
        wcgaInfo,
        setWcgaInfo,
        resetOfferModal,
        resetErrorModal,
        resetExpandableErrorsModal,
        preferedPlacesFrom,
        setPreferedPlacesFrom,
        preferedPlacesTo,
        setPreferedPlacesTo,
        verifyOfferAndTrainCat,
        verifyDiscountCodesWithOffers,
        verifyTicketsAndApplyOffer,
        choiceOfPlaceMobileData,
        setChoiceOfPlaceMobileData,
        checkTicketInfo,
        setCheckTicketInfo,
        samePlaceWithChildFrom,
        setSamePlaceWithChildFrom,
        samePlaceWithChildTo,
        setSamePlaceWithChildTo,
        connectionSearchListSelectedIndex,
        setConnectionSearchListSelectedIndex,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export default TicketProvider;
