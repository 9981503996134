/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';

import BlueTitleOnBorderContainer from 'components/common/BlueTitleOnBorderContainer/BlueTitleOnBorderContainer';
import {INewTicket} from 'api/sprzedaz/interfaces';
import {getTicketLabels} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';

import S from './TicketsNewDesktop.module.scss';
import {ReactComponent as Pencil} from 'assets/orangePencil.svg';
import {ReactComponent as ContrastPencil} from 'assets/contrastPencil.svg';
import Ticket from 'assets/ticket.svg';
import ContrastTicket from 'assets/contrastTicket.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  adultCount: INewTicket[];
  childrenCount: INewTicket[];
  discounts: () => void;
}

const TicketsNewDesktop: FC<Props> = ({adultCount, childrenCount, discounts}) => {
  const {t} = useTranslation();
  const {shopDiscounts} = useData();
  const {isContrastTheme} = useStyledThemeState();
  const checkLastWord = (text: string) => {
    const txtArr = text.split(' ');
    if (txtArr[txtArr.length - 1] === 'ulgę') return false;
    return true;
  };

  return (
    <section className={S.sectionWrapper}>
      <BlueTitleOnBorderContainer title={t('11010')}>
        <div className={S.ticketList}>
          <div className={S.ticketLabelWrapper}>
            {adultCount.length > 0 &&
              getTicketLabels(adultCount, true, shopDiscounts, t, false, true).map((label, index) => (
                <div className={S.ticketLabelContainer} key={`adultLabel${index}`}>
                  <img src={isContrastTheme ? ContrastTicket : Ticket} alt={t('11010')} className={S.tickerImg} />
                  <p className={S.ticketLabel}>{label}</p>
                </div>
              ))}
            {childrenCount.length > 0 &&
              getTicketLabels(childrenCount, false, shopDiscounts, t, false, true).map((label, index) => (
                <div className={S.ticketLabelContainer} key={`childLabel${index}`}>
                  <img src={isContrastTheme ? ContrastTicket : Ticket} alt={t('11010')} className={S.tickerImg} />
                  {checkLastWord(label) ? (
                    <p className={S.ticketLabel}>{label}</p>
                  ) : (
                    <a
                      tabIndex={0}
                      aria-label={t('13002')}
                      className={S.ticketLabelError}
                      onClick={discounts}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          discounts();
                        }
                      }}
                    >
                      {label}
                    </a>
                  )}
                </div>
              ))}
          </div>
          <button
            type="button"
            className={S.changeCtaWrapper}
            aria-label={t('13003')}
            onClick={discounts}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                discounts();
              }
            }}
          >
            {t('13004')}
            <span className="hiddenLabel">{t('13005')}</span>
            {isContrastTheme ? <ContrastPencil /> : <Pencil />}
          </button>
        </div>
      </BlueTitleOnBorderContainer>
    </section>
  );
};

export default TicketsNewDesktop;
