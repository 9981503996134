import {FC, useState, useEffect, useRef} from 'react';
import ReactFocusLock from 'react-focus-lock';

import UniversalHeader from 'components/common/UniversalHeader';
import ConfirmButton from 'components/common/ConfirmButton';
import OutlineButton from 'components/common/OutlineButton';
import MovableLabelInput from 'components/common/MovableLabelInput';
import ExclamationLabel from 'common/components/base/ExclamationLabel';
import Icon from 'common/components/base/Icon';
import {Text} from 'common/components/base/Text';

import documentsApi from 'api/dokumenty/repository';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';

import {useWindowResize} from 'common/hooks/screen/useWindowResize';
import {getSeparatedSerialAndNumber} from '../../common/utils';

import {
  ChangeTransportOperation,
  DocumentType,
  DocumentTypeValue,
  IChangeTransportDocumentPayload,
  IDownloadTransportDocumentPayload,
  IDownloadTransportDocumentResponse,
} from 'api/dokumenty/interfaces';
import {IError, IErrorsList} from 'api/error-interface';

import style from './AddPhysicalTicket.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
}

const AddPhysicalTicketDialog: FC<Props> = ({isOpen, closeDialog}) => {
  const {t} = useTranslation();
  const [stage, setStage] = useState<number>(1);
  const [ticketType, setTicketType] = useState<string>('');
  const [ticketSerialAndNumber, setTicketSerialAndNumber] = useState<string>('');
  const [intercityCode, setIntercityCode] = useState<string>('');
  const [internationalTicketNumber, setInternationalTicketNumber] = useState<string>('');
  const [purchaseDate, setPurchaseDate] = useState<string>('');
  const [errors, setErrors] = useState<IError[] | null>(null);
  const {invalidateSeasonalTickets} = useInvalidateMyTicketsQueries();

  const {isMobile} = useWindowResize();
  const ref = useRef<HTMLDialogElement>(null);

  const isIntercityTicketType = ticketType === t('13083');
  const isInternationalTicketType = ticketType === t('13084');

  const ticketTypes: {formName: string; id: string; value: string; description?: string}[] = [
    {
      formName: 'physicalTicket',
      id: 'option1',
      value: '13081',
    },
    {
      formName: 'physicalTicket',
      id: 'option2',
      value: '13082',
    },
    {
      formName: 'physicalTicket',
      id: 'option3',
      value: '13083',
    },
    // Work on this section is suspended until further notice from IC
    // {
    //   formName: 'physicalTicket',
    //   description: '29463',
    //   id: 'option4',
    //   value: '13084',
    // },
  ];

  useEffect(() => {
    if (isOpen) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [isOpen]);

  const setTitle = (num: number) => {
    switch (num) {
      case 1:
        return t('21045');
      case 2:
        return t('13085');
      case 3:
        return t('29319');
      case 4:
        return 'Bilet został dodany';
      default:
        return '';
    }
  };

  const handleClose = () => {
    closeDialog();
    setStage(1);
    setTicketType('');
    setTicketSerialAndNumber('');
    setPurchaseDate('');
    setIntercityCode('');
  };

  const saveTransportDocument = async (
    downloadTransportResponse: IDownloadTransportDocumentResponse,
    documentType: DocumentTypeValue,
  ) => {
    const {dokumentyPrzewozowe} = downloadTransportResponse;
    const {
      seriaDokumentu,
      numerDokumentu,
      numerOferty,
      dataSprzedazy,
      klasa,
      kodZnizki,
      liczbaN,
      liczbaU,
      obowiazujeOd,
      obowiazujeDo,
      odleglosc,
      podrozny,
      stacjaOdjazdu,
      stacjaPrzyjazdu,
    } = dokumentyPrzewozowe[0];

    const request: IChangeTransportDocumentPayload = {
      operacja: ChangeTransportOperation.Save,
      typDokumentu: documentType,
      seriaDokumentu,
      numerDokumentu,
      numerOferty,
      dataSprzedazy,
      klasa,
      kodZnizki,
      liczbaN,
      liczbaU,
      obowiazujeOd,
      obowiazujeDo,
      odleglosc,
      podrozny,
      stacjaOdjazdu,
      stacjaPrzyjazdu,
    };
    const response: IErrorsList | undefined = await documentsApi.changeTransportDocument(request);
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!response) {
      if (response.bledy.length > 0) {
        setErrors(response.bledy);
      } else {
        setErrors(null);
        setStage(4);
        invalidateSeasonalTickets();
      }
    }
  };

  const downloadTransportDocument = async (
    documentType: DocumentTypeValue,
    saleDate?: string,
    documentSerial?: string,
    documentNumber?: string,
    documentCode?: string,
  ) => {
    const request: IDownloadTransportDocumentPayload = {
      typDokumentu: documentType,
      ...(saleDate && {dataSprzedazy: saleDate}),
      ...(documentSerial && {seriaDokumentu: documentSerial}),
      ...(documentNumber && {numerDokumentu: documentNumber}),
      ...(documentCode && {kodDokumentu: documentCode}),
    };
    const response: IDownloadTransportDocumentResponse | undefined =
      await documentsApi.downloadTransportDocument(request);
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!response) {
      if (response.bledy.length > 0) {
        setErrors(response.bledy);
      } else {
        setErrors(null);
        saveTransportDocument(response, documentType);
      }
    }
  };

  const renderErrors = () => {
    return errors && <ExclamationLabel status="error">{t('27008')}</ExclamationLabel>;
  };

  const getUrl = () => {
    switch (ticketType) {
      case t('13083'):
        return (
          <a
            href="https://www.intercity.pl/pl/seria-i-nr-biletu"
            target="_blank"
            rel="noreferrer"
            className={style.addPhysicalTicket__link}
          >
            {t('13089')}
          </a>
        );
      case t('13084'):
        return (
          <a
            href="https://www.intercity.pl/pl/seria-i-nr-biletu"
            target="_blank"
            rel="noreferrer"
            className={style.addPhysicalTicket__link}
          >
            {t('29465')}
          </a>
        );

      default:
        return (
          <a
            href="https://www.intercity.pl/pl/seria-i-nr-biletu"
            target="_blank"
            rel="noreferrer"
            className={style.addPhysicalTicket__link}
          >
            {t('13090')}
          </a>
        );
    }
  };

  const getInputs = () => {
    switch (ticketType) {
      case t('13083'):
        return (
          <MovableLabelInput
            t={t}
            name="addIntercityCode"
            type="text"
            labelText="29356"
            value={intercityCode}
            callback={(e) => {
              setIntercityCode(e.target.value);
              setErrors(null);
            }}
          />
        );
      case t('13084'):
        return (
          <>
            <MovableLabelInput
              t={t}
              name="addInternationalCode"
              type="text"
              labelText="29466"
              value={internationalTicketNumber}
              callback={(e) => {
                setInternationalTicketNumber(e.target.value);
                setErrors(null);
              }}
            />
            <MovableLabelInput
              t={t}
              name="addTicket2"
              type="date"
              labelText="29467"
              value={purchaseDate}
              callback={(e) => {
                setPurchaseDate(e.target.value);
                setErrors(null);
              }}
              max="9999-12-31"
            />
          </>
        );

      default:
        return (
          <>
            <MovableLabelInput
              t={t}
              name="addTicket1"
              type="text"
              labelText="29174"
              value={ticketSerialAndNumber}
              callback={(e) => {
                setTicketSerialAndNumber(e.target.value);
                setErrors(null);
              }}
            />
            <MovableLabelInput
              t={t}
              name="addTicket2"
              type="date"
              labelText="29175"
              value={purchaseDate}
              callback={(e) => {
                setPurchaseDate(e.target.value);
                setErrors(null);
              }}
              max="9999-12-31"
            />
          </>
        );
    }
  };

  return (
    <dialog className={style.addPhysicalTicket__dialog} ref={ref}>
      <ReactFocusLock returnFocus>
        <UniversalHeader
          title={setTitle(stage)}
          goBack={() => {
            !isMobile || (isMobile && stage === 1) ? handleClose() : setStage((prevSage) => prevSage - 1);
          }}
          variant={!isMobile || (isMobile && stage === 1) ? 'exit' : 'goBack'}
        />

        {stage === 1 ? (
          <>
            <div className={style.addPhysicalTicket__stage1}>
              <p>{t('13086')}</p>
              <p>{t('13087')}</p>
            </div>
            <div className={style.stage1_btnBox}>
              <ConfirmButton text={t('22044')} execute={() => setStage(2)} />
            </div>
          </>
        ) : null}
        {stage === 2 ? (
          <div className={style.addPhysicalTicket__stage2}>
            <form name="physicalTicket">
              {ticketTypes.map((item) => {
                return (
                  <label className={style.label} htmlFor={item.id} key={item.id}>
                    <div className={style.inputInnerContainer}>
                      <input
                        className={style.addPhysicalTicket__stage2__input}
                        type="radio"
                        name={item.formName}
                        id={item.id}
                        value={t(item.value)}
                        onChange={(e) => {
                          setTicketType(e.target.value);
                          isMobile && setStage(3);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setStage(3);
                          }
                        }}
                      />
                      <Text variant={isMobile ? 'P' : 'H4'}>{t(item.value)}</Text>
                    </div>
                    {item.description && !isMobile && (
                      <div className={style.additionalDescriptionContainer}>
                        <Text variant="P2">{t(item.description)}</Text>
                      </div>
                    )}
                  </label>
                );
              })}
            </form>
            {isMobile ? null : (
              <div className={style.stage2_btnBox}>
                <OutlineButton text={t('22006')} path={() => handleClose()} />
                <ConfirmButton text={t('29177')} execute={() => setStage(3)} disabled={ticketType.length === 0} />
              </div>
            )}
          </div>
        ) : null}
        {stage === 3 ? (
          <div className={style.addPhysicalTicket__stage3}>
            <p>{t('13088')}</p>
            {getUrl()}
            <aside className={style.addPhysicalTicket__info}>
              <div>
                <Icon icon="info" />
              </div>
              <Text variant="P3" height={1.5}>
                {isInternationalTicketType ? t('29464') : t('13091')}
              </Text>
            </aside>
            <form name="addTicket">
              <p className={style.addPhysicalTicket__redText}>{t('26018')}</p>
              {getInputs()}
              {renderErrors()}
            </form>
            <div className={style.stage3_btnBox}>
              {isIntercityTicketType ? (
                <ConfirmButton
                  text={t('29176')}
                  execute={() => {
                    downloadTransportDocument(DocumentType.ColourCard, undefined, undefined, undefined, intercityCode);
                  }}
                  disabled={!intercityCode || errors !== null}
                />
              ) : (
                <ConfirmButton
                  text={t('29176')}
                  execute={() => {
                    const {serial, number} = getSeparatedSerialAndNumber(ticketSerialAndNumber) || {};
                    downloadTransportDocument(DocumentType.NationalDocumentAutomatic, purchaseDate, serial, number);
                  }}
                  disabled={!ticketSerialAndNumber || !purchaseDate || errors !== null}
                />
              )}
            </div>
          </div>
        ) : null}
        {stage === 4 ? (
          <div className={style.addPhysicalTicket__stage4}>
            <div className={style.addPhysicalTicket__stage4__checkboxIconContainer}>
              <Icon icon="checkboxWithBackground" />
            </div>
            <Text variant="H5" weight={600}>
              Bilet znajdziesz w w Twoich biletach, w zakładce bilety okresowe.
            </Text>
            <div className={style.stage3_btnBox}>
              <ConfirmButton text="ok" execute={() => handleClose()} />
            </div>
          </div>
        ) : null}
      </ReactFocusLock>
    </dialog>
  );
};

export default AddPhysicalTicketDialog;
