import {styled} from '@mui/material';

export const StyledModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.color.shadow.secondary};
  z-index: 100;
`;

export const StyledModal = styled('div')`
  border-radius: 0.4rem;
  border: 1px solid ${({theme}) => theme.color.border.primary};
  background-color: ${({theme}) => theme.color.background.primary};
`;

export const ModalWrapper = styled('div')`
  min-width: 85rem;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  gap: 1.6rem;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const TicketList = styled('div')`
  max-height: 80rem;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
