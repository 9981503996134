import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Loader from 'components/common/Loader/Loader';
import {useStyledThemeState} from 'common/theme';

import S from './PaymentRedirectModal.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';

interface Props {
  onClose: () => void;
  loaderMessage: string;
}

const PaymentRedirectModal: FC<Props> = ({onClose, loaderMessage}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <div className={S.modalBackground}>
      <div className={S.modalContainer}>
        <button className={S.closeButton} onClick={onClose}>
          <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('29002')} width={20} height={20} />
        </button>
        <div className={S.topRow}>
          <h1 className={S.header}>{t('22004').toString()}</h1>
        </div>
        <div className={S.content}>
          <Loader variant="circle" />
          <p className={S.text}>{t(loaderMessage)}</p>
        </div>
      </div>
    </div>
  );
};
export default PaymentRedirectModal;
