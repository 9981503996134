import {removeToken} from 'Utils/auth';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {profileMenuElements} from './userProfileMenu';
import {env} from 'env';
import {useTranslation} from 'react-i18next';

const useRegister = () => {
  const [login, setLogin] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<number>(0);
  const navigate = useNavigate();
  const {i18n} = useTranslation();

  const handleLogout = () => {
    setLogin(false);
    removeToken('eic2Token');
    removeToken('eic2Uuid');
    window.location.replace(`${env.REACT_APP_SSO_SERVER}/logout?lang=${i18n.language}`);
  };

  const handleActiveButton = (btnNumber: number) => {
    const active = profileMenuElements.find((x) => x.id === btnNumber) ?? profileMenuElements[0];

    setActiveButton(btnNumber);
    navigate(active.url);
  };

  useEffect(() => {
    const localstorage = window.sessionStorage.eic2Token;
    if (localstorage) {
      setLogin(true);
    }
  }, []);

  return {
    login,
    setLogin,
    activeButton,
    setActiveButton,
    handleActiveButton,
    handleLogout,
  };
};

export default useRegister;
