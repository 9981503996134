import {IAmenities} from 'api/pociagi/interfaces';

const InitioalObject: IAmenities = {
  polaczeniaBezposrednie: 0,
  kategoriePociagow: [],
  kodyPrzewoznikow: [],
  rodzajeMiejsc: [],
  typyMiejsc: [],
  braille: 0,
  fakeNumberToCounter: [],
};

export default InitioalObject;
