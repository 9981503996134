import React from 'react';
import {useIsFetching} from '@tanstack/react-query';
import {MyTicketIdentifier, useMyTicketsPageData} from 'common/api/myTickets';
import {MyTicketsListPageLoader} from '../MyTicketsListPageLoader';
import {Ticket} from '../Ticket';
import {TicketDetailsModal} from '../../wrappers';

interface MyTicketsListPageProps {
  identifiers: MyTicketIdentifier[] | undefined;
}

export const MyTicketsListPage = React.memo(({identifiers}: MyTicketsListPageProps) => {
  const isFetchingIdentifiers = useIsFetching({queryKey: ['myTickets']});
  const {data, isFetching} = useMyTicketsPageData(identifiers);

  return isFetchingIdentifiers || isFetching ? (
    <MyTicketsListPageLoader />
  ) : (
    <React.Fragment>
      {identifiers?.map((identifier, index) => {
        const ticket = data[index];
        return ticket ? (
          <Ticket
            key={`${identifier.transakcjaNr}_${identifier.biletSeria}_${identifier.biletNr}`}
            identifier={identifier}
            ticket={ticket}
          />
        ) : null;
      })}
      <TicketDetailsModal />
    </React.Fragment>
  );
});
