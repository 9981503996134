import {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState} from 'react';

import {IGetTokenResponse} from '../api/auth/interfaces';
import {getJwtTokenOrDefault} from './auth';
import userApi from 'api/user/repository';
import {IUserData} from 'api/user/interfaces';

type IAuthContext = {
  token: IGetTokenResponse | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  userData: IUserData | undefined;
  fetchAndSaveUserData: () => Promise<void> | null;
};

const AuthContext = createContext<IAuthContext>({
  token: undefined,
  loading: false,
  setLoading: () => null,
  userData: undefined,
  fetchAndSaveUserData: () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface Props {
  children: ReactNode;
}

const AuthProvider: FC<Props> = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>();

  const token: IGetTokenResponse | undefined = getJwtTokenOrDefault('eic2Token');

  const fetchAndSaveUserData = async () => {
    await userApi.getUserData().then((data) => {
      data && setUserData(data);
    });
  };

  const value = {
    token,
    loading,
    setLoading,
    userData,
    fetchAndSaveUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
