import {FC, useState, useEffect} from 'react';
import {ISearchConnectionFormData} from '../../../../../interfaces/forms';
import {IAmenities} from 'api/pociagi/interfaces';
import {INewTicket} from 'api/sprzedaz/interfaces';
import {useStyledThemeState} from 'common/theme';
import ConfirmButton from 'components/common/ConfirmButton';
import TicketsNewDesktop from 'components/pages/PurchasePath/PurchasePathDesktop/TicketsNewDesktop';
import AmenitiesNewDesktop from 'components/pages/PurchasePath/PurchasePathDesktop/AmenitiesNewDesktop';
import useSearchLogic from 'components/pages/PurchasePath/useSearchLogic';
import AmenitiesMobile from 'components/pages/PurchasePath/PurchasePathMobile/Amenities/AmenitiesMobile';
import Tickets from 'components/pages/PurchasePath/PurchasePathMobile/Tickets';
import style from './BuyerProfile.module.scss';
import {ReactComponent as Arrows} from 'assets/orangeHorizontalArrows.svg';
import {ReactComponent as ContrastArrows} from 'assets/contrastHorizontalArrows.svg';
import {ReactComponent as TicketIcon} from 'assets/ticket.svg';
import {ReactComponent as TicketIconContrast} from 'assets/contrastTicket.svg';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import ProfilesList from 'components/pages/UserProfile/components/BuyerProfile/ProfilesList';
import api from 'api/user/repository';
import {produce, setAutoFreeze} from 'immer';
import queryString from 'query-string';
import {BuyerProfileActions, BuyerProfileScreen, useDispatch, useTrackedState} from 'Utils/BuyerProfileContext';
import SetDiscounts from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts';
import ticketsToDiscounts from 'components/pages/UserProfile/components/BuyerProfile/helpers/ticketsToDiscounts';
import {useSWRConfig} from 'swr';
import {getTicketLabelsFromDiscounts} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import AutocompleteInput, {AutocompleteVariants} from 'components/common/AutocompleteInput';
import AutocompleteWrapper from 'components/common/AutocompleteInput/AutocompleteWrapper';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Discount} from './BuyerProfiles.interface';

interface Props {
  close?: () => void;
  initialSearchData?: ISearchConnectionFormData;
}

setAutoFreeze(false);

const AddBuyerProfile: FC<Props> = ({initialSearchData}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [discounts, setDiscounts] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openTickets, setOpenTickets] = useState<boolean>(false);
  const {mutate} = useSWRConfig();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {activeProfile} = useTrackedState();
  const {amenities, setAmenities, searchFormData, setSearchFormTripBack, setTripBack} = useSearchLogic(true);

  const {shopDiscounts} = useData();
  const [adultTickets, setAdultTickets] = useState<INewTicket[]>([
    {
      discountCode: 1010,
      ticketTypeCode: 1,
    },
  ]);
  const [childrenTickets, setChildrenTickets] = useState<INewTicket[]>([]);
  const [searchData, setSearchData] = useState({...initialSearchData});
  const [tripBackState, setTripBackState] = useState(false);
  const [profileName, setProfileName] = useState<string>(activeProfile?.name ? activeProfile?.name : '');

  const childTicketsFromDiscounts = () => {
    const query = queryString?.parse(window.location.search);
    const queryDiscounts: [] = query?.discounts
      ? JSON.parse(query.childDiscounts as string)
      : childrenTickets.map((el) => el.discountCode?.toString());
    const childrenTicketsList: Discount[] = queryDiscounts.map((el: string): Discount => {
      return {
        type: 'CHILD',
        id: Number(el),
        code: Number(el),
      };
    });

    return childrenTicketsList.filter((x) => x.type === 'CHILD');
  };

  const adultTicketsFromDiscounts = () => {
    const query = queryString?.parse(window.location.search);
    const queryDiscounts: [] = query?.discounts
      ? JSON.parse(query.discounts as string)
      : adultTickets.map((el) => el.discountCode?.toString());
    const adultTicketsList: Discount[] = queryDiscounts.map((el: string): Discount => {
      return {
        type: 'ADULT',
        id: Number(el),
        code: Number(el),
      };
    });
    return adultTicketsList.filter((x) => x.type === 'ADULT');
  };

  useEffect(() => {
    setSearchData({...initialSearchData});
  }, [initialSearchData]);

  useEffect(() => {
    if (tripBackState && (searchData.from === 0 || searchData.destination === 0)) {
      setTripBackState(false);
      handleTripBackToggle(false);
      window.scrollTo(0, 0);
    }
  }, [searchData.from, searchData.destination]);

  useEffect(() => {
    console.log(discounts);
  }, [discounts]);

  const GetFromReturn = (data: number) => {
    setSearchFormTripBack((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };
  const GetDestinationReturn = (data: number) => {
    setSearchFormTripBack((prev) => {
      return {
        ...prev,
        destination: data,
      };
    });
  };
  const handleTripBackToggle = (arg: boolean) => {
    GetFromReturn(searchFormData.destination);
    GetDestinationReturn(searchFormData.from);
    setTripBack(arg);
  };

  const handleSetAmenities = (amenities: IAmenities) => {
    setAmenities(amenities);

    if (activeProfile) {
      const jsonifiedAmen = JSON.stringify(amenities);
      activeProfile.filters = [{id: 99999, filter: `amenities_${jsonifiedAmen}`}];
    }
  };
  const isAllRequiredDataProvided =
    activeProfile?.name &&
    activeProfile.name.length > 0 &&
    activeProfile?.stationFrom &&
    activeProfile?.stationTo &&
    activeProfile?.discounts?.length &&
    activeProfile?.discounts?.length > 0;

  useEffect(() => {
    if (activeProfile) {
      dispatch({
        type: BuyerProfileActions.UPDATE_ACTIVE_PROFILE,
        payload: {
          name: profileName,
          discounts: ticketsToDiscounts(adultTickets, childrenTickets),
          filters: activeProfile.filters ? activeProfile.filters : [],
          intermediateStation: 0,
        },
      });
    }
  }, [searchData, adultTickets, childrenTickets, searchFormData]);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const stationFrom = !!query?.stationFrom && typeof query?.stationFrom == 'string' && query?.stationFrom;

    const stationTo = !!query?.stationTo && typeof query?.stationTo == 'string' && query?.stationTo;

    if (query?.discounts && typeof query.discounts === 'string' && !!query.discounts.length) {
      try {
        const queryDiscounts = JSON.parse(query?.discounts);

        const mappedQueryDiscounts: INewTicket[] = queryDiscounts?.map((e: string) => ({
          discountCode: e,
          ticketTypeCode: null,
        }));
        setAdultTickets(mappedQueryDiscounts);
      } catch (error) {
        console.error(error);
      }
    }
    if (query?.childDiscounts && typeof query.childDiscounts === 'string' && !!query.childDiscounts.length) {
      try {
        const queryDiscounts = JSON.parse(query?.childDiscounts);
        const mappedQueryDiscounts: INewTicket[] = queryDiscounts?.map((e: string) => ({
          discountCode: e,
          ticketTypeCode: null,
        }));
        setChildrenTickets(mappedQueryDiscounts);
      } catch (error) {
        console.error(error);
      }
    }
    if (stationFrom && stationTo) {
      dispatch({
        type: BuyerProfileActions.SET_ACTIVE_PROFILE,
        payload: {
          name: '',
          stationFrom: Number(stationFrom),
          stationTo: Number(stationTo),
        },
      });
    }
  }, []);

  const handleSubmit = async () => {
    if (submitted) {
      return;
    }

    setSubmitted(true);

    try {
      if (activeProfile) {
        const data = produce(activeProfile, (draft) => {
          delete draft.id;
        });

        await api.postBuyerProfile(data);

        await mutate('get_user_profiles');
        dispatch({
          type: BuyerProfileActions.SET_SCREEN,
          payload: BuyerProfileScreen.MAIN,
        });
        navigate('/profil/profilezakupowe');
      }
    } catch (error) {
      console.error();
    }
  };

  const handleReSetDiscounts = () => null;

  const handleCloseModal = () => {
    setDiscounts(false);
  };

  return (
    <div className={`${style.buyer_modal}`}>
      <div className={`${style.buyer_modal__box}`}>
        <div>
          <header className={style.buyer_modal__header}>
            <h2>{t('29208')}</h2>
            <button
              type="button"
              aria-label={t('29208')}
              onClick={() =>
                dispatch({
                  type: BuyerProfileActions.SET_SCREEN,
                  payload: BuyerProfileScreen.MAIN,
                })
              }
            >
              <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
            </button>
          </header>
          <ProfilesList setProfileName={setProfileName} />
          <h3 className={style.buyer_modal__blueheader}>{t('29075')}</h3>
          <AutocompleteWrapper>
            <AutocompleteInput
              variant={AutocompleteVariants.FROM}
              label={t('11030')}
              autocompleteValue={activeProfile?.stationFrom}
              setAutocompleteValue={(arg) => {
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!activeProfile) {
                  dispatch({
                    type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                    payload: {...activeProfile, stationFrom: arg.kod},
                  });
                }
              }}
            />
            <button
              onClick={() => {
                dispatch({
                  type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                  payload: produce(activeProfile ? activeProfile : {name: ''}, (draft) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const from = produce(draft.stationFrom, (draft) => {});
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const to = produce(draft.stationTo, (draft) => {});
                    draft.stationTo = from;
                    draft.stationFrom = to;
                  }),
                });
              }}
              className={style.changeDirectionButton}
              type="button"
            >
              {isContrastTheme ? <ContrastArrows /> : <Arrows />}
            </button>
            <AutocompleteInput
              variant={AutocompleteVariants.TO}
              label={t('11032')}
              autocompleteValue={activeProfile?.stationTo}
              setAutocompleteValue={(arg) => {
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!activeProfile) {
                  dispatch({
                    type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                    payload: {...activeProfile, stationTo: arg.kod},
                  });
                }
              }}
            />
          </AutocompleteWrapper>

          {/*         Mobile       */}

          <div className={style.display_on_mobile}>
            <div className={style.optionBox}>
              <div className={style.optionHeader}>
                <p className={style.optionLabel}>{t('11010')}</p>
              </div>
              <div className={style.ticketsCount}>
                <div className={style.ticketsCountLabels}>
                  {isContrastTheme ? <TicketIconContrast /> : <TicketIcon />}
                  <div className={style.profile_ticketsTickets}>
                    {adultTicketsFromDiscounts() &&
                      getTicketLabelsFromDiscounts(adultTicketsFromDiscounts(), true, shopDiscounts, t).map(
                        (label, index) => <span key={`adultLabel${index}`}>{label}</span>,
                      )}

                    {childTicketsFromDiscounts() &&
                      getTicketLabelsFromDiscounts(childTicketsFromDiscounts(), false, shopDiscounts, t).map(
                        (label, index) => <span key={`adultLabel${index}`}>{label}</span>,
                      )}
                  </div>
                </div>
                <Tickets
                  adultCount={adultTickets}
                  changeAdult={setAdultTickets}
                  childrenCount={childrenTickets}
                  changeChildren={setChildrenTickets}
                  modalModeOpen={openTickets}
                  modalModeClose={setOpenTickets}
                  buyerProfilesMode
                />
              </div>
            </div>
            <AmenitiesMobile amenities={amenities} setAmenities={handleSetAmenities} />
          </div>

          {/*         Desktop          */}

          <div className={`${style.buyer_modal__parameterselect} ${style.display_on_desktop}`}>
            <div className={style.buyer_modal__tickets}>
              <TicketsNewDesktop
                adultCount={adultTickets}
                childrenCount={childrenTickets}
                discounts={() => setDiscounts(!discounts)}
              />
            </div>
            <div className={style.buyer_modal__amenities}>
              <AmenitiesNewDesktop amenities={amenities} setAmenities={handleSetAmenities} />
            </div>
          </div>
        </div>
        <div className={style.buyer_modal__confirmbtn}>
          <ConfirmButton text={t('15048')} execute={() => handleSubmit()} disabled={!isAllRequiredDataProvided} />
        </div>
      </div>
      {discounts && (
        <div className={style.yourTrip__curtain}>
          <div className={style.yourTrip__modal} id="modalScrollContainer">
            <button
              aria-label={t('29217')}
              className={style.closeBtn}
              onClick={() => {
                handleCloseModal();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCloseModal();
                }
              }}
            >
              <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
            </button>
            <SetDiscounts
              adultCount={adultTickets}
              changeAdult={setAdultTickets}
              childrenCount={childrenTickets}
              changeChildren={setChildrenTickets}
              manageDiscounts={handleReSetDiscounts}
              passOpenModal={() => setDiscounts(false)}
              buyerProfileMode={true}
              modalMode
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBuyerProfile;
