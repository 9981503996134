import React from 'react';
import {useLocation} from 'react-router-dom';
import {
  StateContext,
  DispatchContext,
  Action,
  MyTicketsActions,
  MyTicketsLocationState,
  isMyTicketVariant,
  MyTicketsListVariants,
} from '../MyTicketsList.types';
import {exhaustiveCheck} from 'common/utils';
import {isHistoryVariant} from '../MyTicketsList.utils';
import {useWindowResize} from 'common/hooks/screen';

interface MyTicketsProviderProps {
  children: React.ReactNode;
  variants: MyTicketsListVariants[];
}

const MyTicketsStateContext = React.createContext<StateContext | undefined>(undefined);
const MyTicketsDispatchContext = React.createContext<DispatchContext | undefined>(undefined);

const reducer = (state: StateContext, action: Action): StateContext => {
  switch (action.type) {
    case MyTicketsActions.SET_VARIANT: {
      return {
        ...state,
        ticketVariant: action.payload,
        isHistoryVariant: isHistoryVariant(action.payload) ? 1 : 0,
        currentPage: 1,
      };
    }
    case MyTicketsActions.SET_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default: {
      return exhaustiveCheck(action, 'MyTickets Action');
    }
  }
};

export const MyTicketsProvider = React.memo(({children, variants}: MyTicketsProviderProps) => {
  const {isMobile} = useWindowResize();
  const {state} = useLocation() as MyTicketsLocationState;
  const initalTicketVariant = React.useMemo(
    () =>
      isMyTicketVariant(state?.tab) && variants.includes(state.tab)
        ? state.tab
        : variants.length
          ? variants[0]
          : 'SINGLE',
    [state?.tab, variants],
  );
  const [myTicketsState, dispatch] = React.useReducer(
    reducer,
    new StateContext(variants, initalTicketVariant, isHistoryVariant(initalTicketVariant) ? 1 : 0, 1, isMobile),
  );

  const stateValue = React.useMemo(() => ({...myTicketsState, variants}), [myTicketsState, variants]);

  const setTicketVariant = React.useCallback((variant: MyTicketsListVariants) => {
    dispatch({type: MyTicketsActions.SET_VARIANT, payload: variant});
  }, []);

  const changeTicketVariant = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
    if (isMyTicketVariant(newValue)) {
      dispatch({type: MyTicketsActions.SET_VARIANT, payload: newValue});
    } else {
      throw new Error(`Expected 'newValue' to be MyTicketsListVariant, but received ${newValue}`);
    }
  }, []);

  const changeCurrentPage = React.useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    dispatch({type: MyTicketsActions.SET_PAGE, payload: value});
  }, []);

  const dispatchValue = React.useMemo(
    () => ({setTicketVariant, changeTicketVariant, changeCurrentPage}),
    [setTicketVariant, changeTicketVariant, changeCurrentPage],
  );

  return (
    <MyTicketsStateContext.Provider value={stateValue}>
      <MyTicketsDispatchContext.Provider value={dispatchValue}>{children}</MyTicketsDispatchContext.Provider>
    </MyTicketsStateContext.Provider>
  );
});

export const useMyTicketsState = () => {
  const context = React.useContext(MyTicketsStateContext);

  if (!context) {
    throw new Error('useMyTicketsState must be used within the MyTicketsProvider');
  }

  return context;
};

export const useMyTicketsDispatch = () => {
  const context = React.useContext(MyTicketsDispatchContext);

  if (!context) {
    throw new Error('useMyTicketsDispatch must be used within the MyTicketsProvider');
  }

  return context;
};
