import React from 'react';
import {Theme} from './types';
import {StyledEngineProvider, ThemeProvider, createTheme, CssBaseline, GlobalStyles} from '@mui/material';
import {plPL} from '@mui/material/locale';
import {styledThemes, globalStyles} from './consts';

interface MuiProviderProps {
  children: React.ReactNode;
  theme: Theme;
}

export const MuiProvider = React.memo(({children, theme}: MuiProviderProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      {/*
          After implementing i18next, you need to pass a set of translations for the components from MUI.
       */}
      <ThemeProvider theme={createTheme(styledThemes[theme], plPL)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
});
