import {FC, useState} from 'react';
import Box from '@mui/material/Box';
import Switch from 'components/common/Switch/Switch';

import S from './AmenitiesOption.module.scss';
import {useTranslation} from 'react-i18next';

interface ConfirmOptionDataProps {
  text: string;
  selected?: boolean;
  setSelected?: (e: boolean) => void;
}
const AmenitiesOption: FC<ConfirmOptionDataProps> = ({text, selected, setSelected}) => {
  const {t} = useTranslation();
  const [check, setCheck] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '22px',
        width: '100%',
      }}
    >
      <Switch
        selected={selected ? selected : check}
        setSelected={setSelected ? setSelected : setCheck}
        name={text}
        id={`switch-${text}`}
        className={S.amnesties_switch}
      >
        <span className={S.hidden} aria-atomic="true" aria-live="polite">
          {selected ? `${t('15010')} ${text}` : `${t('15011')} ${text}`}
        </span>
        <span style={{display: 'none'}}>{text}</span>
        <Box sx={{width: '80%'}}>
          <p className={S.text}>{text}</p>
        </Box>
      </Switch>
    </Box>
  );
};
export default AmenitiesOption;
