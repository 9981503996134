import {SearchContainer, SearchIconContainer, SearchInputStyled} from './SearchInput.style';
import Icon from '../../base/Icon';
import {useTranslation} from 'react-i18next';
import {ChangeEvent} from 'react';
import {useWindowResize} from 'common/hooks/screen';

interface SearchInputProps {
  searchTerm: string;
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({searchTerm, handleSearchChange}: SearchInputProps) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowResize();
  return (
    <SearchContainer>
      <SearchInputStyled type="text" placeholder={t('29416')} value={searchTerm} onChange={handleSearchChange} />
      {isMobile ? null : (
        <SearchIconContainer>
          <Icon icon="magnifier" variant="large" />
        </SearchIconContainer>
      )}
    </SearchContainer>
  );
};
