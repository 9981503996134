import {myTicketsListGuestVariants, myTicketsListVariants} from './MyTicketsList.consts';

export type MyTicketsListVariants = (typeof myTicketsListVariants)[number];
export type MyTicketsListGuestVariants = (typeof myTicketsListGuestVariants)[number];

export enum MyTicketsActions {
  SET_VARIANT = 'SET_VARIANT',
  SET_PAGE = 'SET_PAGE',
}

export type Action =
  | {type: MyTicketsActions.SET_VARIANT; payload: MyTicketsListVariants}
  | {type: MyTicketsActions.SET_PAGE; payload: number};

export class StateContext {
  variants: MyTicketsListVariants[];
  ticketVariant: MyTicketsListVariants;
  isHistoryVariant: 0 | 1;
  currentPage: number;
  isMobileVersion: boolean;

  constructor(
    variants: MyTicketsListVariants[],
    ticketVariant: MyTicketsListVariants,
    isHistoryVariant: 0 | 1,
    currentPage: number,
    isMobileVersion: boolean,
  ) {
    this.variants = variants;
    this.ticketVariant = ticketVariant;
    this.isHistoryVariant = isHistoryVariant;
    this.currentPage = currentPage;
    this.isMobileVersion = isMobileVersion;
  }
}

export interface MyTicketsLocationState {
  state?: {
    tab?: MyTicketsListVariants;
  };
}

export interface DispatchContext {
  setTicketVariant: (variant: MyTicketsListVariants) => void;
  changeTicketVariant: (event: React.SyntheticEvent, newValue: string) => void;
  changeCurrentPage: (event: React.ChangeEvent<unknown>, value: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMyTicketVariant = (value: any): value is MyTicketsListVariants => {
  return myTicketsListVariants.includes(value);
};
