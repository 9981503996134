import {styled} from '@mui/material/styles';
import {mediaQueries} from 'common/theme/consts';

export const Header = styled('h2')`
  @media ${mediaQueries.sm} {
    margin: 0 0 3.2rem 0;
  }
`;
export const Paragraph = styled('p')`
  margin: 0;
  padding: 0;
`;
export const TextBox = styled('div')`
  margin-top: 8px;
`;
export const RemovalConfirmationModal = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({theme}) => theme.color.background.secondary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media ${mediaQueries.sm} {
    position: unset;
    padding: 4rem 4rem 4.8rem 4rem;
    background: ${({theme}) => theme.color.background.primary};
    width: 86.6rem;
  }
`;
export const Confirmation = styled('p')`
  margin-top: 3.2rem;
  margin-bottom: 4rem;
`;
export const ButtonRow = styled('div')`
  display: flex;
  gap: 1.5rem;
  transform: translateY(-54px);
  flex-direction: column;
  margin: 0 auto 0.8rem;
  width: 100%;
  padding: 0 0.4rem;
  @media ${mediaQueries.sm} {
    display: flex;
    gap: 1.5rem;
    transform: unset;
    flex-direction: unset;
    margin: unset;
    width: unset;
    padding: unset;
  }
`;
export const CloseButtonWrapper = styled('button')`
  all: unset;
  cursor: pointer;
`;
export const ContentRow = styled('div')`
  padding: 1.6rem 1.6rem 0 1.6rem;
  @media ${mediaQueries.sm} {
    padding: unset;
  }
`;
