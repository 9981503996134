import {AppSettings, BuyerProfile, MyData, MyInvoices, GuestLogin} from 'components/pages/UserProfile/components';
import UsefulLinks from './components/UsefulLinks/UsefulLinks';
import MyInvoicesMobile from './components/MyInvoices/MyInvoicesMobile';
import SafeLogin from './components/SafeLogin/SafeLogin';
import {MyTicketsList} from 'common/pages/UserProfile/MyTickets';

export const profileMenuElements = [
  {
    id: 0,
    text: '22015',
    name: 'profil',
    url: '/profil',
    path: '/',
    component: undefined,
    componentMobile: undefined,
    mobile: false,
    desktop: false,
    needLogin: false,
    hideInMenu: false,
    disableInGuestAcc: true,
  },
  {
    id: 1,
    text: '10003',
    name: 'mojebilety',
    url: '/profil/mojebilety',
    path: '/mojebilety',
    component: <MyTicketsList title="10003" variants={['SINGLE', 'SEASONAL', 'HISTORY', 'RETURNED']} />,
    componentMobile: <MyTicketsList title="13092" variants={['SINGLE', 'SEASONAL']} />,
    mobile: true,
    desktop: true,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: false,
  },
  {
    id: 2,
    text: '29030',
    name: 'historiapodrozy',
    url: '/profil/historiapodrozy',
    path: '/historiapodrozy',
    component: undefined,
    componentMobile: <MyTicketsList title="Bilety historyczne" variants={['HISTORY']} />,
    mobile: true,
    desktop: false,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: false,
  },
  {
    id: 3,
    text: '29149',
    name: 'biletyzwrocone',
    url: '/profil/biletyzwrocone',
    path: '/biletyzwrocone',
    component: undefined,
    componentMobile: <MyTicketsList title="29149" variants={['RETURNED']} />,
    mobile: true,
    desktop: false,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: false,
  },
  {
    id: 4,
    text: '10017',
    name: 'mojefaktury',
    url: '/profil/mojefaktury',
    path: '/mojefaktury',
    component: <MyInvoices />,
    componentMobile: <MyInvoicesMobile navigateLink="/profil" />,
    mobile: true,
    desktop: true,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: false,
  },
  {
    id: 5,
    text: '10018',
    name: 'mojedane',
    url: '/profil/mojedane',
    path: '/mojedane',
    component: <MyData />,
    componentMobile: <MyData navigateLink="/profil" />,
    mobile: true,
    desktop: true,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: true,
  },
  {
    id: 6,
    text: '10019',
    name: 'profilezakupowe',
    url: '/profil/profilezakupowe',
    path: '/profilezakupowe',
    component: <BuyerProfile />,
    componentMobile: <BuyerProfile />,
    mobile: true,
    desktop: true,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: true,
  },

  {
    id: 7,
    text: '10020',
    name: 'ustawienia',
    url: '/profil/ustawienia',
    path: '/ustawienia',
    component: <AppSettings />,
    componentMobile: <AppSettings navigateLink="/profil" />,
    mobile: true,
    desktop: true,
    needLogin: true,
    hideInMenu: false,
    disableInGuestAcc: true,
  },

  {
    id: 8,
    text: '10021',
    name: 'loginkontogosc',
    url: '/profil/loginkontogosc',
    path: '/loginkontogosc',
    component: <GuestLogin />,
    componentMobile: <GuestLogin />,
    mobile: true,
    desktop: true,
    needLogin: false,
    hideInMenu: false,
    disableInGuestAcc: false,
    displayOnlyInGuestAccount: true,
  },
  {
    id: 9,
    text: '10022',
    name: 'przydatnelinki',
    url: '/profil/przydatnelinki',
    path: '/przydatnelinki',
    component: <UsefulLinks />,
    componentMobile: undefined,
    mobile: false,
    desktop: true,
    needLogin: false,
    hideInMenu: false,
    disableInGuestAcc: false,
  },
  {
    id: 10,
    text: 'bezpieczne_logowanie',
    name: 'bezpieczne_logowanie',
    url: '/bezpieczne_logowanie',
    path: '/bezpieczne_logowanie', // TODO: check route
    component: <SafeLogin />,
    componentMobile: undefined,
    mobile: true,
    desktop: true,
    needLogin: false,
    hideInMenu: true,
    disableInGuestAcc: true,
  },
];
