/* eslint-disable no-case-declarations */
import {INewTicket, IPkpOffers} from 'api/sprzedaz/interfaces';
export const ticketsAndDisscountInitial = {
  adultTicketsList: [] as INewTicket[],
  tripBackAdultTicketsList: [] as INewTicket[],
  childrenTicketsList: [] as INewTicket[],
  pkpOffers: {
    familyTicket: false,
    bigFamily: false,
    cheaperWithRelatives: false,
  } as IPkpOffers,
  offerChoosenByUser: {
    familyTicket: false,
    bigFamily: false,
    cheaperWithRelatives: false,
  } as IPkpOffers,
  autoOffersDisabled: {
    familyTicket: false,
    bigFamily: false,
    cheaperWithRelatives: false,
  } as IPkpOffers,
  offerModalInfo: [] as string[],
  offerModalError: [] as string[],
  offerExpandableError: [] as ExpandableError[],
  wcgaInfo: {} as {car: string; ticket: string},
};

export const ticketsActions = {
  UPDATE_ADULT_TICKETS: 'UPDATE_ADULT_TICKETS',
  UPDATE_TRIP_BACK_ADULT_TICKETS: 'UPDATE_TRIP_BACK_ADULT_TICKETS',
  UPDATE_CHILDREN_TICKETS: 'UPDATE_CHILDREN_TICKETS',
  SET_OFFER_FAMILY: 'SET_OFFER_FAMILY',
  SET_OFFER_BIG_FAMILY: 'SET_OFFER_BIG_FAMILY',
  SET_OFFER_CHEAPER_WITH_RELATIVES: 'SET_OFFER_CHEAPER_WITH_RELATIVES',
  DISABLE_OFFER: 'DISABLE_OFFER',
  SET_AUTO_OFFERS_DISABLED: 'SET_AUTO_OFFERS_DISABLED',
  SET_OFFER_MODAL_INFO: 'SET_OFFER_MODAL_INFO',
  SET_OFFER_MODAL_ERROR: 'SET_OFFER_MODAL_ERROR',
  SET_OFFER_EXPANDABLE_ERROR: 'SET_OFFER_EXPANDABLE_ERROR',
  SET_WCGA_INFO: 'SET_WCGA_INFO',
  RESET_OFFERS: 'RESET_OFFERS',
  RESET_OFFER_MODAL: 'RESET_OFFER_MODAL',
  RESET_ERRORS_MODAL: 'RESET_ERRORS_MODAL',
  RESET_EXPANDABLE_ERRORS_MODAL: 'RESET_EXPANDABLE_ERRORS_MODAL',
  REMOVE_EXPANDABLE_ERROR: 'REMOVE_EXPANDABLE_ERROR',
};

export type ExpandableError = {
  offerName: string;
  offerError: string;
};

export type Action = {
  type: string;
  payload: INewTicket[] | string | null | {car: string; ticket: string} | ExpandableError;
};
export type Store = typeof ticketsAndDisscountInitial;

const changeAdultTicketsToFamily = (adultTickets: INewTicket[]) => {
  return [...adultTickets].map((x) => {
    if (x.discountCode === 1010) return {...x, discountCode: 1650};
    return x;
  });
};

const changeFamilyTicketsToAdult = (adultTickets: INewTicket[]) => {
  return [...adultTickets].map((x) => {
    if (x.discountCode === 1650) return {...x, discountCode: 1010};
    return x;
  });
};

const saveOfferToSessionStorage = (pkpOffers: IPkpOffers) => {
  const offersEnabledFromStorage = window.sessionStorage.getItem('offersEnabled');
  const offersStringified = JSON.stringify(pkpOffers);

  if (offersEnabledFromStorage) {
    window.sessionStorage.removeItem('offersEnabled');
  }

  window.sessionStorage.setItem('offersEnabled', offersStringified);
};

export const ticketsReducer = (state: Store, action: Action): Store => {
  const cheaperInfo = '14008';
  const familyInfo = '14009';
  const bigFamilyInfo = '14010';
  const cheaperFamilyInfo = '14011';

  switch (action.type) {
    case ticketsActions.UPDATE_ADULT_TICKETS:
      const adultList = action.payload as INewTicket[];
      return {...state, adultTicketsList: adultList};

    case ticketsActions.UPDATE_TRIP_BACK_ADULT_TICKETS:
      const tbAdultList = action.payload as INewTicket[];
      return {...state, tripBackAdultTicketsList: tbAdultList};

    case ticketsActions.UPDATE_CHILDREN_TICKETS:
      const childrenList = action.payload as INewTicket[];
      return {...state, childrenTicketsList: childrenList};

    case ticketsActions.SET_OFFER_FAMILY:
      const familyOffer = {
        familyTicket: true,
        bigFamily: false,
        cheaperWithRelatives: state.pkpOffers.cheaperWithRelatives,
      };

      const familyAdultTickets = changeAdultTicketsToFamily(state.adultTicketsList);
      let infoFamArray = state.offerModalInfo;

      if (familyOffer.cheaperWithRelatives && !state.offerModalInfo.some((x) => x === cheaperFamilyInfo)) {
        infoFamArray = [
          ...state.offerModalInfo.filter((x) => x !== familyInfo && x !== cheaperInfo),
          cheaperFamilyInfo,
        ];
      } else if (!familyOffer.cheaperWithRelatives && !state.offerModalInfo.some((x) => x === familyInfo)) {
        infoFamArray = [...state.offerModalInfo.filter((x) => x !== cheaperFamilyInfo), familyInfo];
      }

      saveOfferToSessionStorage(familyOffer);

      return {
        ...state,
        adultTicketsList: familyAdultTickets,
        pkpOffers: familyOffer,
        offerModalInfo: infoFamArray,
        autoOffersDisabled: {...state.autoOffersDisabled, familyTicket: false},
      };

    case ticketsActions.SET_OFFER_BIG_FAMILY:
      const adultTicketsForBigFamily = changeFamilyTicketsToAdult(state.adultTicketsList);

      saveOfferToSessionStorage({...state.pkpOffers, bigFamily: true});

      return {
        ...state,
        pkpOffers: {...state.pkpOffers, bigFamily: true},
        offerModalInfo: [bigFamilyInfo],
        autoOffersDisabled: {...state.autoOffersDisabled, bigFamily: false},
        adultTicketsList: adultTicketsForBigFamily,
      };

    case ticketsActions.SET_OFFER_CHEAPER_WITH_RELATIVES:
      const cheaperOffers = {
        familyTicket: state.pkpOffers.familyTicket,
        bigFamily: false,
        cheaperWithRelatives: true,
      };

      let infoArray = state.offerModalInfo;

      if (cheaperOffers.familyTicket && !state.offerModalInfo.some((x) => x === cheaperFamilyInfo)) {
        infoArray = [...state.offerModalInfo.filter((x) => x !== familyInfo && x !== cheaperInfo), cheaperFamilyInfo];
      } else if (!cheaperOffers.familyTicket && !state.offerModalInfo.some((x) => x === cheaperInfo)) {
        infoArray = [...state.offerModalInfo.filter((x) => x !== cheaperFamilyInfo), cheaperInfo];
      }

      saveOfferToSessionStorage(cheaperOffers);

      return {
        ...state,
        adultTicketsList: state.adultTicketsList,
        pkpOffers: cheaperOffers,
        offerModalInfo: infoArray,
        autoOffersDisabled: {...state.autoOffersDisabled, cheaperWithRelatives: false},
      };

    case ticketsActions.SET_AUTO_OFFERS_DISABLED:
      const promoName = action.payload as string;

      return {
        ...state,
        autoOffersDisabled: {...state.autoOffersDisabled, [promoName]: true},
        offerModalInfo: ticketsAndDisscountInitial.offerModalInfo,
      };

    case ticketsActions.DISABLE_OFFER:
      const disableOfferName = action.payload as string;
      let modalInfo = state.offerModalInfo;
      let adultTickets = state.adultTicketsList;

      if (disableOfferName === 'familyTicket') {
        modalInfo = state.offerModalInfo.filter((x) => x !== familyInfo && x !== cheaperFamilyInfo);
        adultTickets = changeFamilyTicketsToAdult(state.adultTicketsList);
      }

      if (disableOfferName === 'bigFamily') {
        modalInfo = state.offerModalInfo.filter((x) => x !== bigFamilyInfo);
      }

      if (disableOfferName === 'cheaperWithRelatives')
        modalInfo = state.offerModalInfo.filter((x) => x !== cheaperInfo && x !== cheaperFamilyInfo);

      saveOfferToSessionStorage({...state.pkpOffers, [disableOfferName]: false});

      return {
        ...state,
        adultTicketsList: adultTickets,
        pkpOffers: {...state.pkpOffers, [disableOfferName]: false},
        offerModalInfo: modalInfo,
        autoOffersDisabled: {...state.autoOffersDisabled, [disableOfferName]: true},
      };

    case ticketsActions.SET_OFFER_MODAL_INFO:
      const info = action.payload as string;
      const duplicate = state.offerModalInfo.find((x) => x === info);

      return {
        ...state,
        offerModalInfo: duplicate ? [...state.offerModalInfo] : [...state.offerModalInfo, info],
      };

    case ticketsActions.SET_OFFER_MODAL_ERROR:
      const error = action.payload as string;
      const errorDuplicate = state.offerModalError.find((x) => x === error);

      return {
        ...state,
        offerModalError: errorDuplicate ? [...state.offerModalError] : [...state.offerModalError, error],
      };

    case ticketsActions.SET_OFFER_EXPANDABLE_ERROR:
      const errorExpandable = action.payload as ExpandableError;
      let offerExpandableErrorState = state.offerExpandableError;
      const errorExpandableDuplicate = state.offerExpandableError.find(
        (x) => x.offerName === errorExpandable.offerName,
      );

      if (errorExpandableDuplicate) {
        offerExpandableErrorState = state.offerExpandableError.filter((x) => x.offerName !== errorExpandable.offerName);
      }

      offerExpandableErrorState.push(errorExpandable);

      return {
        ...state,
        offerExpandableError: offerExpandableErrorState,
      };

    case ticketsActions.SET_WCGA_INFO:
      const message = action.payload as {car: string; ticket: string};

      return {
        ...state,
        wcgaInfo: message,
      };

    case ticketsActions.RESET_OFFERS:
      const offerResetAdults = changeFamilyTicketsToAdult(state.adultTicketsList);

      return {
        ...state,
        adultTicketsList: offerResetAdults,
        pkpOffers: ticketsAndDisscountInitial.pkpOffers,
        offerModalInfo: ticketsAndDisscountInitial.offerModalInfo,
        offerModalError: ticketsAndDisscountInitial.offerModalError,
        offerExpandableError: [],
      };

    case ticketsActions.RESET_OFFER_MODAL:
      return {...state, offerModalInfo: ticketsAndDisscountInitial.offerModalInfo};

    case ticketsActions.RESET_ERRORS_MODAL:
      return {...state, offerModalError: ticketsAndDisscountInitial.offerModalError};

    case ticketsActions.RESET_EXPANDABLE_ERRORS_MODAL:
      return {...state, offerExpandableError: []};

    case ticketsActions.REMOVE_EXPANDABLE_ERROR:
      const offerName = action.payload as string;
      const errorExists = state.offerExpandableError.some((x) => x.offerName === offerName);

      return errorExists
        ? {
            ...state,
            offerExpandableError: state.offerExpandableError.filter((x) => x.offerName !== offerName),
          }
        : state;

    default:
      return state;
  }
};
