/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {useStyledThemeState} from 'common/theme';
import S from './Login.module.scss';

import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';

import ssoUrlBuilder, {SSOType} from 'Utils/ssoUrlBuilder';

interface Props {
  closeFn: () => void;
}

const RegisterModal: FC<Props> = ({closeFn}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <div className={S.registerModal}>
      <div className={S.registerModalContainer}>
        <div className={S.closeIconContainer} aria-label={t('10023')} onClick={closeFn}>
          <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} className={S.outside_icon} />
        </div>
        <div className={S.modalRow}>
          <button
            className={`${S.outside_login}`}
            onClick={(e) => {
              e.preventDefault();
              window.location.replace(ssoUrlBuilder(SSOType.REGISTER, isContrastTheme));
            }}
          >
            <p>{t('10024')}</p>
          </button>
        </div>
        {/* <div className={S.modalRow}>
                    <button 
                        className={`${S.outside_login}`}
                        onClick={(e)=>{
                            e.preventDefault();
                        }}
                    >
                        <img src={Facebook} alt="" className={S.outside_icon}/>
                        <p>{t('10025')}</p>
                    </button>
                </div>
                <div className={S.modalRow}>
                    <button 
                        className={`${S.outside_login}`}
                        onClick={(e)=>{
                            e.preventDefault();
                        }}
                    >
                        <img src={Google} alt="" className={S.outside_icon}/>
                        <p>{t('10026')}</p>
                    </button>
                </div> */}
      </div>
    </div>
  );
};

export default RegisterModal;
