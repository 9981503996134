import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useStyledThemeState} from 'common/theme';

import style from './SearchAndFilter.module.scss';
import Settings from 'assets/settings.svg';
import SettingsContrast from 'assets/settingsWhite.svg';
import SettingsDisabled from 'assets/settingsGray.svg';

interface Props {
  dataToFilter: number;
  placeholderText: string;
  value: string;
  setValue: (e: string) => void;
  filter: string;
  setFilter: (e: string) => void;
  forInvoices?: boolean;
  filterList: {
    value: string;
    label: string;
    shortLabel: string;
    showArr: number[];
  }[];
}

const SearchAndFilter: FC<Props> = ({
  dataToFilter,
  placeholderText,
  value,
  setValue,
  filter,
  setFilter,
  forInvoices,
  filterList,
}) => {
  const {isContrastTheme} = useStyledThemeState();
  const [filterToggle, setFilterToggle] = useState<boolean>(false);
  const {t} = useTranslation();

  const checkContrastSetting = isContrastTheme ? SettingsContrast : Settings;

  return (
    <div className={`grid col-sm-12 col-12 ${style.add_marginbottom}`}>
      <div className="col-sm-9 col-9">
        <input
          disabled={dataToFilter === 0}
          className={
            dataToFilter === 0
              ? style.input_search_disabled
              : `${style.input_search} ${isContrastTheme && style.contrast}`
          }
          type="text"
          placeholder={placeholderText}
          value={value}
          onInput={(e) => setValue((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className={`col-sm-3 col-3 ${style.filterButton_box}`}>
        <button
          aria-label={t('29167')}
          disabled={dataToFilter === 0}
          className={style.filter_button}
          onClick={() => setFilterToggle(!filterToggle)}
        >
          {filterList.filter((el) => el.value === filter)[0]
            ? t(filterList.filter((el) => el.value === filter)[0]?.shortLabel)
            : t('17009')}
          <img
            src={dataToFilter === 0 ? SettingsDisabled : checkContrastSetting}
            alt={t('29166')}
            width="20px"
            height="20px"
          />
        </button>
        <div className={style.filter_options_box} style={{display: filterToggle ? '' : 'none'}}>
          {!forInvoices && (
            <button
              onClick={() => {
                setFilter(t('17009'));
                setFilterToggle(false);
              }}
              className={style.filter_option}
            >
              {t('29165')}
            </button>
          )}
          {filterList
            ? filterList.map((el) => (
                <button
                  key={`filterButton_${t(el.label)}`}
                  onClick={() => {
                    setFilter(t(el.value));
                    setFilterToggle(false);
                  }}
                  className={style.filter_option}
                >
                  {t(el.label)}
                </button>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default SearchAndFilter;
