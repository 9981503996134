import styles from './Autocomplete.module.scss';

type Props = {
  children: React.ReactNode;
};

const AutocompleteWrapper = ({children}: Props) => {
  return <div className={styles.autocompleteWrapper}>{children}</div>;
};

export default AutocompleteWrapper;
