import {FC, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import {useStyledThemeState} from 'common/theme';
import AddSeasonalTicketDialog from './AddPhysicalTicketDialog';

import {ReactComponent as Plus} from 'assets/orangePlus.svg';
import {ReactComponent as PlusContrast} from 'assets/contrastPlus.svg';
import {ReactComponent as Info} from 'assets/infoIcon.svg';
import {ReactComponent as InfoContrast} from 'assets/contrastInfoIcon.svg';

import S from './AddPhysicalTicket.module.scss';
import {useTranslation} from 'react-i18next';

const AddPhysicalTicket: FC = () => {
  const {t} = useTranslation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const {isContrastTheme} = useStyledThemeState();

  return (
    <>
      <div className={S.addPhysicalTicket__btnAndTooltipContainer}>
        <button className={S.addPhysicalTicket__btn} onClick={() => setDialogOpen(true)}>
          {isContrastTheme ? <PlusContrast /> : <Plus />}
          <span className={S.addPhysicalTicket__text}>{t('29319')}</span>
        </button>
        {isContrastTheme ? (
          <InfoContrast data-tooltip-id="ticketInfoTooltip" data-tooltip-content={t('13079')} />
        ) : (
          <Info data-tooltip-id="ticketInfoTooltip" data-tooltip-content={t('13079')} />
        )}
        <Tooltip id="ticketInfoTooltip" place="bottom" className={S.addPhysicalTicket__tooltip} />
      </div>
      <AddSeasonalTicketDialog isOpen={dialogOpen} closeDialog={() => setDialogOpen(false)} />
    </>
  );
};

export default AddPhysicalTicket;
