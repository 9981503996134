import React from 'react';
import {Response} from 'common/api';
import {MyTicket, MyTicketIdentifier} from 'common/api/myTickets';

interface StateContext {
  identifier: MyTicketIdentifier | undefined;
  ticket: Response<'bilety', MyTicket> | undefined;
}

interface DispatchContext {
  setTicketDetails: (
    identifier: MyTicketIdentifier | undefined,
    ticket: Response<'bilety', MyTicket> | undefined,
  ) => void;
}

const MyTicketStateContext = React.createContext<StateContext | undefined>(undefined);
const MyTicketDispatchContext = React.createContext<DispatchContext | undefined>(undefined);

export const MyTicketProvider = React.memo(({children}: {children: React.ReactNode}) => {
  const [ticketDetails, setTicket] = React.useState<StateContext>({identifier: undefined, ticket: undefined});

  const stateValue = React.useMemo(() => ticketDetails, [ticketDetails]);

  const setTicketDetails = React.useCallback(
    (identifier: MyTicketIdentifier | undefined, ticket: Response<'bilety', MyTicket> | undefined) => {
      setTicket({identifier, ticket});
    },
    [],
  );

  const dispatchValue = React.useMemo(() => ({setTicketDetails}), [setTicketDetails]);

  return (
    <MyTicketStateContext.Provider value={stateValue}>
      <MyTicketDispatchContext.Provider value={dispatchValue}>{children}</MyTicketDispatchContext.Provider>
    </MyTicketStateContext.Provider>
  );
});

export const useMyTicketState = () => {
  const context = React.useContext(MyTicketStateContext);

  if (!context) {
    throw new Error('useMyTicketState must be used within the MyTicketProvider');
  }

  return context;
};

export const useMyTicketDispatch = () => {
  const context = React.useContext(MyTicketDispatchContext);

  if (!context) {
    throw new Error('useMyTicketDispatch must be used within the MyTicketProvider');
  }

  return context;
};
