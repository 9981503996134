import {env} from 'env';
import {v4 as uuidv4} from 'uuid';
import {encode as base64encode} from 'base64-arraybuffer';
import i18n from 'i18n';

export enum SSOType {
  REGISTER = 'register',
  AUTHORIZE = 'authorize',
}

/**
 * Creates a SSO url for the given type
 * @param type The type of SSO url to create
 * @param isContrastTheme Whether to use the contrast theme
 * @returns The SSO url
 *
 * @example
 * const url = ssoUrlBuilder(SSOType.REGISTER, true);
 */
const ssoUrlBuilder = (type: SSOType, isContrastTheme: boolean): string => {
  const base = env.REACT_APP_SSO_SERVER;
  return `${base}/oauth/${type}?client_id=eic_2&response_type=code${type === 'authorize' ? `&state=${uuidv4()}` : ''}${isContrastTheme ? '&contrast' : ''}&lang=${i18n.language}`;
};

const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = base64encode(digest);
  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const ssoUrlBuilderGuest = async (type: SSOType, isContrastTheme: boolean): Promise<string> => {
  const base = env.REACT_APP_SSO_SERVER;
  const codeVerifier = uuidv4();
  const codeChallenge = await generateCodeChallenge(codeVerifier).then((challenge) => {
    sessionStorage.setItem('eic2Uuid', codeVerifier);
    return challenge;
  });

  return `${base}/oauth/${type}?client_id=eic_guest&response_type=code&type=guest&state=${uuidv4()}&code_challenge=${codeChallenge}&t_m=S256${isContrastTheme ? '&contrast' : ''}&lang=${i18n.language}`;
};

export const ssoUrlBuilderLoginGuest = async (isContrastTheme: boolean): Promise<string> => {
  const base = env.REACT_APP_SSO_SERVER;
  const codeVerifier = uuidv4();
  const codeChallenge = await generateCodeChallenge(codeVerifier).then((challenge) => {
    sessionStorage.setItem('eic2Uuid', codeVerifier);
    return challenge;
  });

  return `${base}/login/guest?client_id=eic_guest&response_type=code&type=guest&state=${uuidv4()}&code_challenge=${codeChallenge}&t_m=S256${isContrastTheme ? '&contrast' : ''}&lang=${i18n.language}`;
};
/**
 * Creates a SSO url for authorization with PKCE params
 * @param isContrastTheme Whether to use the contrast theme
 * @returns Promise with the SSO url
 */
export const ssoUrlBuilderWithPKCEParams = async (isContrastTheme: boolean): Promise<string> => {
  const base = env.REACT_APP_SSO_SERVER;
  const codeVerifier = uuidv4();
  const codeChallenge = await generateCodeChallenge(codeVerifier).then((challenge) => {
    sessionStorage.setItem('eic2Uuid', codeVerifier);
    return challenge;
  });
  return `${base}/oauth/authorize?client_id=eic_2&response_type=code&state=${uuidv4()}&code_challenge=${codeChallenge}&t_m=S256${isContrastTheme ? '&contrast' : ''}&lang=${i18n.language}`;
};

export default ssoUrlBuilder;
