import {ticketSourceCodes} from 'common/api/myTickets/consts';
import {
  PRICE_CODE_OFFERS,
  SEASONAL_CODE_OFFERS,
  SEASONAL_NON_SPECIFIED_ROUTE_CODE_OFFERS,
  SEASONAL_ONE_WAY_CODE_OFFERS,
  SEASONAL_PERIODIC_CODE_OFFERS,
  SEASONAL_THERE_AND_BACK_CODE_OFFERS,
} from 'common/consts';
import {
  SeasonalCodeOffer,
  SeasonalNonSpecifiedRouteCodeOffer,
  SeasonalOneWayCodeOffer,
  SeasonalPeriodicCodeOffer,
  SeasonalThereAndBackCodeOffer,
} from 'common/types';

export const exhaustiveCheck = (param: never, type: string): never => {
  throw new TypeError(`${param} is not a proper ${type}`);
};

export const isZeroPriceCodeOffer = (priceCodeOffers: number) =>
  priceCodeOffers === PRICE_CODE_OFFERS.INTERCITY_CARD_RESERVATION;

export const isSeasonalCodeOffer = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_CODE_OFFERS).includes(codeOffer as SeasonalCodeOffer);

export const isOneWaySeasonalOffer = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_ONE_WAY_CODE_OFFERS).includes(codeOffer as SeasonalOneWayCodeOffer);

export const isThereAndBackSeasonalOffer = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_THERE_AND_BACK_CODE_OFFERS).includes(codeOffer as SeasonalThereAndBackCodeOffer);

export const isNonSpecifiedRoutSeasonalOffer = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_NON_SPECIFIED_ROUTE_CODE_OFFERS).includes(codeOffer as SeasonalNonSpecifiedRouteCodeOffer);

export const isPeriodicSeasonalOffer = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_PERIODIC_CODE_OFFERS).includes(codeOffer as SeasonalPeriodicCodeOffer);

export const isTicketSourceEIC1 = (ticketSerial: string | undefined) => ticketSerial === 'WE';

export const isTicketManuallyAdded = (ticketSourceCode: number | undefined, ticketSerial: string | undefined) =>
  ticketSourceCode === ticketSourceCodes.EIC && !isTicketSourceEIC1(ticketSerial); // solution imposed by IC https://jsoft.intercity.pl/browse/EIC2-1715
