import {FC, Fragment, useMemo, useState} from 'react';
import style from './MyTicketsSingleTicketOpenMobile.module.scss';

import {getFullTimeFromDate, getFullDate} from 'Utils/commonFunctions';

import {IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';
import ArrowBottomContrast from 'assets/contrastArrowBottomDesktop.svg';

import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {isReservationFromSeasonalTicket} from 'components/pages/UserProfile/common/utils';
import {isSeasonalCodeOffer, isTicketManuallyAdded} from 'common/utils';

interface Props {
  details: IDownloadTicketResponseSingleTicket[];
}

interface ISale {
  cuantiti: number;
  code: number;
}

const MyTicketSingleTicketOpenDetails: FC<Props> = ({details}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const {shopDiscounts} = useData();
  const [showTrip, setShowTrip] = useState<boolean>(false);

  const isSeasonal = useMemo(() => isSeasonalCodeOffer(details[0]?.ofertaKod), [details]);
  const {biletRodzaj, biletZrodloKod, biletSeria} = details[0];

  const ticketTypeArr = [1, 2, 10, 20, 21, 22, 23, 30, 31, 32]; //TODO this need to be removed

  const filteredDetails = details.filter((el) => ticketTypeArr.includes(el.biletRodzaj));

  const makeArrOfSale = () => {
    const arr = [] as ISale[];
    filteredDetails[0]?.podrozni.forEach((e) => {
      let test = false;
      let testI = -1;
      arr.forEach((e2, index) => {
        if (e.znizkaKod === e2.code) {
          test = true;
          testI = index;
        }
      });
      if (test) {
        arr[testI] = {
          cuantiti: arr[testI].cuantiti + 1,
          code: arr[testI].code,
        };
      } else {
        arr.push({
          cuantiti: 1,
          code: e.znizkaKod,
        });
      }
    });
    return arr;
  };

  const getDiscountName = (num: number) => {
    if (num === 1) return 'Normalny';
    else {
      return shopDiscounts
        .filter((el) => el?.kodZakupowy === 1000 + num * 10)[0]
        ?.opisy.filter((el2) => el2.jezyk === i18n.language.toUpperCase())[0].nazwa;
    }
  };

  const km = () => {
    let km = 0;
    details[0]?.odcinki.forEach((el) => {
      km = km + el.kilometry;
    });
    return km;
  };

  const returnPaymentMethod = (kode: number) => {
    if (kode === 4) {
      return 'Przelewy(P24)';
    } else if (kode === 8) {
      return 'GooglePay/ApplePay';
    } else {
      return 'BLIK';
    }
  };

  const makeFullPrice = () => {
    let sum = 0;
    filteredDetails.forEach((el) => {
      sum = sum + (el.biletRodzaj === 31 ? el.cenaCalkowita : el.cenaBilet + el.cenaRezerwacja);
    });
    return sum;
  };
  const isManuallyAdded = isTicketManuallyAdded(biletZrodloKod, biletSeria);
  return (
    <div className={`${style.mobile_ticket__box} ${style.expandedDetails}`}>
      <div className={`${style.mobile_ticket__box_textbox}`}>
        <div>
          <p className={style.text_blue_details}>{t('11010')}</p>
          {makeArrOfSale().map((el) => {
            return (
              <p key={`${el.code}-${el.cuantiti}`} className={style.text_medium}>
                {el.cuantiti}x {isReservationFromSeasonalTicket(biletRodzaj) ? t('29304') : getDiscountName(el.code)}
              </p>
            );
          })}
        </div>
      </div>
      <aside className={style.mobile_ticket__accordion}>
        <button onClick={() => setShowTrip(!showTrip)} aria-label={t('29303')}>
          <span className="sr-only">{t('29302')}</span>
          <span className={style.text_small_details}>{t('16028')}</span>
          <img
            src={isContrastTheme ? ArrowBottomContrast : ArrowBottom}
            alt=""
            style={{transform: showTrip ? 'rotate(180deg)' : ''}}
          />
        </button>
      </aside>

      <div className={`${style.summary_container} ${showTrip ? style.summary_open : ''}`}>
        <div className={`${style.mobile_ticketDetailsRow}`}>
          {!isManuallyAdded && (
            <div>
              <p className={style.text_blue_details}>{t('22032')}</p>
              <p className={style.text_medium}>
                {(makeFullPrice() / 100).toFixed(2).replace('.', ',')}
                {t('24086')}
              </p>
            </div>
          )}
        </div>
        <div className={`${style.mobile_ticketDetailsRow}`}>
          <div>
            <p className={style.text_blue_details}>{t('29301')}</p>
            <p className={style.text_medium}>
              {getFullDate(filteredDetails[0]?.sprzedazData)}, {getFullTimeFromDate(filteredDetails[0]?.sprzedazData)}
            </p>
          </div>
        </div>
        <div className={`${style.mobile_ticketDetailsRow}`}>
          <div>
            <p className={style.text_blue_details}>{t('29379')}</p>
            <p className={style.text_small_details}>
              {t('29298')}: {filteredDetails[0]?.ofertaNazwa}
            </p>
            {!isManuallyAdded && (
              <p className={style.text_small_details}>
                {t('22023')} {(filteredDetails[0]?.cenaBilet / 100).toFixed(2).replace('.', ',')} {t('24086')}
              </p>
            )}

            {!!filteredDetails[0]?.cenaRezerwacja && (
              <p className={style.text_small_details}>
                {t('29304')} {(filteredDetails[0].cenaRezerwacja / 100).toFixed(2).replace('.', ',')} {t('24086')}
              </p>
            )}
            {!isManuallyAdded && (
              <p className={style.text_small_details}>
                {t('29305')} {filteredDetails[0]?.ptuBiletProcent}% PTU
              </p>
            )}
            <p className={style.text_small_details}>{filteredDetails[0]?.poziomCenowy}</p>
          </div>
        </div>
        {filteredDetails.length > 1 && (
          <div className={`${style.mobile_ticketDetailsRow}`}>
            <div>
              <p className={style.text_blue_details}>{t('29182')}</p>
              {filteredDetails
                .filter((f, index) => index !== 0)
                .map((el) => {
                  return (
                    <Fragment key={el.biletNr}>
                      <p className={style.text_small_details}>
                        {el?.podrozni.length}x {el.ofertaNazwa} {(el.cenaBilet / 100).toFixed(2).replace('.', ',')}{' '}
                        {t('24086')} {el.zwrotOpis ? `(${t('29153')})` : null}
                      </p>
                      <p className={style.text_small_details}>
                        {t('29305')} {((el.cenaBilet * (el.ptuBiletProcent / 100)) / 100).toFixed(2).replace('.', ',')}
                        {t('24086')} PTU ({el.ptuBiletProcent}%)
                      </p>
                    </Fragment>
                  );
                })}
            </div>
          </div>
        )}
        {!isManuallyAdded && (
          <div className={`${style.mobile_ticketDetailsRow}`}>
            <div>
              <p className={style.text_blue_details}>{t('11009')}</p>
              <p className={style.text_small_details}>{returnPaymentMethod(filteredDetails[0]?.platnoscSposobKod)}</p>
              {filteredDetails[0]?.transakcjaNr !== 0 && (
                <p className={style.text_small_details}>
                  {t('29306')} {filteredDetails[0]?.transakcjaNr}
                </p>
              )}
            </div>
          </div>
        )}
        <div className={`${style.mobile_ticketDetailsRow}`}>
          <div>
            <p className={style.text_blue_details}>{t('29307')}</p>
            <p className={style.text_small_details}>{km()}km</p>
          </div>
        </div>
        {!isSeasonal && (
          <div className={`${style.mobile_ticketDetailsRow}`}>
            <div>
              <p className={style.text_blue_details}>{t('29308')}</p>
              {filteredDetails[0]?.odcinki?.map((el) => (
                <p key={el.nrReferencyjnyRezerwacji} className={style.text_medium}>
                  {el.nrReferencyjnyRezerwacji}
                </p>
              ))}
            </div>
          </div>
        )}
        <div className={`${style.mobile_ticketDetailsRow}`}>
          <div>
            <p className={style.text_blue_details}>{t('29309')}</p>
            <p className={style.text_small_details}>PKP INTERCITY</p>
            <p className={style.text_small_details}>
              {t('13167')} <br />
              {t('13168')}{' '}
              <strong
                style={{
                  color: 'Red',
                  display: 'inline-block',
                  textAlign: 'center',
                  width: '16px',
                  border: '1px solid black',
                  textTransform: 'capitalize',
                }}
              >
                R
              </strong>{' '}
              {t('13169')}
            </p>
            <p className={style.text_small_details}>{t('13170')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTicketSingleTicketOpenDetails;
