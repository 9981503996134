import {GrmContexActionTypes, GrmContextActions} from './GrmContextActions.enum';
import {produce} from 'immer';
import GrmContextState from './GrmContextState';
import {GrmPlace} from './GrmPlace.interface';
import {GrmInitialData} from './GrmContext';
import popups from './popups';
import i18n from 'i18n';
const t = i18n.t;
const indexer = (index: number, length: number, placesToTake: GrmPlace[]) => {
  const firstVacant = placesToTake.findIndex((x: GrmPlace) => !x.placeNumber);

  if (firstVacant > -1) {
    return firstVacant;
  }

  if (index === 0 && length <= 1) {
    return 0;
  }

  if (index === 0 && length > 1) {
    return 1;
  }

  if (index === 1 && length > 2) {
    return 2;
  }

  return (index + 1) % length;
};

export default (state: GrmContextState, action: GrmContextActions) => {
  switch (action.type) {
    case GrmContexActionTypes.SET_ACTIVE_CARRIAGE:
      return produce(state, (draft) => {
        if (action.payload) {
          draft.currentCarriage = String(action.payload);
        }

        if (action.carriages) {
          draft.carriages = action.carriages;
        }

        if (action.wagonySchemat) {
          draft.wagonySchemat = action.wagonySchemat;
        }

        if (action.klasa0) {
          draft.klasa0 = action.klasa0;
        }

        if (action.klasa1) {
          draft.klasa1 = action.klasa1;
        }

        if (action.klasa2) {
          draft.klasa2 = action.klasa2;
        }

        if (action.pojazdTyp) {
          draft.pojazdTyp = action.pojazdTyp;
        }

        if (action.zmieniaKierunek) {
          draft.zmieniaKierunek = action.zmieniaKierunek;
        }
      });

    case GrmContexActionTypes.RESET_GRM_PLACES_TO_TAKE:
      return produce(state, (draft) => {
        draft.placesToTake = [];
      });

    case GrmContexActionTypes.SET_GRM_STATE:
      return produce(GrmInitialData, (draft) => {
        draft.active = true;
        draft.placesToTakeIndex = 0;
        draft.train = action.train;
        draft.placesToTake = action.placesToTake;
        draft.confirmPopup = null;
        draft.mode = action.mode;
      });

    case GrmContexActionTypes.SET_NEXT_CARRIAGE: {
      const c = state.placesToTake[state.placesToTakeIndex].klasa;
      let next = 0;

      if (c === 2) {
        const current = state.klasa2.findIndex((x) => x == state?.currentCarriage);
        next = Number(typeof state.klasa2[current + 1] !== 'undefined' ? state.klasa2[current + 1] : state.klasa2[0]);
      }

      if (c === 1) {
        const current = state.klasa1.findIndex((x) => x == state?.currentCarriage);
        next = Number(typeof state.klasa1[current + 1] !== 'undefined' ? state.klasa1[current + 1] : state.klasa1[0]);
      }

      if (c === 0) {
        const current = state.klasa0.findIndex((x) => x === state?.currentCarriage);
        next = Number(typeof state.klasa0[current + 1] !== 'undefined' ? state.klasa0[current + 1] : state.klasa0[0]);
      }

      return produce(state, (draft) => {
        draft.currentCarriage = next;
      });
    }

    case GrmContexActionTypes.SET_PREV_CARRIAGE: {
      const c = state.placesToTake[state.placesToTakeIndex].klasa;
      let prev = 0;

      if (c === 2) {
        const current = state.klasa2.findIndex((x) => x == state?.currentCarriage);
        const p = current - 1 >= 0 ? current - 1 : state.klasa2.length - 1;
        prev = Number(state.klasa2[p]);
      }

      if (c === 1) {
        const current = state.klasa1.findIndex((x) => x == state?.currentCarriage);
        const p = current - 1 >= 0 ? current - 1 : state.klasa1.length - 1;
        prev = Number(state.klasa1[p]);
      }

      if (c === 0) {
        const current = state.klasa0.findIndex((x) => x == state?.currentCarriage);
        const p = current - 1 >= 0 ? current - 1 : state.klasa0.length - 1;
        prev = Number(state.klasa1[p]);
      }

      return produce(state, (draft) => {
        draft.currentCarriage = prev;
      });
    }

    case GrmContexActionTypes.CLOSE_GRM:
      return produce(state, (draft) => {
        draft.active = false;
      });

    case GrmContexActionTypes.CLOSE_POPUP:
      return produce(state, (draft) => {
        draft.confirmPopup = null;
      });

    case GrmContexActionTypes.TAKE_PLACE:
      // eslint-disable-next-line no-case-declarations
      const seatsTakenInDiffrentCarriage = state.placesToTake.find((x) => {
        return x.carriageNumber && x.carriageNumber != state.currentCarriage;
      });
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!seatsTakenInDiffrentCarriage) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.changeCarriage(seatsTakenInDiffrentCarriage?.carriageNumber),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.RESET_OTHER_PLACES, place: action.place});
            },
            headline: t('21044'),
          };
        });
      }

      //Get the index of clicked seat
      // eslint-disable-next-line no-case-declarations
      const taken = state.placesToTake.findIndex((x) => {
        return (
          !!x.carriageNumber &&
          !!x.placeNumber &&
          Number(x.placeNumber) === Number(action?.place?.nb) &&
          Number(x.carriageNumber) === Number(state.currentCarriage)
        );
      });

      // If seat is taken unmark it - make it available
      if (taken > -1) {
        return produce(state, (draft) => {
          draft.placesToTake[taken].miejsceTypKod = undefined;
          draft.placesToTake[taken].iconCode = undefined;
          draft.placesToTake[taken].placeNumber = undefined;
          draft.placesToTake[taken].carriageNumber = undefined;
          draft.placesToTake[taken].bikesTicket = false;
          draft.placesToTake[taken].dogsTicket = false;
          draft.placesToTake[taken].luggageTicket = false;
          draft.placesToTakeIndex = taken;
        });
      }

      if (
        !state.placesToTake.some((place) => {
          return !place.placeNumber && !place.carriageNumber;
        })
      ) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.allSeatsTaken,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.CLOSE_POPUP});
            },
            headline: t('21045'),
          };
        });
      }

      if (action?.place?.specialType?.carriageCode && action?.place?.specialType?.carriageCode === '52') {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.quietZone,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.FORCE_TAKE_PLACE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      if (
        action?.place?.specialType?.iconCode === 'S8' ||
        action?.place?.specialType?.iconCode === 'S6' ||
        action.place?.specialType?.iconCode === 'PERSON_WITH_CHILD_EZT'
      ) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.kidsZone,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.FORCE_TAKE_PLACE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      if (
        action?.place?.specialType?.iconCode === 'S4' ||
        action?.place?.specialType?.iconCode === 'S5' ||
        action?.place?.specialType?.iconCode === 'HANDICAPPED_WITHOUT_WHEELCHAIR_EZT'
      ) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.ozn,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.FORCE_TAKE_PLACE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      if (action?.place?.specialType?.iconCode === 'S1' || action?.place?.specialType?.iconCode === 'BIKE') {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.bike,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.TAKE_PLACE_FOR_BIKE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      if (
        action?.place?.specialType?.iconCode === 'S3' ||
        action?.place?.specialType?.iconCode === 'HANDICAPPED_GUARDIAN'
      ) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.guardian,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.FORCE_TAKE_PLACE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      if (
        action?.place?.specialType?.iconCode === 'S2' ||
        action?.place?.specialType?.iconCode === 'HANDICAPPED_WITH_WHEELCHAIR'
      ) {
        return produce(state, (draft) => {
          draft.confirmPopup = {
            text: popups.wheelchair,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: (dispatch: any) => {
              dispatch({type: GrmContexActionTypes.FORCE_TAKE_PLACE, place: action.place});
            },
            headline: t('21045'),
          };
        });
      }

      return produce(state, (draft) => {
        draft.placesToTake[draft.placesToTakeIndex].iconCode = action?.place?.specialType?.iconCode;
        draft.placesToTake[draft.placesToTakeIndex].miejsceTypKod = action.place?.specialType?.ref
          ? Number(action.place?.specialType?.ref)
          : action.place?.zorder;
        draft.placesToTake[draft.placesToTakeIndex].placeNumber = Number(action?.place?.nb);
        draft.placesToTake[draft.placesToTakeIndex].carriageNumber = Number(draft?.currentCarriage);
        draft.placesToTakeIndex = indexer(draft?.placesToTakeIndex, draft?.placesToTake?.length, draft?.placesToTake);
      });

    case GrmContexActionTypes.FORCE_TAKE_PLACE: {
      return produce(state, (draft) => {
        draft.confirmPopup = null;
        draft.placesToTake[draft.placesToTakeIndex].iconCode = action?.place?.specialType?.iconCode;
        draft.placesToTake[draft.placesToTakeIndex].miejsceTypKod = action.place?.specialType?.ref
          ? Number(action.place?.specialType?.ref)
          : action.place?.zorder;
        draft.placesToTake[draft.placesToTakeIndex].placeNumber = Number(action?.place?.nb);
        draft.placesToTake[draft.placesToTakeIndex].carriageNumber = Number(draft?.currentCarriage);
        draft.placesToTakeIndex = indexer(draft.placesToTakeIndex, draft.placesToTake.length, draft.placesToTake);
      });
    }

    case GrmContexActionTypes.RESET_OTHER_PLACES: {
      return produce(state, (draft) => {
        draft.placesToTake = draft.placesToTake.map((x: GrmPlace, index: number) => {
          if (index === draft.placesToTakeIndex) {
            return x;
          }
          return {...x, carriageNumber: undefined, placeNumber: undefined};
        });

        draft.confirmPopup = null;
        draft.placesToTake[draft.placesToTakeIndex].iconCode = action?.place?.specialType?.iconCode;
        draft.placesToTake[draft.placesToTakeIndex].miejsceTypKod = action.place?.specialType?.ref
          ? Number(action.place?.specialType?.ref)
          : action.place?.zorder;
        draft.placesToTake[draft.placesToTakeIndex].placeNumber = Number(action.place.nb);
        draft.placesToTake[draft.placesToTakeIndex].carriageNumber = Number(draft.currentCarriage);
        draft.placesToTakeIndex = indexer(draft.placesToTakeIndex, draft.placesToTake.length, draft.placesToTake);
      });
    }

    case GrmContexActionTypes.TAKE_PLACE_FOR_BIKE: {
      return produce(state, (draft) => {
        draft.confirmPopup = null;
        draft.placesToTake[draft.placesToTakeIndex].iconCode = action?.place?.specialType?.iconCode;
        draft.placesToTake[draft.placesToTakeIndex].miejsceTypKod = action.place?.specialType?.ref
          ? Number(action.place?.specialType?.ref)
          : action.place?.zorder;
        draft.placesToTake[draft.placesToTakeIndex].placeNumber = Number(action.place.nb);
        draft.placesToTake[draft.placesToTakeIndex].carriageNumber = Number(draft.currentCarriage);
        draft.placesToTake[draft.placesToTakeIndex].bikesTicket = true;
        draft.placesToTakeIndex = indexer(draft.placesToTakeIndex, draft.placesToTake.length, draft.placesToTake);
      });
    }
  }
  return state;
};
