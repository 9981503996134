import {FC} from 'react';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

import style from './MyProfileHeader.module.scss';
import ArrowLeft from 'assets/orangeArrowRightNarrow.svg';
import ArrowLeftContrast from 'assets/contrastArrowRightNarrow.svg';

interface Props {
  closeAction?: () => void;
  text: string;
}

const MyProfileHeader: FC<Props> = ({closeAction, text}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <h2 className={`col-12 ${style.header_style}`}>
      <button className={style.return_btn} aria-label={t('29044')} onClick={closeAction}>
        <img src={isContrastTheme ? ArrowLeftContrast : ArrowLeft} alt={t('11026')} />
      </button>
      {text}
    </h2>
  );
};

export default MyProfileHeader;
