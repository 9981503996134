export const SEASONAL_CODE_OFFERS = {
  MONTHLY_THERE: 32,
  MONTHLY_THERE_AND_BACK: 33,
  PROMO_THERE: 36,
  PROMO_THERE_AND_BACK: 37,
  QUARTERLY_THERE: 42,
  QUARTERLY_THERE_AND_BACK: 43,
  WEAKLY_THERE: 58,
  WEAKLY_THERE_AND_BACK: 59,
  MULTIRIDE: 150,
  MULTIRIDE_MAX: 188,
  UUT_QUARTERLY: 832,
  UUT_YEARLY: 833,
  INTERCITY_CARD: 9999,
} as const;

export const SEASONAL_NON_SPECIFIED_ROUTE_CODE_OFFERS = {
  MULTIRIDE: 150,
  MULTIRIDE_MAX: 188,
  UUT_QUARTERLY: 832,
  UUT_YEARLY: 833,
  INTERCITY_CARD: 9999,
} as const;

export const SEASONAL_THERE_AND_BACK_CODE_OFFERS = {
  MONTHLY_THERE_AND_BACK: 33,
  PROMO_THERE_AND_BACK: 37,
  QUARTERLY_THERE_AND_BACK: 43,
  WEAKLY_THERE_AND_BACK: 59,
} as const;

export const SEASONAL_ONE_WAY_CODE_OFFERS = {
  MONTHLY_THERE: 32,
  PROMO_THERE: 36,
  QUARTERLY_THERE: 42,
  WEAKLY_THERE: 58,
} as const;

export const SEASONAL_PERIODIC_CODE_OFFERS = {
  ...SEASONAL_THERE_AND_BACK_CODE_OFFERS,
  ...SEASONAL_ONE_WAY_CODE_OFFERS,
} as const;

export const SEASONAL_TICKET_GROUP = {
  ELSE: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  QUARTERLY: 3,
} as const;

export const SEASONAL_OFFER_CODE_TYPES = {
  ONE_WAY: 0,
  THERE_AND_BACK: 1,
  NON_SPECIFIED_ROUTE: 2,
} as const;

export const SEASONAL_OFFER_CODE_KINDS = {
  PERIODIC: 1,
  MULTIRIDE: 2,
  MULTIRIDE_MAX: 3,
  UUT_YEARLY: 4,
  UUT_QUARTERLY: 5,
  INTERRAIL: 6,
} as const;
