/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {FC} from 'react';

import ConfirmButton from 'components/common/ConfirmButton';
import {useStyledThemeState} from 'common/theme';

import S from './AccDeleteModal.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  onClose: () => void;
}

const AccDeleteModal: FC<Props> = ({onClose}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <div className={S.modalBackground}>
      <div className={S.modalContainer}>
        <div className={S.topRow}>
          <h1 className={S.header}>{t('29201')}</h1>
          <button className={S.closeButton} onClick={onClose} aria-label={t('11035')}>
            <img tabIndex={0} src={isContrastTheme ? ContrastX : OrangeX} alt="X" />
          </button>
        </div>
        <div className={S.content}>
          <p className={S.text}>{t('29022')}</p>
          <p className={S.text}>{t('29023')}</p>
          <ConfirmButton text={t('26048')} execute={onClose} />
        </div>
      </div>
    </div>
  );
};
export default AccDeleteModal;
