import {BuyerProfile} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import {useReducer} from 'react';
import {createContainer} from 'react-tracked';
import {produce, setAutoFreeze} from 'immer';

export enum BuyerProfileScreen {
  MAIN = 'MAIN',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum BuyerProfileActions {
  SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE',
  RESET_ACTIVE_PROFILE = 'RESET_ACTIVE_PROFILE',
  SET_FROM_AND_DESTINATION = 'SET_FROM_AND_DESTINATION',
  SET_SCREEN = 'SET_SCREEN',
  SET_PROFILE_AND_SCREEN = 'SET_PROFILE_AND_SCREEN',
  UPDATE_ACTIVE_PROFILE = 'UPDATE_ACTIVE_PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',
}
setAutoFreeze(false);

const initialState: {
  activeProfile: BuyerProfile;
  screen: BuyerProfileScreen;
  deleteProfileInfo: string | undefined;
} = {
  activeProfile: {
    name: '',
    stationFrom: undefined,
    stationTo: undefined,
    intermediateStation: undefined,

    stopMinutes: 14,
    discounts: [],
    filters: [],
  },
  screen: BuyerProfileScreen.MAIN,
  deleteProfileInfo: undefined,
};

const reducer = (
  state: {
    activeProfile: BuyerProfile;
    screen: BuyerProfileScreen;
    deleteProfileInfo: string | undefined;
  },
  action:
    | {
        type: BuyerProfileActions.SET_ACTIVE_PROFILE;
        payload: BuyerProfile;
      }
    | {
        type: BuyerProfileActions.SET_SCREEN;
        payload: BuyerProfileScreen;
      }
    | {
        type: BuyerProfileActions.SET_PROFILE_AND_SCREEN;
        screen: BuyerProfileScreen;
        profile: BuyerProfile;
      }
    | {
        type: BuyerProfileActions.RESET_ACTIVE_PROFILE;
      }
    | {
        type: BuyerProfileActions.UPDATE_ACTIVE_PROFILE;
        payload: Partial<BuyerProfile>;
      }
    | {
        type: BuyerProfileActions.DELETE_PROFILE;
        screen: BuyerProfileScreen;
        deleteProfileInfo: string;
      },
) => {
  switch (action.type) {
    case BuyerProfileActions.SET_ACTIVE_PROFILE:
      return produce(state, (draft) => {
        draft.activeProfile = {...initialState, ...action.payload};
      });

    case BuyerProfileActions.UPDATE_ACTIVE_PROFILE:
      return produce(state, (draft) => {
        const d = {...initialState.activeProfile, ...draft.activeProfile, ...action.payload};

        draft.activeProfile = {...d, name: d.name ? d.name : ''};
      });

    case BuyerProfileActions.SET_SCREEN:
      return produce(state, (draft) => {
        draft.screen = action.payload;
      });
    case BuyerProfileActions.SET_PROFILE_AND_SCREEN:
      return produce(state, (draft) => {
        draft.screen = action.screen;
        draft.activeProfile = action.profile;
      });
    case BuyerProfileActions.RESET_ACTIVE_PROFILE:
      return produce(state, (draft) => {
        draft.activeProfile = initialState.activeProfile;
      });
    case BuyerProfileActions.DELETE_PROFILE:
      return produce(state, (draft) => {
        draft.screen = action.screen;
        draft.deleteProfileInfo = action.deleteProfileInfo;
      });

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider: BuyerProfileContextProvider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
