import {Theme, Font} from '../types';
import {baseFontSizes} from '../consts';

export const setCssThemeClass = (theme: Theme) => {
  document.documentElement.className = `theme-${theme}`;
};

export const setCssFontSize = (font: Font) => {
  document.documentElement.style.fontSize = `${baseFontSizes[font]}px`;
};
