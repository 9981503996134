/* eslint-disable no-extra-boolean-cast */
import {Dispatch, FC, ReactNode, createContext, useContext, useEffect, useMemo, useState, SetStateAction} from 'react';
import {Basket, BasketAdditionalTickets, BasketItem, BasketTicket, BasketTransaction} from './ShoppingBasketTypes';
import {
  ICancelTicketRequest,
  IGenerateAdditionalFeeResponse,
  IGenerateAdditionalTicketsResponse,
  IGenerateTicketResponse,
  IGenerateTicketTripResponse,
  INewTicket,
} from 'api/sprzedaz/interfaces';
import {IConnectionSearchResultsList} from 'api/pociagi/interfaces';

import api from 'api/sprzedaz/repository';

import {AddTicket} from 'Utils/TicketContext';
import {formatPrice} from 'Utils/commonFunctions';

import noop from 'lodash/noop';
import {IPaymentInvoiceData} from '../../../../interfaces/forms';
import {TFunction} from 'i18next';

type IShoppingBasketContext = {
  basket: Basket | null;
  addFromToItemsToBasket: (
    searchResultsFrom: IConnectionSearchResultsList,
    generatedTicketFrom: IGenerateTicketResponse,
    addTicketsFrom: IGenerateAdditionalTicketsResponse | null,
    addTicketsContextFrom: AddTicket | null,
    searchResultsTo: IConnectionSearchResultsList | null,
    generatedTicketTo: IGenerateTicketResponse | null,
    addTicketsTo: IGenerateAdditionalTicketsResponse | null,
    addTicketsContextTo: AddTicket | null,
    buyersName: string,
    adultTickets: INewTicket[] | null,
    childrenTickets: INewTicket[] | null,
  ) => void;
  addSeasonalTicketToBasket: (generatedTicket: IGenerateTicketResponse, buyersName: string) => void;
  removeItemFromBasket: (transNr: number) => void;
  returnBasketTransactions: () => BasketTransaction[];
  clearItemsAfterPayment: () => void;
  clearBasket: () => void;
  getBasketFinalPriceAsNumber(): number;
  getBasketPTU(t: TFunction, percents: 8 | 23): string | null;
  setBasketFromStorage: () => void;
  itemsNumber: number;
  basketCancelled: boolean;
  resetBasketOnTimeout: () => void;
  lessThan2MinutesLeft: boolean;
  setLessThan2MinutesLeft(value: boolean): void;
  timeLimit: string | null;
  userSaw2MinutesLeftToPayModal: boolean | null;
  setUserSaw2MinutesLeftToPayModal: Dispatch<SetStateAction<boolean | null>>;
  userSaw0MinutesModal: boolean | null;
  setUserSaw0MinutesModal: Dispatch<SetStateAction<boolean | null>>;
  setBuyerNameForTickets(transactions: BasketTransaction[], newName: string): void;
  buyerNameEditMode: boolean;
  setBuyerNameEditMode: Dispatch<SetStateAction<boolean>>;
  setWaitingForPayment(value: boolean): void;
  setInvoiceFormDataForItem(transNr: number, invoceForm: IPaymentInvoiceData | null): void;
  getItemsWithInvoiceData(): BasketItem[] | null;
  findSameTicketInBasketAsTicketToAdd(ticket: IGenerateTicketResponse, passengerName: string): boolean;
  basketEcoOffsetDiscount: IGenerateAdditionalFeeResponse | null;
  setBasketEcoOffsetDiscount: Dispatch<SetStateAction<IGenerateAdditionalFeeResponse | null>>;
};

const ShoppingBasketContext = createContext<IShoppingBasketContext>({
  basket: null,
  addFromToItemsToBasket: () => null,
  addSeasonalTicketToBasket: () => null,
  removeItemFromBasket: () => null,
  returnBasketTransactions: () => [],
  clearItemsAfterPayment: () => null,
  clearBasket: () => null,
  getBasketFinalPriceAsNumber: () => 0,
  getBasketPTU: () => null,
  setBasketFromStorage: () => null,
  itemsNumber: 0,
  basketCancelled: false,
  resetBasketOnTimeout: () => null,
  lessThan2MinutesLeft: false,
  setLessThan2MinutesLeft: noop,
  timeLimit: null,
  userSaw2MinutesLeftToPayModal: false,
  setUserSaw2MinutesLeftToPayModal: noop,
  userSaw0MinutesModal: false,
  setUserSaw0MinutesModal: noop,
  setBuyerNameForTickets: () => null,
  buyerNameEditMode: false,
  setBuyerNameEditMode: noop,
  setWaitingForPayment: () => null,
  setInvoiceFormDataForItem: () => null,
  getItemsWithInvoiceData: () => null,
  findSameTicketInBasketAsTicketToAdd: () => false,
  basketEcoOffsetDiscount: null,
  setBasketEcoOffsetDiscount: () => null,
});

export const useShoppingBasket = () => {
  return useContext(ShoppingBasketContext);
};

interface Props {
  children: ReactNode;
}

const ShoppingBasketProvider: FC<Props> = ({children}) => {
  const [basket, setBasket] = useState<Basket | null>(null);
  const [items, setItems] = useState<BasketItem[] | null>(null);
  const [timeLimit, setTimeLimit] = useState<string | null>(null);
  const [lessThan2MinutesLeft, setLessThan2MinutesLeft] = useState<boolean>(false);
  const [basketCancelled, setBasketCancelled] = useState<boolean>(false);
  const [userSaw2MinutesLeftToPayModal, setUserSaw2MinutesLeftToPayModal] = useState<boolean | null>(false);
  const [userSaw0MinutesModal, setUserSaw0MinutesModal] = useState<boolean | null>(false);
  const [buyerNameEditMode, setBuyerNameEditMode] = useState<boolean>(false);

  const itemsNumber = useMemo(() => items?.length ?? 0, [items]);

  const [basketEcoOffsetDiscount, setBasketEcoOffsetDiscount] = useState<IGenerateAdditionalFeeResponse | null>(null);

  const basketStorageName = 'basketStorage';

  const clearState = () => {
    setItems(null);
    setBasketEcoOffsetDiscount(null);
    setBasketCancelled(false);
    setLessThan2MinutesLeft(false);
    setWaitingForPayment(false);
    setTimeLimit(null);
    setUserSaw2MinutesLeftToPayModal(false);
    setUserSaw0MinutesModal(false);
    window.sessionStorage.removeItem(basketStorageName);
  };

  const returnItemsAfterDuplicatesCheck = (item: BasketItem, basketItems: BasketItem[] | null) => {
    const duplicates = checkForDuplicates(item);

    if (!!duplicates && duplicates.length > 0) {
      item.duplicate = true;

      if (!!duplicates.some((el) => el.buyer === item.buyer)) {
        item.duplicateSameName = true;
      }

      const duplicatesTransactions = duplicates.map((el) => {
        if (!!el.ticket && !!el.ticket.transactions) {
          return el.ticket.transactions[0].transakcjaNr;
        }

        return false;
      });

      if (!!duplicatesTransactions && duplicatesTransactions.length > 0 && !!basketItems) {
        const newItems = basketItems.map((el) => {
          const elTransactions = el.ticket?.transactions;

          if (
            !!elTransactions &&
            elTransactions.some((sm) => duplicatesTransactions.find((tr) => tr === sm.transakcjaNr))
          ) {
            return {
              ...el,
              duplicate: true,
              duplicateSameName: el.buyer === item.buyer,
            };
          }

          return el;
        });

        newItems.push(item);

        return newItems;
      }
    }

    return [...(basketItems ?? []), item];
  };

  const couldAddItemToBasketItems = () => items === null || (!!items && items.length < 42);

  const generateBasketItem = (
    searchResults: IConnectionSearchResultsList | null,
    generatedTicket: IGenerateTicketResponse,
    additionalTickets: IGenerateAdditionalTicketsResponse | null,
    additionalTicketsContext: AddTicket | null,
    buyersName: string,
    adultTickets: INewTicket[] | null,
    childrenTickets: INewTicket[] | null,
    seasonal: boolean,
  ): BasketItem => {
    const ticket = {
      generatedTrips: generatedTicket.odcinki,
      price: generatedTicket.cenaBilet,
      class: generatedTicket.odcinki[0].klasa,
      offer: generatedTicket.ofertaKod,
      adultTickets: adultTickets,
      childrenTickets: childrenTickets,
      travelLength: searchResults?.dystans ?? 0,
      transactions: generatedTicket.transakcjeNr,
      travelTime: searchResults?.czasJazdy ?? 0,
      departureDate: searchResults?.dataWyjazdu ?? generatedTicket.odcinki[0].wyjazdData,
      arrivalDate: searchResults?.dataPrzyjazdu ?? generatedTicket.odcinki[0].przyjazdData,
      timeToPay: generatedTicket.czasNaOplacenie,
      trains: searchResults?.pociagi ?? [],
    } as BasketTicket;

    const newAdditionalTickets = {
      priceBikes: generatedTicket.cenaRowery,
      ptuPriceBikes: generatedTicket.ptuRoweryKwota,
      bikesTickets: additionalTicketsContext?.bikesTickets,
      dogsTickets: additionalTicketsContext?.dogsTickets,
      guideDogsTickets: additionalTicketsContext?.guideDogsTickets,
      generatedAddTickets: additionalTickets,
      luggageTickets: additionalTicketsContext?.luggageTickets,
      transactions: additionalTickets?.transakcjeNr,
    } as BasketAdditionalTickets;

    return {
      ticket: ticket,
      additionalTickets: newAdditionalTickets,
      buyer: buyersName,
      timeOut: false,
      duplicate: false,
      duplicateSameName: false,
      invoiceFormData: null,
      seasonal: seasonal,
    };
  };

  const addSeasonalTicketToBasket = (generatedTicket: IGenerateTicketResponse, buyersName: string) => {
    const traveler = [
      {
        discountCode: generatedTicket.podrozni[0].kodZakupowyZnizki,
      },
    ] as INewTicket[];

    const newSeasonalTicket = generateBasketItem(null, generatedTicket, null, null, buyersName, traveler, [], true);

    if (couldAddItemToBasketItems()) {
      setItems((prev) => [...(prev ?? []), newSeasonalTicket]);
    }
  };

  const addFromToItemsToBasket = (
    searchResultsFrom: IConnectionSearchResultsList,
    generatedTicketFrom: IGenerateTicketResponse,
    addTicketsFrom: IGenerateAdditionalTicketsResponse | null,
    addTicketsContextFrom: AddTicket | null,
    searchResultsTo: IConnectionSearchResultsList | null,
    generatedTicketTo: IGenerateTicketResponse | null,
    addTicketsTo: IGenerateAdditionalTicketsResponse | null,
    addTicketsContextTo: AddTicket | null,
    buyersName: string,
    adultTickets: INewTicket[] | null,
    childrenTickets: INewTicket[] | null,
  ) => {
    if (couldAddItemToBasketItems()) {
      const itemFrom = generateBasketItem(
        searchResultsFrom,
        generatedTicketFrom,
        addTicketsFrom,
        addTicketsContextFrom,
        buyersName,
        adultTickets,
        childrenTickets,
        false,
      );

      let newItems = returnItemsAfterDuplicatesCheck(itemFrom, items);

      if (!!searchResultsTo && !!generatedTicketTo) {
        const itemTo = generateBasketItem(
          searchResultsTo,
          generatedTicketTo,
          addTicketsTo,
          addTicketsContextTo,
          buyersName,
          adultTickets,
          childrenTickets,
          false,
        );

        newItems = returnItemsAfterDuplicatesCheck(itemTo, newItems);
      }

      setItems(newItems);
    }
  };

  const checkForDuplicates = (newItem: BasketItem): BasketItem[] | undefined => {
    if (!!items && items.length > 0) {
      const isThereAnyDuplicates = items.filter((el) => {
        if (
          el.ticket &&
          newItem.ticket &&
          el.ticket.arrivalDate === newItem.ticket.arrivalDate &&
          el.ticket.departureDate === newItem.ticket.departureDate
        ) {
          const elTrainsLength = el.ticket.trains.length;
          const newItemTrainsLength = el.ticket.trains.length;

          if (elTrainsLength > 0 && newItemTrainsLength > 0 && elTrainsLength === newItemTrainsLength) {
            const elTrains = el.ticket.trains;
            const newItemTrains = newItem.ticket.trains;

            let counter = 0;

            for (let i = 0; i < elTrains.length; i++) {
              if (
                elTrains[i].stacjaWyjazdu === newItemTrains[i].stacjaWyjazdu &&
                elTrains[i].stacjaPrzyjazdu === newItemTrains[i].stacjaPrzyjazdu &&
                elTrains[i].nrPociagu === newItemTrains[i].nrPociagu
              ) {
                counter++;
              }
            }

            if (counter > 0 && counter === elTrainsLength) {
              return true;
            }
          }
        }

        return false;
      });

      return isThereAnyDuplicates;
    }
  };

  const returnBasketTransactions = () => {
    const transactions = [] as BasketTransaction[];

    if (!!items && items.length > 0) {
      items.forEach((it) => {
        if (!!it.ticket && !!it.ticket.transactions && it.ticket.transactions.length > 0 && !it.timeOut) {
          it.ticket.transactions.forEach((el) => transactions.push(el));
        }
      });

      items.forEach((add) => {
        if (
          !!add.additionalTickets &&
          !!add.additionalTickets.transactions &&
          add.additionalTickets.transactions.length > 0 &&
          !add.timeOut
        ) {
          add.additionalTickets.transactions.forEach((el) => transactions.push(el));
        }
      });

      if (!!basketEcoOffsetDiscount) {
        basketEcoOffsetDiscount?.transakcjeNr?.forEach((el) =>
          transactions.push({
            transakcjaNr: el.transakcjaNr,
            cena: el.kwota,
          }),
        );
      }
    }

    return transactions;
  };

  const removeItemFromBasket = (transNr: number) => {
    if (items && items.length > 0) {
      const itemToDelete = items.find((i) => i.ticket?.transactions.some((t) => t.transakcjaNr === transNr));

      if (!!itemToDelete && itemToDelete.ticket) {
        const transactions = itemToDelete.ticket.transactions.map((x) => x.transakcjaNr);
        const additTransactions = itemToDelete.additionalTickets?.transactions?.map((x) => x.transakcjaNr) ?? [];
        const allTransactions = [...transactions, ...additTransactions];
        cancelAllTickets(allTransactions);

        let itemsFiltered = [...items].filter((x) => x.ticket?.transactions.every((t) => t.transakcjaNr !== transNr));

        if (!!itemsFiltered && itemsFiltered.length > 0) {
          const duplicates = checkForDuplicates(itemToDelete);

          if (!!duplicates && duplicates.length > 0) {
            const filteredDuplicates = duplicates.filter(
              (el) => !el.ticket?.transactions.some((sm) => sm.transakcjaNr === transNr),
            );

            if (!!filteredDuplicates && filteredDuplicates.length > 0) {
              const duplicatesTransactions = filteredDuplicates.map((el) => {
                if (!!el.ticket && !!el.ticket.transactions) {
                  return el.ticket.transactions[0].transakcjaNr;
                }

                return false;
              });

              itemsFiltered = itemsFiltered.map((el) => {
                if (
                  duplicatesTransactions.some(
                    (sm) =>
                      !!el.ticket &&
                      !!el.ticket.transactions &&
                      el.ticket.transactions.some((tt) => tt.transakcjaNr === sm),
                  )
                ) {
                  if (filteredDuplicates.length === 1) {
                    return {
                      ...el,
                      duplicate: false,
                      duplicateSameName: false,
                    };
                  }

                  if (filteredDuplicates.length > 1) {
                    const elTransaction = el.ticket?.transactions[0].transakcjaNr ?? 0;

                    return {
                      ...el,
                      duplicate: true,
                      duplicateSameName: filteredDuplicates
                        .filter((fl) => !fl.ticket?.transactions.some((sm) => sm.transakcjaNr === elTransaction))
                        .some((fd) => el.buyer === fd.buyer),
                    };
                  }
                }

                return el;
              });
            }
          }

          setItems(itemsFiltered);
          setBasket((prev) => ({...(prev ?? ({} as Basket)), items: itemsFiltered}));
        } else {
          clearState();
          clearBasket();
        }
      }
    }
  };

  const cancelAllTickets = async (transactions: number[]) => {
    const promises = transactions.map(async (el) => {
      const payload: ICancelTicketRequest = {
        urzadzenieNr: 0,
        metoda: '',
        transakcjaNr: el,
      };
      return await api.cancelTicket(payload);
    });
    try {
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  };

  const clearItemsAfterPayment = () => {
    if (!!items && items.length > 0) {
      clearState();
    }
  };

  const clearBasket = () => {
    if (!items || (!!items && items.length < 1) || !!basketCancelled) {
      setBasket(null);
      setBasketEcoOffsetDiscount(null);
    }
  };

  const setWaitingForPayment = (value: boolean) => {
    !!basket &&
      setBasket((prev) => ({
        ...(prev ?? ({} as Basket)),
        waitingForPayment: value,
      }));
  };

  const disableOutdatedItemsAndReturnArray = (itemsArr: BasketItem[]) => {
    const dateNow = Date.parse(new Date().toISOString());

    const arrayWithDisabledItems = itemsArr.map((el) => {
      const elTimeLeft = el.ticket?.timeToPay;

      if (!!elTimeLeft) {
        const parsedTimeToPay = Date.parse(elTimeLeft);

        if (parsedTimeToPay <= dateNow && !el.timeOut) {
          const transactions = el.ticket?.transactions?.map((x) => x.transakcjaNr) ?? [];
          const additTransactions = el.additionalTickets?.transactions?.map((x) => x.transakcjaNr) ?? [];
          const allTransactions = [...transactions, ...additTransactions];

          if (allTransactions.length > 0) {
            cancelAllTickets(allTransactions);
          }

          return {...el, timeOut: true};
        }
      }

      return el;
    });

    return arrayWithDisabledItems;
  };

  const getBasketFinalPriceAsNumber = () => {
    let final = 0;

    if (!!items && items.length > 0) {
      const itemsWithoutDisabled = items.filter((el) => !el.timeOut);

      if (!!itemsWithoutDisabled && itemsWithoutDisabled.length > 0) {
        const allPrices = itemsWithoutDisabled.map((x) => x.ticket?.price ?? 0).reduce((prev, next) => prev + next);

        const additionalPrices = items
          .map((x) => {
            const item = x.additionalTickets;

            if (!!item) {
              const bagPrice = item.generatedAddTickets?.bagazCena ?? 0;
              const dogPrice = item.generatedAddTickets?.piesCena ?? 0;

              return item.priceBikes + bagPrice + dogPrice;
            }

            return 0;
          })
          .reduce((prev, next) => prev + next);

        final = allPrices + additionalPrices;
      }
    }

    return final;
  };

  const getFinalPriceForBikes = () => {
    let final = 0;

    if (!!items && items.length > 0) {
      const bikesPrices = items.map((x) => x.additionalTickets?.priceBikes ?? 0).reduce((prev, next) => prev + next);

      if (bikesPrices > 0) {
        final = bikesPrices;
      }
    }

    return final;
  };

  const getBasketPTU = (t: TFunction, taxRate: 8 | 23): string | null => {
    if (taxRate === 8) {
      const PTUBase = getBasketFinalPriceAsNumber() - getFinalPriceForBikes();
      return !!PTUBase ? formatPrice(t, PTUBase - PTUBase / 1.08) : null;
    } else if (taxRate === 23) {
      const PTUBase = getFinalPriceForBikes();
      return !!PTUBase ? formatPrice(t, PTUBase - PTUBase / 1.23) : null;
    } else return null;
  };

  const setBasketFromStorage = () => {
    const basketFromStorage = window.sessionStorage.getItem(basketStorageName);

    if (!!basketFromStorage && !basket) {
      const basketToObj = JSON.parse(basketFromStorage) as Basket;

      if (!!basketToObj && !!basketToObj.items) {
        const disableOutdatedItems = disableOutdatedItemsAndReturnArray(basketToObj.items);

        basketToObj.items = disableOutdatedItems;

        setItems(basketToObj.items);
        setBasket(basketToObj);

        if (basketToObj.items.every((x) => x.timeOut) && !basketCancelled) {
          setBasketCancelled(true);
        }
      }
    }
  };

  const setInvoiceFormDataForItem = (transNr: number, invoiceForm: IPaymentInvoiceData | null) => {
    if (!!items && items.length > 0) {
      const itemExists = items.find((i) => i.ticket?.transactions.some((t) => t.transakcjaNr === transNr));

      if (!!itemExists) {
        const itemIndex = items.indexOf(itemExists);

        if (itemIndex > -1) {
          const newItems = items;
          newItems[itemIndex].invoiceFormData = invoiceForm;

          setItems(newItems);
          setBasket((prev) => ({...(prev ?? ({} as Basket)), items: newItems}));
        }
      }
    }
  };

  const getItemsWithInvoiceData = (): BasketItem[] | null => {
    if (!items) return null;

    return items.filter((el) => !!el.invoiceFormData && el.invoiceFormData.invoice);
  };

  const sortDates = (basketItems: BasketItem[]) => {
    const dateNow = Date.parse(new Date().toISOString());

    const items = basketItems
      .filter((el) => {
        const parsedDate = Date.parse(el.ticket?.timeToPay ?? '');

        return parsedDate > dateNow;
      })
      .sort((prev, curr) => {
        return Date.parse(curr.ticket?.timeToPay ?? '') - Date.parse(prev.ticket?.timeToPay ?? '');
      })
      .reverse();

    if (items.length > 0) return items;

    return null;
  };

  const findItemWithEarlierDepartureDateThanPaytime = () => {
    const dateNowPlus15Minutes = new Date();
    dateNowPlus15Minutes.setMinutes(dateNowPlus15Minutes.getMinutes() + 15);

    const elements = items
      ?.filter((el) => {
        const elementDepartureDate = el.ticket?.departureDate;

        if (!el.seasonal && !!elementDepartureDate) {
          const parsedElDepartureDate = new Date(elementDepartureDate);

          return parsedElDepartureDate < dateNowPlus15Minutes;
        }

        return false;
      })
      .sort((prev, curr) => {
        return Date.parse(curr.ticket?.departureDate ?? '') - Date.parse(prev.ticket?.departureDate ?? '');
      })
      .reverse();

    if (!!elements && elements.length > 0 && !!elements[0].ticket) {
      const ticketDepartureDate = new Date(elements[0].ticket.departureDate);
      ticketDepartureDate.setMinutes(ticketDepartureDate.getMinutes() - 5);

      return ticketDepartureDate.toISOString();
    }

    return null;
  };

  const setBuyerNameForTickets = (transactions: BasketTransaction[], newName: string) => {
    if (!!transactions && transactions.length > 0 && !!items) {
      const itemToChange = items?.find((x) =>
        x.ticket?.transactions.some((t) => t.transakcjaNr === transactions[0].transakcjaNr),
      );

      if (!!itemToChange) {
        const oldName = itemToChange.buyer;

        if (oldName !== newName) {
          const duplicates = checkForDuplicates(itemToChange);

          let updatedItems = [...items].map((el) => {
            if (el.ticket?.transactions.some((o) => o.transakcjaNr === transactions[0].transakcjaNr)) {
              const item = {
                ...el,
                buyer: newName,
              };

              if (!!duplicates) {
                item.duplicateSameName = duplicates.some((dp) => dp.buyer === newName);
              }

              return item;
            }

            return el;
          });

          if (!!duplicates && duplicates.length > 0) {
            const filteredDuplicates = duplicates.filter(
              (el) =>
                !el.ticket?.transactions.some((sm) => sm.transakcjaNr === transactions[0].transakcjaNr) &&
                el.duplicateSameName,
            );

            if (!!filteredDuplicates && filteredDuplicates.length === 1) {
              const oneElementTransactions = filteredDuplicates[0].ticket?.transactions;

              if (!!oneElementTransactions && oneElementTransactions.length > 0) {
                updatedItems = updatedItems.map((el) => {
                  if (
                    !!el.ticket &&
                    el.ticket.transactions.some((x) => x.transakcjaNr === oneElementTransactions[0].transakcjaNr) &&
                    el.duplicateSameName
                  ) {
                    return {
                      ...el,
                      duplicateSameName: false,
                    };
                  }

                  return el;
                });
              }
            }
          }

          setItems(updatedItems);
          setBasket((prev) => ({...(prev ?? ({} as Basket)), items: updatedItems}));
        }
      }
    }
  };

  const currentBasketItemTicketHasSameDepartureDateAsTicketToAdd = (
    generatedTrips: IGenerateTicketTripResponse[],
    ticketDate: string,
  ) => {
    return generatedTrips.some((el) => {
      const elDate = new Date(el.wyjazdData).toISOString();

      if (elDate === ticketDate) {
        return true;
      }

      return false;
    });
  };

  const ticketWithSameTripDataExistsInBasket = (
    ticketToAdd: IGenerateTicketTripResponse,
    ticketInBasket: IGenerateTicketTripResponse,
  ) => {
    return (
      ticketInBasket.stacjaOdKod === ticketToAdd.stacjaOdKod &&
      ticketInBasket.stacjaDoKod === ticketToAdd.stacjaDoKod &&
      ticketInBasket.pociagNr === ticketToAdd.pociagNr &&
      ticketInBasket.wyjazdData === ticketToAdd.wyjazdData
    );
  };

  const counterHasSameNumberOfTripsAsTicketToAdd = (counter: number, ticketTrainsLength: number) =>
    counter > 0 && counter === ticketTrainsLength;

  const findSameTicketInBasketAsTicketToAdd = (
    ticketToAdd: IGenerateTicketResponse,
    passengerName: string,
  ): boolean => {
    if (!!items && items.length > 0 && !!ticketToAdd && !!ticketToAdd.odcinki && ticketToAdd.odcinki.length > 0) {
      const ticketTrains = ticketToAdd.odcinki;
      const ticketDate = new Date(ticketTrains[0].wyjazdData).toISOString();

      const ticketDuplicateExistsInBasket = items.find((basketItem) => {
        if (
          !!basketItem.ticket &&
          !!basketItem.ticket.generatedTrips &&
          basketItem.ticket.generatedTrips.length > 0 &&
          basketItem.buyer === passengerName
        ) {
          const currentBasketItemTrains = basketItem.ticket.generatedTrips;

          if (currentBasketItemTicketHasSameDepartureDateAsTicketToAdd(currentBasketItemTrains, ticketDate)) {
            let counter = 0;

            for (let i = 0; i < ticketTrains.length; i++) {
              ticketWithSameTripDataExistsInBasket(ticketTrains[i], currentBasketItemTrains[i]) && counter++;
            }

            return counterHasSameNumberOfTripsAsTicketToAdd(counter, ticketTrains.length);
          }
        }

        return false;
      });

      return !!ticketDuplicateExistsInBasket;
    }

    return false;
  };

  const resetBasketOnTimeout = () => {
    const disabledBasket = basket?.items.map((el) => {
      const transactions = el.ticket?.transactions?.map((x) => x.transakcjaNr) ?? [];
      const additTransactions = el.additionalTickets?.transactions?.map((x) => x.transakcjaNr) ?? [];
      const allTransactions = [...transactions, ...additTransactions];

      if (allTransactions.length > 0) {
        cancelAllTickets(allTransactions);
      }

      return {...el, timeOut: true};
    });

    setItems(disabledBasket ?? []);
    setBasketCancelled(true);
    setTimeLimit(null);
    !!lessThan2MinutesLeft && setLessThan2MinutesLeft(false);
    setBasketEcoOffsetDiscount(null);
  };

  useEffect(() => {
    if (!!items && !!basket && !!basket.items && basket.items.length > 0) {
      const basketToString = JSON.stringify(basket);

      window.sessionStorage.setItem(basketStorageName, basketToString);
    }
  }, [basket]);

  useEffect(() => {
    if (!!items && items.length > 0) {
      setBasket((prev) => ({...(prev ?? ({} as Basket)), items: items}));

      const sortedDates = sortDates(items);

      if (
        !!sortedDates &&
        sortedDates.length > 0 &&
        sortedDates.some((x) => !x.timeOut) &&
        !!sortedDates[0].ticket &&
        !!sortedDates[0].ticket.timeToPay
      ) {
        const timeLeftToPay = findItemWithEarlierDepartureDateThanPaytime() ?? sortedDates[0].ticket.timeToPay;

        if ((!!timeLimit && timeLimit !== timeLeftToPay) || !timeLimit) {
          setBasket((prev) => ({...(prev ?? ({} as Basket)), timeLimit: timeLeftToPay}));
          setTimeLimit(timeLeftToPay);
          lessThan2MinutesLeft && setLessThan2MinutesLeft(false);
        }
      }
    }
  }, [items, timeLimit]);

  const value = {
    basket,
    addFromToItemsToBasket,
    addSeasonalTicketToBasket,
    removeItemFromBasket,
    returnBasketTransactions,
    clearItemsAfterPayment,
    clearBasket,
    getBasketPTU,
    getBasketFinalPriceAsNumber,
    setBasketFromStorage,
    itemsNumber,
    basketCancelled,
    resetBasketOnTimeout,
    timeLimit,
    lessThan2MinutesLeft,
    setLessThan2MinutesLeft,
    userSaw2MinutesLeftToPayModal,
    setUserSaw2MinutesLeftToPayModal,
    userSaw0MinutesModal,
    setUserSaw0MinutesModal,
    setBuyerNameForTickets,
    buyerNameEditMode,
    setBuyerNameEditMode,
    setWaitingForPayment,
    setInvoiceFormDataForItem,
    getItemsWithInvoiceData,
    findSameTicketInBasketAsTicketToAdd,
    basketEcoOffsetDiscount,
    setBasketEcoOffsetDiscount,
  };

  return <ShoppingBasketContext.Provider value={value}>{children}</ShoppingBasketContext.Provider>;
};

export default ShoppingBasketProvider;
