import React from 'react';
import {useMyTicketsState} from '../../hooks/useTickets';
import {UserTicketsContext} from 'Utils/UserTicketsContext';
import ReturnTicket from 'components/pages/UserProfile/components/MyTickets/ReturnTicket';

export const ReturnTicketModal = React.memo(() => {
  const {isMobileVersion} = useMyTicketsState();
  const {returnedTicketData} = React.useContext(UserTicketsContext);

  return returnedTicketData && <ReturnTicket isMobile={isMobileVersion} />;
});
