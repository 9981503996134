import {api} from '../config';
import {MyTicketsRequestParams, MyTicketIdentifier, MyTicketRequestParams, MyTicket} from './types';
import {IdentifiersResponse, Response} from '../types';

export const getMyTickets = async (params: MyTicketsRequestParams) => {
  const response = await api.post<IdentifiersResponse<MyTicketIdentifier>>('private/endpoint/Sprzedaz', {
    metoda: 'pobierzIdentyfikatoryBiletow',
    ...params,
  });
  return response.data;
};

export const getMyTicket = async (params: MyTicketRequestParams) => {
  const response = await api.post<Response<'bilety', MyTicket>>('private/endpoint/Sprzedaz', {
    metoda: 'pobierzBilet',
    ...params,
  });
  return response.data;
};
