import React from 'react';
import {StateContext, DispatchContext, Action, StyledThemeActions} from '../types';
import {exhaustiveCheck} from 'common/utils';
import {hydrate} from '../utils/hydrate';
import {storageName} from '../consts';
import {setCssThemeClass, setCssFontSize} from '../utils/css';
import {MuiProvider} from '../MuiProvider';

const StyledThemeStateContext = React.createContext<StateContext>(hydrate());
const StyledThemeDispatchContext = React.createContext<DispatchContext | undefined>(undefined);

const reducer = (state: StateContext, action: Action) => {
  switch (action.type) {
    case StyledThemeActions.SET_THEME: {
      return {
        ...state,
        theme: action.payload,
        isContrastTheme: action.payload === 'contrast',
      };
    }
    case StyledThemeActions.SET_FONT: {
      return {
        ...state,
        font: action.payload,
      };
    }
    default: {
      return exhaustiveCheck(action, 'StyledTheme Action');
    }
  }
};

export const StyledThemeProvider = React.memo(({children}: {children: React.ReactNode}) => {
  const [state, dispatch] = React.useReducer(reducer, hydrate());
  const stateValue = React.useMemo(() => state, [state]);
  const dispatchValue = React.useMemo(() => ({dispatch}), [dispatch]);

  React.useEffect(() => {
    setCssThemeClass(state.theme);
  }, [state.theme]);

  React.useEffect(() => {
    setCssFontSize(state.font);
  }, [state.font]);

  React.useEffect(() => {
    localStorage.setItem(storageName, JSON.stringify(state));
  }, [state]);

  return (
    <StyledThemeStateContext.Provider value={stateValue}>
      <StyledThemeDispatchContext.Provider value={dispatchValue}>
        <MuiProvider theme={state.theme}>{children}</MuiProvider>
      </StyledThemeDispatchContext.Provider>
    </StyledThemeStateContext.Provider>
  );
});

export const useStyledThemeState = () => {
  const context = React.useContext(StyledThemeStateContext);

  if (!context) {
    throw new Error('useStyledThemeState must be used within the StyledThemeProvider');
  }

  return context;
};

export const useStyledThemeDispatch = () => {
  const context = React.useContext(StyledThemeDispatchContext);

  if (!context) {
    throw new Error('useStyledThemeDispatch must be used within the StyledThemeProvider');
  }

  return context;
};
