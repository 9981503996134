import {useEffect, useState} from 'react';

import {checkIfGuest} from 'Utils/commonFunctions';
import {getMyTickets} from 'common/api/myTickets/services';
import {myTicketsListVariantRequestParams} from '../MyTicketsList.consts';
import {MyTicketsListGuestVariants} from '../MyTicketsList.types';
import {useWindowResize} from 'common/hooks/screen';

/* 
  The hook was created to check all tickets for guest account after guest login, to recognize if the ticket used for login is active, history or returned.
*/

export const useDesktopCheckAllGuestTickets = () => {
  const {isMobile} = useWindowResize();

  const [guestTicket, setGuestTicket] = useState<MyTicketsListGuestVariants>();
  useEffect(() => {
    if (checkIfGuest() && !isMobile) {
      getMyTickets({
        ...myTicketsListVariantRequestParams['SINGLE'],
        numerStrony: 1,
      }).then((result) => result.identyfikatory.length > 0 && setGuestTicket('SINGLE'));
      getMyTickets({
        ...myTicketsListVariantRequestParams['RETURNED'],
        numerStrony: 1,
      }).then((result) => result.identyfikatory.length > 0 && setGuestTicket('RETURNED'));
      getMyTickets({
        ...myTicketsListVariantRequestParams['HISTORY'],
        numerStrony: 1,
      }).then((result) => result.identyfikatory.length > 0 && setGuestTicket('HISTORY'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return guestTicket;
};
