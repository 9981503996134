// Generated by https://quicktype.io

// Generated by https://quicktype.io

export interface Sklad {
  pojazdTyp: string;
  pojazdNazwa: string;
  wagony: string[];
  wagonyUdogodnienia?: {[key: string]: string[]};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wagonyNiedostepne: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  klasa0: any[];
  klasa1: string[];
  klasa2: string[];
  zmieniaKierunek: boolean;
}

export interface SkladWbnet {
  pojazdTyp: string;
  pojazdNazwa: string;
  wagony: number[];
  wagonyUdogodnienia: {[key: string]: string[]};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wagonyNiedostepne: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  klasa0: any[];
  klasa1: number[];
  klasa2: number[];
  kierunekJazdy: number;
  wagonySchemat: {[key: string]: string};
  klasaDomyslnyWagon: {[key: string]: number};
  zmieniaKierunek: boolean;
}

export interface Grm {
  id: number;
  layoutNT: LayoutNT;
  layoutNb: string;
  layoutUUID: string;
  createTime: number;
}

export interface LayoutNT {
  id: number;
  gridSize: GridSize;
  items: Items;
}

export interface GridSize {
  id: number;
  x: number;
  y: number;
}

export interface Items {
  id: number;
  place: Place[];
  internal: Internal[];
  sign: Internal[];
  cardinality: number;
}

export interface Internal {
  id: number;
  type: number;
  x: number;
  y: number;
  mounting?: string;
  zorder: number;
}

export interface Place {
  id: number;
  type: number;
  nb: string;
  direction: string;
  x: number;
  y: number;
  specialType: SpecialType | null;
  zorder: number;
  status?: PlaceStatus;
}

export interface Places {
  miejsceTypKod: number;
  usytowanieKod: number;
  wyborMiejscaRodzaj: number;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
  iconCode?: string;
}

export interface SpecialType {
  id: number;
  descriptionPl: string;
  descriptionEn: string;
  carriageCode: null;
  sectionCode: string;
  specialOffer: null;
  releaseTime: number;
  iconCode: string;
  ref?: number;
}

export enum CarriegeType {
  ACTIVE_LEFT = 'active-left.png',
  ACTIVE_MIDDLE = 'active-middle.png',
  ACTIVE_RIGHT = 'active-right.png',
  CURRENT_LEFT = 'current-left.png',
  CURRENT_MIDDLE = 'current-middle.png',
  CURRENT_RIGHT = 'current-right.png',
  DISABLED_LEFT = 'disabled-left.png',
  DISABLED_MIDDLE = 'disabled-middle.png',
  DISABLED_RIGHT = 'disabled-right.png',
  LOKOMOTYWA = 'lokomotywy.png',
}

// Generated by https://quicktype.io

export interface Carriage {
  pojazdTyp: string;
  pojazdNazwa: string;
  wagony: number[];
  wagonyUdogodnienia: {[key: string]: string[]};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wagonyNiedostepne: number[];
  wagonySchemat?: {[key: number]: string};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  klasa0: any[];
  klasa1: number[];
  klasa2: number[];
}

/**
 * Icon status for GRM
 */
export enum PlaceStatus {
  Available = '1',
  Taken = '2',
  NotAvailable = '3',
}

/**
 * Response from grm/miejsca
 */
export interface TakenPlaces {
  numer: number;
  wagony: {
    numer: number;
    miejsca: GRMAvailabilityPlace[];
  }[];
}

export interface GRMAvailabilityResponse {
  numer: number;
  wagony: {
    numer: number;
    miejsca: GRMAvailabilityPlace[];
  }[];
}

export interface GRMAvailabilityPlace {
  numer: string;
  dostepne: boolean;
  zajete: boolean;
}

export enum GRMAvailability {
  GRM_AVAILABLE = 'GRM_AVAILABLE',
  GRM_WAGONOWY_AVAILABLE = 'GRM_WAGONOWY_AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface MiejscaSpecjalne {
  pojazdTyp: string;
  pojazdNazwa: string;
  skladSpecjalne: SkladSpecjalne[];
  wagonySpecjalne: WagonySpecjalne[];
}

export interface SkladSpecjalne {
  id: number;
  opis: Opis;
  icon: string;
  wagony: string[];
}

export interface Opis {
  EN: string;
  PL: string;
}

export interface WagonySpecjalne {
  id: number;
  wagon_nr: number;
  carraige_code: null;
  section_code: null;
  dostepne: number;
  miejsca: string[];
}

export enum TrainDirectionEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
