import {Station} from 'common/api/localData';

export const getStationByCode = (code: number, stationsList: Station[]): Station | undefined =>
  code
    ? stationsList.find(
        (station: Station): boolean => station.kod === code || station.kodEVA === code || code === station.kodEPA,
      )
    : undefined;

export const getStationByCodeIBNR = (code: number, stationsList: Station[]): Station | undefined =>
  code ? stationsList.find((station: Station): boolean => station.kod === code) : undefined;

export const getStationByCodeEVA = (code: number, stationsList: Station[]): Station | undefined =>
  code ? stationsList.find((station: Station): boolean => station.kodEVA === code) : undefined;

export const isMetaStationByCodeEVA = (stationCode: number | string): boolean => {
  const code = stationCode.toString();
  return code.length === 7 && code.slice(2, 4) === '96';
};
