export const STATION_TYPES = {
  STANDARD_STATION: 0,
  PUBLIC_META_STATION: 1,
  NON_PUBLIC_META_STATION: 2,
  PKS_POLONUS: 3,
  POLREGIO: 4,
} as const;

export const PLACE_KIND_CODES = {
  SITTING: 1,
  LYING_PLACE: 2,
  SLEEPING_PLACE: 3,
} as const;

export const PLACE_POSITION_CODES = {
  ANY: 0,
  BOTTOM: 1,
  MIDDLE: 2,
  TOP: 3,
} as const;

export const USER_TYPE_CODES = {
  ANY: 0,
  ADULT: 1,
  CHILD: 2,
} as const;

export const DISCOUNT_OFFER_TYPE_CODES = {
  SINGLE: 1,
  SEASONAL: 2,
} as const;

export const SHOP_DISCOUNT_KIND_CODES = {
  STATUTORY: 1,
  COMMERCIAL: 2,
  RAILWAY: 3,
} as const;

export const STALE_TIME = 1000 * 60 * 60 * 24; // 24 hours,
