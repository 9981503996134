import {styled, Pagination as MuiPagination} from '@mui/material';

export const Pagination = styled(MuiPagination)`
  & .MuiPaginationItem-root {
    padding: 0.8rem;
    color: ${({theme}) => theme.color.inactive.default};
    border-radius: 0;

    &:hover {
      background: none;
    }

    &:focus-visible {
      outline: 2px solid ${({theme}) => theme.color.dark.default};
      background: none;
    }

    &.Mui-disabled {
      opacity: 1;

      & .MuiSvgIcon-root {
        fill: ${({theme}) => theme.color.components.button.inactive};
      }
    }

    &.Mui-selected {
      color: ${({theme}) => theme.color.highlight.default};
      border-bottom: 2px solid ${({theme}) => theme.color.highlight.default};
      background: none;

      &:hover {
        background: none;
      }
    }
  }

  & .MuiPaginationItem-sizeMedium {
    height: auto;
    font-size: ${({theme}) => theme.text.size[16]};
    font-weight: ${({theme}) => theme.text.weight[600]};
    line-height: 2.2rem;
  }

  & .MuiSvgIcon-root {
    fill: ${({theme}) => theme.color.accent.default};
  }

  & .MuiSvgIcon-fontSizeMedium {
    width: 24px;
    height: 24px;
  }

  & .MuiTouchRipple-root {
    display: none;
  }
`;
