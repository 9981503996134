/* eslint-disable no-extra-boolean-cast */
import {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AccDelete from '../AccDelete';
import AccDeleteModal from '../AccDeleteModal/AccDeleteModal';
import MyProfileHeader from 'components/common/MyProfileHeader';
import {useAuth} from 'Utils/AuthContext';
import {env} from 'env';
import style from './MyData.module.scss';
import useMyTickets from '../MyTickets/useMyTickets';
import {IIdResponseList} from 'api/sprzedaz/interfaces';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';
interface Props {
  navigateLink?: string;
}

const MyData: FC<Props> = ({navigateLink}) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const {getTicket} = useMyTickets();
  const [activeTickets, setActiveTickets] = useState<number>(0);
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);
  const [isAccDeleteModalOpen, setIsAccDeleteModalOpen] = useState<boolean>(false);
  const {userData} = useAuth();
  const {isContrastTheme} = useStyledThemeState();

  const getActiveTickets = async () => {
    try {
      const actualTick = (await getTicket({
        preType: 1,
        preHis: 0,
        wynikowNaStronie: 1,
        listaTyp: 0,
        sortowanie: 0,
        numerStrony: 1,
      })) as IIdResponseList;

      return actualTick.paginacja.wszystkichWynikow;
    } catch (error) {
      console.error(error);
    }
    return 0;
  };

  useEffect(() => {
    const numberOfActiveTickets = async () => {
      setActiveTickets(await getActiveTickets());
    };
    numberOfActiveTickets();
  }, []);

  return (
    <section className={`grid col-12 ${style.mydata_container}`}>
      <MyProfileHeader text={t('10018')} closeAction={() => navigate(navigateLink ?? '/')} />
      {userData && (
        <>
          <div className={`col-8 col-sm-12 ${style.data_box}`}>
            <span className={`${style.mydata_bluetext}`}>E-mail</span>
            <p className={style.value}>{userData?.email}</p>
            <form
              name="edit-email"
              action={`${env.REACT_APP_SSO_SERVER}/account/edit/email${isContrastTheme ? '?contrast' : ''}${isContrastTheme ? '&lang=' + i18n.language : '?lang=' + i18n.language}`}
              method="post"
            >
              <input
                type="hidden"
                name="token"
                value={sessionStorage.eic2Token && JSON.parse(sessionStorage.eic2Token).access_token}
              />
              <button
                className={`${style.change_button} ${style.mydata_orangetext}`}
                type="submit"
                aria-label={`${t('13004')} e-mail`}
              >
                {t('13004')}
              </button>
            </form>
          </div>
          <div className={`col-8 col-sm-12 ${style.data_box}`}>
            <span className={`${style.mydata_bluetext}`}>{t('29196')}</span>
            <p className={style.value}>{userData?.firstName}</p>
            <form
              name="edit-first-name"
              action={`${env.REACT_APP_SSO_SERVER}/account/edit/first_name${isContrastTheme ? '?contrast' : ''}${isContrastTheme ? '&lang=' + i18n.language : '?lang=' + i18n.language}`}
              method="post"
            >
              <input
                type="hidden"
                name="token"
                value={sessionStorage.eic2Token && JSON.parse(sessionStorage.eic2Token).access_token}
              />
              <button
                className={`${style.change_button} ${style.mydata_orangetext}`}
                type="submit"
                aria-label={`${t('13004')} ${t('29196')}`}
              >
                {t('13004')}
              </button>
            </form>
          </div>
          <div className={`col-8 col-sm-12 ${style.data_box}`}>
            <span className={`${style.mydata_bluetext}`}>{t('29197')}</span>
            <p className={style.value}>{userData?.lastName}</p>
            <form
              name="edit-last-name"
              action={`${env.REACT_APP_SSO_SERVER}/account/edit/last_name${isContrastTheme ? '?contrast' : ''}${isContrastTheme ? '&lang=' + i18n.language : '?lang=' + i18n.language}`}
              method="post"
            >
              <input
                type="hidden"
                name="token"
                value={sessionStorage.eic2Token && JSON.parse(sessionStorage.eic2Token).access_token}
              />
              <button
                className={`${style.change_button} ${style.mydata_orangetext}`}
                type="submit"
                aria-label={`${t('13004')} ${t('29197')}`}
              >
                {t('13004')}
              </button>
            </form>
          </div>
          <div className={`col-8 col-sm-12 ${style.data_box}`}>
            <span className={`${style.mydata_bluetext}`}>{t('29198')}</span>
            *************
            <form
              name="change-password"
              action={`${env.REACT_APP_SSO_SERVER}/account/password/change${isContrastTheme ? '?contrast' : ''}${isContrastTheme ? '&lang=' + i18n.language : '?lang=' + i18n.language}`}
              method="post"
            >
              <input
                type="hidden"
                name="token"
                value={sessionStorage.eic2Token && JSON.parse(sessionStorage.eic2Token).access_token}
              />
              <button
                className={`${style.change_button} ${style.mydata_orangetext}`}
                type="submit"
                aria-label={`${t('13004')} ${t('29198')}`}
              >
                {t('13004')}
              </button>
            </form>
          </div>
          {/* <div className={`col-8 col-sm-12 ${style.data_box}`}>
                        <span className={`${style.mydata_bluetext}`}>Dane do faktury</span>
                        {!userData?.address ?
                            "Podaj dane do faktury"
                            :
                            <div className={style.addressDetails}>
                                {userData.address.addressType.id === 1 ?
                                    <p>{userData.address.firstName} {userData.address.lastName}</p>
                                    :
                                    <p>{userData.address.name}</p>
                                }
                                <p>
                                    {userData.address.street} {userData.address.houseNumber}
                                    {!!userData.address.apartmentNumber.length && `/${userData.address.apartmentNumber}`}
                                </p>
                                <p>{userData.address.zipCode} {userData.address.city}</p>
                                {!!userData.address.nip.length && userData.address.addressType.id !== 1 &&
                                    <p>NIP {userData.address.nip}</p>
                                }
                            </div>
                        }
                        <MyDataModal
                            title="Dane do faktury"
                            inputName="updateInvoice"
                            userData={userData}
                            onSuccessfulUpdate={fetchAndSaveUserData}
                        />
                    </div> */}
          <div className={`col-12 col-sm-12 ${style.account_del}`}>
            <span>{t('29199')}</span>
            <p>{t('29200')}</p>
            <button onClick={() => (!!activeTickets ? setIsAccDeleteModalOpen(true) : setDeleteAccount(true))}>
              {t('29199')}
            </button>
          </div>
          {isAccDeleteModalOpen && <AccDeleteModal onClose={() => setIsAccDeleteModalOpen(false)} />}
          {deleteAccount && <AccDelete close={() => setDeleteAccount(false)} />}
        </>
      )}
    </section>
  );
};

export default MyData;
