import {MyTicketsRequestParams, TicketKind, ticketKinds} from 'common/api/myTickets';
import {MyTicketsListVariants, MyTicketsLocationState} from './MyTicketsList.types';

export const TICKETS_PER_PAGE = 5;

export const myTicketsListVariants = ['SINGLE', 'SEASONAL', 'HISTORY', 'RETURNED'] as const;

export const myTicketsListVariantRequestParams: Readonly<Record<MyTicketsListVariants, MyTicketsRequestParams>> = {
  SINGLE: new MyTicketsRequestParams(101, 101, 0, 0, TICKETS_PER_PAGE, 1),
  SEASONAL: new MyTicketsRequestParams(102, 101, 0, 0, TICKETS_PER_PAGE, 1),
  HISTORY: new MyTicketsRequestParams(103, 101, 1, 1, TICKETS_PER_PAGE, 1),
  RETURNED: new MyTicketsRequestParams(103, 102, 1, 1, TICKETS_PER_PAGE, 1),
};

export const myTicketsListVariantInvalidateParams: Readonly<
  Record<MyTicketsListVariants, [MyTicketsRequestParams['biletTyp'], MyTicketsRequestParams['listaTyp']]>
> = {
  SINGLE: [101, 101],
  SEASONAL: [102, 101],
  HISTORY: [103, 101],
  RETURNED: [103, 102],
};

export const myTicketsLocationStates: Readonly<Record<MyTicketsListVariants, Required<MyTicketsLocationState>>> = {
  SINGLE: {state: {tab: 'SINGLE'}},
  SEASONAL: {state: {tab: 'SEASONAL'}},
  HISTORY: {state: {tab: 'HISTORY'}},
  RETURNED: {state: {tab: 'RETURNED'}},
};

export const myTicketsListSeasonalVariants: MyTicketsListVariants[] = ['SEASONAL'];

export const myTicketsListReturnedVariants: MyTicketsListVariants[] = ['RETURNED'];

export const myTicketsListHistoryVariants: MyTicketsListVariants[] = ['HISTORY', 'RETURNED'];

export const myTicketsListGuestVariants: MyTicketsListVariants[] = ['SINGLE', 'HISTORY', 'RETURNED'];

export const additionalTicketKinds: TicketKind[] = [
  ticketKinds.BICYCLE,
  ticketKinds.DOG,
  ticketKinds.GUIDE_DOG,
  ticketKinds.LUGGAGE,
  ticketKinds.INTERNATIONAL_BICYCLE,
];

export const primaryTicketKinds: TicketKind[] = [
  ticketKinds.TICKET,
  ticketKinds.TICKET_WITH_RESERVATION,
  ticketKinds.RESERVATION,
  ticketKinds.RESERVATION_NATIONAL_SEASON_TICKET,
  ticketKinds.INTERNATIONAL_EPA,
  ticketKinds.INTERCITY_CARD_RESERVATION,
];

// Add translations from i18n.
export const tabLabels: Readonly<Record<MyTicketsListVariants, {mobile: string; desktop: string}>> = {
  SINGLE: {
    mobile: '29263',
    desktop: '13092',
  },
  SEASONAL: {
    mobile: '29264',
    desktop: '13093',
  },
  HISTORY: {
    mobile: '29030',
    desktop: '29030',
  },
  RETURNED: {
    mobile: '29031',
    desktop: '29031',
  },
};

// Add translations from i18n.
export const noDataTexts: Readonly<Record<MyTicketsListVariants, string>> = {
  SINGLE: '29265',
  SEASONAL: '29265',
  HISTORY: '29171',
  RETURNED: '29172',
};
