/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {FC, Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';

import {InvoiceCountryOption} from 'Utils/invoiceCountriesList';

import S from './Invoice.module.scss';

interface Props {
  searchValue: string;
  onSearchValueChange: Dispatch<SetStateAction<string>>;
  onSearchChange: (value: string) => void;
  countryList: InvoiceCountryOption[];
  filteredCountries: InvoiceCountryOption[];
  onFilteredCountriesChange: Dispatch<SetStateAction<InvoiceCountryOption[]>>;
  handleCountryChange: (country: InvoiceCountryOption) => void;
}

const CountriesSearcher: FC<Props> = ({
  searchValue,
  onSearchValueChange,
  onSearchChange,
  countryList,
  filteredCountries,
  onFilteredCountriesChange,
  handleCountryChange,
}) => {
  const {t} = useTranslation();
  return (
    <div className={S.countriesBox}>
      <input
        value={searchValue}
        className={S.countrySearchInput}
        type="text"
        placeholder={t('26023')}
        onChange={(e) => onSearchChange(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onSearchValueChange('');
            onFilteredCountriesChange([]);
          }
        }}
      />
      <ul className={S.countryList}>
        {searchValue.length < 1 &&
          countryList.map((el) => (
            <li
              key={`countryOption${el.code}`}
              className={S.countryOption}
              onClick={() => handleCountryChange(el)}
              tabIndex={0}
              onKeyDown={(event) => event.key === 'Enter' && handleCountryChange(el)}
            >
              {el.namePL}
            </li>
          ))}
        {!!filteredCountries.length &&
          searchValue.length > 1 &&
          filteredCountries.map((el) => (
            <li
              key={`countryOption${el.code}`}
              className={S.countryOption}
              onClick={() => handleCountryChange(el)}
              tabIndex={0}
              onKeyDown={(event) => event.key === 'Enter' && handleCountryChange(el)}
            >
              {el.namePL}
            </li>
          ))}
        {!filteredCountries.length && searchValue.length > 1 && <li className={S.countryOption}>{t('26024')}</li>}
      </ul>
    </div>
  );
};

export default CountriesSearcher;
