import {styled} from '@mui/material';

export const TicketDetailsModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  padding: 0.8rem 0.8rem 6rem;
  z-index: 2;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: ${({theme}) => theme.color.background.primary};
`;

export const SearchInputContainer = styled('div')`
  margin-bottom: 1rem;
`;
