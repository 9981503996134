import {useState, FC} from 'react';
import Grid from '@mui/material/Grid';

import {ReactComponent as Pencil} from 'assets/orangePencil.svg';
import {ReactComponent as ContrastPencil} from 'assets/contrastPencil.svg';
import Gear from 'assets/gear.svg';
import ContrastGear from 'assets/contrastGear.svg';

import {IAmenities} from 'api/pociagi/interfaces';
import {useStyledThemeState} from 'common/theme';
import AmenietiesModal from '../../AmenitiesModal';

import S from './AmenitiesNewDesktop.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  amenities: IAmenities;
  setAmenities: (e: IAmenities) => void;
}

const AmenitiesNewDesktop: FC<Props> = ({amenities, setAmenities}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div className={S.container}>
      <Grid
        container
        sx={{
          maxHeight: '96px',
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '32px 24px',
          }}
        >
          <Grid
            item
            sx={{
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={isContrastTheme ? ContrastGear : Gear}
                alt="Koło zębate"
                style={{
                  width: '26px',
                  height: '26px',
                }}
              />
            </Grid>
            <p className={S.text}>{t('15001')}</p>
          </Grid>
          <button
            type="button"
            className={S.changeBtn}
            aria-label={t('15002')}
            onClick={handleClickOpen}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickOpen();
              }
            }}
          >
            {t('13004')}
            <span className="hiddenLabel"> {t('15001')}</span>
            {isContrastTheme ? <ContrastPencil /> : <Pencil />}
          </button>
        </Grid>
      </Grid>
      {open && <AmenietiesModal open={open} setOpen={setOpen} amenities={amenities} setAmenities={setAmenities} />}
    </div>
  );
};
export default AmenitiesNewDesktop;
