import {ticketKinds} from 'common/api/myTickets';

export const getSeparatedSerialAndNumber = (ticketString: string) => {
  // regex looks for one or more letters followed by one or more numbers,
  // possibly separated by spaces
  const regex = /([a-zA-Z]+)\s*(\d+)/;
  const matches = ticketString.match(regex);

  if (matches) {
    return {
      serial: matches[1],
      number: matches[2],
    };
  }
};

export const checkIfSeasonalTicketIsActive = (
  dateStart: string | undefined,
  dateEnd: string | undefined,
  dateToCheck?: string,
) => {
  if (!dateStart || !dateEnd) return false;
  const actualDate = dateToCheck ? new Date(dateToCheck) : new Date();
  const ticketDateStart = new Date(dateStart);
  const ticketDateEnd = new Date(dateEnd);

  return actualDate.getTime() < ticketDateEnd.getTime() && actualDate.getTime() > ticketDateStart.getTime();
};

export const isReservationFromSeasonalTicket = (ticketKind: number | undefined): boolean => {
  return (
    ticketKind === ticketKinds.RESERVATION_NATIONAL_SEASON_TICKET ||
    ticketKind === ticketKinds.INTERCITY_CARD_RESERVATION
  );
};
