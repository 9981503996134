import {styled} from '@mui/material';

import Loader from './Loader';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;
export const CenteredLoader = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default CenteredLoader;
