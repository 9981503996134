/* eslint-disable @typescript-eslint/ban-types */
import axios, {AxiosResponse} from 'axios';
import {IErrorsList} from './error-interface';
import {env} from 'env';
import i18n from 'i18n';

const API_URL = env.REACT_APP_MAIN_API_URL;

export const client = axios.create({
  baseURL: API_URL,
  headers: {'Content-Type': 'application/json'},
});

client.interceptors.response.use(async function (response) {
  checkForApiError(response);
  return response;
});

const checkForApiError = (response: AxiosResponse) => {
  const error = (response.data as IErrorsList) ?? undefined;

  if (error && error.bledy && error.bledy.length > 0) {
    error.bledy.forEach((element) => {
      const message = element.opisy.find((x) => x.jezyk === i18n.language.toUpperCase())?.komunikat;
      const code = element.kod;
      const body = response.config.data;

      console.error(`API ERROR - ${message}, kod: ${code}\nbody: ${body}`);
    });
  }
};

const methods = {
  get: async <T,>(url: string) => {
    try {
      return await client.get<T>(url).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  getExt: async <T,>(url: string, headers: {}) => {
    try {
      return await client.get<T>(url, {headers: headers}).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  post: async <T,>(url: string, body: {}) => {
    try {
      return await client.post<T>(url, body).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  postExt: async <T,>(url: string, body: {}, headers: {}) => {
    try {
      return await client.post<T>(url, body, {headers: headers}).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  postJson: async <T,>(url: string, body: {}) => {
    try {
      return await client.post<T>(url, body, {headers: {'Content-Type': 'application/json'}}).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  put: async (url: string, body: {}) => {
    try {
      return await client.put(url, body).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  del: async (url: string) => {
    try {
      await client.delete(url).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  delExt: async <T,>(url: string, headers: {}) => {
    try {
      return await client.delete<T>(url, {headers: headers}).then((res) => {
        return res?.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
};

export default methods;
