export const PRICE_CODE_OFFERS = {
  UNAVAILABLE: 0,
  THERE_AND_BACK: 1,
  IRT: 2,
  NRT: 3,
  FAMILY_TICKET_OR_SUPER_PROMO_INTERNATIONAL: 4, //For domestic ticket - FAMILY_TICKET, international ticket - SPI
  BETTER_WITH_FAMILY: 14,
  INTERCITY_CARD_RESERVATION: 4998,
  //TODO add more after documentation update - https://jsoft.intercity.pl/browse/EXTIK-3
};
