import {useState, useEffect} from 'react';

export const useWindowWidth = (): number => {
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const observer = new ResizeObserver((e) => {
        e.forEach(() => {
          setWidth(window.innerWidth);
        });
      });
      observer.observe(document.body);
      return () => observer.disconnect();
    }
  }, []);
  return width;
};
