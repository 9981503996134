import {FC} from 'react';
import {isMobile} from 'react-device-detect';
import S from './BlueHeader.module.scss';

interface BlueHeaderDataProps {
  title: string;
}
const BlueHeader: FC<BlueHeaderDataProps> = ({title}) => (
  <h2 className={`${isMobile ? S.blueHeaderMobile : S.blueHeader}`}>{title}</h2>
);

export default BlueHeader;
