import {Dispatch, ReactElement, SetStateAction, useState} from 'react';
import style from './BuyerProfile.module.scss';
import BluePlus from 'assets/bluePlus.svg';
import ContrastPlus from 'assets/contrastPlus.svg';
import {setAutoFreeze} from 'immer';
import {useStyledThemeState} from 'common/theme';

import {BuyerProfile} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import {BuyerProfileActions, useDispatch, useTrackedState} from 'Utils/BuyerProfileContext';
import {useTranslation} from 'react-i18next';

type Props = {
  setProfileName: Dispatch<SetStateAction<string>>;
};

export enum InitialProfileViews {
  INPUT = 'INPUT',
  BUTTONS = 'BUTTONS',
}

setAutoFreeze(false);

const ProfilesList = ({setProfileName}: Props): ReactElement => {
  const {t} = useTranslation();
  const [userProfiles] = useState<BuyerProfile[]>([{name: t('29218')}, {name: t('29219')}, {name: t('29220')}]);
  const {isContrastTheme} = useStyledThemeState();
  const [profileNameState, setProfileNameState] = useState<string>('');
  const {activeProfile} = useTrackedState();
  const [mode, setMode] = useState<InitialProfileViews>(InitialProfileViews.BUTTONS);
  const dispatch = useDispatch();

  const handelProfileNameChange = (name: string) => {
    setProfileName(name);
    setProfileNameState(name);

    dispatch({
      type: BuyerProfileActions.UPDATE_ACTIVE_PROFILE,
      payload: {name: name},
    });
  };

  return (
    <div className={style.buyer_modal__nameselect}>
      <h3 className={style.buyer_modal__blueheader}>{activeProfile?.name}</h3>

      {mode === InitialProfileViews.INPUT ? (
        <input
          className={`${style.buyer_modal__nameinput}`}
          placeholder={t('29222')}
          value={profileNameState}
          onChange={(e) => {
            const {value} = e.currentTarget;
            handelProfileNameChange(value);
          }}
        />
      ) : (
        <ul className={style.buyer_modal__btn_box}>
          {userProfiles.map((profile: BuyerProfile) => {
            return (
              <li key={profile.name}>
                <button
                  type="button"
                  className={`${style.buyer_modal__namebtn} ${
                    activeProfile?.name === profile.name ? style.active : ''
                  }`}
                  onClick={() => {
                    handelProfileNameChange(profile.name);
                  }}
                >
                  {profile.name}
                </button>
              </li>
            );
          })}
          <button
            type="button"
            className={style.blue_btn}
            onClick={() => {
              handelProfileNameChange('');
              setMode(InitialProfileViews.INPUT);
            }}
          >
            <img src={isContrastTheme ? ContrastPlus : BluePlus} alt="Plus" />
            <p>{t('29221')}</p>
          </button>
        </ul>
      )}
    </div>
  );
};

export default ProfilesList;
