import {styled} from '@mui/material';

export const XButtonWrapper = styled('button')`
  background: none;
  border: none;
  padding: 0.4rem;
  display: flex;
  margin-left: auto;
  cursor: pointer;
`;
