import {Box, Skeleton} from '@mui/material';
import style from 'components/pages/UserProfile/components/MyTickets/MyTickets.module.scss';
import {MyTicketsListVariants} from '../../MyTicketsList.types';

interface TicketsListPageLoaderProps {
  elementsLength: number;
  isMobile: boolean;
  ticketVariant: MyTicketsListVariants;
}

export const TicketsListPageLoader = ({elementsLength, isMobile, ticketVariant}: TicketsListPageLoaderProps) => {
  return (
    <>
      {Array.from({length: elementsLength}, (_, index) =>
        isMobile ? (
          <Box key={index} mb={1}>
            <Skeleton variant="rounded" width="100%" height={ticketVariant === 'SEASONAL' ? 154 : 277} />
          </Box>
        ) : (
          <div key={index} className={style.ticket_container}>
            <div className={style.ticket_head_box}>
              <Box mb={3}>
                <Skeleton variant="rounded" width={270} height={44} />
              </Box>
            </div>
            <div className={style.ticket_main_data_wrapper}>
              <div className={style.ticket_main_data_left_column}>
                <div className={style.ticket_main_data_left_column_content}>
                  <div className={style.ticket_row}>
                    <div className={style.left_column_little}>
                      <Box mb={0.5}>
                        <Skeleton variant="rounded" width={40} height={17} />
                      </Box>
                      <Skeleton variant="rounded" width={ticketVariant !== 'SEASONAL' ? 100 : 220} height={22} />
                    </div>
                    {ticketVariant !== 'SEASONAL' && (
                      <>
                        <div className={style.left_column_little}>
                          <Box mb={0.5}>
                            <Skeleton variant="rounded" width={100} height={17} />
                          </Box>
                          <Skeleton variant="rounded" width={140} height={22} />
                        </div>
                        <div className={style.left_column_little}>
                          <Box mb={0.5}>
                            <Skeleton variant="rounded" width={60} height={17} />
                          </Box>
                          <Skeleton variant="rounded" width={80} height={22} />
                        </div>
                      </>
                    )}
                  </div>
                  {ticketVariant !== 'SEASONAL' && (
                    <>
                      <div className={style.ticket_row}>
                        <div>
                          <Box mb={0.5}>
                            <Skeleton variant="rounded" width={40} height={17} />
                          </Box>
                          <Skeleton variant="rounded" width={250} height={22} />
                        </div>
                      </div>
                      <div className={style.ticket_row}>
                        <Skeleton variant="rounded" width={80} height={22} />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={style.ticket_main_data_right_column}>
                <Skeleton variant="rounded" width={240} height={50} />
                {ticketVariant === 'SEASONAL' && (
                  <Box mt={1} mb={5}>
                    <Skeleton variant="rounded" width={240} height={50} />
                  </Box>
                )}
              </div>
            </div>
          </div>
        ),
      )}
    </>
  );
};
