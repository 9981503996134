import {removePolishLetters} from './commonFunctions';

const filterSingleKey = (obj, key, phraze) => {
  if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
    if (
      key === 'kod2D' ||
      key === 'sladWeglowy' ||
      key === 'ofertaNazwa' ||
      key === 'podroznyNazwa' ||
      key === 'miejsceTypNazwa' ||
      key === 'usytowanieNazwa' ||
      key === 'poziomCenowy'
    ) {
      return false;
    } else if (
      removePolishLetters(obj[key].toString()).replaceAll('-', '.').includes(removePolishLetters(phraze.toString()))
    )
      return true;
  }
  return false;
};

const arrayOfObjectFilter = (phraze, array) => {
  let test = false;
  if (typeof array === 'object' && array && array.length) {
    array.forEach((el) => {
      if (typeof el === 'object') {
        for (const key in el) {
          if (
            key === 'kod2D' ||
            key === 'sladWeglowy' ||
            key === 'ofertaNazwa' ||
            key === 'podroznyNazwa' ||
            key === 'miejsceTypNazwa' ||
            key === 'usytowanieNazwa' ||
            key === 'poziomCenowy'
          ) {
            if (!test) test = false;
          } else {
            if (filterSingleKey(el, key, phraze)) {
              test = true;
            } else if (arrayOfObjectFilter(phraze, el[key])) {
              test = true;
            }
          }
        }
      }
    });
  }
  return test;
};

const pureObjectFilter = (phraze, obj) => {
  if (typeof obj === 'object' && !obj.lenght) {
    for (const key in obj) {
      if (
        key === 'kod2D' ||
        key === 'sladWeglowy' ||
        key === 'ofertaNazwa' ||
        key === 'podroznyNazwa' ||
        key === 'miejsceTypNazwa' ||
        key === 'usytowanieNazwa' ||
        key === 'poziomCenowy'
      ) {
        return;
      } else {
        if (filterSingleKey(obj, key, phraze)) {
          return obj;
        } else if (arrayOfObjectFilter(phraze, obj[key])) {
          return obj;
        } else if (pureObjectFilter(phraze, obj[key])) {
          return obj;
        }
      }
    }
  }
};

export const searchFunction = (phraze, array) => {
  return array.filter((el) => {
    for (const key in el) {
      if (
        key === 'kod2D' ||
        key === 'sladWeglowy' ||
        key === 'ofertaNazwa' ||
        key === 'podroznyNazwa' ||
        key === 'miejsceTypNazwa' ||
        key === 'usytowanieNazwa' ||
        key === 'poziomCenowy'
      ) {
        return false;
      } else {
        if (filterSingleKey(el, key, phraze)) {
          return el;
        } else if (arrayOfObjectFilter(phraze, el[key])) {
          return el;
        } else if (pureObjectFilter(phraze, el[key])) {
          return el;
        }
      }
    }
    return false;
  });
};
