/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC, useState} from 'react';

import ConfirmButton from 'components/common/ConfirmButton';
import MovableLabelInput from 'components/common/MovableLabelInput';
import {getFullDate, returnStationByCode} from 'Utils/commonFunctions';
import {IDownloadTicketResponseSingleTicket, IChangePassengerDataRequest} from 'api/sprzedaz/interfaces';
import saleApi from 'api/sprzedaz/repository';
import {useStyledThemeState} from 'common/theme';
import {useData} from 'Utils/DataContex';

import style from './ChangePassengerName.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import QRcode from 'assets/qr-code-icon.svg';
import QRcodeContrast from 'assets/qr-code-icon-contrast.svg';
import ArrowRight from 'assets/orangeArrowRight.svg';
import ArrowRightContrast from 'assets/contrastArrowRight.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  currentName: string;
  ticket: IDownloadTicketResponseSingleTicket;
  closeModal: () => void;
  onSuccessChange: (name: string) => void;
  isSeasonal?: boolean;
}

const ChangePassengerName: FC<Props> = ({currentName, closeModal, ticket, onSuccessChange, isSeasonal}) => {
  const {isContrastTheme} = useStyledThemeState();
  const [newPassengerName, setNewPassengerName] = useState('');

  const {stationsList} = useData();
  const {t} = useTranslation();

  const handleChangePassengerName = async () => {
    const obj: IChangePassengerDataRequest = {
      urzadzenieNr: 0,
      metoda: '',
      transakcjaNr: ticket.transakcjaNr,
      podroznyNazwa: newPassengerName,
    };
    try {
      await saleApi.changePassengerData(obj).then((res) => {
        if (res && !res?.bledy.length) {
          onSuccessChange(newPassengerName);
        }
        closeModal();
      });
    } catch (e) {
      closeModal();
    }
  };

  const NameValidation = (name: string) => {
    return /(?:[A-Za-z-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+ )([A-Za-z-żźćńółęąśŻŹĆĄŚĘŁÓŃ-])+[^-]$/.test(name);
  };

  const NameInputValidation = (input: string) => {
    if (newPassengerName.length === 0) {
      return /[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]/.test(input);
    }
    if (newPassengerName.split(' ').length === 2) {
      return newPassengerName.match(/-/)
        ? /[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]/.test(input)
        : /[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]/.test(input);
    }
    return /[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]/.test(input);
  };

  const hasForbiddenCharacters =
    newPassengerName.match(/[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]/g)?.length !== newPassengerName.length;

  return (
    <div className={style.modal}>
      <div className={style.modalWrapper}>
        <header className={style.modalHeader}>
          <h2>{t('29247')}</h2>
          <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('29483')} onClick={closeModal} />
        </header>
        <div className={style.modalTicketData}>
          <img src={isContrastTheme ? QRcodeContrast : QRcode} alt={t('29137')} />
          <div>
            {!!ticket.odcinki.length &&
              !!ticket.odcinki[0].stacjaOdKod &&
              ticket?.odcinki.map((el, index) => (
                <div key={el.pociagNr}>
                  {el.stacjaOdKod !== ticket.odcinki[index - 1]?.stacjaDoKod ? (
                    <span>
                      <span className={style.ticketDetail}>{returnStationByCode(el.stacjaOdKod, stationsList)}</span>
                      <img
                        className={style.arrowIcon}
                        src={isContrastTheme ? ArrowRightContrast : ArrowRight}
                        alt={t('11027')}
                      />
                    </span>
                  ) : null}
                  <span>
                    <span className={style.ticketDetail}>{returnStationByCode(el.stacjaDoKod, stationsList)}</span>
                    {ticket.odcinki[index + 1] ? (
                      <img
                        className={style.arrowIcon}
                        src={isContrastTheme ? ArrowRightContrast : ArrowRight}
                        alt={t('11027')}
                      />
                    ) : null}
                  </span>
                </div>
              ))}
            <p className={style.ticketDetail}>
              {isSeasonal
                ? `${getFullDate(ticket?.waznoscBiletuOd)} - ${getFullDate(ticket?.waznoscBiletuDo)}`
                : getFullDate(ticket?.odcinki[0]?.wyjazdData)}
            </p>
            <p className={style.ticketDetail}>
              {t('29009')} {ticket.biletNr.toString()}
            </p>
          </div>
        </div>
        <div className={style.modalPassengerInfo}>
          <div>
            <p className={style.passengerNameHeader}>{t('29485')}</p>
            <p className={style.passengerName}>{currentName}</p>
          </div>
        </div>
        <div className={style.modalInput}>
          <MovableLabelInput
            t={t}
            name="passengerName"
            type="text"
            labelText="29475"
            value={newPassengerName}
            callback={(e) => setNewPassengerName(e.target.value)}
            hideRedStar
            regExValidation={NameInputValidation}
            trimPasteEvent
          />
        </div>
        {newPassengerName && hasForbiddenCharacters && <p className={style.inputError}>{t('24024')}</p>}
        <div className={style.modalBtnBox}>
          <ConfirmButton
            text={t('29474')}
            disabled={newPassengerName === currentName || !NameValidation(newPassengerName)}
            execute={handleChangePassengerName}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassengerName;
