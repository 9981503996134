import {Station} from 'common/api/localData';

export default (
  value: Station | number | undefined,
  stationListMap?: {[key: number]: Station},
): Station | undefined => {
  if (typeof value === 'number') {
    // eslint-disable-next-line no-extra-boolean-cast
    return !!stationListMap ? stationListMap[value] : undefined;
  }

  if (value === undefined) {
    return undefined;
  }

  return value;
};
