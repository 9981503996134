import {useData} from 'Utils/DataContex';
import {Station} from 'common/api/localData';
import getAutocompleteValueAsStation from 'components/common/AutocompleteInput/helpers/getAutocompleteValueAsStation';
import createOptionsDataForAutocomplete from 'components/common/AutocompleteInput/helpers/createOptionsDataForAutocomplete';
import React, {ReactElement, useDeferredValue, useEffect, useId, useMemo, useState} from 'react';
import {useStyledThemeState} from 'common/theme';
import styles from './Autocomplete.module.scss';
import AutocompleteOption from 'components/common/AutocompleteInput/AutocompleteOption';
import {ReactComponent as Circle} from 'assets/orangeCircle.svg';
import {ReactComponent as ContrastCircle} from 'assets/contrastCircle.svg';
import {ReactComponent as Pin} from 'assets/orangePin.svg';
import {ReactComponent as ContrastPin} from 'assets/contrastPin.svg';
import {ReactComponent as OrangeX} from 'assets/orangeX.svg';
import {ReactComponent as ContrastX} from 'assets/contrastX.svg';

type Props = {
  autocompleteValue: Station | number | undefined;
  setAutocompleteValue: React.Dispatch<Station>;
  label: string;
  variant: AutocompleteVariants;
};

export enum AutocompleteMode {
  DISABLED = 'DISABLED',
  OPTIONS_OPENED = 'OPTIONS_OPENED',
  OPTIONS_CLOSED = 'OPTIONS_CLOSED',
}

export enum AutocompleteVariants {
  FROM = 'FROM',
  TO = 'TO',
}

const AutocompleteInput = ({autocompleteValue, setAutocompleteValue, label, variant}: Props): ReactElement => {
  const {isContrastTheme} = useStyledThemeState();
  const [stationInputValue, setStationInputValue] = useState('');
  const deferredStationInputValue = useDeferredValue(stationInputValue);
  const {stationListMap} = useData();
  const [activeOption, setActiveOption] = useState<Station | null>(null);
  const uniqId = useId();
  const [mode, setMode] = useState<AutocompleteMode>(AutocompleteMode.OPTIONS_CLOSED);

  const availableOptions = useMemo(() => {
    return createOptionsDataForAutocomplete(deferredStationInputValue, stationListMap);
  }, [deferredStationInputValue]);

  //As autocompleteValue may by either number or Station convert it to Station
  const autocompleteValueAsStation: Station | undefined = useMemo(
    () => getAutocompleteValueAsStation(autocompleteValue, stationListMap),
    [autocompleteValue, stationListMap],
  );

  useEffect(() => {
    //set input value when its explicitly set by autocompleteValue

    // eslint-disable-next-line no-extra-boolean-cast
    setStationInputValue(!!autocompleteValueAsStation?.nazwa ? autocompleteValueAsStation?.nazwa : '');
  }, [autocompleteValueAsStation]);

  const handleStationInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setStationInputValue(e.currentTarget.value);
  };

  const handleSelectOption = (station: Station) => {
    setAutocompleteValue(station);
    setMode(AutocompleteMode.OPTIONS_CLOSED);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentIndex = (availableOptions || []).findIndex((station: Station) => {
      if (!activeOption) return false;
      return activeOption.kod === station.kod && activeOption.kodEPA === station.kodEPA;
    });

    switch (e.key) {
      case 'ArrowUp':
        if (currentIndex === -1) setActiveOption(availableOptions[availableOptions.length - 1]);
        else setActiveOption(availableOptions[(currentIndex + 1) % availableOptions.length]);
        break;
      case 'ArrowDown':
        if (currentIndex === -1) setActiveOption(availableOptions[0]);
        else setActiveOption(availableOptions[currentIndex === 0 ? availableOptions.length - 1 : currentIndex - 1]);
        break;
      case 'Enter':
        !!activeOption && handleSelectOption(activeOption);
        break;
      default:
        setMode(AutocompleteMode.OPTIONS_OPENED);
    }
  };

  const circleThemeCheck = isContrastTheme ? <ContrastCircle /> : <Circle />;
  const pinThemeCheck = isContrastTheme ? <ContrastPin /> : <Pin />;

  return (
    <div className={styles.autocomplete}>
      <input type="hidden" value={autocompleteValueAsStation?.kod ? autocompleteValueAsStation?.kod : ''} />
      <label className={styles.autocompleteStationLabel} htmlFor={uniqId}>
        <span className={styles.autocompleteLabelText}>{label}</span>
        <span className={styles.autocompleteInputContainer}>
          <span className={`${styles.autocompleteInputMeta} ${styles.autocompleteInputMetaIcon}`}>
            {variant === AutocompleteVariants.FROM ? circleThemeCheck : pinThemeCheck}
          </span>
          <input
            className={styles.autocompleteStationInput}
            id={uniqId}
            disabled={mode === AutocompleteMode.DISABLED}
            onFocus={() => setMode(AutocompleteMode.OPTIONS_OPENED)}
            onBlur={() => setMode(AutocompleteMode.OPTIONS_CLOSED)}
            value={stationInputValue}
            onChange={handleStationInputChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
          <button
            type="button"
            aria-label="Wyczyść pole"
            onMouseDown={(e) => {
              e.stopPropagation();
              setStationInputValue('');
            }}
            className={`${styles.autocompleteInputMeta} ${styles.autocompleteInputMetaClose}`}
          >
            {isContrastTheme ? <ContrastX /> : <OrangeX />}
          </button>
        </span>
      </label>

      {mode === AutocompleteMode.OPTIONS_OPENED && (
        <ul className={styles.autocompleteOptions}>
          {availableOptions.map((station: Station) => {
            // eslint-disable-next-line no-extra-boolean-cast
            const isActive = !!activeOption
              ? activeOption.kod === station.kod && activeOption.kodEPA === station.kodEPA
              : false;

            return (
              <AutocompleteOption
                key={station.kod ? station.kod : station.kodEPA}
                station={station}
                handleSelectOption={handleSelectOption}
                isActive={isActive}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
