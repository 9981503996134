import React from 'react';
import {useTranslation} from 'react-i18next';

import {useStyledThemeDispatch, StyledThemeActions, Font} from 'common/theme';

import S from './TextResizeControls.module.scss';

/*
 * Used for E2E tests.
 */
const TAG = 'TextResizeControls';

export const TextResizeControls = React.memo(() => {
  const {t} = useTranslation();
  const {dispatch} = useStyledThemeDispatch();

  const changeFont = (e: React.MouseEvent | React.KeyboardEvent, font: Font) => {
    if (!(e as React.KeyboardEvent).key || (e as React.KeyboardEvent).key === 'Enter') {
      dispatch({type: StyledThemeActions.SET_FONT, payload: font});
    }
  };

  return (
    <div className={S.controls}>
      <button
        data-testid={`${TAG}-small-font`}
        aria-label={t('10007')}
        onClick={(e) => changeFont(e, 'small')}
        onKeyDown={(e) => changeFont(e, 'small')}
      >
        A
      </button>
      <button
        data-testid={`${TAG}-medium-font`}
        className={S.fontMedium}
        aria-label={t('10008')}
        onClick={(e) => changeFont(e, 'medium')}
        onKeyDown={(e) => changeFont(e, 'medium')}
      >
        A
      </button>
      <button
        data-testid={`${TAG}-big-font`}
        className={S.fontBig}
        aria-label={t('10009')}
        onClick={(e) => changeFont(e, 'large')}
        onKeyDown={(e) => changeFont(e, 'large')}
      >
        A
      </button>
    </div>
  );
});
