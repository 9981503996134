/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {Station} from 'common/api/localData';
import React, {ReactElement, useEffect, useRef} from 'react';
import styles from './Autocomplete.module.scss';

type Props = {
  isActive: boolean;
  handleSelectOption: React.Dispatch<Station>;
  station: Station;
};

const AutocompleteOption = ({isActive, handleSelectOption, station}: Props): ReactElement => {
  const ref = useRef<HTMLLIElement>(null);
  const className = isActive ? `${styles.option} ${styles.optionActive}` : `${styles.option}`;

  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [isActive]);

  return (
    <li
      ref={ref}
      className={className}
      onMouseDown={() => {
        // inputRef.current?.focus()
        handleSelectOption(station);
      }}
    >
      {station.nazwa}
    </li>
  );
};

export default AutocompleteOption;
