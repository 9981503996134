import {FC} from 'react';

import style from './Register.module.scss';
import Checked from 'assets/orangeCheckboxIcon.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  password: string;
}

const PasswordValidation: FC<Props> = ({password}) => {
  const {t} = useTranslation();
  const ErrorArray: string[] = [t('29063'), t('29064'), t('29065'), t('29066')];

  const CheckLowerCase = (pass: string) => {
    const lowerCase = /[a-z]/.test(pass);
    return lowerCase;
  };
  const CheckUpperCase = (pass: string) => {
    const upperCase = /[A-Z]/.test(pass);
    return upperCase;
  };
  const CheckNumber = (pass: string) => {
    const number = /\d/.test(pass);
    return number;
  };
  const CheckSpecialSign = (pass: string) => {
    const specialSign = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(pass);
    return specialSign;
  };

  function GatherError(pass: string) {
    if (CheckLowerCase(pass)) {
      const index = ErrorArray.indexOf(t('29063'));
      ErrorArray.splice(index, 1);
    }
    if (CheckUpperCase(pass)) {
      const index = ErrorArray.indexOf(t('29064'));
      ErrorArray.splice(index, 1);
    }
    if (CheckNumber(pass)) {
      const index = ErrorArray.indexOf(t('29065'));
      ErrorArray.splice(index, 1);
    }
    if (CheckSpecialSign(pass)) {
      const index = ErrorArray.indexOf(t('29066'));
      ErrorArray.splice(index, 1);
    }
  }
  GatherError(password);

  return (
    <div>
      <p className={style.password_validation}>
        {password.length >= 8 && password.length <= 32 && <img src={Checked} alt={t('16027')} />}
        {t('29067')}
      </p>
      <p className={style.password_validation}>
        {ErrorArray.length <= 1 && <img src={Checked} alt={t('16027')} />}
        {t('29068', {interpolation: {escapeValue: false}})}
      </p>
      <p className={style.password_validation}>
        {CheckLowerCase(password) && <img src={Checked} alt={t('16027')} />}
        {t('29069')}
      </p>
      <p className={style.password_validation}>
        {CheckUpperCase(password) && <img src={Checked} alt={t('16027')} />}
        {t('29070')}
      </p>
      <p className={style.password_validation}>
        {CheckNumber(password) && <img src={Checked} alt={t('16027')} />}
        {t('29071')}
      </p>
      <p className={style.password_validation}>
        {CheckSpecialSign(password) && <img src={Checked} alt={t('16027')} />}
        {t('29072')}
      </p>
    </div>
  );
};

export default PasswordValidation;
