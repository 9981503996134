import {
  GDANSK_STATION_CODE,
  KRAKOW_STATION_CODE,
  POZNAN_STATION_CODE,
  SZCZECIN_STATION_CODE,
  WARSAW_STATION_CODE,
  WROCLAW_STATION_CODE,
} from 'Utils/constants';
import {Station} from 'common/api/localData';
import deburr from 'lodash';

export default (searchPhrase: string, stationListMap?: {[key: number]: Station}): Station[] => {
  if (!!searchPhrase && stationListMap) {
    return Object.values(stationListMap)
      .filter((station: Station) => {
        const neddle = deburr(searchPhrase.toLocaleLowerCase());
        return deburr(station.nazwa.toLocaleLowerCase()).includes(String(neddle));
      })
      .slice(0, 10);
  }

  if (stationListMap) {
    return [
      stationListMap[GDANSK_STATION_CODE],
      stationListMap[KRAKOW_STATION_CODE],
      stationListMap[POZNAN_STATION_CODE],
      stationListMap[SZCZECIN_STATION_CODE],
      stationListMap[WARSAW_STATION_CODE],
      stationListMap[WROCLAW_STATION_CODE],
    ].filter((x) => !!x);
  }

  return [];
};
