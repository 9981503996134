import React, {FC, useState, useContext, useMemo, MouseEventHandler} from 'react';
import {useNavigate} from 'react-router-dom';
import {IDownloadTicketResponse, IDownloadTicketResponseSingleTicket, ISingleId} from 'api/sprzedaz/interfaces';

import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {UserTicketsContext} from 'Utils/UserTicketsContext';
import useMyTickets from './useMyTickets';

import ChangePassengerName from './ChangePassengerName/ChangePassengerName';
import MyTicketsRouteModal from './MyTicketsRouteModal/MyTicketsRouteModal';
import ExchangeTicketModal from 'components/pages/PurchasePath/TicketsExchange/ExchangeTicketModal/ExchangeTicketModal';
import createProfileLinkWithParams from 'components/pages/UserProfile/components/BuyerProfile/helpers/createProfileLinkWithParams';
import MyDataModal from '../MyData/MyDataModal';
import InvoiceInProgressModal from 'components/common/Invoice/InvoiceInProgressModal';
import {TicketDetailsInfoModal} from './TicketDetailsInfoModal';
import {profileMenuElements} from '../../userProfileMenu';

import {handleInvoiceDownload} from 'components/common/Invoice/invoiceHelpers';

import ArrowRightWhite from 'assets/whiteArrowRightNarrow.svg';
import ArrowRightContrast from 'assets/contrastArrowRightNarrow.svg';
import ArrowRight from 'assets/orangeArrowRightNarrow.svg';
import ContrastX from 'assets/contrastX.svg';
import WhiteX from 'assets/whiteX.svg';
import style from './MyTickets.module.scss';
import {
  findEVACode,
  getFullTimeFromDate,
  getFullDate,
  makeTimeOfTravel,
  downloadPdf,
  returnCarrierLogo,
  returnStationByCode,
  isInvoiceIssuable,
  checkIfGuest,
  returnDiscountName,
  isSeasonalTicketReturnable,
} from 'Utils/commonFunctions';
import {ReservationContext} from 'Utils/ReservationContext';
import {useTranslation} from 'react-i18next';
import {checkIfSeasonalTicketIsActive, isReservationFromSeasonalTicket} from '../../common/utils';
import {isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';
import {formatDateToEuropean} from 'components/pages/SeasonalTicket/common/utils';
import {SEASONAL_CODE_OFFERS} from 'common/consts';

import RemoveTicketModal from './RemoveTicketModal/RemoveTicketModal';
import {AddedManuallyText} from './MyTicketsSingleTicket.style';
import {ReservationListModal} from './ReservationListModal';
import {SeasonalTicketInformationModal} from './SeasonalTicketInformationModal';
import {TicketComponentVariant} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';
import {isTicketManuallyAdded, isTicketSourceEIC1} from 'common/utils';

interface Props {
  tickets: IDownloadTicketResponseSingleTicket[];
  mainTicketIdentifier: ISingleId;
  ticketsToReturn: IDownloadTicketResponseSingleTicket[];
  history: number;
  query: string;
  queryAgain: string;
  details: IDownloadTicketResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRef: any;
  isReturned?: boolean;
  isSeazonal?: boolean;
  variant?: TicketComponentVariant;
}

interface ButtonData extends ButtonProps {
  renderCondition: boolean | undefined;
}

interface ButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  text: string;
  className?: string;
  disabled?: boolean;
}

const Button = ({onClick, className = `${style.outline_button}`, text, disabled}: ButtonProps) => (
  <button className={className} onClick={onClick} disabled={disabled}>
    {text}
  </button>
);
const MyTicketsSingleTicketInnerData: FC<Props> = ({
  tickets,
  mainTicketIdentifier,
  ticketsToReturn,
  history,
  query,
  queryAgain,
  details,
  containerRef,
  isSeazonal,
  isReturned,
  variant,
}) => {
  const {t} = useTranslation();
  const {stationsList, shopDiscounts} = useData();
  const {isContrastTheme, theme} = useStyledThemeState();
  const {checkIfTrainStarts} = useMyTickets();
  const {setReturnedTicketData} = useContext(UserTicketsContext);
  const {setDownloadedTicketData, setReminderModalSearchData} = useContext(ReservationContext);
  const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);
  const [showReservationListModal, setShowReservationListModal] = useState(false);
  const [showSeasonalTicketInformationModal, setShowSeasonalTicketInformationModal] = useState(false);
  const {seasonalOffers} = useData();

  const navigate = useNavigate();

  const [primaryTickets, setPrimaryTickets] = useState(tickets);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openExchangeTicket, setOpenExchangeTicket] = useState<boolean>(false);
  const [openChangePassName, setOpenChangePassName] = useState<boolean>(false);
  const [showChangedName, setShowChangedName] = useState<boolean>(false);
  const [isMyDataModalVisible, setIsMyDataModalVisible] = useState<boolean>(true);
  const [isInvoiceInProgress, setIsInvoiceInProgress] = useState<boolean>(false);
  const [invoiceError, setInvoiceError] = useState<string>('');
  const [showRemoveTicketModal, setShowRemoveTicketModal] = useState<boolean>(false);
  const isInternationalConnection = useMemo(
    () =>
      tickets.some((ticket: IDownloadTicketResponseSingleTicket): boolean =>
        ticket.odcinki.some(({stacjaDoKod, stacjaOdKod}) => {
          const fromCode = `${stacjaOdKod}`;
          const destinationCode = `${stacjaDoKod}`;
          const isEVACodes = destinationCode.length === 7 && fromCode.length === 7;
          const isInternational = fromCode.slice(0, 2) !== '51' || destinationCode.slice(0, 2) !== '51';

          return isEVACodes && isInternational;
        }),
      ),
    [tickets],
  );
  const {invalidateMyTicket} = useInvalidateMyTicketsQueries();

  const downloadReservationClickHandler = (clickFromSeasonalReminderModal?: boolean) => {
    !clickFromSeasonalReminderModal && setReminderModalSearchData(null);
    navigate('/rezerwacje');
    if (primaryTickets.length === 1) {
      setDownloadedTicketData(primaryTickets[0]);
    }
  };

  const goToOldVersion = () => {
    window.location.href = 'https://bilet.intercity.pl/bezpieczne_logowanie.jsp?typ=1';
  };

  const trainTypeFilter = (ticket: IDownloadTicketResponseSingleTicket) => {
    if (ticket && ticket.biletRodzaj && (ticket.biletRodzaj === 24 || isPrimaryTicketKind(ticket.biletRodzaj)))
      return ticket;
  };

  const handleScrollToContainer = () => {
    const yRef = containerRef?.current?.getBoundingClientRect()?.top;
    window.scrollTo({
      top: yRef ? yRef - document.body.getBoundingClientRect().top - 25 : 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const hasInvoice: boolean = !!primaryTickets[0]?.fakturaNr?.length && !!primaryTickets[0]?.fakturaSeria?.length;
  const [isRouteModalVisible, setIsRouteModalVisible] = useState<boolean>(false);

  const routeData = {
    dataWyjazdu: details?.bilety[0]?.odcinki[0].wyjazdData.replace(' ', 'T'),
    stacjaWyjazdu: findEVACode(Number(details?.bilety[0]?.odcinki[0]?.stacjaOdKod), stationsList),
    stacjaPrzyjazdu: findEVACode(Number(details?.bilety[0]?.odcinki[0]?.stacjaDoKod), stationsList),
    numerPociagu: Number(details?.bilety[0]?.odcinki[0]?.pociagNr),
  };

  const isReservationListModalVariant = variant === 'reservationListModal';
  const isSeasonalTicketInformationModalVariant = variant === 'seasonalTicketInformationModal';
  const isSeasonalTicketReminderModalVariant = variant === 'seasonalTicketReminderModal';
  const ticketSerialNumber = primaryTickets[0]?.biletSeria;
  const ticketNumber = primaryTickets[0]?.biletNr;
  const isManuallyAdded = isTicketManuallyAdded(primaryTickets[0]?.biletZrodloKod, ticketSerialNumber);

  const {
    ofertaKod,
    biletNr,
    biletSeria,
    biletPodstawowySeria,
    biletPodstawowyNr,
    podrozni,
    podroznyNazwa,
    odcinki,
    waznoscBiletuDo,
    waznoscBiletuOd,
    sprzedazData,
    zwrotOpis,
    biletRodzaj,
    transakcjaNr,
  } = details?.bilety[0] ?? {};
  const {klasa} = odcinki[0] ?? {};
  const {znizkaKod} = podrozni[0] ?? {};
  const isIntercityCard = ofertaKod === SEASONAL_CODE_OFFERS.INTERCITY_CARD;

  const isSeasonalTicketActive = useMemo(
    () => checkIfSeasonalTicketIsActive(waznoscBiletuOd, waznoscBiletuDo) && !isReturned,
    [waznoscBiletuOd, waznoscBiletuDo, isReturned],
  );
  const numberOfTrains = primaryTickets.length;
  const indexOfLastTrain = numberOfTrains - 1;
  const lastTrain = primaryTickets[indexOfLastTrain];

  const from = primaryTickets[0]?.odcinki[0].stacjaOdKod;
  const to = lastTrain?.odcinki[0].stacjaDoKod;

  const departureDate = primaryTickets[0]?.odcinki[0]?.wyjazdData;
  const arrivalDate =
    primaryTickets[primaryTickets.length - 1]?.odcinki[primaryTickets[primaryTickets?.length - 1]?.odcinki?.length - 1]
      ?.przyjazdData;

  const checkInvoiceIssuable = !isTicketSourceEIC1(ticketSerialNumber) ? (
    isInvoiceIssuable(sprzedazData) && isMyDataModalVisible ? (
      <MyDataModal
        title={t('29248')}
        inputName="issueInvoice"
        ticketDetails={details.bilety}
        customOpenBtn={<button className={style.outline_button}>{t('26045')}</button>}
        handleInvoiceIssue={(error: string) => {
          setIsInvoiceInProgress(true);
          setIsMyDataModalVisible(false);
          setInvoiceError(error);
        }}
      />
    ) : (
      <button className={style.outline_button_disabled} disabled>
        {t('26054')}
      </button>
    )
  ) : null;
  const handleProgressModalClose = () => {
    navigate(profileMenuElements[invoiceError.length > 0 ? 1 : 4].url);
    window.scrollTo(0, 0);
    invalidateMyTicket(transakcjaNr, biletSeria, biletNr);
  };

  const buttonsData: ButtonData[] = [
    {
      renderCondition: isSeasonalTicketReminderModalVariant,
      onClick: () => downloadReservationClickHandler(isSeasonalTicketReminderModalVariant),
      text: t('29511'), // Użyj tego biletu
    },
    {
      renderCondition: isIntercityCard && !isSeasonalTicketReminderModalVariant,
      onClick: () => setShowTicketDetailsModal(true),
      text: t('29352'), // Szczegóły
    },
    {
      renderCondition: !isIntercityCard && !isManuallyAdded && !isSeasonalTicketReminderModalVariant,
      onClick: () => downloadPdf(ticketSerialNumber, ticketNumber),
      text: t('29025'), // Pobierz PDF
    },
    {
      renderCondition:
        !isIntercityCard &&
        !isManuallyAdded &&
        isReservationFromSeasonalTicket(primaryTickets[0]?.biletRodzaj) &&
        !isReservationListModalVariant &&
        !isReturned &&
        !history &&
        !isSeasonalTicketReminderModalVariant,
      onClick: () => setShowSeasonalTicketInformationModal(true),
      text: t('13103'), // Bilet okresowy
    },
    {
      renderCondition: !history && !isReturned && isSeazonal && !isSeasonalTicketReminderModalVariant,
      onClick: () => downloadReservationClickHandler(),
      text: t('29252'), // Pobierz rezerwację
    },
    {
      renderCondition:
        !history &&
        !isReturned &&
        isSeazonal &&
        !isSeasonalTicketInformationModalVariant &&
        !isSeasonalTicketReminderModalVariant,
      onClick: () => setShowReservationListModal(true),
      text: t('29411'), // Lista rezerwacji
    },
    {
      renderCondition: isSeazonal && isManuallyAdded && !isReturned && !isSeasonalTicketReminderModalVariant,
      onClick: () => setShowRemoveTicketModal(true),
      text: t('29299'), // Usuń bilet
    },
    {
      renderCondition: !checkIfGuest() && !isSeazonal && !isSeasonalTicketReminderModalVariant,
      onClick: () => {
        const link = createProfileLinkWithParams({
          stationFrom: from,
          stationTo: to,
        });

        navigate(`/profil/profilezakupowe?${link}`);
      },
      text: t('29024'), // Stwórz profil zakupowy
    },
  ];

  const otherFunctionsButtonsData: ButtonData[] = [
    {
      renderCondition: history === 0 && !isSeazonal,
      onClick: (e) => {
        e.preventDefault();
        if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
        else navigate(query);
      },
      text: t('13094'), // Bilet powrotny
    },
    {
      renderCondition: history === 0 && !checkIfTrainStarts(odcinki[0].wyjazdData),
      className: `${style.outline_button} ${isSeazonal && isSeasonalTicketReturnable(waznoscBiletuOd, ofertaKod, seasonalOffers) ? style.outline_button_disabled : ''}`,
      onClick: () => {
        if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
        else {
          setReturnedTicketData({
            ticket: {bilety: ticketsToReturn, bledy: []},
            ticketSr: ticketSerialNumber,
            seazonalTicket: isSeazonal,
            mainTicketIdentifier: mainTicketIdentifier,
          });
        }
      },
      disabled: isSeasonalTicketReturnable(waznoscBiletuOd, ofertaKod, seasonalOffers),
      text: t('29027'), // Zwróć
    },
    {
      renderCondition: history === 0 && !checkIfTrainStarts(odcinki[0].wyjazdData) && !isSeazonal,
      onClick: () => {
        if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
        else setOpenExchangeTicket(true);
      },
      text: t('29028'), // Wymiana
    },
    {
      renderCondition: history === 0 && !checkIfTrainStarts(odcinki[0].wyjazdData),
      onClick: () => {
        if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
        else setOpenChangePassName(true);
      },
      text: t('29029'), // Zmień dane
    },
    {
      renderCondition: !!zwrotOpis.length,
      onClick: (e) => {
        e.preventDefault();
        if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
        else navigate(queryAgain);
      },
      text: t('29244'), // Wyszukaj ponownie
    },
  ];
  return (
    <>
      {/* Modal section */}
      {showRemoveTicketModal && (
        <RemoveTicketModal
          ticketType={t(isIntercityCard ? '13083' : '13103')}
          ticket={details.bilety[0]}
          onClose={() => setShowRemoveTicketModal(false)}
        />
      )}
      {showSeasonalTicketInformationModal && (
        <SeasonalTicketInformationModal
          onButtonClick={() => setShowSeasonalTicketInformationModal(false)}
          onXClick={() => setShowSeasonalTicketInformationModal(false)}
          buttonText={t('29002')}
          seasonalTicketSerial={biletPodstawowySeria}
          seasonalTicketNumber={biletPodstawowyNr}
          header={t('13103')}
        />
      )}
      {showReservationListModal && (
        <ReservationListModal
          onButtonClick={() => setShowReservationListModal(false)}
          onXClick={() => setShowReservationListModal(false)}
          buttonText={t('29002')}
          ticketSerial={biletSeria}
          ticketNumber={biletNr}
          header={t('29411')}
        />
      )}
      {showChangedName && (
        <div className={style.singleTicket__passengerNameChangeContainer}>
          <p>
            {t('29245')}
            <button
              onClick={() => {
                downloadPdf(ticketSerialNumber, ticketNumber);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') downloadPdf(ticketSerialNumber, ticketNumber);
              }}
            >
              {t('29246')}
            </button>
            .
          </p>
          <button
            onClick={() => {
              setShowChangedName(false);
            }}
            aria-label={t('11035')}
            className={style.closeButton}
          >
            <img src={isContrastTheme ? ContrastX : WhiteX} alt={t('29002')} />
          </button>
        </div>
      )}
      {isRouteModalVisible && (
        <MyTicketsRouteModal
          routeData={routeData}
          trainCategory={odcinki[0]?.pociagKategoriaNazwa}
          handleCloseModal={() => setIsRouteModalVisible(false)}
        />
      )}
      {isInvoiceInProgress && <InvoiceInProgressModal onClose={handleProgressModalClose} errorMessage={invoiceError} />}
      {openChangePassName && primaryTickets && (
        <ChangePassengerName
          currentName={podroznyNazwa || ''}
          ticket={primaryTickets[0]}
          isSeasonal={isSeazonal}
          closeModal={() => setOpenChangePassName(false)}
          onSuccessChange={(name) => {
            if (podroznyNazwa !== name) {
              setPrimaryTickets((tickets) =>
                tickets.map((el) => {
                  return {...el, podroznyNazwa: name};
                }),
              );
              setShowChangedName(true);
            }
          }}
        />
      )}
      {openExchangeTicket && primaryTickets && (
        <ExchangeTicketModal
          primaryTicket={primaryTickets[0]}
          allTickets={ticketsToReturn}
          closeModal={() => {
            setOpenExchangeTicket(false);
          }}
          exchangeReservation={isReservationFromSeasonalTicket(biletRodzaj)}
        />
      )}
      <TicketDetailsInfoModal
        showModal={showTicketDetailsModal}
        onXClick={() => setShowTicketDetailsModal(false)}
        onButtonClick={() => setShowTicketDetailsModal(false)}
        buttonText={t('29002')}
        header={t('13083')}
        details={[
          ...(biletSeria && biletNr ? [{title: t('29356'), text: `${biletSeria}${biletNr}`}] : []),
          ...(podroznyNazwa === '' || podroznyNazwa
            ? [{title: t('29124'), text: podroznyNazwa === '' ? t('29382') : podroznyNazwa}]
            : []),
          ...(waznoscBiletuOd && waznoscBiletuDo
            ? [
                {
                  title: t('29357'),
                  text: `${formatDateToEuropean(waznoscBiletuOd)} - ${formatDateToEuropean(waznoscBiletuDo)}`,
                },
              ]
            : []),
          ...(klasa ? [{title: t('22012'), text: `${klasa}`}] : []),
          ...(znizkaKod ? [{title: t('29358'), text: returnDiscountName(znizkaKod * 10 + 1000, shopDiscounts)}] : []),
        ]}
      />
      <div className={style.ticket_main_data_wrapper}>
        <div className={style.ticket_main_data_left_column}>
          <div className={style.ticket_main_data_left_column_content}>
            <div className={`${style.ticket_row} ${isSeazonal ? style.ticket_row__seasonal : ''}`}>
              <div className={isSeazonal ? style.left_column_return_sixty : style.left_column_little}>
                <p className={style.blue_text}>{isSeazonal ? t('29398') : t('11035')}</p>
                <p className={style.navy_text_big}>
                  {isSeazonal ? getFullDate(details.bilety[0]?.waznoscBiletuOd) : getFullDate(departureDate)}
                  {isSeazonal && ` - ${getFullDate(details.bilety[0]?.waznoscBiletuDo)}`}
                </p>
                {getFullDate(departureDate) !==
                  getFullDate(
                    primaryTickets[primaryTickets.length - 1]?.odcinki[primaryTickets[0]?.odcinki?.length - 1]
                      ?.przyjazdData,
                  ) && <p className={style.navy_text_big}>/{getFullDate(arrivalDate)}</p>}
              </div>
              {!isSeazonal && (
                <div className={style.left_column_little}>
                  <p className={style.blue_text}>{`${t('17027')} ${makeTimeOfTravel(
                    departureDate,
                    primaryTickets[primaryTickets.length - 1]?.odcinki[
                      primaryTickets[primaryTickets.length - 1]?.odcinki.length - 1
                    ]?.przyjazdData,
                  )}`}</p>
                  {departureDate && arrivalDate ? (
                    <>
                      <div className={style.time_box}>
                        <p className={style.navy_text_big}>{getFullTimeFromDate(departureDate)}</p>
                        <img src={isContrastTheme ? ArrowRightWhite : ArrowRight} alt={t('17003')} />
                        <p className={style.navy_text_big}>{getFullTimeFromDate(arrivalDate)}</p>
                      </div>
                    </>
                  ) : (
                    <p className={style.navy_text_big}>-</p>
                  )}
                </div>
              )}
              {primaryTickets && !isSeazonal && (
                <div className={style.left_column_little}>
                  <p className={style.blue_text}>
                    {t('17021')}{' '}
                    {trainTypeFilter(primaryTickets[0]) ? trainTypeFilter(primaryTickets[0])?.odcinki[0]?.klasa : '-'}
                  </p>
                  <p className={style.navy_text_big}>
                    {`${
                      !!primaryTickets.length &&
                      primaryTickets
                        .map((el) => {
                          return (el.biletRodzaj === 31 ? el?.cenaCalkowita : el?.cenaRezerwacja + el?.cenaBilet) / 100;
                        })
                        .reduce((a, b) => a + b)
                        .toFixed(2)
                        .replace('.', ',')
                    } ${t('24086')}`}
                  </p>
                </div>
              )}

              {!isReturned && isSeazonal ? (
                <>
                  <p className={isSeasonalTicketActive ? style.ticket_sezonal_active : style.ticket_sezonal_inactive}>
                    {isSeasonalTicketActive ? t('29250') : t('29251')}
                  </p>
                  {isManuallyAdded && (
                    <AddedManuallyText variant="P4" height={2.4} style={{whiteSpace: 'nowrap'}}>
                      {t('13134')}
                    </AddedManuallyText>
                  )}
                </>
              ) : null}
            </div>
            {!!primaryTickets[0]?.odcinki[0]?.stacjaOdKod && (
              <>
                <div className={`${style.ticket_row} ${style.blue_text}`}>{t('17028')}</div>
                <div className={`${style.ticket_row} ${style.station_list_box}`}>
                  {!!primaryTickets.length &&
                    primaryTickets.map(({odcinki, biletNr}, ticketIndex) =>
                      odcinki.map((item, index) => (
                        <React.Fragment key={`${biletNr}-${item.pociagNr}`}>
                          {index > 0 && <img src={isContrastTheme ? ArrowRightWhite : ArrowRight} alt={t('17003')} />}

                          {item?.stacjaOdKod !== odcinki[index - 1]?.stacjaDoKod &&
                          item?.stacjaOdKod !== primaryTickets[ticketIndex - 1]?.odcinki[index]?.stacjaDoKod ? (
                            <>
                              <span className={`${style.regular_text} ${style.bold_text}`}>
                                {returnStationByCode(item?.stacjaOdKod, stationsList)}
                              </span>
                              <img src={isContrastTheme ? ArrowRightWhite : ArrowRight} alt={t('17003')} />
                            </>
                          ) : null}
                          <span className={`${style.regular_text} ${style.bold_text}`}>
                            {returnStationByCode(item?.stacjaDoKod, stationsList)}
                          </span>
                          {primaryTickets.length > 1 && ticketIndex !== primaryTickets.length - 1 && (
                            <img src={isContrastTheme ? ArrowRightWhite : ArrowRight} alt="Strzałka w prawo" />
                          )}
                        </React.Fragment>
                      )),
                    )}
                </div>
              </>
            )}
            {!!primaryTickets[0]?.odcinki[0]?.pociagNr && (
              <div className={`${style.ticket_row} ${style.carrier_list_box}`}>
                {!!primaryTickets.length &&
                  primaryTickets.map((el) => {
                    return el?.odcinki.map((el) => {
                      return (
                        <div key={el?.pociagNr}>
                          {returnCarrierLogo(el.pociagKategoriaNazwa, theme)}
                          <span className={style.regular_text}>{el?.pociagNr}</span>
                        </div>
                      );
                    });
                  })}
              </div>
            )}
          </div>
        </div>
        <div className={style.ticket_main_data_right_column}>
          {/* Buttons section */}

          {buttonsData
            .filter((button) => button.renderCondition)
            .map(({onClick, text, className, disabled}, index) => (
              <Button key={index} onClick={onClick} text={text} className={className} disabled={disabled} />
            ))}

          {!isInternationalConnection && !isManuallyAdded && !isSeasonalTicketReminderModalVariant && (
            <>
              <button
                className={` ${style.expand_button}  ${openDrawer && style.expand_button__reversed}`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDrawer(!openDrawer);
                  !openDrawer && handleScrollToContainer();
                }}
              >
                <p className={style.underline_text}>{t('29026')}</p>
                <img src={isContrastTheme ? ArrowRightContrast : ArrowRight} alt={t('17003')} />
              </button>
              <div
                className={`
                                    ${style.button_drawer} 
                                    ${openDrawer ? style.button_drawer_open : ''}
                                `}
              >
                {otherFunctionsButtonsData
                  .filter((button) => button.renderCondition)
                  .map(({onClick, text, className, disabled}, index) => (
                    <Button key={index} onClick={onClick} text={text} className={className} disabled={disabled} />
                  ))}

                {hasInvoice ? ( // TODO refactor this button
                  <button
                    className={style.outline_button}
                    onClick={() => {
                      if (isTicketSourceEIC1(ticketSerialNumber)) goToOldVersion();
                      else
                        primaryTickets.forEach((singleTicket) =>
                          handleInvoiceDownload(singleTicket.fakturaSeria, singleTicket.fakturaNr),
                        );
                    }}
                  >
                    {t('29253')}
                  </button>
                ) : (
                  checkInvoiceIssuable
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyTicketsSingleTicketInnerData;
