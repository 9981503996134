/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {FC, ChangeEvent, useState, useEffect} from 'react';

import style from './MyData.module.scss';
import orangeX from 'assets/orangeX.svg';
import contrastX from 'assets/contrastX.svg';
import ConfirmButton from 'components/common/ConfirmButton';
import MovableLabelInput from 'components/common/MovableLabelInput';
import PasswordValidation from 'components/pages/Register/PasswordValidation';
import Invoice from 'components/common/Invoice';
import {IPaymentInvoiceData} from 'interfaces/forms';
import {getInvoiceFormFromUserData, invoiceFormInitialData} from 'Utils/forms';
import {useAuth} from 'Utils/AuthContext';
import {
  generateUpdateAddressRequest,
  hasInvoiceAllRequiredValues,
  hasInvoiceValidationErrors,
} from 'components/common/Invoice/invoiceHelpers';
import {IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import {generateInvoiceRequest} from '../../../PurchasePath/PaymentForm/paymentHelpers';
import {IGenerateInvoiceElement} from 'api/faktury/interfaces';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

interface Props {
  title: string;
  inputName?: string;
  inputType?: string;
  inputCallback?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputLabelText?: string;
  inputValue?: string;
  onSuccessfulUpdate?: () => void;
  customOpenBtn?: React.ReactNode;
  ticketDetails?: IDownloadTicketResponseSingleTicket[] | null;
  handleInvoiceIssue?: (error: string) => void;
}

const MyDataModal: FC<Props> = ({
  title,
  inputName,
  inputType,
  inputCallback,
  inputLabelText,
  inputValue,
  onSuccessfulUpdate,
  customOpenBtn,
  ticketDetails,
  handleInvoiceIssue,
}) => {
  const {t, i18n} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [repeatEmail, setRepeatEmail] = useState<string>('');
  const [invoiceFormData, setInvoiceFormData] = useState<IPaymentInvoiceData>({
    ...invoiceFormInitialData,
    invoice: true,
  });
  const invoiceValidationErrors = hasInvoiceValidationErrors(invoiceFormData);
  const {userData} = useAuth();

  useEffect(() => {
    if (userData && userData.address && toggleModal) {
      setInvoiceFormData(getInvoiceFormFromUserData(userData.address));
    }
  }, [userData, toggleModal]);

  const handleUserAddressUpdate = async () => {
    await generateUpdateAddressRequest(invoiceFormData)
      .then(() => onSuccessfulUpdate && onSuccessfulUpdate())
      .then(() => setToggleModal(false))
      .catch(async (error) => {
        if (error.response.data.message === 'Address not found') {
          await generateUpdateAddressRequest(invoiceFormData, true)
            .then(() => onSuccessfulUpdate && onSuccessfulUpdate())
            .then(() => setToggleModal(false));
        }
      });
  };

  const handleIssueInvoiceFromPanel = async () => {
    if (ticketDetails) {
      const tickets: IGenerateInvoiceElement[] = [];
      ticketDetails?.map((ticket) =>
        tickets.push({
          seria: ticket.biletSeria,
          nr: ticket.biletNr,
        }),
      );
      generateInvoiceRequest(invoiceFormData, tickets)
        .then((response) => {
          const errors = response.map((singleTicket) => singleTicket?.bledy[0]);

          const localizedErrorDescription = errors[0]?.opisy.find(
            (el) => el.jezyk === i18n.language.toLocaleUpperCase(),
          )?.komunikat;

          handleInvoiceIssue && handleInvoiceIssue(localizedErrorDescription ? localizedErrorDescription : '');
        })
        .catch(async (error) => {
          console.error(error);
          setToggleModal(false);
        });
    }
  };

  return (
    <>
      {customOpenBtn ? (
        <div onClick={() => setToggleModal(true)}>{customOpenBtn}</div>
      ) : (
        <button className={`${style.change_button} ${style.mydata_orangetext}`} onClick={() => setToggleModal(true)}>
          {t('13004')}
        </button>
      )}
      {toggleModal && (
        <div className={style.modal_container}>
          <div className={style.action_box}>
            <div>
              <header className={style.modal_header}>
                <h2>{title}</h2>
                <img
                  src={isContrastTheme ? contrastX : orangeX}
                  tabIndex={0}
                  alt={t('11035')}
                  onClick={() => setToggleModal(false)}
                />
              </header>
              <div className={style.input_box}>
                {inputName === 'email' && (
                  <>
                    <MovableLabelInput
                      t={t}
                      name={inputName}
                      type={inputType || ''}
                      callback={inputCallback}
                      labelText={inputLabelText || ''}
                      value={inputValue || ''}
                    />
                    <MovableLabelInput
                      t={t}
                      name="email-repeat"
                      type="email"
                      callback={(e) => setRepeatEmail(e.target.value)}
                      labelText="29479"
                      value={repeatEmail}
                    />
                    <MovableLabelInput
                      t={t}
                      name="password"
                      type="password"
                      callback={(e) => setConfirmPassword(e.target.value)}
                      labelText="29198"
                      value={confirmPassword}
                    />
                  </>
                )}
                {inputName === 'name' && (
                  <MovableLabelInput
                    t={t}
                    name={inputName}
                    type={inputType || ''}
                    callback={inputCallback}
                    labelText={inputLabelText || ''}
                    value={inputValue || ''}
                  />
                )}
                {inputName === 'last-name' && (
                  <MovableLabelInput
                    t={t}
                    name={inputName}
                    type={inputType || ''}
                    callback={inputCallback}
                    labelText={inputLabelText || ''}
                    value={inputValue || ''}
                  />
                )}
                {inputName === 'password' && (
                  <>
                    <MovableLabelInput
                      t={t}
                      name="Obecne hasło"
                      type="password"
                      callback={(e) => setConfirmPassword(e.target.value)}
                      labelText="29478"
                      value={confirmPassword}
                    />
                    <MovableLabelInput
                      t={t}
                      name={inputName}
                      type={inputType || ''}
                      callback={inputCallback}
                      labelText={inputLabelText || ''}
                      value={inputValue || ''}
                    />
                    <PasswordValidation password={inputValue || ''} />
                    <MovableLabelInput
                      t={t}
                      name="confirm-new-password"
                      type="password"
                      callback={(e) => setConfirmNewPassword(e.target.value)}
                      labelText="29477"
                      value={confirmNewPassword}
                    />
                  </>
                )}
                {(inputName === 'issueInvoice' || inputName === 'updateInvoice') && (
                  <Invoice isUserPanel values={invoiceFormData} onInputChange={setInvoiceFormData} />
                )}
                {inputName !== 'issueInvoice' && inputName !== 'updateInvoice' && (
                  <p className={style.red_paragraph}>{t('26018')}</p>
                )}
              </div>
            </div>
            {inputName === 'updateInvoice' && (
              <ConfirmButton
                text={t('13004')}
                disabled={!hasInvoiceAllRequiredValues(invoiceFormData) || invoiceValidationErrors}
                execute={handleUserAddressUpdate}
              />
            )}
            {inputName === 'issueInvoice' && (
              <ConfirmButton
                text={t('29476')}
                disabled={!hasInvoiceAllRequiredValues(invoiceFormData) || invoiceValidationErrors}
                execute={handleIssueInvoiceFromPanel}
              />
            )}
            {inputName !== 'issueInvoice' && inputName !== 'updateInvoice' && (
              <ConfirmButton text={t('13004')} disabled={true} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MyDataModal;
