import {styled} from '@mui/material/styles';
import {Text} from 'common/components/base/Text';
const InfoBanner = styled('div')`
  background: ${({theme}) => theme.color.background.info};
  display: flex;
  padding: 1.6rem;
  border-radius: 0.4rem;
  width: 100%;
`;
export const SeasonalTicketInfoBanner = styled(InfoBanner)`
  padding: 1.6rem;
  margin-bottom: 0.8rem;
`;
export const SeasonalTicketMobileInfoBanner = styled(InfoBanner)`
  padding: 0.8rem 1rem;
`;
export const AddedManuallyText = styled(Text)`
  white-space: nowrap;
`;
export const SeasonalTicketDataText = styled(Text)`
  font-weight: 600;
  padding: 4px 0;
`;
export const FlexRow = styled('div')`
  gap: 8px;
  display: flex;
`;
