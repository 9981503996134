import {StyledTheme} from './types';

export const text: StyledTheme['text'] = {
  font: 'Montserrat, sans-serif',
  size: {
    10: '1rem',
    12: '1.2rem',
    14: '1.4rem',
    16: '1.6rem',
    18: '1.8rem',
    20: '2rem',
    22: '2.2rem',
    32: '3.2rem',
  },
  weight: {
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
  },
};
