/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmButton from 'components/common/ConfirmButton';
import Loader from '../Loader/Loader';
import {useStyledThemeState} from 'common/theme';

import S from './Invoice.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';

interface Props {
  onClose: () => void;
  errorMessage: string;
}
const InvoiceInProgressModal: FC<Props> = ({onClose, errorMessage}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <div className={S.modalBackground}>
      <div className={S.modalContainer}>
        <div className={S.topRow}>
          <h1 className={S.header}>{t('26049')} </h1>
          <button className={S.closeButton} onClick={onClose} aria-label={t('26050')}>
            <img tabIndex={0} src={isContrastTheme ? ContrastX : OrangeX} alt="X" />
          </button>
        </div>
        <div className={S.loaderWrapper}>
          <Loader />
        </div>
        <div className={S.content}>
          {errorMessage ? (
            <p className={`${S.text} ${S.error}`}>{errorMessage}</p>
          ) : (
            <>
              <p className={S.text}>{t('26051')}</p>
              <p className={S.text}>{t('26052')}</p>
            </>
          )}
          <ConfirmButton text={t('29002')} execute={onClose} />
        </div>
      </div>
    </div>
  );
};
export default InvoiceInProgressModal;
