/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import usePayLaterTicket from './usePayLaterTicket';
import usePaymentMethods from 'Utils/usePaymentMethods';
import {usePayment} from 'Utils/PaymentContext';
import {useStyledThemeState} from 'common/theme';
import {isSeasonalCodeOffer} from 'common/utils';
import {makeTimeOfTravel} from 'Utils/commonFunctions';

import PaymentRedirectModal from 'components/pages/PurchasePath/PaymentRedirectModal/PaymentRedirectModal';
import PaymentDeadlineTimer from 'components/pages/PurchasePath/PaymentSummaryView/PaymentDeadlineTimer/PaymentDeadlineTimer';
import MyTicketsSingleTicketOpenMobileTripPart from './MyTicketsSingleTicketOpenMobile/MyTicketsSingleTicketOpenMobileTripPart';
import PaymentErrorModal from 'components/pages/PurchasePath/PaymentErrorModal/PaymentErrorModal';

import {ReactComponent as OrangeX} from 'assets/orangeX.svg';
import {ReactComponent as ContrastX} from 'assets/contrastX.svg';
import Clock from 'assets/clock.svg';
import ClockContrast from 'assets/clockContrast.svg';

import S from './MyTicketsMobilet.module.scss';

interface IProps {
  closeFn: () => void;
  name: string;
}

const MyTicketsSingleTicketMobileUnpaidModal: FC<IProps> = ({closeFn, name}) => {
  const navigate = useNavigate();
  const {isContrastTheme} = useStyledThemeState();
  const {t} = useTranslation();
  const numberWordsArray = ['Pierwszy', 'Drugi', 'Trzeci', 'Czwarty', 'Piąty', 'Szósty', 'Siódmy'];

  const {registerPayment, setBlik, blik, specialModal, setSpecialModal, modalText} = usePaymentMethods(() => {});

  const {ticketFrom} = usePayment();

  const {payByBlik, setPayByBlik, isPaymentModalOpen, setIsPaymentModalOpen} = usePayLaterTicket();

  const isSeasonalOffer = isSeasonalCodeOffer(ticketFrom?.transakcjeNr[0]?.oferta);

  useEffect(() => {
    if (specialModal) {
      setIsPaymentModalOpen(false);
    }
  }, [specialModal]);

  return (
    <div className={S.MobileUnpaidModal__container}>
      <div className={S.MobileUnpaidModal__wrapper}>
        <div className={S.singleTicket__payLaterContainer} onClick={closeFn}>
          <p className={S.MobileUnpaidModal__modalHeader}>Twój bilet</p>
          {isContrastTheme ? <ContrastX /> : <OrangeX />}
        </div>

        <div className={S.singleTicket__payLaterContainer}>
          <p className={S.singleTicket__payLaterInfo}>Oczekujący na płatność</p>
          <div className={S.timerContainer}>
            <PaymentDeadlineTimer
              isPayLater
              onPaymentDeadline={() => {
                window.localStorage.removeItem('payLaterTicket');
                navigate('/profil');
              }}
            />
          </div>
        </div>

        {!isSeasonalOffer &&
          ticketFrom?.odcinki.map((el, index, arr) => {
            return (
              <div
                key={`${el.pociagNr}-${el.pociagNazwa}`}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {arr.length > 1 && <p className={S.text_big}>{numberWordsArray[index]} odcinek</p>}
                <MyTicketsSingleTicketOpenMobileTripPart
                  ticket={el}
                  generateTicketDetails={ticketFrom}
                  index={index}
                  onCheckRouteClick={() => {}}
                />
                {index !== arr.length - 1 && (
                  <div className={S.addTicketContainer}>
                    <img
                      src={isContrastTheme ? ClockContrast : Clock}
                      alt={t('17051')}
                      style={{
                        marginRight: '10px',
                      }}
                    />
                    <p className={S.addticketText}>
                      {makeTimeOfTravel(el.przyjazdData, arr[index + 1].wyjazdData)} {t('17052')}
                    </p>
                  </div>
                )}
              </div>
            );
          })}

        {isSeasonalOffer && (
          <p className={S.MobileUnpaidModal__text_blue}>{ticketFrom?.transakcjeNr[0]?.nazwaOferty}</p>
        )}

        <div className={`${S.MobileUnpaidModal__box}`}>
          <div className={`${S.MobileUnpaidModal__box_textbox}`}>
            <div>
              <p className={S.MobileUnpaidModal__text_blue}>Podróżny</p>
              <p className={S.MobileUnpaidModal__text_medium}>{name}</p>
            </div>
          </div>
        </div>

        <div className={`${S.MobileUnpaidModal__box}`}>
          <button
            className={`col-sm-12 col-12 ${S.outline_button}  ${S.outline_buttonLater}`}
            onClick={(e) => {
              e.preventDefault();
              setPayByBlik(!payByBlik);
            }}
          >
            BLIK
          </button>
          {payByBlik && (
            <div className={S.inputContainer}>
              <input
                className={S.blikInpu}
                type="number"
                inputMode="numeric"
                id="blickCode"
                name="blickCode"
                placeholder="Kod blik"
                value={blik}
                onChange={(e) => {
                  const blikNumber = e.target.value;
                  blikNumber.length <= 6 && setBlik(blikNumber.replace(/\D/g, ''));
                }}
              />
              <button
                className={`col-sm-12 col-12 ${S.outline_button} ${S.outline_buttonLater}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (blik.length > 5) {
                    registerPayment('P24Blik', name);
                    setIsPaymentModalOpen(true);
                  }
                }}
              >
                ZAPŁAĆ
              </button>
            </div>
          )}
          <button
            className={`col-sm-12 col-12 ${S.outline_button}  ${S.outline_buttonLater}`}
            onClick={(e) => {
              e.preventDefault();
              registerPayment('P24', name);
              setIsPaymentModalOpen(true);
              setPayByBlik(false);
            }}
          >
            PŁATNOŚĆ KARTĄ
          </button>
          <button
            className={`col-sm-12 col-12 ${S.outline_button}  ${S.outline_buttonLater}`}
            onClick={(e) => {
              e.preventDefault();
              registerPayment('P24Przelew', name);
              setIsPaymentModalOpen(true);
              setPayByBlik(false);
            }}
          >
            PŁATNOŚĆ PRZELEWEM
          </button>
          <button
            className={`col-sm-12 col-12 ${S.outline_button}  ${S.outline_buttonLater}`}
            onClick={(e) => {
              e.preventDefault();
              window.localStorage.removeItem('payLaterTicket');
              navigate('/profil');
            }}
          >
            ANULUJ TRANSAKCJĘ
          </button>
        </div>

        {isPaymentModalOpen && (
          <PaymentRedirectModal
            loaderMessage={payByBlik ? '24102' : '24003'}
            onClose={() => setIsPaymentModalOpen(false)}
          />
        )}
        {specialModal && (
          <PaymentErrorModal htmlMessage={modalText} isPayLaterTicket closeFn={setSpecialModal} isMobile />
        )}
      </div>
    </div>
  );
};

export default MyTicketsSingleTicketMobileUnpaidModal;
