import {useTranslation} from 'react-i18next';

import SearchHeader from 'components/pages/PurchasePath/PurchasePathMobile/components/SearchHeader';
import Icon from 'common/components/base/Icon';

import S from './UniversalHeader.module.scss';

type HeaderVariant = 'exit' | 'goBack';
interface UniversalHeaderDataProps {
  title: string;
  isMainHeader?: boolean;
  goBack: () => void;
  variant?: HeaderVariant;
}
const UniversalHeader = ({title, isMainHeader, goBack, variant = 'exit'}: UniversalHeaderDataProps) => {
  const {t} = useTranslation();
  return (
    <div className={S.headerWrapper}>
      {variant === 'goBack' ? (
        <button
          type="button"
          aria-label={t('11036')}
          className={S.actionBtn}
          onClick={(e) => {
            e.stopPropagation();
            goBack();
          }}
        >
          <Icon icon="chevronLeft" variant="medium" />
        </button>
      ) : null}
      <div className={`${variant === 'goBack' ? S.goBack : ''}`}>
        <SearchHeader title={title} isMainHeader={isMainHeader} />
      </div>
      {variant === 'exit' ? (
        <button
          type="button"
          aria-label={t('11036')}
          className={S.actionBtn}
          onClick={(e) => {
            e.stopPropagation();
            goBack();
          }}
        >
          <Icon icon="X" />
        </button>
      ) : null}
    </div>
  );
};
export default UniversalHeader;
