/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import MaskedInput from 'react-text-mask';
import {useTranslation} from 'react-i18next';

import ErrorYellow from '../ErrorYellow';
import CountriesSearcher from './CountriesSearcher';
import {invoiceCountriesList, InvoiceCountryOption} from 'Utils/invoiceCountriesList';
import {IPaymentInvoiceData} from 'interfaces/forms';
import {filterCountries} from 'Utils/commonFunctions';

import S from './Invoice.module.scss';
import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';

interface Props {
  values: IPaymentInvoiceData;
  onInputChange: Dispatch<SetStateAction<IPaymentInvoiceData>>;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  validationErrors: {[p: string]: string};
}

const ForeignNipInputs: FC<Props> = ({values, onInputChange, handleInputChange, validationErrors}) => {
  const {t} = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<InvoiceCountryOption>();
  const [filteredCountries, setFilteredCountries] = useState<InvoiceCountryOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const countryList = invoiceCountriesList
    .filter((el) => el.namePL !== 'Polska')
    .sort((a, b) => a.namePL.localeCompare(b.namePL));
  const isCompanyAddressSaved: boolean = !!values.companyCountry.length;

  useEffect(() => {
    if (isCompanyAddressSaved && !!values.companyCountry.length) {
      const country = invoiceCountriesList.find((el) => el.namePL === values.companyCountry);
      country && setSelectedCountry(country);
    }
  }, [values.companyCountry]);

  const handleCountryChange = (country: InvoiceCountryOption) => {
    if (!country.isEU && !!values.companyNip.length) {
      onInputChange((prev) => ({...prev, companyNip: ''}));
    }
    onInputChange((prev) => ({...prev, companyCountry: country.namePL}));
    setSelectedCountry(country);
  };

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    if (value.length > 0) {
      const filtered = filterCountries(value, countryList);
      setFilteredCountries(filtered);
    }
    if (value.length === 0) {
      setFilteredCountries([]);
    }
  };

  return (
    <section className={`grid ${S.foreignNipForm}`}>
      <div className={`col-12 ${S.countriesSection}`}>
        {selectedCountry ? (
          <>
            <div className={S.countrySelectInput} onClick={() => setSelectedCountry(undefined)}>
              <select className={`col-12 ${S.custom_select}`} disabled>
                <option value={selectedCountry.code}>{selectedCountry.namePL}</option>
              </select>
              <img src={ArrowBottom} alt={t('26035')} />
            </div>
            {selectedCountry.isEU ? (
              <div className={S.foreignNipInputWrapper}>
                <div className={`${S.input_box} ${S.countryCodeInput}`}>
                  <input type="text" value={selectedCountry.code} disabled />
                </div>
                <div className={`${S.input_box} ${validationErrors?.companyNip ? S.inputBoxError : ''}`}>
                  <input
                    id="companyNip"
                    type="search"
                    name="companyNip"
                    value={values.companyNip}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                  <label htmlFor="companyNip" className={S.company_invoice_label}>
                    {t('26036')}
                    <span className={S.requiredRedStar}>*</span>
                  </label>
                </div>
              </div>
            ) : (
              <div className={S.noEUCountryAlert}>
                <ErrorYellow displayText={t('26037')} />
              </div>
            )}
          </>
        ) : (
          <CountriesSearcher
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            onSearchChange={onSearchChange}
            countryList={countryList}
            filteredCountries={filteredCountries}
            onFilteredCountriesChange={setFilteredCountries}
            handleCountryChange={handleCountryChange}
          />
        )}
      </div>
      {selectedCountry && (
        <>
          <div className={`col-12 ${S.input_box} ${validationErrors?.companyName ? S.inputBoxError : ''}`}>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={values.companyName}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <label htmlFor="companyName" className={S.company_invoice_label}>
              {t('26038')}
              <span className={S.requiredRedStar}>*</span>
            </label>
          </div>
          <div className={`col-12 ${S.input_box} ${validationErrors?.companyStreet ? S.inputBoxError : ''}`}>
            <input
              type="text"
              id="companyStreet"
              name="companyStreet"
              value={values.companyStreet}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <label htmlFor="companyStreet" className={S.company_invoice_label}>
              {t('26039')}
              <span className={S.requiredRedStar}>*</span>
            </label>
          </div>
          <div
            className={`col-6 col-sm-12 ${S.input_box} ${validationErrors?.companyHouseNumber ? S.inputBoxError : ''}`}
          >
            <input
              type="text"
              id="companyHouseNumber"
              name="companyHouseNumber"
              value={values.companyHouseNumber}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <label htmlFor="companyHouseNumber" className={S.company_invoice_label}>
              {t('26040')}
              <span className={S.requiredRedStar}>*</span>
            </label>
          </div>
          <div className={`col-6 col-sm-12 ${S.input_box}`}>
            <input
              type="text"
              id="companyLocalNumber"
              name="companyLocalNumber"
              value={values.companyLocalNumber}
              onChange={handleInputChange}
            />
            <label htmlFor="companyLocalNumber" className={S.company_invoice_label}>
              {t('26041')}
            </label>
          </div>
          <div className={`col-12 ${S.input_box} ${validationErrors?.companyZipCode ? S.inputBoxError : ''}`}>
            <MaskedInput
              mask={values.companyCountry === 'Polska' ? [/\d/, /\d/, '-', /\d/, /\d/, /\d/] : false}
              guide={false}
              type="text"
              inputMode="numeric"
              id="companyZipCode"
              name="companyZipCode"
              value={values.companyZipCode}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <label htmlFor="companyZipCode" className={S.company_invoice_label}>
              {t('26042')}
              <span className={S.requiredRedStar}>*</span>
            </label>
          </div>
          <div className={`col-12 ${S.input_box} ${validationErrors?.companyCity ? S.inputBoxError : ''}`}>
            <input
              type="text"
              id="companyCity"
              name="companyCity"
              value={values.companyCity}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <label htmlFor="companyCity" className={S.company_invoice_label}>
              {t('26043')}
              <span className={S.requiredRedStar}>*</span>
            </label>
          </div>
          <div className={`col-12 ${S.requiredMsg}`}>*{t('26044')}</div>
        </>
      )}
    </section>
  );
};

export default ForeignNipInputs;
