/* eslint-disable no-extra-boolean-cast */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import S from './ShoppingBasketTimeoutModal.module.scss';

import BigBasketIcon from 'assets/ShoppingBasket/sb_big_basket_icon.svg';
import {useShoppingBasket} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import Timer from 'components/common/Timer/Timer';

interface Props {
  timeOutMode: boolean;
}

const ShoppingBasketTimeoutModal: FC<Props> = ({timeOutMode}) => {
  const {t} = useTranslation();
  const {timeLimit} = useShoppingBasket();

  return (
    <div className={S.modal_main}>
      {!timeOutMode ? (
        <>
          <p>{t('27012')}</p>
          <p>{t('27013')}</p>
        </>
      ) : (
        <p>{t('27014')}</p>
      )}
      <div className={S.counter_container}>
        <p className={S.bolder_text}>{t('29369')}</p>
        <p className={S.counter_icon_container}>
          <img src={BigBasketIcon} alt={t('29370')} />
          <span className={S.counter_text}>{!!timeLimit ? <Timer deadline={timeLimit} /> : '0:00'}</span>
        </p>
      </div>
    </div>
  );
};

export default ShoppingBasketTimeoutModal;
