import {FC, useState, Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmButton from '../ConfirmButton';
import OrangeRadioButton from '../OrangeRadioButton/OrangeRadioButton';
import {IVerifyNipInGusData} from 'api/gus/interfaces';

import S from './Invoice.module.scss';

interface Props {
  handleClose: (close: boolean) => void;
  nipOptions: IVerifyNipInGusData[];
  onSelectedNIpOption: (values: IVerifyNipInGusData) => void;
}

const NipModal: FC<Props> = ({handleClose, nipOptions, onSelectedNIpOption}) => {
  const {t} = useTranslation();
  const [selectedCompanyName, setSelectedCompanyName] = useState('');

  const confirmCompanySelection = () => {
    const selected = nipOptions.find((el) => el.Nazwa === selectedCompanyName);
    if (selected) {
      onSelectedNIpOption(selected);
      handleClose(true);
    }
  };

  return (
    <div className={S.nipModal}>
      <div className={S.nipModalContainer}>
        <div className={S.nipModalTopRow}>
          <p>{t('26045')}</p>
          <button
            className={S.nipModalCloseBtn}
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              handleClose(false);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClose(false);
              }
            }}
            aria-label={t('26046')}
          >
            x
          </button>
        </div>
        <p className={S.nipModalLabel}>{t('26047')}</p>
        <div className={S.nipModalOptions}>
          {nipOptions.map((el, index) => (
            <Fragment key={el.Nazwa}>
              <OrangeRadioButton
                label={el.Nazwa}
                name="invoiceNipOption"
                id={`invoiceNipOption${index}`}
                checked={selectedCompanyName === el.Nazwa}
                value={el.Nazwa}
                onChange={(e) => setSelectedCompanyName(e.target.value)}
              />
            </Fragment>
          ))}
        </div>
        <div className={S.nipModalBtn}>
          <ConfirmButton text={t('26048')} execute={confirmCompanySelection} disabled={!selectedCompanyName.length} />
        </div>
      </div>
    </div>
  );
};

export default NipModal;
