import {styled, Theme, css} from '@mui/material';
import {Styles} from 'common/components/types';
import {TextVariants, TextStyles, TextProps} from './Text.types';
import {get} from 'lodash';

const styles = (theme: Theme): Styles<TextVariants, TextStyles> => ({
  H1: {
    fontSize: theme.text.size[32],
    fontWeight: theme.text.weight[700],
    lineHeight: '4.2rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  H2: {
    fontSize: theme.text.size[22],
    fontWeight: theme.text.weight[700],
    lineHeight: '3.2rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  H3: {
    fontSize: theme.text.size[20],
    fontWeight: theme.text.weight[700],
    lineHeight: '2.4rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  H4: {
    fontSize: theme.text.size[18],
    fontWeight: theme.text.weight[700],
    lineHeight: '2.4rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  H5: {
    fontSize: theme.text.size[16],
    fontWeight: theme.text.weight[700],
    lineHeight: '2.2rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P: {
    fontSize: theme.text.size[12],
    fontWeight: theme.text.weight[700],
    lineHeight: '1.7rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P1: {
    fontSize: theme.text.size[18],
    fontWeight: theme.text.weight[400],
    lineHeight: '2.8rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P2: {
    fontSize: theme.text.size[16],
    fontWeight: theme.text.weight[400],
    lineHeight: '2.4rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P3: {
    fontSize: theme.text.size[14],
    fontWeight: theme.text.weight[400],
    lineHeight: '2rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P4: {
    fontSize: theme.text.size[12],
    fontWeight: theme.text.weight[400],
    lineHeight: '1.5rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  P5: {
    fontSize: theme.text.size[10],
    fontWeight: theme.text.weight[400],
    lineHeight: '1.2rem',
    color: theme.color.text.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  BOLD_WHITE: {
    fontSize: theme.text.size[16],
    fontWeight: theme.text.weight[600],
    lineHeight: '2rem',
    color: theme.color.light.primary,
    textDecoration: 'none',
    textTransform: 'none',
  },
  CAPTION: {
    fontSize: theme.text.size[16],
    fontWeight: theme.text.weight[700],
    lineHeight: '2rem',
    color: theme.color.text.secondary,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  CAPTION_LINK: {
    fontSize: theme.text.size[16],
    fontWeight: theme.text.weight[700],
    lineHeight: '2rem',
    color: theme.color.accent.default,
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
  CAPTION_LINK_SMALL: {
    fontSize: theme.text.size[14],
    fontWeight: theme.text.weight[700],
    lineHeight: '2rem',
    color: theme.color.accent.default,
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
});

export const Text = styled('span')<TextProps>`
  margin: 0;
  padding: 0;

  ${({theme, variant}) =>
    variant &&
    css`
      font-size: ${styles(theme)[variant].fontSize};
      font-weight: ${styles(theme)[variant].fontWeight};
      line-height: ${styles(theme)[variant].lineHeight};
      color: ${styles(theme)[variant].color};
      text-decoration: ${styles(theme)[variant].textDecoration};
      text-transform: ${styles(theme)[variant].textTransform};
    `}

  ${({theme, size}) =>
    size &&
    css`
      font-size: ${theme.text.size[size]};
    `}

  ${({theme, weight}) =>
    weight &&
    css`
      font-weight: ${theme.text.weight[weight]};
    `}

  ${({height}) =>
    height &&
    css`
      line-height: ${height}rem;
    `}

  ${({theme, palette}) =>
    palette &&
    css`
      color: ${get(theme.color, palette, theme.color.text.primary)};
    `}

  ${({decoration}) =>
    decoration &&
    css`
      text-decoration: ${decoration};
    `}

  ${({transform}) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
`;
