import {FullTicket} from 'api/sprzedaz/interfaces';
import {makeQuerySearch} from './querySearch/queryString';

export const makeTicketQuery = (ticket: FullTicket, findAgain?: boolean) => {
  const stacjaWyj =
    ticket.ticketDetail.bilety[ticket.ticketDetail.bilety.length - 1]?.odcinki[
      ticket.ticketDetail.bilety[ticket.ticketDetail.bilety.length - 1]?.odcinki.length - 1
    ]?.stacjaDoKod;
  const stacjaPrzy = ticket.ticketDetail.bilety[0]?.odcinki[0].stacjaOdKod;
  const dateOfEnd =
    ticket.ticketDetail.bilety[ticket.ticketDetail?.bilety.length - 1]?.odcinki[
      ticket.ticketDetail.bilety[ticket.ticketDetail?.bilety.length - 1]?.odcinki.length - 1
    ].przyjazdData;

  const grownUpArr = ticket?.ticketDetail?.bilety[0]?.podrozni
    .filter(
      (x) => x.znizkaKod > 0 && x.znizkaKod !== Number(77) && x.znizkaKod !== Number(71) && x.znizkaKod !== Number(176),
    )
    .map((x) => (x.znizkaKod * 10 + 1000).toString());

  const childArr = ticket?.ticketDetail?.bilety[0]?.podrozni
    .filter((x) => x.znizkaKod === 77 || x.znizkaKod === 71 || x.znizkaKod === 176)
    .map((x) => {
      return (x.znizkaKod * 10 + 1000).toString();
    });

  const makeStringFromArr = (arr: string[]) => {
    let str = '';
    arr.forEach((el, index) => {
      str = `${str}${index !== 0 ? ';' : ''}${el}`;
    });
    return str;
  };

  const makeNextDayDate = (day: string) => {
    const date = new Date(day);
    date.setDate(date.getDate() + 1);
    return `${date.getFullYear()}-${date.getMonth() + 1 <= 9 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() <= 9 ? '0' : ''}${date.getDate()}`;
  };

  const obj = {
    urzadzenieNr: 1,
    metoda: 'string',
    dataWyjazdu: makeNextDayDate(dateOfEnd),
    dataPrzyjazdu: '',
    stacjaWyjazdu: findAgain ? stacjaPrzy : stacjaWyj,
    stacjaPrzyjazdu: findAgain ? stacjaWyj : stacjaPrzy,
    stacjePrzez: [],
    polaczeniaBezposrednie: 0,
    polaczeniaNajszybsze: 0,
    liczbaPolaczen: 0,
    kategoriePociagow: [],
    kodyPrzewoznikow: [],
    rodzajeMiejsc: [],
    typyMiejsc: [],
    czasNaPrzesiadkeMin: 10,
    czasNaPrzesiadkeMax: 1440,
    braille: 0,
    przyjazd: 0,
    time: '00:00',
    ticket100: grownUpArr ? makeStringFromArr(grownUpArr) : '',
    ticket50: childArr ? makeStringFromArr(childArr) : '',
  };

  return makeQuerySearch(obj);
};
