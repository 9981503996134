import {FC} from 'react';

import {TrasaPrzejazdu} from 'api/pociagi/interfaces';
import {getComparableDateFormat} from 'Utils/commonFunctions';
import Checkbox from 'assets/checkboxGreen.svg';

import {TimeType} from './MyTicketsRouteModal.util';

import S from './MyTicketsRouteModal.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  id: number;
  route: TrasaPrzejazdu[] | [];
  routeSection: TrasaPrzejazdu;
  departureStationIndex: number;
  arrivalStationIndex: number;
  isFullRouteVisible: boolean;
}

const MyTicketsRouteModalTimes: FC<Props> = ({
  id,
  route,
  routeSection,
  departureStationIndex,
  arrivalStationIndex,
  isFullRouteVisible,
}) => {
  const {t} = useTranslation();

  const currentDate = new Date();

  const getArrivalStyles = (isDelayed?: boolean, isDelayValue?: boolean) => {
    if (isFullRouteVisible && (id <= departureStationIndex || id > arrivalStationIndex)) {
      return `${S.detailsText} ${S.inactive} ${isDelayed && S.crossedOut} ${isDelayValue && S.error}`;
    } else if (!isFullRouteVisible && id === 0) {
      return `${S.detailsText} ${S.invisible}`;
    } else {
      return `${S.detailsText} ${isDelayed && S.crossedOut} ${isDelayValue && S.error}`;
    }
  };

  const getDepartureStyles = (isDelayed?: boolean, isDelayValue?: boolean) => {
    if (isFullRouteVisible && (id < departureStationIndex || id >= arrivalStationIndex)) {
      return `${S.detailsText} ${S.inactive} ${isDelayed && S.crossedOut} ${isDelayValue && S.error}`;
    } else if (!isFullRouteVisible && id === route.length - 1) {
      return `${S.detailsText} ${S.invisible}`;
    } else {
      return `${S.detailsText} ${isDelayed && S.crossedOut} ${isDelayValue && S.error}`;
    }
  };

  const getDelay = (scheduledDate: string, actualDate: string) => {
    if (actualDate) {
      return Math.round(
        (getComparableDateFormat(actualDate).getTime() - getComparableDateFormat(scheduledDate).getTime()) / 60000,
      );
    } else return 0;
  };

  const isDelayed = (timeType: TimeType) => {
    if (timeType === 'arrival') {
      return getDelay(routeSection.dataPrzyjazdu, routeSection.dataPrzyjazduRzeczywista) > 0;
    } else {
      return getDelay(routeSection.dataWyjazdu, routeSection.dataWyjazduRzeczywista) > 0;
    }
  };

  const isSectionEnded = (timeType: TimeType) => {
    if (timeType === 'arrival') {
      return (
        currentDate.getTime() >
        getComparableDateFormat(routeSection.dataPrzyjazduRzeczywista || routeSection.dataPrzyjazdu).getTime()
      );
    } else {
      return (
        currentDate.getTime() >
        getComparableDateFormat(routeSection.dataWyjazduRzeczywista || routeSection.dataWyjazdu).getTime()
      );
    }
  };

  return (
    <>
      <div className={S.timeRow}>
        <p className={`${getArrivalStyles(isDelayed('arrival'))} ${S.time}`}>
          {routeSection.dataPrzyjazdu.substring(10, 16)}
        </p>
        {!isDelayed('arrival') && routeSection.dataPrzyjazdu && isSectionEnded('arrival') && (
          <img className={`${getArrivalStyles(isDelayed('arrival'))} ${S.checkbox}`} src={Checkbox} alt={t('16027')} />
        )}
        <p className={`${getArrivalStyles()} ${S.time}`}>{routeSection.dataPrzyjazduRzeczywista.substring(10, 16)}</p>
        {isDelayed('arrival') && routeSection.dataPrzyjazdu && (
          <p className={getArrivalStyles(false, true)}>
            (+
            {getDelay(routeSection.dataPrzyjazdu, routeSection.dataPrzyjazduRzeczywista)})
          </p>
        )}
      </div>
      <div className={S.timeRow}>
        <p className={`${getDepartureStyles(isDelayed('departure'))} ${S.time}`}>
          {routeSection.dataWyjazdu.substring(10, 16)}
        </p>
        {!isDelayed('departure') && routeSection.dataWyjazdu && isSectionEnded('departure') && (
          <img
            className={`${getDepartureStyles(isDelayed('departure'))} ${S.checkbox}`}
            src={Checkbox}
            alt={t('16027')}
          />
        )}
        <p className={`${getDepartureStyles()} ${S.time}`}>{routeSection.dataWyjazduRzeczywista.substring(10, 16)}</p>
        {isDelayed('departure') && routeSection.dataWyjazdu && (
          <p className={getDepartureStyles(false, true)}>
            (+
            {getDelay(routeSection.dataWyjazdu, routeSection.dataWyjazduRzeczywista)})
          </p>
        )}
      </div>
    </>
  );
};

export default MyTicketsRouteModalTimes;
