/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {ChangeEvent, FC, RefObject, useEffect, useMemo, useState} from 'react';
import {Element as ScrollTarget} from 'react-scroll';

import {ShopDiscount} from 'common/api/localData';
import {useData} from 'Utils/DataContex';

import style from './Ticket.module.scss';
import Edit from 'assets/orangePencil.svg';
import EditContrast from 'assets/contrastPencil.svg';
import TicketIcon from 'assets/ticket.svg';
import TicketIconContrast from 'assets/contrastTicket.svg';
import {IRailwayEmployee} from 'api/sprzedaz/interfaces';
import {
  EmployeeIdStatus,
  isRailwayEmployeeRelated,
} from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';
import {useStyledThemeState} from 'common/theme';
import RailwayCompaniesSearcher from '../../../../PurchasePathDesktop/SetDiscounts/RailwayCompaniesSearcher/RailwayCompaniesSearcher';
import {useTranslation} from 'react-i18next';
import {discountListLanguageMapper} from 'Utils/commonFunctions';
// import BlueInputText from "components/common/BlueInputText/BlueInputText";

interface Props {
  isAdult: boolean;
  discountCode?: number | null;
  onChangeClick?: () => void;
  isActive?: boolean;
  employeeData?: IRailwayEmployee;
  onEmployeeIdChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onEmployerCodeChange?: (code: number) => void;
  railwayEmplIdStatus?: EmployeeIdStatus;
  index?: number;
  employerSearcherRef?: RefObject<HTMLDivElement>;
}
const Ticket: FC<Props> = ({
  isAdult,
  discountCode,
  onChangeClick,
  isActive,
  employeeData,
  // onEmployeeIdChange,
  onEmployerCodeChange,
  railwayEmplIdStatus,
  index,
  employerSearcherRef,
}) => {
  const {t, i18n} = useTranslation();
  const [languageNumber, setLanguageNumber] = useState(0);
  const {isContrastTheme} = useStyledThemeState();
  const [discount, setDiscount] = useState<ShopDiscount>();
  const {shopDiscounts} = useData();
  const isRailwayEmployee = discount && isRailwayEmployeeRelated(discount);
  const idInputError: boolean =
    (employeeData && employeeData.employeeId.length < 13) || railwayEmplIdStatus === EmployeeIdStatus.INVALID;

  useEffect(() => {
    if (!discount && shopDiscounts.length > 0 && discountCode) {
      const find = shopDiscounts.find((el) => el?.kodZakupowy === discountCode);
      find && setDiscount(find);
    }
  }, [shopDiscounts]);

  useEffect(() => {
    if (shopDiscounts.length > 0 && discountCode) {
      const find = shopDiscounts.find((el) => el?.kodZakupowy === discountCode);
      find && setDiscount(find);
    }
  }, [discountCode]);

  useEffect(() => {
    const selectedLang = discountListLanguageMapper(i18n.language);
    setLanguageNumber(selectedLang);
  }, [i18n.language]);

  const ticketTitle = useMemo(() => {
    const ageCategory = isAdult ? t('13015') : t('13016');
    const discountName = discount ? discount.opisy[languageNumber].nazwa : t('13017');
    if (index) {
      return `${ageCategory} ${index}: ${discountName}`;
    }
    return `${ageCategory}: ${discountName}`;
  }, [discount, isAdult, index]);

  return (
    <>
      <div className={`${style.ticketBox} ${isActive ? style.ticketBoxActive : ''}`} tabIndex={1}>
        <span className={style.absolute_text}>{t('22018')}</span>
        <div className={style.ticketBox_box}>
          <img
            src={isContrastTheme ? TicketIconContrast : TicketIcon}
            alt={t('11010')}
            className={style.ticket_icon_style}
          />
          <p className={style.font_style}>{ticketTitle}</p>
        </div>
        <button
          type="button"
          className={style.button_style}
          aria-label={t('13018')}
          onClick={onChangeClick}
          onKeyDown={(e) => e.key === 'Enter' && onChangeClick && onChangeClick()}
        >
          <p>{t('29469')}</p>
          <img src={isContrastTheme ? EditContrast : Edit} alt={t('29076')} className={style.icon_style} />
        </button>
      </div>
      <p className={`${style.alert} ${!discountCode ? style.alert_display : ''}`}>{t('13019')}</p>
      {isActive && <ScrollTarget name="railwayEmployeeBox" />}
      {isRailwayEmployee && isActive && employeeData && (
        <div
          className={`
                        ${style.railwayEmployeeBox} 
                        ${idInputError ? style.railwayEmployeeBoxInputError : ''}
                    `}
        >
          {/* Uncomment to enable employee id validation */}
          {/*<p className={style.railwayEmployeeHeader}>Uzupełnij poniższe dane:</p>*/}
          {/*<BlueInputText*/}
          {/*    id="employeeId"*/}
          {/*    name="employeeId"*/}
          {/*    label="Seria / Nr Legitymacji"*/}
          {/*    value={employeeData.employeeId}*/}
          {/*    onChange={onEmployeeIdChange}*/}
          {/*    withMaskedInput*/}
          {/*    mask={[/[a-zA-Z]/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}*/}
          {/*    autoFocus={!employeeData.employeeId.length}*/}
          {/*/>*/}
          {/*{railwayEmplIdStatus === EmployeeIdStatus.INVALID &&*/}
          {/*    <p className={style.invalidIdMsg}>*/}
          {/*        Seria i/lub numer legitymacji są niepoprawne lub legitymacja jest nieaktywna.*/}
          {/*    </p>*/}
          {/*}*/}
          {/*<a href="https://www.intercity.pl/pl/seria-i-nr-biletu" target="_blank" rel="noreferrer" className={style.railwayEmployeeLink}>*/}
          {/*    Gdzie znajdę serię i numer?*/}
          {/*</a>*/}
          <span className="sr-only">{t('13024')}</span>
          {onEmployerCodeChange && (
            <RailwayCompaniesSearcher
              employeeData={employeeData}
              onEmployerCodeChange={onEmployerCodeChange}
              employerSearcherRef={employerSearcherRef}
            />
          )}
        </div>
      )}
    </>
  );
};
export default Ticket;
