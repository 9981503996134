import {styled, Tabs as MuiTabs, Tab as MuiTab} from '@mui/material';
import {mediaQueries} from 'common/theme/consts';

export const Tabs = styled(MuiTabs)`
  position: relative;
  min-height: auto;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 4px solid ${({theme}) => theme.color.border.primary};
  }

  & .MuiTabs-indicator {
    background-color: ${({theme}) => theme.color.accent.default};
    height: 4px;
  }

  @media (${mediaQueries.sm}) {
    &::before {
      border-bottom-width: 2px;
    }

    & .MuiTabs-indicator {
      background-color: ${({theme}) => theme.color.highlight.default};
      height: 2px;
    }
  }
`;

export const Tab = styled(MuiTab)`
  min-height: auto;
  min-width: auto;
  padding: 1rem 0 1.4rem 0;
  font-size: ${({theme}) => theme.text.size[12]};
  font-weight: ${({theme}) => theme.text.weight[700]};
  line-height: 1.5rem;
  letter-spacing: -0.0009em;
  color: ${({theme}) => theme.color.components.button.inactive};

  &.Mui-selected {
    color: ${({theme}) => theme.color.secondary.default};
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.color.dark.default};
    outline-offset: -2px;
  }

  & .MuiTouchRipple-root {
    display: none;
  }

  @media (${mediaQueries.sm}) {
    padding: 0.8rem 0.8rem 1rem 0.8rem;
    font-size: ${({theme}) => theme.text.size[14]};
    font-weight: ${({theme}) => theme.text.weight[600]};
    line-height: 1.8rem;

    &.Mui-selected {
      color: ${({theme}) => theme.color.highlight.default};
    }
  }
`;
