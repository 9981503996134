import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorOutlineOutlined} from '@mui/icons-material/';

import ConfirmButton from 'components/common/ConfirmButton';
import Loader from 'components/common/Loader/Loader';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import api from 'api/pociagi/repository';
import {IGetRouteRequest, TrasaPrzejazdu} from 'api/pociagi/interfaces';
import {getComparableDateFormat, returnCarrierLogo} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';

import MyTicketsRouteModalGridHeader from './MyTicketsRouteModalGridHeader';
import MyTicketsRouteModalGridContent from './MyTicketsRouteModalGridContent';
import {RouteStatus, getRouteStatusText} from './MyTicketsRouteModal.util';

import S from './MyTicketsRouteModal.module.scss';

interface Props {
  routeData: IGetRouteRequest;
  trainCategory: string;
  handleCloseModal: () => void;
}

const MyTicketsRouteModal: FC<Props> = ({routeData, trainCategory, handleCloseModal}) => {
  const currentDate = new Date();
  const {isContrastTheme, theme} = useStyledThemeState();
  const [loader, setLoader] = useState<boolean>(true);
  const [routeStatus, setRouteStatus] = useState<RouteStatus>(RouteStatus.UNKNOWN);
  const [fullRouteDetails, setFullRouteDetails] = useState<TrasaPrzejazdu[] | []>([]);
  const [sectionRouteDetails, setSectionRouteDetails] = useState<TrasaPrzejazdu[] | []>([]);
  const [isFullRouteVisible, setIsFullRouteVisible] = useState<boolean>(false);
  const {t} = useTranslation();

  const departureStationIndex = fullRouteDetails
    ? fullRouteDetails.findIndex((el) => parseInt(el.kodStacji) === routeData.stacjaWyjazdu)
    : 0;

  const arrivalStationIndex = fullRouteDetails
    ? fullRouteDetails.findIndex((el) => parseInt(el.kodStacji) === routeData.stacjaPrzyjazdu)
    : 0;

  const toggleRoute = () => setIsFullRouteVisible((prev) => !prev);

  const getFullRoute = async () => {
    try {
      const routes = await api.getTrainRoute(routeData);
      setFullRouteDetails(routes?.trasePrzejezdu?.trasaPrzejazdu || []);
      setLoader(false);
    } catch {
      setLoader(false);
      return;
    }
  };

  const getRouteSection = () => {
    if (departureStationIndex && arrivalStationIndex && fullRouteDetails) {
      setSectionRouteDetails(fullRouteDetails.slice(departureStationIndex, arrivalStationIndex + 1));
    } else {
      setSectionRouteDetails([]);
    }
  };

  const getDepartureTime = () =>
    getComparableDateFormat(
      sectionRouteDetails[0]?.dataWyjazduRzeczywista || sectionRouteDetails[0]?.dataWyjazdu,
    ).getTime();

  const getArrivalTime = () =>
    getComparableDateFormat(
      sectionRouteDetails[sectionRouteDetails.length - 1]?.dataPrzyjazduRzeczywista ||
        sectionRouteDetails[sectionRouteDetails.length - 1]?.dataPrzyjazdu,
    ).getTime();

  useEffect(() => {
    getFullRoute();
  }, []);

  useEffect(() => {
    getRouteSection();
  }, [fullRouteDetails]);

  useEffect(() => {
    getDepartureTime();
    getArrivalTime();

    if (fullRouteDetails.length === 0) {
      setRouteStatus(RouteStatus.UNKNOWN);
    } else if (currentDate.getTime() >= getDepartureTime() && currentDate.getTime() < getArrivalTime()) {
      setRouteStatus(RouteStatus.DEPARTED);
    } else if (currentDate.getTime() >= getDepartureTime() && currentDate.getTime() >= getArrivalTime()) {
      setRouteStatus(RouteStatus.ARRIVED);
    } else if (currentDate.getTime() < getDepartureTime()) {
      setRouteStatus(RouteStatus.BEFORE_DEPARTURE);
    } else {
      setRouteStatus(RouteStatus.UNKNOWN);
    }
  }, [sectionRouteDetails]);

  return (
    <div className={S.modalBackground}>
      <div className={S.modalContainer}>
        {loader ? (
          <div className={S.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={S.topRow}>
              <h2>Twój pociąg na mapie</h2>
              <button
                className={S.closeButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModal();
                  setIsFullRouteVisible(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleCloseModal();
                    setIsFullRouteVisible(false);
                  }
                }}
                aria-label="Zamknij okno podglądu trasy"
              >
                <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
              </button>
            </div>
            {getRouteStatusText(routeStatus)}
            <div className={S.carrierDetails}>
              {returnCarrierLogo(trainCategory, theme)}
              <span className={S.carrierNumber}>{routeData.numerPociagu}</span>
            </div>
            <div className={S.routeDetailsWrapper}>
              <div className={S.desktopOnly}>
                <MyTicketsRouteModalGridHeader isFullRouteVisible={isFullRouteVisible} toggleRoute={toggleRoute} />
                <MyTicketsRouteModalGridContent
                  route={isFullRouteVisible ? fullRouteDetails : sectionRouteDetails}
                  departureStationIndex={departureStationIndex}
                  arrivalStationIndex={arrivalStationIndex}
                  isFullRouteVisible={isFullRouteVisible}
                />
              </div>
            </div>
            {routeStatus !== RouteStatus.ARRIVED && (
              <div className={S.warningWrapper}>
                <ErrorOutlineOutlined className={S.warningIcon} />
                <p className={S.warningText}>
                  Pamiętaj, żeby przed odjazdem upewnić się, z którego peronu odjeżdża pociąg.
                </p>
              </div>
            )}
            <div className={S.confirmButtonWrapper}>
              <ConfirmButton text={t('29002')} execute={handleCloseModal} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyTicketsRouteModal;
