import React from 'react';
import {themes, fonts, fontSizeScales, breakpoints} from './consts';

export type Theme = (typeof themes)[number];

export type Font = (typeof fonts)[number];

export type FontSizeScale = (typeof fontSizeScales)[number];

export type Breakpoints = (typeof breakpoints)[number];

export enum StyledThemeActions {
  SET_THEME = 'SET_THEME',
  SET_FONT = 'SET_FONT',
}

export type Action =
  | {type: StyledThemeActions.SET_THEME; payload: Theme}
  | {type: StyledThemeActions.SET_FONT; payload: Font};

export class StateContext {
  theme: Theme;
  isContrastTheme: boolean;
  font: Font;

  constructor(theme: Theme, font: Font) {
    this.theme = theme;
    this.isContrastTheme = theme === 'contrast';
    this.font = font;
  }
}

export interface DispatchContext {
  dispatch: React.Dispatch<Action>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFontSizeScale = (value: any): value is FontSizeScale => {
  return fontSizeScales.includes(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStyledTheme = (value: any): value is StateContext => {
  return typeof value === 'object' && themes.includes(value.theme) && fonts.includes(value.font);
};

export const assertIsStyledTheme: (value: unknown) => asserts value is StateContext = (value) => {
  if (!isStyledTheme(value)) {
    throw new Error(`Expected 'value' to be styled theme context, but received ${value}`);
  }
};
