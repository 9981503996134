/* eslint-disable no-case-declarations */
import {useState, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {UserTicketsContext} from 'Utils/UserTicketsContext';
import {IDownloadTicketResponse, IIdResponseList, ISingleId} from 'api/sprzedaz/interfaces';
import {useData} from 'Utils/DataContex';

import apiPayment from 'api/sprzedaz/repository';

import {searchFunction} from 'Utils/searchFunction';

import {returnStationByCode, getFullDate} from 'Utils/commonFunctions';

import {BuyerProfile} from '../BuyerProfile/BuyerProfiles.interface';

export interface FullTicket {
  ticketInfo: ISingleId;
  ticketDetail: IDownloadTicketResponse;
  searchDate: string;
}

export interface TicketListData {
  preType: number;
  preHis: number;
  wynikowNaStronie: number;
  listaTyp: number;
  sortowanie: number;
  numerStrony: number;
}

const useMyTickets = (preHistory?: number, forInvoices?: boolean, preReturned?: boolean) => {
  const {setReturnedTicketIdent} = useContext(UserTicketsContext);
  const {t} = useTranslation();

  const [tickets, setTickets] = useState<FullTicket[] | null | BuyerProfile[]>(null);
  const [history, setHistory] = useState(preHistory ? preHistory : 0);
  const [ticketType, setTicketType] = useState(1);
  const [searchPhase, setSearchPhase] = useState('');
  const [filter, setFilter] = useState(forInvoices ? 'Faktury' : t('17009'));
  const [returnedTickets, setReturnedTickets] = useState(preReturned ? preReturned : false);

  const [active, setActive] = useState<number>(forInvoices ? 2 : 1);
  const [page, setPage] = useState<number>(1);

  const [error, setError] = useState(false);
  const [errorCounter, setErrorCounter] = useState(0);

  const {stationsList} = useData();

  let killswitch = false;

  useEffect(() => {
    killswitch = false;
    return () => {
      killswitch = true;
    };
  }, []);

  const getTicket = async (data: TicketListData) => {
    try {
      const getIndexes = await apiPayment.getTicketIdent({
        biletTyp: data.preType,
        historia: data.preHis,
        wynikowNaStronie: data.wynikowNaStronie,
        listaTyp: data.listaTyp,
        sortowanie: data.sortowanie,
        numerStrony: data.numerStrony,
      });

      return getIndexes;
    } catch {
      setError(true);
    }
    return [];
  };

  const returnActiveFiltersArr = (arr: FullTicket[] | undefined) => {
    if (arr && !!arr.length) {
      switch (filter) {
        case 'Oczekujące':
        case '29155':
        case 'Waiting':
          return [];
        case 'Faktura':
        case '26004':
        case 'Invoice':
          return arr.filter((el) => el.ticketInfo.typeOfNote && el.ticketInfo.typeOfNote === 1);
        case 'Korekta':
        case '29186':
        case 'Adjustment':
          return arr.filter((el) => el.ticketInfo.typeOfNote && el.ticketInfo.typeOfNote === 2);
        case 'Nota':
        case '29187':
        case 'Note':
          return arr.filter((el) => el.ticketInfo.typeOfNote && el.ticketInfo.typeOfNote === 3);
        case 'NajbliższyHistory':
        case 'Najnowsze':
        case '29188':
        case 'Latest':
        case 'Najdalszy':
        case '29159':
        case 'Fathest':
          const newArr = [...arr];
          newArr.sort((a, b) => {
            return (
              new Date(b.ticketDetail.bilety[0]?.odcinki[0].wyjazdData).getTime() -
              new Date(a.ticketDetail.bilety[0]?.odcinki[0].wyjazdData).getTime()
            );
          });
          return newArr;
        case 'NajdalszyHistory':
        case 'Najstarsze':
        case '29189':
        case 'The oldest':
        case 'Najbliższy':
        case '29157':
        case 'Nearest':
          const newArr2 = [...arr];
          newArr2.sort((a, b) => {
            return (
              new Date(a.ticketDetail.bilety[0]?.odcinki[0].wyjazdData).getTime() -
              new Date(b.ticketDetail.bilety[0]?.odcinki[0].wyjazdData).getTime()
            );
          });
          return newArr2;
        case 'Najwyższa':
        case '29161':
        case 'The highest':
          const newArr3 = [...arr];
          newArr3.sort((a, b) => {
            return b.ticketDetail.bilety[0].cenaBilet - a.ticketDetail.bilety[0].cenaBilet;
          });
          return newArr3;
        case 'Najniższa':
        case '29163':
        case 'Lowest':
          const newArr4 = [...arr];
          newArr4.sort((a, b) => {
            return a.ticketDetail.bilety[0].cenaBilet - b.ticketDetail.bilety[0].cenaBilet;
          });
          return newArr4;
        default:
          return arr;
      }
    }
    return [];
  };

  const returnActiveFiltersArrProfiles = (arr: BuyerProfile[] | undefined) => {
    if (arr && !!arr.length) {
      switch (filter) {
        case 'alfabetAZ':
          return arr.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        case 'alfabetZA':
          return arr.sort((a, b) => {
            return b.name.localeCompare(a.name);
          });
        case 'Najnowsze':
        case '29188':
        case 'Latest':
          return [];
        case 'Najstarsze':
        case '29189':
        case 'The oldest':
          return [];
        default:
          return arr;
      }
    }
    return [];
  };

  const makeDetailTicketArr = async (tick: IIdResponseList) => {
    const arr = [] as IDownloadTicketResponse[];

    if (tick && tick?.identyfikatory) {
      for (let i = 0; i < tick?.identyfikatory.length; i++) {
        if (killswitch === true) break;
        const res = (await apiPayment.downloadTicket({
          transakcjaNr: tick?.identyfikatory[i].transakcjaNr,
          biletSeria: tick?.identyfikatory[i].biletSeria,
          biletNr: tick?.identyfikatory[i].biletNr,
        })) as IDownloadTicketResponse;
        arr.push({
          bledy: res.bledy,
          bilety: res.bilety.map((el) => {
            return {
              ...el,
              cenaBiletDoWyszukiwania: `${((el.biletRodzaj === 31 ? el?.cenaCalkowita : el.cenaBilet + el.cenaRezerwacja) / 100).toFixed(2).replace('.', ',')}${t('24086')}`,
              nrSeriaDoWyszukiwania: `${el.biletSeria}${el.biletNr}`,
              miastoOdNazwaDoWyszukiwania: returnStationByCode(el?.odcinki[0].stacjaOdKod, stationsList),
              miastoDoNazwaDoWyszukiwania: returnStationByCode(
                el?.odcinki[el?.odcinki.length - 1].stacjaDoKod,
                stationsList,
              ),
            };
          }),
        });
      }
    }
    return arr;
  };

  const getDetailInfo = async (data: TicketListData, preTick?: IIdResponseList) => {
    const tick = preTick ? preTick : ((await getTicket(data)) as IIdResponseList);

    if (tick?.identyfikatory) tick.identyfikatory = tick?.identyfikatory.filter((el) => el.zwrocony !== 1);

    const det = await makeDetailTicketArr(tick);
    if (tick?.identyfikatory?.length) {
      return tick?.identyfikatory.map((el) => {
        return {
          ticketInfo: el,
          ticketDetail: det?.filter(
            (el2) => el2.bilety[0]?.biletNr === el?.biletNr && el2.bilety[0]?.transakcjaNr === el.transakcjaNr,
          )[0],
          searchDate: getFullDate(el.waznoscBiletuOd),
        };
      });
    }
    return [];
  };

  const getReturnInfo = async (preCheck?: boolean) => {
    let actualTick;
    let act;
    let seazon;
    let his;
    let actAb;
    let hisAb;
    let cancelledTickets;

    const checkTick = (await getTicket({
      preType: 1,
      preHis: 0,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 2,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const checkSeazon = (await getTicket({
      preType: 2,
      preHis: 0,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 2,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const checkHistoryTick = (await getTicket({
      preType: 1,
      preHis: 1,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 2,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const checkTickAb = (await getTicket({
      preType: 4,
      preHis: 0,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 2,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const checkHistoryTickAb = (await getTicket({
      preType: 4,
      preHis: 1,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 2,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const checkTickCancelled = (await getTicket({
      preType: 1,
      preHis: 0,
      wynikowNaStronie: preCheck ? 5 : 1,
      listaTyp: 8,
      sortowanie: 0,
      numerStrony: 1,
    })) as IIdResponseList;

    const hisTikToArr = checkHistoryTick.identyfikatory.length ? checkHistoryTick.identyfikatory : [];
    const actTikToArr = checkTick.identyfikatory.length ? checkTick.identyfikatory : [];
    const seazonalTikToArr = checkSeazon.identyfikatory.length ? checkSeazon.identyfikatory : [];
    const hisTikToArrAB = checkHistoryTickAb.identyfikatory.length ? checkHistoryTickAb.identyfikatory : [];
    const actTikToArrAB = checkTickAb.identyfikatory.length ? checkTickAb.identyfikatory : [];
    const actTikToArrCancelled = checkTickCancelled.identyfikatory.length ? checkTickCancelled.identyfikatory : [];

    let wholeArr = [
      ...actTikToArr,
      ...hisTikToArr,
      ...hisTikToArrAB,
      ...actTikToArrAB,
      ...actTikToArrCancelled,
      ...seazonalTikToArr,
    ];

    setReturnedTicketIdent(wholeArr);
    if (!preCheck) {
      act = checkTick.identyfikatory;
      his = checkHistoryTick.identyfikatory;
      actAb = checkTickAb.identyfikatory;
      hisAb = checkHistoryTickAb.identyfikatory;
      cancelledTickets = checkTickCancelled.identyfikatory;
      seazon = checkSeazon.identyfikatory;

      if (checkTick.paginacja.wszystkichWynikow > 1) {
        actualTick = (await getTicket({
          preType: 1,
          preHis: 0,
          wynikowNaStronie: checkTick.paginacja.wszystkichWynikow,
          listaTyp: 2,
          sortowanie: 0,
          numerStrony: 1,
        })) as IIdResponseList;
        act = actualTick.identyfikatory;
      }

      if (checkSeazon.paginacja.wszystkichWynikow > 1) {
        const seazonalTick = (await getTicket({
          preType: 2,
          preHis: 0,
          wynikowNaStronie: checkSeazon.paginacja.wszystkichWynikow,
          listaTyp: 2,
          sortowanie: 0,
          numerStrony: 1,
        })) as IIdResponseList;
        seazon = seazonalTick.identyfikatory;
      }

      if (checkHistoryTick.paginacja.wszystkichWynikow > 1) {
        his = [] as ISingleId[];
        for (let i = 1; i < Math.ceil(checkHistoryTick.paginacja.wszystkichWynikow / 5); i++) {
          const historyTick = (await getTicket({
            preType: 1,
            preHis: 1,
            wynikowNaStronie: 5,
            listaTyp: 2,
            sortowanie: 0,
            numerStrony: i,
          })) as IIdResponseList;
          his = [...his, ...historyTick.identyfikatory];
        }
      }

      if (checkTickAb.paginacja.wszystkichWynikow > 1) {
        actAb = [] as ISingleId[];
        for (let i = 1; i < Math.ceil(checkTickAb.paginacja.wszystkichWynikow / 5); i++) {
          const activeAb = (await getTicket({
            preType: 4,
            preHis: 1,
            wynikowNaStronie: 5,
            listaTyp: 2,
            sortowanie: 0,
            numerStrony: i,
          })) as IIdResponseList;
          actAb = [...actAb, ...activeAb.identyfikatory];
        }
      }

      if (checkHistoryTickAb.paginacja.wszystkichWynikow > 1) {
        hisAb = [] as ISingleId[];
        for (let i = 1; i < Math.ceil(checkHistoryTickAb.paginacja.wszystkichWynikow / 5); i++) {
          const historyTickAb = (await getTicket({
            preType: 4,
            preHis: 1,
            wynikowNaStronie: 5,
            listaTyp: 2,
            sortowanie: 0,
            numerStrony: i,
          })) as IIdResponseList;
          hisAb = [...hisAb, ...historyTickAb.identyfikatory];
        }
      }

      if (checkTickCancelled.paginacja.wszystkichWynikow > 1) {
        cancelledTickets = [] as ISingleId[];
        for (let i = 1; i < Math.ceil(checkTickCancelled.paginacja.wszystkichWynikow / 5); i++) {
          const activeCancelled = (await getTicket({
            preType: 1,
            preHis: 1,
            wynikowNaStronie: checkTickCancelled.paginacja.wszystkichWynikow,
            listaTyp: 8,
            sortowanie: 0,
            numerStrony: i,
          })) as IIdResponseList;
          cancelledTickets = [...cancelledTickets, ...activeCancelled.identyfikatory];
        }
      }

      wholeArr = [...act, ...his, ...actAb, ...hisAb, ...cancelledTickets, ...seazon];
      setReturnedTicketIdent(wholeArr);
    }

    const det = await makeDetailTicketArr({
      identyfikatory: wholeArr,
      bledy: [],
      paginacja: {obecnaStrona: 1, wszystkichStron: 1, wszystkichWynikow: 1, wynikowNaStrone: 1},
    });
    if (wholeArr?.length) {
      return wholeArr.map((el) => {
        return {
          ticketInfo: el,
          ticketDetail: det?.filter(
            (el2) => el2.bilety[0]?.biletNr === el?.biletNr && el2.bilety[0]?.transakcjaNr === el.transakcjaNr,
          )[0],
          searchDate: getFullDate(el.waznoscBiletuOd),
        };
      });
    }
    return [];
  };

  const search = (preTickets?: FullTicket[] | BuyerProfile[] | null) => {
    const filtered = searchFunction(searchPhase, preTickets ? preTickets : tickets) as FullTicket[];
    return filtered;
  };

  const searchBuyerProfile = (preTickets?: BuyerProfile[]) => {
    const filtered = searchFunction(searchPhase, preTickets ? preTickets : tickets) as BuyerProfile[];
    return filtered;
  };

  const filterAddTicket = (ticket: FullTicket[]) => {
    if (ticket) {
      const checkReturnDesc = (tick: FullTicket) => {
        return tick.ticketDetail?.bilety.filter((ticket) => {
          if (ticket.zwrotOpis) return ticket;
          return false;
        }).length;
      };
      return ticket.filter((el) => !!checkReturnDesc(el));
    }
    return [];
  };

  const makeReturnedFullTicket = (
    act: FullTicket[],
    his: FullTicket[],
    ret: FullTicket[],
    fn: (e: FullTicket[]) => void,
  ) => {
    const filteredHistory = filterAddTicket(his);
    const filteredAcc = filterAddTicket(act);

    const fullReturnedTicketArr = [...ret, ...filteredAcc, ...filteredHistory].sort((a, b) => {
      return (
        new Date(b.ticketDetail?.bilety[0]?.odcinki[0]?.wyjazdData).getTime() -
        new Date(a.ticketDetail?.bilety[0]?.odcinki[0]?.wyjazdData).getTime()
      );
    });
    fn([...fullReturnedTicketArr]);
  };

  const checkIfTrainStarts = (date: string) => {
    return new Date(date).getTime() < new Date().getTime();
  };

  return {
    tickets,
    setTickets,
    history,
    setHistory,
    ticketType,
    setTicketType,
    searchPhase,
    setSearchPhase,
    filter,
    setFilter,
    returnedTickets,
    setReturnedTickets,
    active,
    setActive,
    page,
    setPage,
    returnActiveFiltersArr,
    returnActiveFiltersArrProfiles,
    search,
    searchBuyerProfile,
    makeDetailTicketArr,
    getDetailInfo,
    getReturnInfo,
    getTicket,
    makeReturnedFullTicket,
    checkIfTrainStarts,
    error,
    setError,
    errorCounter,
    setErrorCounter,
  };
};

export default useMyTickets;
