import {Station} from 'common/api/localData';

export const getAgglomerationStations = (agglomerationCode: number, stations: Station[]) =>
  agglomerationCode > 0 ? stations.filter(({kodAglomeracji}) => kodAglomeracji === agglomerationCode) : [];

export const getExchangeableAgglomerationStations = (agglomerationCode: number, stations: Station[]) =>
  agglomerationCode > 0
    ? stations.filter(
        ({kodAglomeracji, wymianaAglomeracja, kod}) =>
          kodAglomeracji === agglomerationCode && wymianaAglomeracja === 1 && kod > 0,
      )
    : [];
