export default {
  changeCarriage: (text: number | undefined) => {
    const tt = text || 0;

    return tt;
  },
  allSeatsTaken: '21048',
  quietZone: '21049',
  kidsZone: '21050',
  ozn: '21051',
  bike: '21052',
  guardian: '21053',
  wheelchair: '21054',
};
