/* eslint-disable no-extra-boolean-cast */
import {createElement} from 'react';
import moment from 'moment';
import {pl, enGB, de, ru} from 'date-fns/locale';
import {parseISO, addDays, addMinutes, isAfter} from 'date-fns';
import {
  CommercialAttribute,
  PlacePosition,
  PlaceType,
  SeasonalOffer,
  ShopDiscount,
  Station,
} from 'common/api/localData';

import {
  IDownloadTicketResponseSingleTicket,
  IPriceCheckResponse,
  IGenerateAdditionalTicketsResponse,
  IGenerateTicketResponse,
  INewTicket,
  IPkpOffers,
  IPriceCheckTraveler,
  IGenerateAdditionalFeeResponse,
} from 'api/sprzedaz/interfaces';

import Doggo from 'assets/AdditionalTickets/pet.svg';
import Luggage from 'assets/AdditionalTickets/luggage.svg';
import Bicycle from 'assets/AdditionalTickets/bicycle.svg';
import DoggoContrast from 'assets/AdditionalTickets/petContrast.svg';
import LuggageContrast from 'assets/AdditionalTickets/luggageContrast.svg';
import BicycleContrast from 'assets/AdditionalTickets/bicycleContrast.svg';
import DoggoMobile from 'assets/AdditionalTickets/pet_mobile.svg';
import LuggageMobile from 'assets/AdditionalTickets/bag_mobile.svg';
import BicycleMobile from 'assets/AdditionalTickets/rower_mobile.svg';
import DoggoContrastMobile from 'assets/AdditionalTickets/petContrast.svg';
import LuggageContrastMobile from 'assets/AdditionalTickets/luggageContrast.svg';
import BicycleContrastMobile from 'assets/AdditionalTickets/bicycleContrast.svg';
import {ReactComponent as EIC} from 'assets/trainEIC.svg';
import {ReactComponent as IC} from 'assets/trainIC.svg';
import {ReactComponent as EIP} from 'assets/trainEIP.svg';
import {ReactComponent as TLK} from 'assets/trainTLK.svg';
import {ReactComponent as EC} from 'assets/trainEC.svg';
import {ReactComponent as EICContrast} from 'assets/trainEICContrast.svg';
import {ReactComponent as ICContrast} from 'assets/trainICContrast.svg';
import {ReactComponent as EIPContrast} from 'assets/trainEIPContrast.svg';
import {ReactComponent as TLKContrast} from 'assets/trainTLKContrast.svg';
import {ReactComponent as ECContrast} from 'assets/trainECContrast.svg';

//ikony miejsc

import {ReactComponent as WheelChair} from 'assets/SeatOptions/oznWheelchair.svg';
import {ReactComponent as OzNWithoutWheelchair} from 'assets/SeatOptions/orangeOzNWithoutWheelchair.svg';
import {ReactComponent as Seat} from 'assets/SeatOptions/sittingPerson.svg';
import {ReactComponent as SeatWithTable} from 'assets/SeatOptions/seatWithTable.svg';
import {ReactComponent as QuiteVagon} from 'assets/SeatOptions/quietWagon.svg';
import {ReactComponent as Sleep4} from 'assets/SeatOptions/sleep4.svg';
import {ReactComponent as Sleep6} from 'assets/SeatOptions/sleep6.svg';
import {ReactComponent as Bike} from 'assets/SeatOptions/rower.svg';
import {ReactComponent as Comfort} from 'assets/SeatOptions/comfortZone.svg';
import {ReactComponent as Przedzialowy} from 'assets/SeatOptions/przedzialowy.svg';
import {ReactComponent as Bezprzedzialowy} from 'assets/SeatOptions/bezprzedzialowy.svg';
import {ReactComponent as WheelChairContrast} from 'assets/SeatOptions/oznWheelchairContrast.svg';
import {ReactComponent as OzNWithoutWheelchairContrast} from 'assets/SeatOptions/contrastOzNWithoutWheelchair.svg';
import {ReactComponent as SeatContrast} from 'assets/SeatOptions/sittingPersonContrast.svg';
import {ReactComponent as SeatWithTableContrast} from 'assets/SeatOptions/seatWithTableContrast.svg';
import {ReactComponent as QuiteVagonContrast} from 'assets/SeatOptions/quietWagonContrast.svg';
import {ReactComponent as Sleep4Contrast} from 'assets/SeatOptions/sleep4Contrast.svg';
import {ReactComponent as Sleep6Contrast} from 'assets/SeatOptions/sleep6Contrast.svg';
import {ReactComponent as BikeContrast} from 'assets/SeatOptions/rowerContrast.svg';
import {ReactComponent as ComfortContrast} from 'assets/SeatOptions/comfortZoneContrast.svg';
import {ReactComponent as PrzedzialowyContrast} from 'assets/SeatOptions/przedzialowyContrast.svg';
import {ReactComponent as BezprzedzialowyContrast} from 'assets/SeatOptions/bezprzedzialowyContrast.svg';
import {ReactComponent as OzNCaregiver} from 'assets/SeatOptions/oznCaregiver.svg';
import {ReactComponent as OzNCaregiverContrast} from 'assets/SeatOptions/oznCaregiverContrast.svg';
import {ReactComponent as ParentWithChild} from 'assets/SeatOptions/parentWithChild.svg';
import {ReactComponent as ParentWithChildContrast} from 'assets/SeatOptions/parentWithChildContrast.svg';

import {InvoiceCountryOption} from './invoiceCountriesList';
import {Theme} from 'common/theme';

import apiPayment from 'api/sprzedaz/repository';
import {Discount} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import {isCodeRailwayEmployeeRelated} from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';
import {ISearchConnectionFormData} from 'interfaces/forms';
import {IConnectionSearchResultsList, IConnectionSearchTrainDetails} from 'api/pociagi/interfaces';
import {env} from 'env';
import i18n from 'i18n';
import {ISeasonalTicketsOffersNames} from 'interfaces/seasonal';
import {TFunction} from 'i18next';
import {ticketKinds} from 'common/api/myTickets/consts';
import {DataContext} from './DataContex';
import {SEASON_TICKET_DISTANCE_MARGIN} from 'components/pages/SeasonalTicket/common/const';
const t = i18n.t;
export const removePolishLetters = (string: string) => {
  return string
    .toLowerCase()
    .replaceAll('ą', 'a')
    .replaceAll('ł', 'l')
    .replaceAll('ć', 'c')
    .replaceAll('ź', 'z')
    .replaceAll('ó', 'o')
    .replaceAll('ń', 'n')
    .replaceAll('ś', 's')
    .replaceAll('ę', 'e')
    .replaceAll('ż', 'z');
};

export const getMinutes = (timeInMin: number): number => timeInMin % 60;
export const getHoursFromMin = (timeInMin: number): number => (timeInMin - (timeInMin % 60)) / 60;

export const getFullTimeFromDate = (dateInfo: string) => {
  const newDate = new Date(dateInfo);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export const getDayOfTheWeek = (day: number) => {
  const arrOfDay = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];
  return arrOfDay[day];
};

export const getMonthName = (numb: number) => {
  const monthsArr = [
    '13035',
    '13036',
    '13037',
    '13038',
    '13039',
    '13040',
    '13041',
    '13042',
    '13043',
    '13044',
    '13045',
    '13046',
  ];
  return monthsArr[numb - 1];
};

export const getFullDate = (date: string) => {
  const newDate = new Date(date);

  if (!date) {
    return '-';
  }

  return `${newDate.getDate() <= 9 ? '0' : ''}${newDate.getDate()}.${
    newDate.getMonth() + 1 <= 9 ? '0' : ''
  }${newDate.getMonth() + 1}.${newDate.getFullYear()}`;
};

export const makeTimeOfTravel = (timeFrom: string, timeTo: string) => {
  const tf = new Date(timeFrom).getTime() / 1000 / 60;
  const tt = new Date(timeTo).getTime() / 1000 / 60;

  if (!timeFrom || !timeTo) {
    return '-';
  }

  return formatTripTime(tt - tf);
};

export const durationInDaysBetweenTwoDates = (fisrtDate: string, secondDate: string) => {
  const firstDateMoment = moment(new Date(fisrtDate).setHours(0, 0, 0, 0));
  const secondDateMoment = moment(new Date(secondDate).setHours(0, 0, 0, 0));

  return moment.duration(firstDateMoment.diff(secondDateMoment)).asDays() ?? 0;
};

export const areWeAfterTheDateTZBEnvDate = () => {
  const dateNow = new Date().toDateString();
  const dateFromEnv = env.REACT_APP_NEW_TZB_DATE ?? '2024-09-12';
  const dateSplitted = dateFromEnv.split('-');

  const year = parseInt(dateSplitted[0]);
  const monthIndex = parseInt(dateSplitted[1]) - 1;
  const day = parseInt(dateSplitted[2]);

  const keyDate = new Date(year, monthIndex, day, 0, 0, 0, 0).toDateString();

  if (keyDate === 'Invalid Date' || !keyDate) {
    return false;
  }

  const duration = durationInDaysBetweenTwoDates(dateNow, keyDate);

  return duration >= 0;
};

export const returnSecondsUntilDate = (deadline: string) => {
  const paytime = new Date(deadline).getTime();
  const timeNow = new Date().getTime();

  const secondsLeft = Math.floor((paytime - timeNow) / 1000);

  return secondsLeft > 0 ? secondsLeft : 0;
};

export const getFormatedStringHourMinutesFromSeconds = (secondsNumber: number) => {
  const minutes = Math.floor(secondsNumber / 60).toString();
  const seconds = secondsNumber % 60;
  let secondsString = seconds.toString();

  if (seconds <= 9) {
    secondsString = `0${seconds}`;
  }

  return `${minutes}:${secondsString}`;
};

export const formatTripTime = (tripTime: number): string => {
  const hours = tripTime / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rminutes !== 0 && rhours !== 0) {
    return rhours + 'h ' + rminutes + 'min';
  } else if (rhours === 0) {
    return rminutes + 'min';
  } else {
    return rhours + 'h';
  }
};

export const getTZBRequiredPreSaleDaysFromEnv = () => {
  const envRequiredPreSaleDays = env.REACT_APP_TZB_REQUIRED_PRE_SALE_DAYS ?? null;

  if (!envRequiredPreSaleDays) {
    console.error('The environment variable REACT_APP_TZB_REQUIRED_PRE_SALE_DAYS error.');
    return null;
  }
  const [daysBefore, daysAfter] = envRequiredPreSaleDays.split('|').map(Number);
  return areWeAfterTheDateTZBEnvDate() ? daysAfter : daysBefore;
};

export const setTzbOfferDescription = (t: TFunction) => {
  const requiredPreSaleDays = getTZBRequiredPreSaleDaysFromEnv();

  if (requiredPreSaleDays === null) return '';

  const description = (
    <span>
      {t('16018') as string} <b>{t('29521', {requiredPreSaleDays}) as string}</b>
    </span>
  );

  return description;
};

export const returnPaymentDateFormated = (date?: Date) => {
  if (!date) date = new Date();
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()} ${date.getHours() < 9 ? `0${date.getHours()}` : `${date.getHours()}`}:${date.getMinutes() < 9 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}:${date.getSeconds() < 9 ? `0${date.getSeconds()}` : `${date.getSeconds()}`}`;
};

export const returnSeatLogo = (placeTypeName: string, isContrastTheme?: boolean) => {
  const defaultProps = {
    'aria-label': placeTypeName,
    role: 'img',
  };

  switch (placeTypeName) {
    case 'Wagon dowolny':
    case 'Miejsce w dowolnym wagonie':
      return isContrastTheme ? (
        <SeatContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Seat style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Wagon przedziałowy':
    case 'Wagon z przedziałami':
      return isContrastTheme ? (
        <PrzedzialowyContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Przedzialowy fill="#142458" style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Wagon bezprzedziałowy':
    case 'Wagon bez przedziałów':
      return isContrastTheme ? (
        <BezprzedzialowyContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Bezprzedzialowy fill="#142458" style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Przedział dla osoby z dzieckiem do lat 6':
    case 'Osoba z dzieckiem':
      return isContrastTheme ? (
        <ParentWithChildContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <ParentWithChild style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Miejsce dla OzN - nie na wózku':
      return isContrastTheme ? (
        <OzNWithoutWheelchairContrast
          style={{
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
          {...defaultProps}
        />
      ) : (
        <OzNWithoutWheelchair
          fill="#142458"
          stroke="#142458"
          style={{
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
          {...defaultProps}
        />
      );
    case 'Miejsce przy stoliku':
    case 'Przy stoliku':
      return isContrastTheme ? (
        <SeatWithTableContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <SeatWithTable style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Miejsce dla osoby na wózku':
      return isContrastTheme ? (
        <WheelChairContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <WheelChair style={{marginRight: '10px'}} {...defaultProps} />
      );
    case 'Kuszeta 4-osobowa':
    case 'Kuszeta 6-osobowa':
    case 'Miejsce do leżenia':
      return isContrastTheme ? (
        <Sleep4Contrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Sleep4 style={{marginRight: '10px'}} {...defaultProps} />
      );
    // case "Cały przedział menedżerski":
    //     return <TLK {...defaultProps} />;
    case 'Miejsce dla podróżnego z rowerem':
      return isContrastTheme ? (
        <BikeContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Bike style={{marginRight: '10px'}} {...defaultProps} />
      );
    // case "Osoba z dzieckiem":
    //     return <TLK {...defaultProps} />;
    // case "Dla osoby z niepełnosprawnością":
    //     return <TLK {...defaultProps} />;
    case 'Strefa Ciszy':
      return isContrastTheme ? (
        <QuiteVagonContrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <QuiteVagon style={{marginRight: '10px'}} {...defaultProps} />
      );
    // case "Miejsce dla opiekuna osoby na wózku":
    //     return <TLK {...defaultProps} />;
    case 'Miejsce w strefie wygody':
      return isContrastTheme ? (
        <ComfortContrast
          style={{
            marginRight: '10px',
            width: '32px',
            height: '32px',
          }}
          {...defaultProps}
        />
      ) : (
        <Comfort
          style={{
            marginRight: '10px',
            width: '32px',
            height: '32px',
          }}
          {...defaultProps}
        />
      );
    case 'Single':
    case 'Triple':
    case 'Double':
      return isContrastTheme ? (
        <Sleep6Contrast style={{marginRight: '10px'}} {...defaultProps} />
      ) : (
        <Sleep6 style={{marginRight: '10px'}} {...defaultProps} />
      );
    default:
      return;
  }
};

export const returnSpecialSeatLogo = (placeTypeCode: number, isContrastTheme?: boolean) => {
  switch (placeTypeCode) {
    case 3:
      return isContrastTheme ? (
        <ParentWithChildContrast width={24} height={24} />
      ) : (
        <ParentWithChild width={24} height={24} />
      );
    case 4:
      return isContrastTheme ? (
        <OzNWithoutWheelchairContrast width={24} height={24} />
      ) : (
        <OzNWithoutWheelchair fill="#142458" stroke="#142458" width={24} height={24} />
      );
    case 6:
      return isContrastTheme ? <WheelChairContrast width={24} height={24} /> : <WheelChair width={24} height={24} />;
    case 24:
      return isContrastTheme ? <BikeContrast width={24} height={24} /> : <Bike width={24} height={24} />;
    case 52:
      return isContrastTheme ? <QuiteVagonContrast width={27} height={27} /> : <QuiteVagon width={27} height={27} />;
    case 53:
      return isContrastTheme ? (
        <OzNCaregiverContrast width={21} height={24} />
      ) : (
        <OzNCaregiver width={21} height={24} />
      );
    default:
      return;
  }
};

//atrybuty handlowe

export const returnMarketAttributeIcon = (
  str: string,
  arr: CommercialAttribute[],
  size: string = '20px',
  title?: string,
) => {
  const singleEl = arr?.filter((el) => el.symbol === str)[0];
  const url = singleEl?.plik?.match(/\w{2}/g) as unknown as string[];
  if (url) {
    const img = createElement('img', {
      src: `data:image/png;base64,${btoa(
        url
          .map(function (a) {
            return String.fromCharCode(parseInt(a, 16));
          })
          .join(''),
      )}`,
      title: title ? title : `${singleEl.opisPL}`,
      style: {height: `${size}`, marginRight: '5px'},
    });
    return img;
  }
};

export const returnCarrierLogo = (carrierName: string, theme?: Theme) => {
  switch (carrierName) {
    case 'EIC':
      return theme === 'contrast' ? (
        <EICContrast role="img" style={{marginBottom: '4px'}} aria-label="Express InterCity" />
      ) : (
        <EIC style={{marginBottom: '4px'}} role="img" aria-label="Express InterCity" />
      );
    case 'IC':
      return theme === 'contrast' ? (
        <ICContrast role="img" aria-label="InterCity" />
      ) : (
        <IC role="img" aria-label="InterCity" />
      );
    case 'EIP':
      return theme === 'contrast' ? (
        <EIPContrast role="img" aria-label="Express InterCity Premium" />
      ) : (
        <EIP role="img" aria-label="Express InterCity Premium" />
      );
    case 'TLK':
      return theme === 'contrast' ? (
        <TLKContrast role="img" aria-label="Twoje Linie Kolejowe" />
      ) : (
        <TLK role="img" aria-label="Twoje Linie Kolejowe" />
      );
    case 'EC':
      return theme === 'contrast' ? <ECContrast role="img" aria-label="EC" /> : <EC role="img" aria-label="EC" />;
    case 'EN':
      return theme === 'contrast' ? (
        <p style={{fontWeight: 700, lineHeight: '22px', color: '#F7F7F7'}} aria-label="EN">
          EN
        </p>
      ) : (
        <p style={{fontWeight: 700, lineHeight: '22px'}} aria-label="EN">
          EN
        </p>
      );
    default:
      return theme === 'contrast' ? (
        <ICContrast role="img" aria-label="InterCity" />
      ) : (
        <IC role="img" aria-label="InterCity" />
      );
  }
};

export const selectCodeToSave = (station: Station) => {
  if (station.kod) {
    return station.kod;
  } else if (!station.kod && station.kodEVA) {
    return station.kodEVA;
  } else if (!station.kod && !station.kodEVA && station.kodEPA) {
    return station.kodEPA;
  }
};

export const returnStationByCode = (code: number, arr: Station[]): string => {
  const station = arr.find((el) => code === el.kod);
  if (station) return station.nazwa;

  const stationEVA = arr.find((el) => code === el.kodEVA);

  if (stationEVA) return stationEVA.nazwa;

  const stationEPA = arr.find((el) => code === el.kodEPA);
  return stationEPA ? stationEPA.nazwa : '';
};

export const returnFullStationByCode = (code: number, arr: Station[]) => {
  const station = arr.filter((el) => code === el.kodEVA);
  return !!station.length ? station[0] : null;
};

export const saveChoiceOfPlaceMobileCache = (cache: {
  connections?: IConnectionSearchResultsList[];
  prices?: IPriceCheckResponse[];
  itemOffer?: number[];
  exchangeMode?: boolean;
  stations?: IConnectionSearchTrainDetails[][];
}) => {
  const chopmCache = window.localStorage.getItem('chopmCache');

  if (chopmCache) window.localStorage.removeItem('chopmCache');

  window.localStorage.setItem('chopmCache', JSON.stringify(cache));
};

export const clearChoiceOfPlaceMobileCache = () => {
  const chopmCache = window.localStorage.getItem('chopmCache');

  if (chopmCache) {
    window.localStorage.removeItem('chopmCache');
  }
};

export const capitalizeFirst = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const lowerFirst = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1);
};

export const getPreferredPlaceName = (placeCode: number, placeTypes: [] | PlaceType[]): string => {
  const find = placeTypes.find((el) => el.kod === placeCode);
  if (find) {
    const pl = find.opisy.find((el2) => el2.jezyk === i18n.language.toUpperCase());
    if (pl) return pl.nazwa;
  }
  return '';
};

export const getFormattedTime = (hours: number, minutes: number) =>
  `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

export const formatNumberWithLeadingZero = (number: number): string => number.toString().padStart(2, '0');

export const getDiscountDescriptionName = (
  discountCode: number | null | undefined,
  shopDiscounts: ShopDiscount[],
): string | undefined => {
  return shopDiscounts
    .find((discount) => discount.kodZakupowy === discountCode)
    ?.opisy.find((description) => description.jezyk === i18n.language.toUpperCase())?.nazwa;
};

export const getShopDiscountFromList = (
  code: number | null,
  shopDiscounts: ShopDiscount[],
): ShopDiscount | undefined => {
  if (shopDiscounts) return shopDiscounts.find((el) => el?.kodZakupowy === code);
};

export const formatPrice = (t: TFunction, price: number, showZeroPrice = false): string => {
  if (!showZeroPrice && price === 0) return t('17065');
  const fixedPrice = (price / 100).toFixed(2).replace('.', ',');
  return fixedPrice + ' ' + t('24086');
};

export const formatPriceWithZero = (t: TFunction, price: number): string => {
  if (price === 0) return `0,00 ${t('24086')}`;
  const fixedPrice = (price / 100).toFixed(2).replace('.', ',');
  return fixedPrice + ' ' + t('24086');
};

export const getTicketPriceWithReservation = (ticket: IDownloadTicketResponseSingleTicket) => {
  return `${((ticket?.cenaRezerwacja + ticket?.cenaBilet) / 100).toFixed(2).replace('.', ',')} ${t('24086')}`;
};

export const getPKpOfferCode = (name: string) => {
  switch (name) {
    case 'familyTicket':
      return 4;
    case 'bigFamily':
      return 15;
    case 'cheaperWithRelatives':
      return 14;
    default:
      return null;
  }
};

export const getActiveOffer = (pkpOffers: IPkpOffers): string => {
  if (pkpOffers.cheaperWithRelatives && pkpOffers.familyTicket) return 'familyTicket';
  if (pkpOffers.bigFamily) return 'bigFamily';
  if (pkpOffers.cheaperWithRelatives) return 'cheaperWithRelatives';
  if (pkpOffers.familyTicket) return 'familyTicket';

  return '';
};

export const returnOfferName = (t: TFunction, pkpOffers: IPkpOffers): string => {
  if (pkpOffers.bigFamily) return t('16012');
  if (pkpOffers.cheaperWithRelatives) return t('16016');
  if (pkpOffers.familyTicket) return t('16008');

  return '';
};

export const returnOfferStringFromCode = (offerCode: number): string => {
  if (offerCode === 15) return 'bigFamily';
  if (offerCode === 14) return 'cheaperWithRelatives';
  if (offerCode === 4) return 'familyTicket';

  return '';
};

export const returnOfferNameFromCode = (t: TFunction, offerCode: number): string => {
  if (offerCode === 15) return t('16012');
  if (offerCode === 14) return t('16016');
  if (offerCode === 4) return t('16008');

  return '';
};

export const getSeasonalOfferName = (offerCode: number, seasonalOffers: SeasonalOffer[]) => {
  return seasonalOffers.find((item) => item.kod === offerCode)?.nazwa ?? 'brak';
};

export const returnOfferNameFromOffersModalError = (t: TFunction, error: string) => {
  if (error.toLowerCase().includes('taniej z bliskimi')) return ` ${t('16016')}`;
  if (error.toLowerCase().includes('duża rodzina')) return ` ${t('16012')}`;
  if (error.toLowerCase().includes('bilet rodzinny')) return ` ${t('16008')}`;
  return '';
};

export const returnDiscountName = (discountCode: number, shopDiscounts: ShopDiscount[]) => {
  const number = shopDiscounts.find((x) => x?.kodZakupowy === discountCode);
  const name = number?.opisy.find((x) => x.jezyk === i18n.language.toUpperCase())?.nazwa ?? '';

  return name;
};

export const returnDiscountPercentageAndName = (discountCode: number, shopDiscounts: ShopDiscount[]) => {
  const discount = shopDiscounts.find((x) => x?.kodZakupowy === discountCode);

  const name = discount?.opisy.find((x) => x.jezyk === i18n.language.toUpperCase())?.nazwa ?? '';
  const percentage = discount?.opisy[0]?.informacja?.split('<br>')[0]?.slice(-4)?.replace('-', '') ?? '';
  return `${percentage.trim() !== '0%' ? percentage.trim() : ''} ${name}`;
};

export const returnPlaceNumberAndName = (t: TFunction, wagonNr: number, miejsceNr: number, usytowanieNazwa: string) => {
  if (wagonNr !== 0 && miejsceNr !== 0) {
    return `${t('21001')} ${wagonNr}, ${t('21002')} ${miejsceNr}, ${usytowanieNazwa}`;
  }
  return t('25003');
};

export const makeBetweenStationArr = (station: string[] | string) => {
  const makeStringToArr = (str: string) => {
    const newStr = str.split(',');
    return {
      stacjaPrzez: parseInt(newStr[0]),
      czasNaPrzesiadke: parseInt(newStr[1]),
    };
  };
  if (typeof station === 'string') {
    return [makeStringToArr(station)];
  } else {
    const newArr = [] as unknown as {
      stacjaPrzez: number;
      czasNaPrzesiadke: number;
    }[];
    station.forEach((el) => {
      newArr.push(makeStringToArr(el));
    });
    return newArr;
  }
};

export const discountListLanguageMapper = (lang: string) => {
  switch (lang) {
    case 'pl': {
      return 0;
    }
    case 'en': {
      return 1;
    }
    case 'de': {
      return 2;
    }
    case 'ru': {
      return 3;
    }
    case 'ua': {
      return 4;
    }
    default: {
      return 0;
    }
  }
};

export const getTicketLabels = (
  tickets: INewTicket[],
  isAdult: boolean,
  shopDiscounts: ShopDiscount[],
  t: TFunction,
  shortLabel?: boolean,
  withPercentage?: boolean,
) => {
  const labelsMap: Map<number, string> = new Map();
  tickets.forEach((el) => {
    const repeated = tickets.filter((el1) => el1.discountCode === el.discountCode);
    const discount = el.discountCode ? getShopDiscountFromList(el.discountCode, shopDiscounts) : undefined;
    const percentage =
      discount?.opisy[discountListLanguageMapper(i18n.language)]?.informacja
        ?.split('<br>')[0]
        ?.slice(-4)
        ?.replace('-', '') ?? '';
    labelsMap.set(
      el.discountCode ?? 0,
      `${!shortLabel ? `${isAdult ? t('22046', {wait: true}) : t('22047', {wait: true})}: ` : ''}${
        repeated.length
      }x ${withPercentage && percentage.trim() !== '0%' ? percentage.trim() : ''} ${
        discount ? discount.opisy[discountListLanguageMapper(i18n.language)].nazwa : t('13017')
      }`,
    );
  });
  return Array.from(labelsMap.values());
};

export const getTicketLabelsFromDiscounts = (
  tickets: Discount[],
  isAdult: boolean,
  shopDiscounts: ShopDiscount[],
  t: TFunction,
  shortLabel?: boolean,
) => {
  const labelsMap: Map<number, string> = new Map();
  tickets.forEach((el) => {
    const repeated = tickets.filter((el1) => el1.code === el.code);
    const discount = el.code ? getShopDiscountFromList(el.code, shopDiscounts) : undefined;
    labelsMap.set(
      el.code || 0,
      `${!shortLabel ? `${isAdult ? t('22046') : t('22047')}: ` : ''}${
        repeated.length
      }x ${discount ? discount.opisy[discountListLanguageMapper(i18n.language)].nazwa : t('13017')}`,
    );
  });
  return Array.from(labelsMap.values());
};

export const returnArrAdultInRequestForm = (
  choosenOfferCode: number,
  verifiedOfferCode: number,
  adultTickets: INewTicket[],
): IPriceCheckTraveler[] => {
  const defaultArrAdults = [...adultTickets].map((x) => {
    if (x.discountCode === 1650) return {kodZakupowyZnizki: 1010} as IPriceCheckTraveler;
    return {
      kodZakupowyZnizki: x.discountCode,
    } as IPriceCheckTraveler;
  });

  if (choosenOfferCode !== verifiedOfferCode) {
    if (verifiedOfferCode === 14 || verifiedOfferCode === 1) {
      return defaultArrAdults;
    }
  }

  return [...adultTickets].map((x) => {
    return {
      kodZakupowyZnizki: x.discountCode,
    } as IPriceCheckTraveler;
  });
};

export const filterCountries = (value: string, countryList: InvoiceCountryOption[]) => {
  const filtered = filterCountriesOptions(countryList, value);
  const firstLetterMatch = filtered.filter((el) => {
    return removePolishLetters(value) === removePolishLetters(el.namePL).slice(0, value.length);
  });
  const others = filtered.filter((el) => {
    return removePolishLetters(value) !== removePolishLetters(el.namePL).slice(0, value.length);
  });
  return [...firstLetterMatch, ...others];
};

export const filterCountriesOptions = (options: InvoiceCountryOption[], value: string) => {
  const newOptions: InvoiceCountryOption[] = [];
  options.forEach((el: InvoiceCountryOption) => {
    if (
      removePolishLetters(el.namePL).includes(value.toLowerCase()) ||
      el.namePL.toLowerCase().includes(value.toLowerCase())
    )
      newOptions.push(el);
  });
  return newOptions;
};

export const getTransactionNumbers = (
  ticketFrom: IGenerateTicketResponse | null,
  ticketTo: IGenerateTicketResponse | null,
  additionalTicketsFrom: IGenerateAdditionalTicketsResponse | null,
  additionalTicketsTo: IGenerateAdditionalTicketsResponse | null,
  ecoOffsetDiscount: IGenerateAdditionalFeeResponse | null,
): number[] => {
  let arr = [] as number[];
  if (ticketFrom) ticketFrom.transakcjeNr.forEach((el) => arr.push(el.transakcjaNr));
  if (ticketTo) ticketTo.transakcjeNr.forEach((el) => arr.push(el.transakcjaNr));
  if (additionalTicketsFrom) arr = [...arr, ...additionalTicketsFrom.transakcjeNr.map((el) => el.transakcjaNr)];
  if (additionalTicketsTo) arr = [...arr, ...additionalTicketsTo.transakcjeNr.map((el) => el.transakcjaNr)];
  if (ecoOffsetDiscount) arr = [...arr, ...ecoOffsetDiscount.transakcjeNr.map((el) => el.transakcjaNr)];
  return arr;
};

export const downloadPdf = (biletSeria: string, biletNr: number) => {
  const pdf = async () =>
    await apiPayment.downloadTicketAsPdf(biletSeria, biletNr).then((res) => {
      if (res && res.obrazPDF) {
        const blob = new Blob([new Uint8Array(res.obrazPDF).buffer], {
          type: 'application/pdf',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `bilet_${biletSeria}_${biletNr}.pdf`;
        link.click();
      }
    });

  pdf();
};

export const getRailwayCompanyCode = (discount: INewTicket): number => {
  if (isCodeRailwayEmployeeRelated(discount.discountCode)) {
    return discount?.railwayEmployee?.employerCode || 321;
  }
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getRailwayEmployeeId = (discount: INewTicket): string | null => {
  // Uncomment to enable employee id validation
  // if (isCodeRailwayEmployeeRelated(discount.discountCode)) {
  //     return (
  //         discount?.railwayEmployee?.employeeId
  //             .trim()
  //             .replace(/\s/g, "")
  //             .replace("/", "")
  //     ) || "";
  // }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const romanToArabic: any = {
  I: 1,
  V: 5,
  X: 10,
  L: 50,
  C: 100,
  D: 500,
  M: 1000,
};

export const convertRomanNumerals = (romanNumeral: string) => {
  //Some of platforms like Lublin have a and b in numbers.
  const indexOfExtraCharA = romanNumeral.indexOf('a');
  const indexOfExtraCharB = romanNumeral.indexOf('b');
  let extraChar = '';

  if (indexOfExtraCharA !== -1 || indexOfExtraCharB !== -1) {
    extraChar = romanNumeral.substring(indexOfExtraCharA > -1 ? indexOfExtraCharA : indexOfExtraCharB, 2);
    romanNumeral = romanNumeral.replace(/[ab]/g, '');
  }
  let result = 0;
  for (let i = 0; i < romanNumeral.length; i++) {
    if (romanNumeral[i] === 'I' && romanNumeral[i + 1] === 'V') {
      result += 4;
      i++;
    } else if (romanNumeral[i] === 'I' && romanNumeral[i + 1] === 'X') {
      result += 9;
      i++;
    } else if (romanNumeral[i] === 'X' && romanNumeral[i + 1] === 'L') {
      result += 40;
      i++;
    } else if (romanNumeral[i] === 'X' && romanNumeral[i + 1] === 'C') {
      result += 90;
      i++;
    } else if (romanNumeral[i] === 'C' && romanNumeral[i + 1] === 'D') {
      result += 400;
      i++;
    } else if (romanNumeral[i] === 'C' && romanNumeral[i + 1] === 'M') {
      result += 900;
      i++;
    } else {
      result += romanToArabic[romanNumeral[i]];
    }
  }
  return result + extraChar;
};

export const getComparableDateFormat = (date: string) => {
  if (date?.includes('CEST')) {
    return new Date(date?.replace('CEST', 'GMT+0200 (czas środkowoeuropejski letni)'));
  } else if (date?.includes('CET')) {
    return new Date(date?.replace('CET', 'GMT+0100 (czas środkowoeuropejski standardowy)'));
  } else return new Date();
};

export const findEVACode = (id: number, stationsList: Station[]) => {
  return stationsList?.filter((el) => id === el.kod)[0]?.kodEVA;
};

export const getTicketSource = (ticketSeries: string) => {
  switch (ticketSeries) {
    case 'WH':
      return 'EIC2';
    case 'WN':
      return '29266';
    case 'WE':
      return 'EIC1';
    default:
      return '';
  }
};

export const getPolishWordForm = (number: number, singular: string, plural: string, pluralGenitive: string) => {
  if (number === 1) return singular;
  const rest10 = number % 10;
  const rest100 = number % 100;
  if (rest10 > 4 || rest10 < 2 || (rest100 < 15 && rest100 > 11)) return pluralGenitive;
  return plural;
};

export const isInvoiceIssuable = (purchaseDateStr: string): boolean => {
  const purchaseDate = moment(purchaseDateStr);
  const deadline = purchaseDate.add(1, 'month').set('date', 15);
  return !moment().isAfter(deadline);
};

export const isSeasonalTicketReturnable = (
  validFromDateString: string,
  ticketCode: number,
  seasonalOffer: SeasonalOffer[],
): boolean => {
  const offerFromTicket = seasonalOffer.find((offer: SeasonalOffer) => offer.kod === ticketCode);
  const returnConditions = offerFromTicket?.warunkiZwrotu;
  const validFromDate = parseISO(validFromDateString);
  if (!returnConditions) return false;
  const deadline = addMinutes(addDays(validFromDate, returnConditions?.[0].maxdni), returnConditions?.[0].maxminut);

  const now = new Date();
  return isAfter(now, deadline);
};

export const modifySingleChars = (str: string) =>
  str.replace(/ ([a-zA-Z]) /g, ' $1\u00A0').replace(/ ([(]w) /g, ' $1\u00A0');

const POLISH_STATION_CODE_PREFIX = '51';

const checkForPolishStationPrefix = (code: number) => String(code).startsWith(POLISH_STATION_CODE_PREFIX);

const getForeignStationsList = (stationsList: Station[]) =>
  stationsList.filter((el) => !checkForPolishStationPrefix(el.kodEVA || el.kodEPA));

export const checkIfForeignStation = (code: number, stationsList: Station[]) =>
  getForeignStationsList(stationsList).filter((el) => code === (el.kodEVA || el.kodEPA)).length > 0;

export const makeTicketsArrFromQuery = (ticketQuery: string): INewTicket[] => {
  const arr: INewTicket[] = [];
  const ticketList = ticketQuery.split(';');
  ticketList.forEach((ticket) => {
    const split = ticket.split(',');
    if (!!split[0]) {
      const newTicket: INewTicket = {
        discountCode: parseInt(split[0]),
        ticketTypeCode: 1,
        ...(!!split[1] &&
          !!split[2] && {
            railwayEmployee: {
              employeeId: split[1],
              employerCode: parseInt(split[2]),
            },
          }),
      };
      arr.push(newTicket);
    }
  });
  return arr;
};

export const makeTicketsQuery = (tickets: INewTicket[]) => {
  return tickets
    .map(
      (el) =>
        `${el.discountCode ?? 0}${el.railwayEmployee ? `,${el.railwayEmployee.employeeId},${el.railwayEmployee.employerCode}` : ''}`,
    )
    .join(';');
};

export const areEmptyViaStationsOnSearch = (
  tripBack: boolean,
  fromData: ISearchConnectionFormData,
  backData: ISearchConnectionFormData,
) => {
  if (!tripBack && !!fromData?.viaStations.length) {
    const find = fromData?.viaStations.find((el) => el?.stacjaPrzez === 0);
    return !!find;
  }
  if (tripBack && (!!fromData?.viaStations?.length || !!backData?.viaStations?.length)) {
    const findFrom = fromData?.viaStations?.find((el) => el?.stacjaPrzez === 0);
    const findTo = backData?.viaStations?.find((el) => el?.stacjaPrzez === 0);
    return !!(findFrom || findTo);
  }
  return false;
};

export const isTechnologicalBreakNow = (startTime: string, endTime: string) =>
  new Date().getTime() >= new Date(startTime).getTime() && new Date().getTime() <= new Date(endTime).getTime();

export const isPlaceCouchette = (seatCode: number): boolean => {
  return !!seatCode && (seatCode === 13 || seatCode === 14);
};

export const isPlaceSleeper = (seatCode: number): boolean => {
  return !!seatCode && (seatCode === 101 || seatCode === 102 || seatCode === 103);
};

export const hasForbiddenCharacters = (input: string) =>
  input.match(/[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]/g)?.length !== input.length;

export const isDateAfter = (dateToCompare: string, otherDate: string) => {
  return moment(dateToCompare).startOf('day').isAfter(moment(otherDate).startOf('day'));
};

export const checkIfGuest = () => {
  const storage = window?.sessionStorage.getItem('eic2Token');
  if (storage && storage !== 'undefined') {
    return JSON.parse(storage).scope === 'guest';
  }
  return false;
};

export const filterPricesByClass = (prices: IPriceCheckResponse, classFilter?: number) => {
  const filteredPrices = prices.ceny.filter(({klasa}) => {
    if (classFilter === undefined || classFilter === 1) return true;
    return klasa === 2;
  });
  return {...prices, ceny: filteredPrices};
};

export const returnStationName = (stationCode: number, seasonal: boolean, stationsList: Station[]): string =>
  !!seasonal && stationCode === 0 ? t('11095') : returnStationByCode(stationCode, stationsList);

export const returnPassengersCount = (
  tickets50: INewTicket[] | undefined,
  tickets100: INewTicket[],
  t: TFunction,
): string => {
  const ticketsLength = tickets50 ? tickets100.length + tickets50.length : tickets100.length;
  return `${ticketsLength}${ticketsLength > 1 ? t('29289') : t('29290')}`;
};

export const getTimeFromDate = (date: string): string => moment(date).format('HH:mm');

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getLocationInDiferentLanguage = (data: PlacePosition[], targetName: string): string => {
  let findObj: PlacePosition | null = null;
  for (const item of data) {
    if (item.opisy) {
      for (const opis of item.opisy) {
        if (opis.nazwa === targetName) {
          findObj = item;
          break;
        }
      }
    }
  }
  const langId = discountListLanguageMapper(i18n.language);
  return findObj ? findObj.opisy[langId].nazwa : '';
};

export const languageSetterForDateFns = (): Locale => {
  const language = i18n.language;
  switch (language) {
    case 'pl':
      return pl;
    case 'en':
      return enGB;
    case 'de':
      return de;
    case 'ua':
      return pl;
    case 'ru':
      return ru;
    default:
      return pl;
  }
};

export const translateSeasonalTicketsOffersNames = (descriptions: ISeasonalTicketsOffersNames[]): string => {
  const description = descriptions.find((el: ISeasonalTicketsOffersNames) => el.jezyk === i18n.language.toUpperCase());
  return description ? description.opis : '';
};

export const filterConnectionsByMaxDistance = (
  maxDistance: number | undefined,
  connections: IConnectionSearchResultsList[],
  onFilteredConnections: () => void,
) => {
  if (maxDistance === undefined || maxDistance === 0) return connections;
  const filteredConnections = connections.filter(
    (connection) => connection.ceny.kilometry <= maxDistance + SEASON_TICKET_DISTANCE_MARGIN,
  );

  if (filteredConnections.length < connections.length) onFilteredConnections();
  return filteredConnections;
};

export const returnTimeDiff = (date1: string, date2: string): number => {
  const firstDate = moment(date1);
  const secondDate = moment(date2);

  return moment.duration(firstDate.diff(secondDate)).asMinutes() ?? 0;
};

export const checkIfFirstDateIsPastSecond = (firstDate: string, secondDate: string) => {
  const duration = returnTimeDiff(firstDate, secondDate);

  if (duration > 0) {
    return true;
  }

  return false;
};

export const returnAdditionalTypeTicketData = (t: TFunction, type: number, theme: boolean, isMobile: boolean) => {
  if (type === ticketKinds.DOG || type === ticketKinds.GUIDE_DOG) {
    return {
      image: theme ? (isMobile ? DoggoContrastMobile : DoggoContrast) : isMobile ? DoggoMobile : Doggo,
      nazwa: `${t('29152')} ${type === 22 ? 'p.' : ''}`,
    };
  } else if (type === ticketKinds.LUGGAGE) {
    return {
      image: theme ? (isMobile ? LuggageContrastMobile : LuggageContrast) : isMobile ? LuggageMobile : Luggage,
      nazwa: t('19007'),
    };
  } else if (type === ticketKinds.BICYCLE || type === ticketKinds.INTERNATIONAL_BICYCLE) {
    return {
      image: theme ? (isMobile ? BicycleContrastMobile : BicycleContrast) : isMobile ? BicycleMobile : Bicycle,
      nazwa: t('29151'),
    };
  }
};

export const isTodayBigger = (): boolean => {
  const today = new Date();
  return env.REACT_APP_ECO_OFFSET_START ? isAfter(today, new Date(env.REACT_APP_ECO_OFFSET_START)) : false;
};

export const isStorageDataValid = (data: DataContext) => {
  for (const [value] of Object.entries(data)) {
    if (value === null) {
      return false;
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return false;
      }
    } else if (typeof value === 'object') {
      if (Object.keys(value).length === 0) {
        return false;
      }
    }
  }
  return true;
};
