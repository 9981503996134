/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {useState, FC, useEffect, useContext} from 'react';
import {Element as ScrollTarget} from 'react-scroll/modules';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UniversalHeader from 'components/common/UniversalHeader';
import ConfirmButton from 'components/common/ConfirmButton';
import ExpandableWindow from 'components/common/ExpandableWindow';
import BlueHeader from 'components/common/BlueHeader';
import TicketHandler from './components/TicketHandler';
import Ticket from './components/Ticket';
import DiscountsModal from './components/DiscountsModal/DiscountsModal';
import ErrorYellow from 'components/common/ErrorYellow';
import ErrorBlue from 'components/common/ErrorColouredLabel';

import {ICheckDiscountsRequest, INewTicket, IPriceCheckTraveler} from 'api/sprzedaz/interfaces';
import {ActiveTicket, InitialCount} from '../../PurchasePathDesktop/SetDiscounts/SetDiscounts';
import {
  handleCancelDiscounts,
  hasNotSelectedDiscount,
  isDiscountQuantityValid,
  validateAdditionalTicketsQQ,
} from '../../PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';
import {TicketContext} from 'Utils/TicketContext';

import S from './components/DiscountsModal/DiscountsModal.module.scss';
import {ReactComponent as ExpPoin} from 'assets/iconExpPoint.svg';
import {SearchContext} from 'Utils/SearchContext';
import saleApi from 'api/sprzedaz/repository';
import {getPKpOfferCode, getActiveOffer, setTzbOfferDescription} from 'Utils/commonFunctions';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';

interface Props {
  adultCount: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any;
  childrenCount: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any;
  modalModeOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalModeClose?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetDiscountFunc?: any;
  exchangeMode?: boolean;
  buyerProfilesMode?: boolean;
}

const Tickets: FC<Props> = ({
  adultCount,
  changeAdult,
  childrenCount,
  changeChildren,
  modalModeOpen,
  modalModeClose,
  resetDiscountFunc,
  exchangeMode,
  buyerProfilesMode,
}) => {
  const {t} = useTranslation();
  const [initialCount, setInitialCount] = useState<InitialCount>({
    adultCount,
    childrenCount,
  });

  const [openDiscounts, setOpenDiscounts] = useState(false);
  const [activeTicket, setActiveTicket] = useState<ActiveTicket>({
    index: 0,
    isAdult: true,
  });
  const [discountQuantityError, setDiscountQuantityError] = useState(false);
  const [checkDiscountsError, setCheckDiscountsError] = useState<string>('');
  const {
    ticketQQ,
    setTicketQQ,
    pkpOffers,
    verifyTicketsAndApplyOffer,
    verifyDiscountCodesWithOffers,
    offersModalInfo,
    resetOfferModal,
    offersModalError,
    resetErrorModal,
    addTicketContextFrom,
    addTicketContextTo,
    setAddTicketContextFrom,
    setAddTicketContextTo,
  } = useContext(TicketContext);
  const {searchFormFrom} = useContext(SearchContext);

  useEffect(() => {
    if (window.localStorage.getItem('openDiscountModal')) {
      window.localStorage.removeItem('openDiscountModal');
    }
    return () => {
      localStorage.removeItem('queryInitlialData');
    };
  }, []);

  useEffect(() => {
    if (modalModeOpen) {
      setCheckDiscountsError('');
      setInitialCount({adultCount, childrenCount});
      resetOfferModal();
    }
    if (modalModeClose && !modalModeOpen) {
      modalModeClose(false);
    }
  }, [modalModeOpen]);

  useEffect(() => {
    if (buyerProfilesMode) return;

    setTicketQQ({
      ...ticketQQ,
      ticket100: adultCount.length,
      ticket50: childrenCount.length,
    });

    if (!isDiscountQuantityValid(adultCount, childrenCount)) {
      !discountQuantityError && setDiscountQuantityError(true);
    } else {
      discountQuantityError && setDiscountQuantityError(false);
    }

    if (exchangeMode) {
      verifyDiscountCodesWithOffers([...adultCount, ...childrenCount]);
    } else {
      verifyTicketsAndApplyOffer(`${searchFormFrom?.date} ${searchFormFrom?.time}`);
    }
  }, [adultCount, childrenCount]);

  const validateDiscounts = () => {
    setCheckDiscountsError('');

    const adultDiscounts =
      [...adultCount].map((x) => {
        return {kodZakupowyZnizki: x.discountCode} as IPriceCheckTraveler;
      }) ?? [];
    const childDiscounts =
      [...childrenCount].map((x) => {
        return {kodZakupowyZnizki: x.discountCode} as IPriceCheckTraveler;
      }) ?? [];
    const offer = getActiveOffer(pkpOffers);
    const offerCode = getPKpOfferCode(offer) ?? 1;

    const checkDiscountsReq: ICheckDiscountsRequest = {
      urzadzenieNr: 0,
      metoda: '',
      jezyk: i18n.language.toUpperCase(),
      ofertaKod: offerCode,
      podrozni: [...adultDiscounts, ...childDiscounts],
    };

    const checkDiscounts = async () => {
      const res = await saleApi.checkDiscounts(checkDiscountsReq);

      if (res && res.komunikatKod !== 0) {
        setCheckDiscountsError(res.komunikatNazwa);
        return;
      }

      validateAdditionalTicketsQQ(
        adultCount.length + childrenCount.length,
        addTicketContextFrom,
        addTicketContextTo,
        setAddTicketContextFrom,
        setAddTicketContextTo,
      );

      resetErrorModal();
      modalModeClose(false);
      resetDiscountFunc && resetDiscountFunc();
    };

    checkDiscounts();
  };

  const onActiveTicketChange = (index: number, isAdult: boolean) => {
    setOpenDiscounts(true);
    setActiveTicket({index, isAdult});
  };

  const handleCloseButton = () => {
    modalModeClose && modalModeClose(false);
  };
  const isAnyTicketSelected = ticketQQ.ticket100 || ticketQQ.ticket50;
  const getButtonText = () => {
    if (!isAnyTicketSelected) return t('29448');
    if (hasNotSelectedDiscount(childrenCount)) return t('13018');
    return t('29177');
  };
  return (
    <>
      {exchangeMode === undefined && (
        <button
          type="button"
          className={S.changeBtnMobile}
          aria-label={t('29446')}
          onClick={() => modalModeClose(true)}
        >
          {t('13004')}
          <span className="hiddenLabel"> {t('29447')}</span>
        </button>
      )}
      {modalModeOpen && (
        <div className={S.curtain}>
          <Box>
            <UniversalHeader
              title={t('12001')}
              isMainHeader
              goBack={() => handleCancelDiscounts(initialCount, changeAdult, changeChildren, handleCloseButton)}
            />
            <Box
              sx={{
                fontFamily: 'Montserrat',
                width: '100%',
                height: '13.6rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '2.4rem',
                mt: '3.0rem',
                mb: '3.5rem',
              }}
            >
              <TicketHandler
                adultCount={adultCount}
                changeAdult={changeAdult}
                childrenCount={childrenCount}
                changeChildren={changeChildren}
                exchangeMode={exchangeMode}
              />
            </Box>
            <ScrollTarget name="discountQuantityError">
              {discountQuantityError && <ErrorYellow displayText={t('13009')} />}
            </ScrollTarget>
            {hasNotSelectedDiscount(childrenCount) && (
              <Box
                sx={{
                  width: '90%',
                  fontFamily: 'Montserrat',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ExpPoin style={{marginRight: '1.0rem'}} />
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '700',
                    fontSize: '1.2rem',
                    color: '#EE0039',
                  }}
                >
                  {t('29445')}
                </Typography>
              </Box>
            )}
            <Box component="ul" sx={{listStyle: 'none', padding: 0}}>
              {adultCount.map((el, index) => (
                <li key={index} onClick={() => onActiveTicketChange(index, true)}>
                  <Ticket index={index + 1} isAdult={true} discountCode={el.discountCode} />
                </li>
              ))}
            </Box>
            <Box
              sx={{mt: childrenCount.length !== 0 ? '3.2rem' : '1.6rem', listStyle: 'none', padding: 0}}
              component="ul"
            >
              {childrenCount.map((el, index) => (
                <li key={index} onClick={() => onActiveTicketChange(index, false)}>
                  <Ticket index={index + 1} isAdult={false} discountCode={el.discountCode} />
                </li>
              ))}
            </Box>
            {!buyerProfilesMode && (
              <div className={S.pkpOffers}>
                <Box sx={{m: '1.6rem 0.8rem 0'}}>
                  <BlueHeader title={t('16007')} />
                </Box>
                {offersModalInfo && offersModalInfo.length > 0 && <ErrorBlue errorsTextArr={offersModalInfo} />}
                <ScrollTarget name="discountQuantityError">
                  {offersModalError && offersModalError.length > 0 && <ErrorYellow errorsTextArr={offersModalError} />}
                </ScrollTarget>
                <ExpandableWindow
                  title={t('16008')}
                  train={t('16009')}
                  details={t('16010')}
                  option={t('16011')}
                  promoName="familyTicket"
                />
                <ExpandableWindow
                  title={t('16012')}
                  train={t('16013')}
                  details={t('16014')}
                  option={t('16015')}
                  promoName="bigFamily"
                />
                <ExpandableWindow
                  title={t('16016')}
                  train={t('16017')}
                  details={setTzbOfferDescription(t)}
                  option={t('16020')}
                  promoName="cheaperWithRelatives"
                />
              </div>
            )}
            <div className={S.confirmBtnWrapper}>
              {checkDiscountsError && (
                <div style={{marginTop: '2.0rem'}}>
                  <ErrorYellow displayText={checkDiscountsError} />
                </div>
              )}
              <ConfirmButton
                text={getButtonText()}
                disabled={discountQuantityError || hasNotSelectedDiscount(childrenCount) || !isAnyTicketSelected}
                execute={validateDiscounts}
              />
            </div>
          </Box>
        </div>
      )}
      {openDiscounts && (
        <DiscountsModal
          adultCount={adultCount}
          childrenCount={childrenCount}
          changeAdult={changeAdult}
          changeChildren={changeChildren}
          setOpen={setOpenDiscounts}
          activeTicket={activeTicket}
        />
      )}
    </>
  );
};
export default Tickets;
