import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {ReactComponent as Gear} from 'assets/gearDesktop.svg';
import {ReactComponent as GearContrast} from 'assets/contrastGearDesktop.svg';

import {useStyledThemeState} from 'common/theme';
import {IAmenities} from 'api/pociagi/interfaces';
import AmenietiesModal from 'components/pages/PurchasePath/AmenitiesModal';

import S from './AmenitiesMobile.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  amenities: IAmenities;
  setAmenities: (e: IAmenities) => void;
}

const AmenitiesMobile: FC<Props> = ({amenities, setAmenities}) => {
  const {t} = useTranslation();

  const {isContrastTheme} = useStyledThemeState();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: '60px',
          width: '100%',
          borderRadius: '6px',
          padding: '23px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className={S.container}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            fontWeight: '700',
            fontSize: '1.2rem',
            textTransform: 'lowercase',
            color: 'colors.navy',
          }}
        >
          {isContrastTheme ? <GearContrast /> : <Gear />}
          <Box>{t('15001')}</Box>
        </Box>
        <button type="button" className={S.changeBtnMobile} onClick={handleClickOpen}>
          {t('13004')}
          <span className="hiddenLabel"> {t('15001')}</span>
        </button>
      </Box>
      {open && <AmenietiesModal open={open} setOpen={setOpen} amenities={amenities} setAmenities={setAmenities} />}
    </>
  );
};
export default AmenitiesMobile;
