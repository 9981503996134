import {FC, useState, useEffect, useMemo} from 'react';
import {ISearchConnectionFormData} from '../../../../../interfaces/forms';
import {INewTicket} from 'api/sprzedaz/interfaces';
import {useStyledThemeState} from 'common/theme';
import ConfirmButton from 'components/common/ConfirmButton';
import TicketsNewDesktop from 'components/pages/PurchasePath/PurchasePathDesktop/TicketsNewDesktop';
import AmenitiesNewDesktop from 'components/pages/PurchasePath/PurchasePathDesktop/AmenitiesNewDesktop';
import useSearchLogic from 'components/pages/PurchasePath/useSearchLogic';
import AmenitiesMobile from 'components/pages/PurchasePath/PurchasePathMobile/Amenities/AmenitiesMobile';
import Tickets from 'components/pages/PurchasePath/PurchasePathMobile/Tickets';

import style from './BuyerProfile.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import {ReactComponent as Arrows} from 'assets/orangeHorizontalArrows.svg';
import {ReactComponent as ContrastArrows} from 'assets/contrastHorizontalArrows.svg';
import {ReactComponent as TicketIcon} from 'assets/ticket.svg';
import {ReactComponent as TicketIconContrast} from 'assets/contrastTicket.svg';
import api from 'api/user/repository';
import {produce, setAutoFreeze} from 'immer';
import {BuyerProfileActions, BuyerProfileScreen, useDispatch, useTrackedState} from 'Utils/BuyerProfileContext';
import SetDiscounts from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts';
import ticketsToDiscounts from 'components/pages/UserProfile/components/BuyerProfile/helpers/ticketsToDiscounts';
import {useSWRConfig} from 'swr';
import {getTicketLabelsFromDiscounts} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import {Discount} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import AutocompleteInput, {AutocompleteVariants} from 'components/common/AutocompleteInput';
import AutocompleteWrapper from 'components/common/AutocompleteInput/AutocompleteWrapper';
import {useTranslation} from 'react-i18next';

setAutoFreeze(false);

interface Props {
  close?: () => void;
  initialSearchData?: ISearchConnectionFormData;
}
type Discounts = Discount[];

const AddBuyerProfile: FC<Props> = ({initialSearchData}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [showDiscounts, setShowDiscounts] = useState<boolean>(false);
  const {mutate} = useSWRConfig();
  const {shopDiscounts} = useData();
  const [openTickets, setOpenTickets] = useState<boolean>(false);
  const {activeProfile} = useTrackedState();
  const dispatch = useDispatch();
  const {stationFrom, stationTo, name, discounts, filters} = activeProfile;
  const {amenities, setAmenities, searchFormData, setSearchFormTripBack, setTripBack} = useSearchLogic(true);
  const [profileName, setProfileName] = useState<string>(name || '');
  const isValid = name && name.length > 0 && stationFrom && stationTo && discounts && discounts.length > 0;

  const {childTicketsFromDiscounts, adultTicketsFromDiscounts} = (discounts || []).reduce<{
    childTicketsFromDiscounts: Discounts;
    adultTicketsFromDiscounts: Discounts;
  }>(
    (allDiscounts, discount) => {
      if (discount.type === 'CHILD') allDiscounts.childTicketsFromDiscounts.push(discount);
      if (discount.type === 'ADULT') allDiscounts.adultTicketsFromDiscounts.push(discount);
      return allDiscounts;
    },
    {childTicketsFromDiscounts: [], adultTicketsFromDiscounts: []},
  );

  const activeFilters = useMemo(() => {
    return filters?.find((x) => x.filter.length > 0);
  }, [filters]);

  const [adultTickets, setAdultTickets] = useState<INewTicket[]>(
    adultTicketsFromDiscounts.map((discount: Discount) => {
      return {
        discountCode: Number(discount?.code),
        ticketTypeCode: Number(discount?.type),
      };
    }),
  );

  const [childrenTickets, setChildrenTickets] = useState<INewTicket[]>(
    childTicketsFromDiscounts.map((discount: Discount) => {
      return {
        discountCode: Number(discount?.code),
        ticketTypeCode: Number(discount?.type),
      };
    }),
  );

  const [searchData, setSearchData] = useState({...initialSearchData});
  const [tripBackState, setTripBackState] = useState(false);
  useEffect(() => {
    if (activeFilters) {
      const amenitiesString = activeFilters.filter.replaceAll('amenities_', '');
      const parsedAmenities = JSON.parse(amenitiesString);

      setAmenities(parsedAmenities);
    }

    setSearchData({...initialSearchData});
  }, [initialSearchData]);

  useEffect(() => {
    if (tripBackState && (searchData.from === 0 || searchData.destination === 0)) {
      setTripBackState(false);
      handleTripBackToggle(false);
      window.scrollTo(0, 0);
    }
  }, [searchData.from, searchData.destination]);

  const GetFromReturn = (data: number) => {
    setSearchFormTripBack((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };
  const GetDestinationReturn = (data: number) => {
    setSearchFormTripBack((prev) => {
      return {
        ...prev,
        destination: data,
      };
    });
  };
  const handleTripBackToggle = (arg: boolean) => {
    GetFromReturn(searchFormData.destination);
    GetDestinationReturn(searchFormData.from);
    setTripBack(arg);
  };

  useEffect(() => {
    if (activeProfile) {
      dispatch({
        type: BuyerProfileActions.UPDATE_ACTIVE_PROFILE,
        payload: {
          name: profileName,
          discounts: ticketsToDiscounts(adultTickets, childrenTickets),
          filters: filters ? filters : [],
          intermediateStation: 0,
        },
      });
    }
  }, [searchData, profileName, adultTickets, childrenTickets, searchFormData]);

  const handleSubmit = async () => {
    try {
      if (activeProfile) {
        if (amenities) {
          let filterId = 99999;

          if (activeFilters) {
            filterId = activeFilters.id;
          }

          const jsonifiedAmen = JSON.stringify(amenities);
          activeProfile.filters = [{id: filterId, filter: `amenities_${jsonifiedAmen}`}];
        }

        await api.editBuyerProfile(activeProfile);
        await mutate('get_user_profiles');
        dispatch({
          type: BuyerProfileActions.SET_SCREEN,
          payload: BuyerProfileScreen.MAIN,
        });
      }
    } catch (error) {
      console.error();
    }
  };

  return (
    <div className={`${style.buyer_modal}`}>
      <div className={`${style.buyer_modal__box}`}>
        <div>
          <header className={style.buyer_modal__header}>
            <h2>{t('29077')}</h2>
            <button
              type="button"
              aria-label={t('29077')}
              onClick={() =>
                dispatch({
                  type: BuyerProfileActions.SET_SCREEN,
                  payload: BuyerProfileScreen.MAIN,
                })
              }
            >
              <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
            </button>
          </header>
          <input
            className={`${style.buyer_modal__nameinput}`}
            placeholder={t('29222')}
            value={profileName}
            onChange={(e) => setProfileName(e.currentTarget.value)}
          />
          <h3 className={style.buyer_modal__blueheader}>{t('29075')}</h3>

          <AutocompleteWrapper>
            <AutocompleteInput
              variant={AutocompleteVariants.FROM}
              label={t('11030')}
              autocompleteValue={stationFrom}
              setAutocompleteValue={(arg) => {
                if (activeProfile) {
                  dispatch({
                    type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                    payload: produce(activeProfile, (draft) => {
                      draft.stationFrom = arg.kod;
                    }),
                  });
                }
              }}
            />
            <button
              onClick={() => {
                if (activeProfile) {
                  dispatch({
                    type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                    payload: produce(activeProfile, (draft) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const from = produce(draft.stationFrom, (draft) => {});
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const to = produce(draft.stationTo, (draft) => {});
                      draft.stationTo = from;
                      draft.stationFrom = to;
                    }),
                  });
                }
              }}
              className={style.changeDirectionButton}
              type="button"
            >
              {isContrastTheme ? <ContrastArrows /> : <Arrows />}
            </button>
            <AutocompleteInput
              variant={AutocompleteVariants.TO}
              label={t('11032')}
              autocompleteValue={stationTo}
              setAutocompleteValue={(arg) => {
                if (activeProfile) {
                  dispatch({
                    type: BuyerProfileActions.SET_ACTIVE_PROFILE,
                    payload: produce(activeProfile, (draft) => {
                      draft.stationTo = arg.kod;
                    }),
                  });
                }
              }}
            />
          </AutocompleteWrapper>

          {/*         Mobile       */}

          <div className={style.display_on_mobile}>
            <div className={style.optionBox}>
              <div className={style.optionHeader}>
                <p className={style.optionLabel}>{t('11010')}</p>
              </div>
              <div className={style.ticketsCount}>
                <div className={style.ticketsCountLabels}>
                  {isContrastTheme ? <TicketIconContrast /> : <TicketIcon />}
                  <div className={style.profile_ticketsTickets}>
                    {adultTicketsFromDiscounts &&
                      getTicketLabelsFromDiscounts(adultTicketsFromDiscounts, true, shopDiscounts, t).map(
                        (label, index) => <span key={`adultLabel${index}`}>{label}</span>,
                      )}

                    {childTicketsFromDiscounts &&
                      getTicketLabelsFromDiscounts(childTicketsFromDiscounts, false, shopDiscounts, t).map(
                        (label, index) => <span key={`adultLabel${index}`}>{label}</span>,
                      )}
                  </div>
                </div>
                <Tickets
                  adultCount={adultTickets}
                  changeAdult={setAdultTickets}
                  childrenCount={childrenTickets}
                  changeChildren={setChildrenTickets}
                  modalModeOpen={openTickets}
                  modalModeClose={setOpenTickets}
                  buyerProfilesMode
                />
              </div>
            </div>
            <AmenitiesMobile amenities={amenities} setAmenities={setAmenities} />
          </div>

          {/*         Desktop          */}

          <div className={`${style.buyer_modal__parameterselect} ${style.display_on_desktop}`}>
            <div className={style.buyer_modal__tickets}>
              <TicketsNewDesktop
                adultCount={adultTickets}
                childrenCount={childrenTickets}
                discounts={() => setShowDiscounts(!showDiscounts)}
              />
            </div>
            <div className={style.buyer_modal__amenities}>
              <AmenitiesNewDesktop amenities={amenities} setAmenities={setAmenities} />
            </div>
          </div>
        </div>
        <div className={style.buyer_modal__confirmbtn}>
          <ConfirmButton text={t('15048')} execute={handleSubmit} disabled={!isValid} />
        </div>
      </div>
      {showDiscounts && (
        <div className={style.yourTrip__curtain}>
          <div className={style.yourTrip__modal} id="modalScrollContainer">
            <SetDiscounts
              adultCount={adultTickets}
              changeAdult={setAdultTickets}
              childrenCount={childrenTickets}
              changeChildren={setChildrenTickets}
              manageDiscounts={() => {}}
              passOpenModal={() => setShowDiscounts(false)}
              buyerProfileMode={true}
              modalMode
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBuyerProfile;
