import Icon from '../Icon/Icon';

import {ExclamationLabelProps} from './ExclamationLabel.types';

import S from './ExclamationLabel.module.scss';

const ExclamationLabel = ({children, status = 'information'}: ExclamationLabelProps) => {
  return (
    <div className={S.exclamationLabel}>
      <Icon icon="exclamation" />
      <span className={`${S.exclamationLabel__text} ${status && S[status]}`}>{children}</span>
    </div>
  );
};
export default ExclamationLabel;
