import {IDownloadTicketResponse} from 'api/sprzedaz/interfaces';
import {PobierzIdentyfikatoryFakturFakturaOdp} from 'api/faktury/interfaces';
import {formatPrice, getFullDate, returnCarrierLogo, returnStationByCode} from 'Utils/commonFunctions';
import {handleInvoiceDownload, handleCorrectedNoteDownload} from 'components/common/Invoice/invoiceHelpers';
import {useStyledThemeState} from 'common/theme';
import {useData} from 'Utils/DataContex';

import Document from 'assets/blueDocument.svg';
import DocumentContrast from 'assets/contrastDocument.svg';
import Download from 'assets/orangeDownloadIcon.svg';
import DownloadContrast from 'assets/contrastDownloadIcon.svg';
import ArrowRight from 'assets/orangeArrowRight.svg';
import ArrowRightContrast from 'assets/contrastArrowRight.svg';

import style from './MyInvoices.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  ticket: PobierzIdentyfikatoryFakturFakturaOdp;
  details: IDownloadTicketResponse | null;
}

const SingleInvoice = ({ticket, details}: Props) => {
  const {t} = useTranslation();
  const {theme, isContrastTheme} = useStyledThemeState();
  const {stationsList} = useData();

  if (details === null) return null;
  const {cenaCalkowita, cenaBilet, cenaRezerwacja} = details.bilety[0];

  return (
    <div className={`grid ${style.invoice_container}`}>
      <div className={`col-12 col-sm-12 ${style.invoiceDataRow}`}>
        <img src={isContrastTheme ? DocumentContrast : Document} alt="Dokument" className={style.invoice_img_mobile} />
        <div className={`${style.invoice_identification}`}>
          <p className={`${style.text_blue}`}>
            {ticket.typeOfNote === 1 ? t('26045') : ''} {ticket.typeOfNote === 3 ? t('29191') : ''}
            {ticket.typeOfNote === 2 ? t('29190') : ''}
          </p>
          {details && (
            <p className={`${style.text_big}`}>
              {ticket.typeOfNote === 1 && details.bilety[0]?.fakturaSeria}
              {ticket.typeOfNote === 2 && details.bilety[0]?.fakturaKorektaSeria}
              {ticket.typeOfNote === 3 && details.bilety[0]?.notaKorygujacaSeria}
              {ticket.typeOfNote === 1 && details.bilety[0]?.fakturaNr}
              {ticket.typeOfNote === 2 && details.bilety[0]?.fakturaKorektaNr}
              {ticket.typeOfNote === 3 && details.bilety[0]?.notaKorygujacaNr}
            </p>
          )}
        </div>
      </div>
      <div className={`col-12 col-sm-12 ${style.invoice_data_box}`}>
        <div className={style.invoice_data}>
          <div>
            <p className={style.text_blue}>{t('29192')}</p>
            <p className={style.text_data}>{`${ticket?.biletSeria}${ticket?.biletNr}`}</p>
          </div>
          <div>
            <p className={style.text_blue}>{t('11035')}</p>
            <p className={style.text_data}>{getFullDate(ticket?.waznoscBiletuOd)}</p>
          </div>
          <div>
            <p className={style.text_blue}>{t('29193')}</p>
            <p className={style.text_data}>
              {ticket.typeOfNote === 1 && formatPrice(t, cenaCalkowita ? cenaCalkowita : cenaBilet + cenaRezerwacja)}
              {ticket.typeOfNote === 2 && '0,00zł'}
              {ticket.typeOfNote === 3 &&
                details &&
                formatPrice(
                  t,
                  details?.bilety[0]?.cenaBilet + details.bilety[0]?.cenaRezerwacja - details?.bilety[0]?.zwrotKwota,
                )}
            </p>
          </div>
          {details && (
            <button
              className={`${style.download_btn}`}
              aria-label={t('29194')}
              onClick={() => {
                if (ticket.typeOfNote === 1) {
                  handleInvoiceDownload(details?.bilety[0]?.fakturaSeria, details?.bilety[0]?.fakturaNr);
                } else if (ticket.typeOfNote === 2) {
                  handleInvoiceDownload(details?.bilety[0]?.fakturaKorektaSeria, details?.bilety[0]?.fakturaKorektaNr);
                } else if (ticket.typeOfNote === 3) {
                  handleCorrectedNoteDownload(
                    details?.bilety[0]?.notaKorygujacaSeria,
                    details?.bilety[0]?.notaKorygujacaNr,
                  );
                }
              }}
            >
              <img src={isContrastTheme ? DownloadContrast : Download} alt={t('29195')} />
              {t('29195')}
            </button>
          )}
        </div>
      </div>
      {details?.bilety[0]?.odcinki ? (
        <div className={`col-12 col-sm-12 ${style.hide_on_mobile}`}>
          <div className={`col-sm-12 col-12 ${style.text_blue}`}>{t('17028')}</div>
          <ul className={`col-sm-12 col-12 ${style.station_list_box}`}>
            {details?.bilety[0]?.odcinki[0] ? (
              <li>
                <span className={style.regular_text}>
                  {returnStationByCode(details.bilety[0].odcinki[0].stacjaOdKod, stationsList)}
                </span>
              </li>
            ) : null}
            {details.bilety[0].odcinki.map((el) => {
              return (
                <li key={`${el.stacjaOdKod}-${el.stacjaDoKod}`}>
                  <img
                    src={isContrastTheme ? ArrowRightContrast : ArrowRight}
                    alt={t('11027')}
                    style={{paddingRight: '14px'}}
                  />
                  <span className={style.regular_text}>{returnStationByCode(el.stacjaDoKod, stationsList)}</span>
                </li>
              );
            })}
          </ul>
          <ul className={`col-sm-12 col-12 ${style.carrier_list_box}`}>
            {details.bilety[0].odcinki.map((el) => {
              return (
                <li key={`${el.stacjaOdKod}-${el.stacjaDoKod}`}>
                  {returnCarrierLogo(el.pociagKategoriaNazwa, theme)}
                  <span className={style.regular_text}>{el.pociagNr}</span>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default SingleInvoice;
