import {env} from 'env';
import api from '../axios-middleware';
import {
  IConnectionSearchRequest,
  IConnectionSearchResponse,
  IFrequencyRequest,
  IFrequencyResponse,
  IGetRouteRequest,
  IGetRouteRequestResponse,
  PobierzNryWewnentrzneStacjiOdDoPociagu,
} from './interfaces';

const methods = {
  indexedStationsForGrm: async (obj: Partial<PobierzNryWewnentrzneStacjiOdDoPociagu>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await api.post<any>('/server/public/endpoint/Pociagi', {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'pobierzNryWewnetrzneStacjiOdDoPociagu',
    });
  },
  connectionsSearch: async (obj: IConnectionSearchRequest) => {
    return await api.post<IConnectionSearchResponse>('/server/public/endpoint/Pociagi', {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'wyszukajPolaczenia',
    });
  },
  getFrequency: async (data: IFrequencyRequest) => {
    return await api.get<IFrequencyResponse>(
      `/availability/frequency/${data.trainCat}/${data.trainNo}/${data.departureStationDate}/${data.arrivalStationDate}/${data.departureStation}/${data.arrivalStation}/`,
    );
  },
  getTrainRoute: async (data: IGetRouteRequest) => {
    return await api.post<IGetRouteRequestResponse>(`/server/public/endpoint/Pociagi`, {
      ...data,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'pobierzTrasePrzejazdu',
    });
  },
};

export default methods;
