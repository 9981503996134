/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {FC, Fragment, useContext, useMemo} from 'react';

import {Text} from 'common/components/base/Text';
import Icon from 'common/components/base/Icon';
import OutlineButton from 'components/common/OutlineButton';

import {
  returnCarrierLogo,
  getFullDate,
  getFullTimeFromDate,
  getDayOfTheWeek,
  getTicketSource,
  returnStationByCode,
  getTimeFromDate,
} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';

import {useData} from 'Utils/DataContex';
import {ReservationContext} from 'Utils/ReservationContext';

import OrangeCircleSmall from 'assets/orangeCircleSmall.svg';
import OrangePinSmall from 'assets/orangePinSmall.svg';
import ContrastCircleSmall from 'assets/contrastCircleSmall2.svg';
import ContrastPinSmall from 'assets/contrastPinSmall2.svg';
import Doggo from 'assets/AdditionalTickets/pet.svg';
import Luggage from 'assets/AdditionalTickets/luggage.svg';
import Bicycle from 'assets/AdditionalTickets/bicycle.svg';
import Profile from 'assets/profileNavyDesktop.svg';
import DoggoContrast from 'assets/AdditionalTickets/petContrast.svg';
import LuggageContrast from 'assets/AdditionalTickets/luggageContrast.svg';
import BicycleContrast from 'assets/AdditionalTickets/bicycleContrast.svg';
import ProfileContrast from 'assets/profileContrastDesktop.svg';
import TicketContrast from 'assets/contrastTicket.svg';
import Ticket from 'assets/ticket.svg';
import {useTranslation} from 'react-i18next';
import {getAvailableCarriers, getCarrierLogo} from 'components/pages/SeasonalTicket/common/utils';
import {checkIfSeasonalTicketIsActive} from '../../common/utils';
import {isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';

import {IDownloadTicketResponse} from 'api/sprzedaz/interfaces';
import {SEASONAL_CODE_OFFERS} from 'common/consts';
import {isNonSpecifiedRoutSeasonalOffer, isTicketManuallyAdded} from 'common/utils';
import {TicketComponentVariant} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';

import style from './MyTicketsMobilet.module.scss';
import {useNavigate} from 'react-router-dom';
import {FlexRow, SeasonalTicketMobileInfoBanner} from './MyTicketsSingleTicket.style';

interface Props {
  details: IDownloadTicketResponse | null;
  isSeasonal?: boolean;
  history?: number;
  variant?: TicketComponentVariant;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyTicketsSingleTicketMobile: FC<Props> = ({details, isSeasonal, history, variant}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {seasonalOffers, stationsList} = useData();
  const {theme, isContrastTheme} = useStyledThemeState();
  const {setDownloadedTicketData, setReminderModalSearchData} = useContext(ReservationContext);
  const downloadedTicket = details?.bilety[0];
  const {biletNr, biletSeria} = downloadedTicket ?? {};
  const firstStation = downloadedTicket?.odcinki[0];
  const ticketSource = getTicketSource(biletSeria || '');
  const isReturned = !!downloadedTicket?.zwrotData;
  const isSeasonalTicketReminderDrawer = variant === 'seasonalTicketReminderDrawer';

  const isSeasonalTicketActive = useMemo(
    () =>
      checkIfSeasonalTicketIsActive(downloadedTicket?.waznoscBiletuOd || '', downloadedTicket?.waznoscBiletuDo || '') &&
      !isReturned,
    [downloadedTicket, isReturned],
  );
  const isManuallyAdded = isTicketManuallyAdded(downloadedTicket?.biletZrodloKod, biletSeria);
  const isIntercityCard = downloadedTicket?.ofertaKod === SEASONAL_CODE_OFFERS.INTERCITY_CARD;
  const downloadReservationClickHandler = () => {
    if (downloadedTicket) {
      !isSeasonalTicketReminderDrawer && setReminderModalSearchData(null);
      navigate('/rezerwacje');
      setDownloadedTicketData(downloadedTicket);
    }
  };

  const ticketTypeArr = [1, 2, 10, 20, 21, 22, 23, 24, 30, 31, 32];

  const returnAdditionalTypeTicketData = (type: number) => {
    if (type === 21) {
      return isContrastTheme ? DoggoContrast : Doggo;
    } else if (type === 23) {
      return isContrastTheme ? LuggageContrast : Luggage;
    } else if (isPrimaryTicketKind(type)) {
      return isContrastTheme ? ProfileContrast : Profile;
    } else if (type === 20) {
      return isContrastTheme ? BicycleContrast : Bicycle;
    }
  };

  const returnAdditionalTypeTicketName = (type: number) => {
    if (type === 21) {
      return 'Odcisk psiej łapy';
    } else if (type === 23) {
      return t('29276');
    } else if (isPrimaryTicketKind(type)) {
      return t('29277');
    } else if (type === 20) {
      return t('29151');
    }
  };

  const availableCarriers = getAvailableCarriers(downloadedTicket?.ofertaKod, seasonalOffers) ?? [];
  const carriersIconsToRender = (
    <div className={style.carriersIconsContainer}>
      {availableCarriers.map((carrier) => (
        <Fragment key={carrier}>{getCarrierLogo(carrier, isContrastTheme)}</Fragment>
      ))}
    </div>
  );

  const isNonSpecifiedRouteTicket = isNonSpecifiedRoutSeasonalOffer(downloadedTicket?.ofertaKod);

  return (
    <div className={style.singleTicket__box}>
      {!history && isManuallyAdded && (
        <SeasonalTicketMobileInfoBanner>
          <Text variant="P3" weight={600} palette="text.info">
            {t('29397')}
          </Text>
        </SeasonalTicketMobileInfoBanner>
      )}
      <div className={style.singleTicket_MainInfo}>
        <div className={style.singleTicket_MainInfo__container}>
          {isSeasonal ? (
            <>
              <p className={style.singleTicket_MainInfoTitle}>
                {isIntercityCard ? t('13083') : downloadedTicket?.ofertaNazwa}
              </p>
              <FlexRow>
                <Text variant="P5">{`${biletSeria}${biletNr}`}</Text>
                {isManuallyAdded && <Text variant="P5">{t('13134')}</Text>}
              </FlexRow>
            </>
          ) : (
            <p className={style.singleTicket_MainInfoTitle}>{t('11060')}</p>
          )}
          <h2 className={`${style.singleTicket__active_period}`}>
            {isSeasonal
              ? `${getFullDate(downloadedTicket?.waznoscBiletuOd!)} - ${getFullDate(downloadedTicket?.waznoscBiletuDo!)}`
              : getFullDate(firstStation?.wyjazdData as string)}{' '}
          </h2>
          {isSeasonal && downloadedTicket && (
            <Text variant="P5">
              {t('29314', {
                from: getTimeFromDate(downloadedTicket.waznoscBiletuOd),
                till: getTimeFromDate(downloadedTicket.waznoscBiletuDo),
              })}
            </Text>
          )}

          {!isSeasonal && (
            <p className={style.singleTicket__day_name}>
              {getDayOfTheWeek(new Date(firstStation?.wyjazdData as string).getDay())}
            </p>
          )}
          {!isReturned &&
            (isSeasonal && downloadedTicket ? (
              isSeasonalTicketActive ? (
                <Text variant="P4" weight={700} palette="success.default">
                  {t('29250')}
                </Text>
              ) : (
                <Text variant="P4" weight={700} palette="error.default">
                  {t('29251')}
                </Text>
              )
            ) : null)}
          {isReturned && <p className={style.red_text}>{t('29153')}</p>}
          {ticketSource && <p className={style.ticketTag}>{t(ticketSource)}</p>}
        </div>
        {!isSeasonal && (
          <div className={style.singleTicket_TimeContainer}>
            <Icon icon="clock" />
            <p className={style.singleTicket__active_period}>
              {firstStation!.wyjazdData ? getFullTimeFromDate(firstStation!.wyjazdData as string) : '-'}
            </p>
          </div>
        )}
      </div>
      {firstStation!.stacjaOdKod !== undefined && (
        <div className={style.trip_data_box}>
          <div className={style.icon_box}>
            <img src={isContrastTheme ? ContrastCircleSmall : OrangeCircleSmall} alt={t('29080')} />
            <div />
            <img src={isContrastTheme ? ContrastPinSmall : OrangePinSmall} alt={t('29081')} />
          </div>
          <div className={style.data_box}>
            {details && (
              <div className={style.singleTicket__cityInfo}>
                <p className={style.from_to}>
                  {isNonSpecifiedRouteTicket
                    ? t('11095')
                    : returnStationByCode(firstStation!.stacjaOdKod, stationsList)}
                </p>
                {!isSeasonal && (
                  <>
                    <p className={style.singleTicket__day_name}>{firstStation?.pociagNr}</p>
                    {returnCarrierLogo(firstStation!.pociagKategoriaNazwa, theme)}
                  </>
                )}
              </div>
            )}
            {details?.bilety
              ?.filter((el) => isPrimaryTicketKind(el.biletRodzaj))
              .map((el, index, arr) => {
                return el.odcinki.map((el2, index2, arr2) => {
                  const hasTransferChange = index2 !== arr2.length - 1 || (index === 0 && arr.length > 1);
                  const isDirect = arr2.length === 1;
                  const nextRouteItem = el.odcinki[index2 + 1];

                  return (
                    <Fragment key={`${el.biletNr}-${el2.pociagNr}`}>
                      {hasTransferChange && <p className={style.singleTicket_MainInfoTitle}>{t('22010')}</p>}
                      <div className={style.singleTicket__cityInfo}>
                        <p className={style.from_to}>
                          {isNonSpecifiedRouteTicket ? t('11095') : returnStationByCode(el2.stacjaDoKod, stationsList)}
                          {hasTransferChange && nextRouteItem && el2.stacjaDoKod !== nextRouteItem.stacjaOdKod && (
                            <>
                              <br />
                              {returnStationByCode(nextRouteItem.stacjaOdKod, stationsList)}
                            </>
                          )}
                        </p>
                        {!isDirect && !hasTransferChange && (
                          <>
                            <p className={style.singleTicket__day_name}>{el2.pociagNr}</p>
                            {returnCarrierLogo(el2.pociagKategoriaNazwa, theme)}
                          </>
                        )}
                      </div>
                    </Fragment>
                  );
                });
              })}
          </div>
        </div>
      )}
      {downloadedTicket && isSeasonal && availableCarriers.length > 0 && (
        <div className={style.textAndIconsContainer}>
          <Text variant="P5">{t('29318')}</Text>
          {carriersIconsToRender}
        </div>
      )}
      {!isSeasonal && (
        <div className={style.singleTicket__ikonContainer}>
          {details && details.bilety.filter((el) => ticketTypeArr.includes(el.biletRodzaj)).length > 0 && (
            <div className={style.singleTicket__singleIconContainer}>
              <img src={isContrastTheme ? TicketContrast : Ticket} alt={t('11010')} />
              <span>x {details.bilety.filter((el) => ticketTypeArr.includes(el.biletRodzaj)).length}</span>
            </div>
          )}
          {details &&
            details.bilety
              .filter((el) => !ticketTypeArr.includes(el.biletRodzaj))
              .map((el, index) => (
                <Fragment key={el.biletNr}>
                  {!el.zwrotOpis && index !== 0 && (
                    <div className={style.singleTicket__singleIconContainer}>
                      <img
                        src={returnAdditionalTypeTicketData(el.biletRodzaj)}
                        alt={returnAdditionalTypeTicketName(el.biletRodzaj)}
                      />
                      <span>x {el?.podrozni.length}</span>
                    </div>
                  )}
                </Fragment>
              ))}
        </div>
      )}
      {isSeasonal && !isReturned && !history && (
        <OutlineButton
          text={isSeasonalTicketReminderDrawer ? t('29511') : t('29252')}
          path={downloadReservationClickHandler}
        />
      )}
    </div>
  );
};

export default MyTicketsSingleTicketMobile;
