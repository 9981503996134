import {IConnectionSearchTrainDetails} from 'api/pociagi/interfaces';
import {GrmPlace} from './GrmPlace.interface';
import {GRMAvailability, Place} from 'interfaces/Grm';

export enum GrmContexActionTypes {
  RESET_GRM_PLACES_TO_TAKE = 'RESET_GRM_PLACES_TO_TAKE',
  SET_ACTIVE_CARRIAGE = 'SET_ACTIVE_CARRIAGE',
  SET_GRM_STATE = 'SET_GRM_STATE',
  CLOSE_GRM = 'CLOSE_GRM',
  TAKE_PLACE = 'TAKE_PLACE',
  FORCE_TAKE_PLACE = 'FORCE_TAKE_PLACE',
  TAKE_PLACE_FOR_BIKE = 'TAKE_PLACE_FOR_BIKE',
  CLOSE_POPUP = 'CLOSE_POPUP',
  SET_NEXT_CARRIAGE = 'SET_NEXT_CARRIAGE',
  SET_PREV_CARRIAGE = 'SET_PREV_CARRIAGE',
  RESET_OTHER_PLACES = 'RESET_OTHER_PLACES',
}

export type GrmContextActions =
  | {
      type: GrmContexActionTypes.SET_ACTIVE_CARRIAGE;
      payload?: number;
      carriages?: string[];
      klasa1?: string[];
      klasa2?: string[];
      klasa0?: string[];
      wagonySchemat?: {[key: string]: string};
      pojazdTyp?: string;
      zmieniaKierunek?: boolean;
    }
  | {
      type: GrmContexActionTypes.SET_GRM_STATE;
      train: IConnectionSearchTrainDetails;
      placesToTake: GrmPlace[];
      mode: GRMAvailability;
    }
  | {type: GrmContexActionTypes.CLOSE_GRM}
  | {type: GrmContexActionTypes.RESET_GRM_PLACES_TO_TAKE}
  | {type: GrmContexActionTypes.TAKE_PLACE; place: Place}
  | {type: GrmContexActionTypes.CLOSE_POPUP}
  | {type: GrmContexActionTypes.SET_NEXT_CARRIAGE}
  | {type: GrmContexActionTypes.SET_PREV_CARRIAGE}
  | {type: GrmContexActionTypes.FORCE_TAKE_PLACE; place: Place}
  | {type: GrmContexActionTypes.TAKE_PLACE_FOR_BIKE; place: Place}
  | {type: GrmContexActionTypes.RESET_OTHER_PLACES; place: Place};
