import {FC, ReactNode} from 'react';

import S from './BlueTitleOnBorderContainer.module.scss';

interface Props {
  children?: ReactNode;
  title: string;
  noMargin?: boolean;
  variant?: 'smallBorderRadius';
}

const BlueTitleOnBorderContainer: FC<Props> = ({children, title, noMargin, variant}) => {
  return (
    <div
      className={`${S.BlueTitleOnBorderContainer}
     ${noMargin ? S.noMargin : ''} ${variant ? S[variant] : ''}`}
    >
      <h2 className={S.BlueTitleOnBorderContainer_title}>{title}</h2>
      {children}
    </div>
  );
};

export default BlueTitleOnBorderContainer;
