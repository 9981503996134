/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmButton from 'components/common/ConfirmButton';
import {useStyledThemeState} from 'common/theme';
import {useNavigate} from 'react-router-dom';

import style from './NoDataToDisplay.module.scss';
import Ticket from 'assets/ticketDesktop.svg';
import TicketContrast from 'assets/contrastTicket.svg';
import DocumentContrast from 'assets/contrastDocument.svg';
import Document from 'assets/blueDocument.svg';
import TrainOnTicket from 'assets/TrainOnTicket.svg';
import TrainOnTicketContrast from 'assets/TrainOnTicketContrast.svg';
import {MyTicketsListVariants} from 'common/pages/UserProfile/MyTickets/MyTicketsList.types';

interface Props {
  imageTicket: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute?: React.Dispatch<any>;
  redirect?: boolean;
  variant?: MyTicketsListVariants;
}

const NoDataToDisplay: FC<Props> = ({imageTicket, text, execute, redirect = true, variant}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const navigate = useNavigate();
  return (
    <div className={`col-12 col-sm-12 ${style.nodata_container}`}>
      {imageTicket === 'ticket' && (
        <img src={isContrastTheme ? TicketContrast : Ticket} alt={t('11010')} className={style.image_style} />
      )}
      {imageTicket === 'document' && (
        <img src={isContrastTheme ? DocumentContrast : Document} alt="Dokument" className={style.image_style} />
      )}
      {imageTicket === 'train' && (
        <img
          src={isContrastTheme ? TrainOnTicketContrast : TrainOnTicket}
          alt={t('11010')}
          className={style.image_style}
        />
      )}
      <p className={style.text_style}>{text}</p>
      <div
        onClick={() => {
          typeof execute === 'function' && execute(null);
        }}
        className={style.btn_box}
      >
        <ConfirmButton
          execute={() => redirect && navigate(variant === 'SEASONAL' ? '/biletokresowy' : '/')}
          disabled={false}
          text={imageTicket === 'train' ? t('29243') : t('10002')}
        />
      </div>
    </div>
  );
};

export default NoDataToDisplay;
