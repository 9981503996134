/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect} from 'react';

import AmenitiesOption from './PurchasePathMobile/Amenities/AmenitiesOption';
import {IAmenities} from 'api/pociagi/interfaces';
import IconHeader from 'components/common/IconHeader';

import {ReactComponent as HorizontalArrows} from 'assets/arrows2.svg';
import {ReactComponent as HorizontalArrowsWhite} from 'assets/arrows2White.svg';
import {ReactComponent as Seat} from 'assets/seat.svg';
import {ReactComponent as SeatWhite} from 'assets/seatWhite.svg';
import {ReactComponent as Settings} from 'assets/settings.svg';
import {ReactComponent as SettingsWhite} from 'assets/settingsWhite.svg';
import {ReactComponent as ContrastX} from 'assets/contrastX.svg';
import {ReactComponent as OrangeX} from 'assets/orangeX.svg';
import Gear from 'assets/gear.svg';
import GearWhite from 'assets/gearWhite.svg';

import ConfirmButton from 'components/common/ConfirmButton';

import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import useAmenities from './AmenietiesModalLogic';
import {returnCarrierLogo} from 'Utils/commonFunctions';
import S from './AmenitiesModal.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  open: boolean;
  setOpen: (e: boolean) => void;
  amenities: IAmenities;
  setAmenities: (e: IAmenities) => void;
  hideTrainsCategorySelection?: boolean;
}

const AmenietiesModal: React.FC<Props> = ({open, setOpen, amenities, setAmenities, hideTrainsCategorySelection}) => {
  const {t} = useTranslation();
  const {
    yellowBar,
    localAmenities,
    setLocalAmenities,
    chekIfShowYellowBar,
    setRestingPlaces,
    selectedType,
    selectedPlaces,
    setDirectConnection,
    setBraileSigns,
    setSleepingPlaces,
    setBikeSpace,
    setWheelChairSpace,
    setMoveDisabilitySpace,
    setCarForFamily,
    setQuietZone,
    selectedTrainSymbol,
    handleClose,
    saveDataAmenities,
    filterTrains,
    showIfSleepOrLaying,
    setFakeNumber,
  } = useAmenities(setOpen, amenities, setAmenities);

  const {theme, isContrastTheme} = useStyledThemeState();

  const {placeTypes, trainCategories, railwayCompanies} = useData();

  useEffect(() => {
    chekIfShowYellowBar(hideTrainsCategorySelection);
  }, [localAmenities]);

  return (
    <>
      {open && placeTypes && trainCategories && railwayCompanies && (
        <div className={S.curtain}>
          <div className={S.container}>
            <div className={`${S.row_grid} ${S.mb_36}`}>
              <div className={S.row_grid_item}>
                <img
                  src={isContrastTheme ? GearWhite : Gear}
                  alt="Koło zębate"
                  style={{
                    width: '26px',
                    height: '26px',
                  }}
                />
                <h1 className={S.title}>{t('15001')}</h1>
              </div>
              <div
                className={S.close_btn}
                onClick={handleClose}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClose();
                  }
                }}
              >
                {isContrastTheme ? <ContrastX /> : <OrangeX />}
              </div>
            </div>
            <div className={S.columnContainer}>
              <ul className={S.column}>
                <li>
                  <IconHeader
                    title={t('15005')}
                    description={t('15006')}
                    icon={isContrastTheme ? <SeatWhite /> : <Seat />}
                  />
                </li>
                <li
                  style={{
                    opacity: `${
                      showIfSleepOrLaying() || selectedType('Miejsce dla podróżnego z rowerem', 24) ? '0.5' : '1'
                    }`,
                  }}
                >
                  <AmenitiesOption
                    selected={selectedType('Strefa Ciszy', 52)}
                    setSelected={setQuietZone}
                    text={t('15009')}
                  />
                </li>
                <li
                  style={{
                    opacity: `${
                      selectedType('Miejsce dla podróżnego z rowerem', 24) || selectedType('Strefa Ciszy', 52)
                        ? '0.5'
                        : '1'
                    }`,
                  }}
                >
                  <AmenitiesOption selected={selectedPlaces(2)} setSelected={setRestingPlaces} text={t('15012')} />
                </li>
                <li
                  style={{
                    opacity: `${
                      selectedType('Miejsce dla podróżnego z rowerem', 24) || selectedType('Strefa Ciszy', 52)
                        ? '0.5'
                        : '1'
                    }`,
                  }}
                >
                  <AmenitiesOption selected={selectedPlaces(3)} setSelected={setSleepingPlaces} text={t('15013')} />
                </li>
                <li
                  style={{
                    opacity: `${showIfSleepOrLaying() || selectedType('Strefa Ciszy', 52) ? '0.5' : '1'}`,
                  }}
                >
                  <AmenitiesOption
                    selected={selectedType('Miejsce dla podróżnego z rowerem', 24)}
                    setSelected={setBikeSpace}
                    text={t('15014')}
                  />
                </li>
                <li>
                  <IconHeader
                    title={t('15015')}
                    description={t('15016')}
                    icon={isContrastTheme ? <SeatWhite /> : <Seat />}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    selected={selectedType('Miejsce dla osoby na wózku', 6)}
                    setSelected={setWheelChairSpace}
                    text={t('15018')}
                  />
                </li>
                <li>
                  <AmenitiesOption selected={!!localAmenities.braille} setSelected={setBraileSigns} text={t('18009')} />
                </li>
                <li>
                  <AmenitiesOption
                    selected={selectedType('Miejsce dla OzN - nie na wózku', 2)}
                    setSelected={setMoveDisabilitySpace}
                    text={t('15021')}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    selected={selectedType('Przedział dla osoby z dzieckiem do lat 6', 3)}
                    setSelected={setCarForFamily}
                    text={t('15023')}
                  />
                </li>
              </ul>
              <ul className={S.column}>
                <li>
                  <IconHeader
                    title={t('15036')}
                    description=""
                    icon={isContrastTheme ? <HorizontalArrowsWhite /> : <HorizontalArrows />}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    selected={!!localAmenities.polaczeniaBezposrednie}
                    setSelected={setDirectConnection}
                    text={t('15037')}
                  />
                </li>
                <li>
                  <IconHeader
                    title={t('15039')}
                    description=""
                    icon={isContrastTheme ? <SettingsWhite /> : <Settings />}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    selected={localAmenities.fakeNumberToCounter && !!localAmenities.fakeNumberToCounter.includes(2)}
                    setSelected={(e) => {
                      setFakeNumber(e, 2);
                    }}
                    text={t('29378')}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    text={t('15041')}
                    selected={localAmenities.fakeNumberToCounter && !!localAmenities.fakeNumberToCounter.includes(3)}
                    setSelected={(e) => {
                      setFakeNumber(e, 3);
                    }}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    text={t('15042')}
                    selected={localAmenities.fakeNumberToCounter && !!localAmenities.fakeNumberToCounter.includes(4)}
                    setSelected={(e) => {
                      setFakeNumber(e, 4);
                    }}
                  />
                </li>
                <li>
                  <AmenitiesOption
                    text={t('15043')}
                    selected={localAmenities.fakeNumberToCounter && !!localAmenities.fakeNumberToCounter.includes(5)}
                    setSelected={(e) => {
                      setFakeNumber(e, 5);
                    }}
                  />
                </li>

                {hideTrainsCategorySelection ? null : (
                  <>
                    <li>
                      <IconHeader
                        title={t('15046')}
                        description=""
                        icon={isContrastTheme ? <SettingsWhite /> : <Settings />}
                      />
                    </li>
                    {trainCategories &&
                      filterTrains(trainCategories).map((el) => {
                        return (
                          <li key={el.symbol} className={S.train_box}>
                            <div className={S.column__option_svg}>{el.nazwa && returnCarrierLogo(el.nazwa, theme)}</div>
                            <AmenitiesOption
                              selected={selectedTrainSymbol(el.nazwa)}
                              setSelected={(value) => {
                                if (value) {
                                  setLocalAmenities({
                                    ...localAmenities,
                                    kategoriePociagow: [...localAmenities.kategoriePociagow, el.nazwa],
                                  });
                                } else {
                                  setLocalAmenities({
                                    ...localAmenities,
                                    kategoriePociagow: [
                                      ...localAmenities.kategoriePociagow.filter((str) => str !== el.nazwa),
                                    ],
                                  });
                                }
                              }}
                              text={el.nazwa}
                            />
                          </li>
                        );
                      })}
                  </>
                )}
              </ul>
            </div>
            {yellowBar && <p className={`${S.text_warning} ${S.mt_24}`}>{t('15047')}</p>}
            <div className={`${S.row_grid} ${S.mt_24}`}>
              <ConfirmButton text={t('15048')} disabled={false} execute={saveDataAmenities} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AmenietiesModal;
