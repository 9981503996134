/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {FC, RefObject, useEffect, useRef, useState} from 'react';

import {useData} from 'Utils/DataContex';
import {IRailwayEmployee} from 'api/sprzedaz/interfaces';
import {RailwayCompany} from 'common/api/localData';
import {useStyledThemeState} from 'common/theme';
import {filterRailwayCompanies} from '../SetDiscountsLogic';

import S from './RailwayCompaniesSearcher.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  employeeData: IRailwayEmployee;
  onEmployerCodeChange: (code: number) => void;
  employerSearcherRef?: RefObject<HTMLDivElement>;
  variant?: 'seasonalUut';
}

const RailwayCompaniesSearcher: FC<Props> = ({employeeData, onEmployerCodeChange, employerSearcherRef, variant}) => {
  const {t} = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<RailwayCompany>();
  const [showOptions, setShowOptions] = useState(false);
  const companiesRef = useRef<HTMLDivElement | null>(null);
  const {railwayCompanies} = useData();
  const {isContrastTheme} = useStyledThemeState();

  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredCompanies, setFilteredCompanies] = useState<RailwayCompany[]>(railwayCompanies);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (employeeData.employerCode) {
      const find = railwayCompanies.find((el) => el.kod === employeeData.employerCode);
      find && setSelectedCompany(find);
    } else {
      setSelectedCompany(undefined);
    }
  }, [employeeData.employerCode]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutside = (event: any) => {
    if (companiesRef.current && !companiesRef.current.contains(event.target)) {
      setShowOptions(false);
      setIsEditActive(false);
      setSearchInput('');
    }
  };

  const handleCompanyChange = (code: number): void => {
    onEmployerCodeChange(code);
    const isCompanyCodeValid: boolean = railwayCompanies.some((el) => el.kod === code);
    if (isCompanyCodeValid) {
      setSearchInput('');
      setShowOptions(false);
      setIsEditActive(false);
    }
  };

  const onSearchChange = (value: string) => {
    setSearchInput(value);
    if (value.length > 0) {
      const filtered = filterRailwayCompanies(value, railwayCompanies);
      setFilteredCompanies(filtered);
      return;
    }
    setFilteredCompanies(railwayCompanies);
  };

  const activeEdition = (): void => {
    setFilteredCompanies(railwayCompanies);
    setIsEditActive(true);
    setShowOptions(true);
  };

  const cancelEdition = (): void => {
    setIsEditActive(false);
    setShowOptions(false);
  };

  return (
    <div className={`${S.blueInputWrapper} ${S.railwayEmployerCode} ${variant ? S[variant] : ''}`} ref={companiesRef}>
      {!isEditActive ? (
        <>
          <div
            ref={employerSearcherRef}
            id="employerCode"
            className={`${S.railwaySearcherBlueInput} ${S.selectedCompany} ${!selectedCompany ? S.unselectedCompany : ''} ${isContrastTheme && S.contrast}`}
            onClick={activeEdition}
            onFocus={activeEdition}
            onBlur={cancelEdition}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && activeEdition()}
          >
            {selectedCompany ? (
              `${selectedCompany.kod} - ${selectedCompany.nazwa}`
            ) : (
              <span className={S.unselectedLabel}>{t('29435')}</span>
            )}
          </div>
          <label htmlFor="employerCode" className={S.railwaySearcherBlueInputLabel}>
            {t('13028')}
          </label>
        </>
      ) : (
        <>
          <input
            id="searchRailwayCompany"
            type="text"
            className={`${S.railwaySearcherBlueInput} ${isContrastTheme && S.contrast}`}
            value={searchInput}
            placeholder={t('29435')}
            onChange={(e) => onSearchChange(e.target.value)}
            onKeyDown={(e) => e.key === 'Escape' && cancelEdition()}
            autoFocus
          />
          <label htmlFor="searchRailwayCompany" className={S.railwaySearcherBlueInputLabel}>
            {t('13028')}
          </label>
        </>
      )}
      {showOptions && (
        <ul className={S.companyOptions}>
          {filteredCompanies?.map((el: RailwayCompany) => (
            <li
              key={el.kod}
              onClick={() => handleCompanyChange(el.kod)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleCompanyChange(el.kod);
                if (e.key === 'Escape') cancelEdition();
              }}
            >
              {el.kod} - {el.nazwa}
            </li>
          ))}
          {searchInput.length >= 1 && !filteredCompanies?.length && <li> {t('13070')}</li>}
        </ul>
      )}
    </div>
  );
};

export default RailwayCompaniesSearcher;
