import React, {FC, ReactElement} from 'react';
import i18n from 'i18next';

const t = i18n.t;
import S from './SkipLinks.module.scss';

type SkipLink = {
  description: string;
  skipTo: string;
};

interface SkipLinksProps {
  main: boolean;
}

const mainList = [
  {skipTo: '#search-main-container', description: t('11089')} as SkipLink,
  {skipTo: '#contrast-mode-button', description: t('11090')} as SkipLink,
  {skipTo: '#user-login-button', description: t('11091')} as SkipLink,
];

const userList = [
  {skipTo: '#buy-tickets-button', description: t('11092')} as SkipLink,
  {skipTo: '#contrast-mode-button', description: t('11093')} as SkipLink,
  {skipTo: '#user-menu-container', description: t('11094')} as SkipLink,
];

const createSkipLink = (skipTo: string, description: string, key: number): ReactElement => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const container: HTMLElement | null = document.querySelector(skipTo);

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return React.cloneElement(
    <button type="button" key={key}>
      {description}
    </button>,
    {onClick, className: `${S.skip_link}`},
  );
};

const SkipLinks: FC<SkipLinksProps> = ({main}): ReactElement => {
  const skipLinksList = main ? mainList : userList;

  return (
    <>
      {skipLinksList.map((el, index) => {
        return createSkipLink(el.skipTo, el.description, index);
      })}
    </>
  );
};

export default SkipLinks;
