import {ChangeEvent, useMemo, useState} from 'react';

import {useData} from 'Utils/DataContex';
import {Response} from 'common/api';
import {MyTicket} from 'common/api/myTickets';
import {getStationByCodeIBNR} from 'common/utils';
import {formatDateToEuropean} from 'components/pages/SeasonalTicket/common/utils';

export const useFilterTickets = (dataToFilter: (Response<'bilety', MyTicket> | undefined)[], isFetching: boolean) => {
  const {stationsList} = useData();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredList = useMemo(() => {
    if (!dataToFilter || isFetching) return [];
    const terms = searchTerm.toLowerCase().split(' ');
    return dataToFilter.filter((singleTicket) => {
      return singleTicket?.bilety.some(({biletSeria, biletNr, waznoscBiletuDo, waznoscBiletuOd, odcinki}) => {
        return terms.every((term) => {
          const filterTickedSerialAndNumber = `${biletSeria.toLowerCase()}${biletNr.toString()}`.includes(term);
          const filterValidFromEuropeanFormat = waznoscBiletuOd
            ? formatDateToEuropean(waznoscBiletuOd).toLowerCase().includes(term)
            : false;
          const filterValidToEuropeanFormat = waznoscBiletuDo
            ? formatDateToEuropean(waznoscBiletuDo).toLowerCase().includes(term)
            : false;
          const filterStationFrom = odcinki.some(({stacjaOdKod}) =>
            getStationByCodeIBNR(stacjaOdKod, stationsList)?.nazwa.toLowerCase().includes(term),
          );
          const filterStationTo = odcinki.some(({stacjaDoKod}) =>
            getStationByCodeIBNR(stacjaDoKod, stationsList)?.nazwa.toLowerCase().includes(term),
          );

          return (
            filterTickedSerialAndNumber ||
            filterValidFromEuropeanFormat ||
            filterValidToEuropeanFormat ||
            filterStationFrom ||
            filterStationTo
          );
        });
      });
    });
  }, [dataToFilter, searchTerm, isFetching, stationsList]);

  const isFilteredResultsEmpty = filteredList?.length === 0;

  return {filteredList, isFilteredResultsEmpty, searchTerm, handleSearchChange} as const;
};
