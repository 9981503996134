import S from './MyTicketsRouteModal.module.scss';

export type TimeType = 'arrival' | 'departure';

export enum RouteStatus {
  BEFORE_DEPARTURE = 'before_departure',
  DEPARTED = 'departed',
  ARRIVED = 'arrived',
  UNKNOWN = 'unknown',
}

export const getRouteStatusText = (routeStatus: RouteStatus) => {
  switch (routeStatus) {
    case RouteStatus.BEFORE_DEPARTURE:
      return (
        <>
          <p className={S.statusText}>Twój pociąg jeszcze nie odjechał ze stacji początkowej.</p>
          <p className={S.statusText}>Zobacz planowaną trasę przejazdu:</p>
        </>
      );
    case RouteStatus.DEPARTED:
      return <p className={S.statusText}>Zobacz aktualne położenie pociągu na mapie:</p>;
    case RouteStatus.ARRIVED:
      return (
        <>
          <p className={S.statusText}>Twój pociąg dojechał do stacji docelowej.</p>
          <p className={S.statusText}>Zobacz archiwalną trasę przejazdu:</p>
        </>
      );
    case RouteStatus.UNKNOWN:
      return <p className={S.statusText}>Brak informacji o statusie pociągu.</p>;
    default:
      return;
  }
};
