import {useQuery, useQueries, useQueryClient} from '@tanstack/react-query';
import {staleTime} from './consts';
import {MyTicketsRequestParams, MyTicketRequestParams, MyTicketsReservationListParams} from './types';
import {getMyTickets, getMyTicket} from './services';
import {myTicketsListVariantInvalidateParams} from 'common/pages/UserProfile/MyTickets';

export const useMyTicketsData = (params: MyTicketsRequestParams | undefined) => {
  const {isLoading, isFetching, isFetched, data} = useQuery({
    queryKey: params ? ['myTickets', params.biletTyp, params.listaTyp, params.historia, params.numerStrony] : [],
    queryFn: params ? () => getMyTickets(params) : undefined,
    staleTime: staleTime,
    enabled: !!params,
  });

  return {isLoading, isFetching, isFetched, data} as const;
};

export const useMyTicketsPageData = (params: MyTicketRequestParams[] | undefined) => {
  const combinedQueries = useQueries({
    queries: params
      ? params.map((param) => ({
          queryKey: ['myTicket', param.transakcjaNr, param.biletSeria, param.biletNr],
          queryFn: () =>
            getMyTicket(
              new MyTicketRequestParams(param.transakcjaNr || null, param.biletSeria || null, param.biletNr || null),
            ),
          staleTime: staleTime,
        }))
      : [],
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        isLoading: results.some((result) => result.isLoading),
        isFetching: results.some((result) => result.isFetching),
      };
    },
  });

  return combinedQueries;
};

export const useMyTicketsReservationListData = (params: MyTicketsReservationListParams | undefined) => {
  const {isLoading, isFetching, isFetched, data} = useQuery({
    queryKey: params ? ['myTicketsReservationList', params.biletPodstawowySeria, params.biletPodstawowyNr] : [],
    queryFn: params ? () => getMyTickets(params) : undefined,
    staleTime: staleTime,
    enabled: !!params,
  });

  return {isLoading, isFetching, isFetched, data} as const;
};

export const useInvalidateMyTicketsQueries = () => {
  const queryClient = useQueryClient();
  const invalidateSingleTickets = () => {
    queryClient.invalidateQueries({
      queryKey: ['myTickets', ...myTicketsListVariantInvalidateParams.SINGLE],
    });
  };
  const invalidateSeasonalTickets = () => {
    queryClient.invalidateQueries({
      queryKey: ['myTickets', ...myTicketsListVariantInvalidateParams.SEASONAL],
    });
  };
  const invalidateHistoryTickets = () => {
    queryClient.invalidateQueries({queryKey: ['myTickets', ...myTicketsListVariantInvalidateParams.HISTORY]});
  };
  const invalidateReturnedTickets = () => {
    queryClient.invalidateQueries({queryKey: ['myTickets', ...myTicketsListVariantInvalidateParams.RETURNED]});
  };
  const invalidateMyTicket = (
    transactionNumber: number | null | undefined,
    ticketSerial: string | null | undefined,
    ticketNumber: number | null | undefined,
  ) => {
    queryClient.invalidateQueries({
      queryKey: ['myTicket', transactionNumber, ticketSerial, ticketNumber],
    });
  };
  const invalidateReservationList = (
    ticketSerial: string | null | undefined,
    ticketNumber: number | null | undefined,
  ) => {
    queryClient.invalidateQueries({
      queryKey: ['myTicketsReservationList', ticketSerial, ticketNumber],
    });
  };
  const invalidateMyTickets = () => {
    queryClient.invalidateQueries({queryKey: ['myTickets']});
  };

  return {
    invalidateSingleTickets,
    invalidateSeasonalTickets,
    invalidateHistoryTickets,
    invalidateReturnedTickets,
    invalidateMyTicket,
    invalidateReservationList,
    invalidateMyTickets,
  } as const;
};
