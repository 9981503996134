export const staleTime = 5 * 60 * 1000;

export const requestTicketTypes = {
  SINGLE_TICKET: 1,
  SEASON_TICKET: 2,
  SEASON_RESERVATION: 3,
  INTERNATIONAL_TICKET: 4,
  TICKET_FROM_OFFICE: 5,
  COLOR_CARD_TICKET: 6,
  SINGLE_AND_RESERVATION_AND_INTERNATIONAL: 101,
  SEASONAL_AND_OFFICE_AND_COLOR: 102,
  ALL: 103,
} as const;

export const requestTicketListTypes = {
  TICKETS_PAID: 0,
  PENDING_TICKETS: 1,
  TICKETS_RETURNED: 2,
  TICKETS_OR_RESERVATIONS_PAID_AND_RETURNED: 5,
  SINGLE_TICKETS_CANCELED: 8,
  SEASON_TICKETS_CANCELED: 9,
  INTERNATIONAL_TICKETS_CANCELED: 10,
  SINGLE_TICKETS_CANCELED_AND_RETURNED: 11,
  RESERVATIONS_CANCELED: 12,
  PAID_AND_PENDING: 101,
  ALL_RETURNED_AND_CANCELED: 102,
} as const;

export const ticketTypes = {
  TICKET: 1,
  TICKET_WITH_RESERVATION: 2,
  RESERVATION: 3,
  SEASON_TICKET: 4,
  TICKET_OFFICE: 5,
  RESERVATION_NATIONAL_SEASON_TICKET: 30,
  INTERCITY_CARD_RESERVATION: 32,
} as const;

export const ticketKinds = {
  TICKET: 1,
  TICKET_WITH_RESERVATION: 2,
  TICKET_WITH_RESERVATION_FIRST_SECTION: 3,
  TICKET_WITH_RESERVATION_SECOND_SECTION: 4,
  TICKET_WITH_RESERVATION_THIRD_SECTION: 5,
  RESERVATION: 10,
  BICYCLE: 20,
  DOG: 21,
  GUIDE_DOG: 22,
  LUGGAGE: 23,
  INTERNATIONAL_BICYCLE: 24,
  RESERVATION_NATIONAL_SEASON_TICKET: 30,
  INTERNATIONAL_EPA: 31,
  INTERCITY_CARD_RESERVATION: 32,
} as const;

export const ticketSourceCodes = {
  USS: 1,
  EIC: 2, // Office Tickets & EIC1
} as const;

export const ticketTransfers = {
  NONE: 0,
  FREE_ACCESS_BY_PUBLIC_TRANSPORT: 1,
  PAID_ACCESS_BY_PUBLIC_TRANSPORT: 2,
} as const;

export const ticketGenderCodes = {
  ANY: 0,
  MALE: 1,
  FEMALE: 2,
} as const;
