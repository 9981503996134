import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseBtn} from 'assets/closeBtn.svg';
import S from './ErrorColouredLabel.module.scss';

interface IErrorColouredLabel {
  displayText?: string;
  errorsTextArr?: Array<string>;
  handleClose?: () => void;
  bgColor?: 'red' | 'blue' | 'yellow';
  width100Percent?: boolean;
}

const ErrorColouredLabel: FC<IErrorColouredLabel> = ({
  displayText,
  errorsTextArr,
  handleClose,
  bgColor,
  width100Percent,
}) => {
  const {t} = useTranslation();
  const returnArray = (errorsArray: string[]) => {
    return errorsArray.map((item, index) => {
      return (
        <span key={index} className={`${S.errorBlue__container_text}`}>
          {t(item)}
        </span>
      );
    });
  };

  const returnText = () => {
    return <span className={`${S.errorBlue__container_text}`}>{displayText ? displayText : t('27009') + '!'}</span>;
  };

  const returnColorClassName = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!bgColor) {
      if (bgColor === 'red') {
        return S.bg_red;
      }

      if (bgColor === 'blue') {
        return S.bg_blue;
      }

      if (bgColor === 'yellow') {
        return S.bg_yellow;
      }
    } else {
      return S.bg_blue;
    }
  };

  const returnWidthClassName = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!width100Percent) {
      return S.max_width;
    }

    return S.default_width;
  };

  return (
    <div className={`${S.errorBlue__container} ${returnColorClassName()} ${returnWidthClassName()}`}>
      <div className={`${S.errorBlue__container_columnFlex}`}>
        {errorsTextArr ? returnArray(errorsTextArr) : returnText()}
      </div>
      {handleClose && (
        <button className={`${S.errorBlue__container_closeBtn}`} onClick={handleClose} aria-label={t('28002')}>
          <CloseBtn />
        </button>
      )}
    </div>
  );
};

export default ErrorColouredLabel;
