import {ISeasonTicketPeriodic} from '../type';
import {SEASONAL_OFFER_CODE_KINDS} from 'common/consts';
import i18n from 'i18n';
const t = i18n.t;
export const SEASONAL_TICKETS_CURRENT_OFFER_TYPE_CODES = Object.keys(SEASONAL_OFFER_CODE_KINDS)
  .filter((key: string) => !isNaN(Number(key)))
  .map((code: string) => Number(code))
  .filter((code: number) => code !== SEASONAL_OFFER_CODE_KINDS.INTERRAIL);
export const UUT_IDS = [SEASONAL_OFFER_CODE_KINDS.UUT_QUARTERLY, SEASONAL_OFFER_CODE_KINDS.UUT_YEARLY];
export const SEASON_TICKET_MAX_DISTANCE = 240;
export const SEASON_TICKET_DISTANCE_MARGIN = 10;
export const SEASON_TICKET_DISTANCE_LIMIT = SEASON_TICKET_MAX_DISTANCE + SEASON_TICKET_DISTANCE_MARGIN;
export const SEASON_TICKET_MOST_POPULAR_DISCOUNT_LIMIT = 3;
export const SEASON_TICKET_OFFER_TYPE_CODE = 2;
export const SEASON_TICKET_DEFAULT_DISCOUNT_CODE: number = 1010;
export const SEASON_TICKET_DEFAULT_RAILWAY_EMPLOYER = 0;
export const SEASON_TICKET_PERIODIC_LIST: ISeasonTicketPeriodic[] = [
  {
    label: t('13124'),
    value: 1, // weekly,
  },
  {
    label: t('13125'),
    value: 2, // monthly,
  },
  {
    label: t('13126'),
    value: 3, // quarterly,
  },
];
