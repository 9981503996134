import {FC, ReactNode, useState} from 'react';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

import S from './Accordion.module.scss';
import expandIcon from 'assets/expandIcon.svg';
import expandIconContrast from 'assets/contrastExpandIcon.svg';

interface Props {
  header: string;
  helperHeader?: string;
  children: ReactNode;
  headerOnRight?: boolean;
  isHeaderUnderlined?: boolean;
  headerAltStyle?: boolean;
}

const Accordion: FC<Props> = ({
  header,
  helperHeader,
  children,
  headerOnRight,
  isHeaderUnderlined = true,
  headerAltStyle,
}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [open, setOpen] = useState(false);

  return (
    <article className={S.accordionWrapper}>
      <button
        aria-label={t('16026')}
        className={`${headerAltStyle ? S.headerAltStyle : S.header} ${headerOnRight && S.headerOnRight}  ${isHeaderUnderlined && S.headerUnderlined}`}
        onClick={() => setOpen(!open)}
      >
        <span>{header}</span>
        <span className={S.visuallyHidden}>{helperHeader}</span>
        <img
          src={isContrastTheme ? expandIconContrast : expandIcon}
          className={`${S.expandIcon} ${open && S.iconActive}`}
          alt={open ? t('10038') : t('10039')}
        />
      </button>
      {open && <div className={S.contentWrapper}>{children}</div>}
    </article>
  );
};

export default Accordion;
