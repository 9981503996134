import {FC} from 'react';
import S from './Badge.module.scss';

interface BadgeProps {
  value: number;
  customBg?: 'orange' | 'light-blue' | 'gray' | 'red';
  customTxtColor?: 'dark-blue' | 'red';
  littleMode?: boolean;
}

const Badge: FC<BadgeProps> = ({value, customBg, customTxtColor, littleMode}) => {
  const bgClasssName = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!customBg) {
      if (customBg === 'orange') return S.badge_color_orange;
      if (customBg === 'light-blue') return S.badge_color_lb;
      if (customBg === 'gray') return S.badge_color_gray;
      if (customBg === 'red') return S.badge_color_red;
    }

    return S.badge_color_default;
  };

  const txtClassName = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!customTxtColor) {
      if (customTxtColor === 'dark-blue') return S.dark_text;
      if (customTxtColor === 'red') return S.error_text;
    }
  };

  return <div className={`${littleMode ? S.littleBadge : S.badge} ${bgClasssName()} ${txtClassName()}`}>{value}</div>;
};

export default Badge;
