/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {useState, FC, useContext, useEffect, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import ExpandIcon from 'assets/expandIcon.svg';
import ExpandIconContrast from 'assets/contrastExpandIcon.svg';
import CheckIcon from 'assets/orange-check.svg';
import CheckIconContrast from 'assets/contrast-check.svg';
import S from './ExpandableWindow.module.scss';
import {TicketContext} from 'Utils/TicketContext';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import {SearchContext} from '../../../Utils/SearchContext';
import {useWindowWidth} from 'Utils/hooks';
import {useStyledThemeState} from 'common/theme';
import ErrorYellow from '../ErrorYellow';
import {IPkpOffers} from 'api/sprzedaz/interfaces';

interface ConfirmWindowDataProps {
  title: string;
  train?: string;
  details: ReactElement | string;
  option: string;
  promoName?: 'familyTicket' | 'bigFamily' | 'cheaperWithRelatives';
}
const ExpandableWindow: FC<ConfirmWindowDataProps> = ({title, train, details, option, promoName}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const [openModal, setOpenModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const {searchFormFrom} = useContext(SearchContext);
  const {
    pkpOffers,
    setPkpOffers,
    disableOffer,
    canApplyOffer,
    offersExpandableError,
    adultTicketsList,
    childrenTicketsList,
  } = useContext(TicketContext);
  const isMobile = useWindowWidth() <= 800;
  const autoOffersDisabledFromStorage = window.sessionStorage.getItem('autoOffersDisabled');
  const offersEnabledFromStorage = window.sessionStorage.getItem('offersEnabled');

  const handleOpenModal = () => {
    const msg = getConfirmationMsg();
    if (msg) {
      setOpenModal(true);
    } else {
      handlePkpOfferSelection();
    }
  };

  const handlePkpOfferSelection = () => {
    setOpenModal(false);

    if (promoName) {
      if (promoName === 'familyTicket' && pkpOffers.bigFamily) {
        setPkpOffers(`autoOffersDisabled:bigFamily`);
        disableOffer('bigFamily');
      }

      setPkpOffers(promoName) && setIsActive(pkpOffers[promoName]);

      if (autoOffersDisabledFromStorage) {
        const offersFromStorageSplitted = autoOffersDisabledFromStorage.split(';');
        const isAutoOfferDisabled = offersFromStorageSplitted.find((x) => x === promoName);

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!isAutoOfferDisabled) {
          const offersDisabledFromStorageWithoutOffer = offersFromStorageSplitted.filter((x) => x !== promoName);

          if (offersDisabledFromStorageWithoutOffer.length > 0) {
            window.sessionStorage.setItem(
              'autoOffersDisabled',
              offersDisabledFromStorageWithoutOffer.toString().replaceAll(',', ';'),
            );
          } else {
            window.sessionStorage.removeItem('autoOffersDisabled');
          }
        }
      }
    }
  };

  const getConfirmationMsg = () => {
    if (promoName === 'bigFamily' && pkpOffers.familyTicket) {
      return t('16021');
    }
    if (promoName === 'cheaperWithRelatives' && pkpOffers.bigFamily) {
      return t('16022');
    }
    if (promoName === 'bigFamily' && pkpOffers.familyTicket && pkpOffers.cheaperWithRelatives) {
      return t('16023');
    }
    if (promoName === 'familyTicket' && pkpOffers.bigFamily) {
      return t('16024');
    }
    if (promoName === 'bigFamily' && pkpOffers.cheaperWithRelatives) {
      return t('16025');
    }
    return null;
  };

  const handleInactive = () => {
    if (promoName) {
      setPkpOffers(`autoOffersDisabled:${promoName}`);
      disableOffer(promoName);

      if (autoOffersDisabledFromStorage) {
        window.sessionStorage.setItem('autoOffersDisabled', `${autoOffersDisabledFromStorage}${promoName};`);
      } else {
        window.sessionStorage.setItem('autoOffersDisabled', `${promoName};`);
      }
    }

    setIsActive(false);
    setToggle(false);
  };

  useEffect(() => {
    promoName && setIsActive(pkpOffers[promoName]);

    const departureDate = searchFormFrom
      ? `${searchFormFrom?.date} ${searchFormFrom?.time}`
      : new Date().toDateString();

    if (canApplyOffer(departureDate, promoName)) {
      setButtonsEnabled(true);

      if (promoName && pkpOffers[promoName] && !toggle) {
        setToggle(true);
      }

      if (offersEnabledFromStorage) {
        const offersFromStorage = JSON.parse(offersEnabledFromStorage) as IPkpOffers;

        if (promoName && offersFromStorage[promoName] && !pkpOffers[promoName]) {
          setPkpOffers(promoName);
        }
      }

      return;
    }

    setButtonsEnabled(false);

    if (offersExpandableError && offersExpandableError.some((x) => x.offerName === promoName)) {
      setToggle(true);
      return;
    }

    setToggle(false);
  }, [
    searchFormFrom,
    adultTicketsList,
    childrenTicketsList,
    promoName,
    canApplyOffer,
    pkpOffers,
    offersExpandableError,
  ]);

  return (
    <>
      <div className={`${S.expandableWindow} ${isActive ? S.expandableWindow__active : ''}`}>
        <div aria-label={t('16026')} className={S.expandableWindow__upperContainer} onClick={() => setToggle(!toggle)}>
          <div style={{flexDirection: train ? 'column' : 'row'}} className={S.expandableWindow__textContainer}>
            <h3
              className={`${S.expandableWindow__title} ${isMobile ? S.expandableWindow__titleMobile : ''} ${isActive ? S.activeTitle : ''}`}
            >
              {isActive && (
                <img src={isContrastTheme ? CheckIconContrast : CheckIcon} alt={t('16027')} width={19} height={14} />
              )}
              {title}
            </h3>
            <p
              className={`${S.expandableWindow__title} ${S.expandableWindow__train} ${isMobile ? S.expandableWindow__titleMobile : ''}`}
            >
              {train}
            </p>
            <div className={S.datailsContainer}>
              <button
                className={`${S.expandableWindow__title} ${S.expandableWindow__detailsTitle} ${isMobile ? S.expandableWindow__titleMobile : ''}`}
                aria-label={`${t('22050')} ${option}`}
              >
                {t('16028')}
              </button>
              <img
                src={isContrastTheme ? ExpandIconContrast : ExpandIcon}
                alt={t('16029')}
                style={{
                  transition: '250ms',
                  transform: toggle ? 'rotate(180deg)' : '',
                  width: isMobile ? '11px' : '15px',
                  height: isMobile ? '7px' : '9px',
                }}
              />
            </div>
          </div>
        </div>
        <div className={S.expandableContainer} style={{maxHeight: `${toggle ? '500px' : '0'}`}}>
          <p
            className={`${S.expandableWindow__title} ${isMobile ? S.expandableWindow__titleMobile : ''} ${S.expandableWindow__details}`}
          >
            {details}
          </p>
          <fieldset style={{display: buttonsEnabled ? 'block' : 'none', border: 'none', padding: 0}}>
            <legend className={`${S.expandableWindow__title} ${isMobile ? S.expandableWindow__titleMobile : ''}`}>
              {t('16030', {oferta: option})}?
            </legend>
            <div className={S.expandableContainer__buttonContainer}>
              {isActive ? (
                <button
                  className={S.button}
                  style={{width: '35%'}}
                  onClick={handleInactive}
                  aria-label={`${t('16032')} ${option}`}
                >
                  {t('16031')}
                </button>
              ) : (
                <button
                  className={S.button}
                  style={{width: '60%'}}
                  onClick={() => promoName && handleOpenModal()}
                  aria-label={`${t('16030', {oferta: option})}`}
                >
                  {t('16033')}
                </button>
              )}
            </div>
          </fieldset>
          {offersExpandableError &&
            offersExpandableError.length > 0 &&
            offersExpandableError.some((x) => x.offerName === promoName) && (
              <ErrorYellow displayText={offersExpandableError.find((x) => x.offerName === promoName)?.offerError} />
            )}
        </div>
      </div>
      {openModal && (
        <div className={S.confirmModal}>
          <ConfirmationModal closeFn={setOpenModal} confirmFn={handlePkpOfferSelection}>
            <p>{getConfirmationMsg()}</p>
          </ConfirmationModal>
        </div>
      )}
    </>
  );
};
export default ExpandableWindow;
