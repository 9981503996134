import {FC, useEffect} from 'react';

import style from './BuyerProfile.module.scss';
import OrangePlus from 'assets/orangePlus.svg';
import ContrastPlus from 'assets/contrastPlus.svg';
import BluePlus from 'assets/bluePlus.svg';
import SingleProfile from './SingleProfile';
import AddBuyerProfile from './AddBuyerProfile';
import MyProfileHeader from 'components/common/MyProfileHeader';
import {useWindowResize} from 'common/hooks/screen';
import {useNavigate} from 'react-router-dom';
import api from 'api/user/repository';
import {BuyerProfile} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import {BuyerProfileActions, BuyerProfileScreen, useDispatch, useTrackedState} from 'Utils/BuyerProfileContext';
import EditBuyerProfile from 'components/pages/UserProfile/components/BuyerProfile/EditBuyerProfile';
import useSWR from 'swr';
import queryString from 'query-string';
import {useStyledThemeState} from 'common/theme';
import useMyTickets from '../MyTickets/useMyTickets';
import ErrorBlue from 'components/common/ErrorColouredLabel';
import BuyerProfileNoDataToDisplay from '../NoDataToDisplay/BuyerProfileNoDataToDisplay';
import SearchAndFilter from '../SearchAndFilter';
import ProfilePagination from '../ProfilePagination';
import {useTranslation} from 'react-i18next';

interface Props {
  navigateLink?: string;
}

const BProfile: FC<Props> = ({navigateLink}) => {
  const {t} = useTranslation();

  const {isContrastTheme} = useStyledThemeState();
  const navigate = useNavigate();

  const {
    searchPhase,
    setSearchPhase,
    filter,
    setFilter,
    page,
    setPage,
    returnActiveFiltersArrProfiles,
    searchBuyerProfile,
  } = useMyTickets();

  const {isMobile} = useWindowResize();
  const {screen, deleteProfileInfo} = useTrackedState();
  const dispatch = useDispatch();
  const q = queryString.parse(window.location.search);

  const profilesFilters = [
    {
      value: 'alfabetAZ',
      label: '29210',
      shortLabel: '29213',
      showArr: [1, 2, 3],
    },
    {
      value: 'alfabetZA',
      label: '29211',
      shortLabel: '29212',
      showArr: [1, 2, 3],
    },
  ];

  useEffect(() => {
    if (Object.keys(q).length > 1) {
      dispatch({
        type: BuyerProfileActions.SET_SCREEN,
        payload: BuyerProfileScreen.ADD,
      });
    }
  }, []);

  const {data} = useSWR<BuyerProfile[]>('get_user_profiles', () => api.getBuyerProfiles());

  const checkOrangePlus = isContrastTheme ? ContrastPlus : OrangePlus;
  const checkBluePlus = isContrastTheme ? ContrastPlus : BluePlus;

  const spanText = {__html: t('29209')};

  return (
    <>
      <section className={`grid col-12 col-sm-12 ${style.buyer_container}`}>
        <MyProfileHeader text={t('10004')} closeAction={() => navigate(navigateLink ?? '/profil')} />
        <div className={`grid col-12 col-sm-12 ${style.hide_on_mobile}`}>
          <SearchAndFilter
            dataToFilter={data?.length ? data?.length : 0}
            placeholderText={t('29206')}
            value={searchPhase}
            setValue={(e: string) => {
              setSearchPhase(e);
              setPage(1);
            }}
            filter={filter}
            setFilter={setFilter}
            filterList={profilesFilters}
          />
        </div>
        <div className="grid col-12 col-sm-12">
          {(!data || data?.length === 0) && (
            <BuyerProfileNoDataToDisplay
              redirect={false}
              text={t('29207')}
              imageTicket="train"
              execute={() => {
                dispatch({
                  type: BuyerProfileActions.SET_PROFILE_AND_SCREEN,
                  screen: BuyerProfileScreen.ADD,
                  profile: {name: ''},
                });
              }}
            />
          )}

          {!!data && data?.length > 0 && (
            <div className={`col-12 col-sm-12 ${style.profile_btn_box}`}>
              <button
                className={`${style.profile_add_btn}`}
                aria-label={t('29208')}
                onClick={() =>
                  dispatch({
                    type: BuyerProfileActions.SET_PROFILE_AND_SCREEN,
                    screen: BuyerProfileScreen.ADD,
                    profile: {name: ''},
                  })
                }
              >
                <img src={isMobile ? checkBluePlus : checkOrangePlus} alt="Plus" />
                <span dangerouslySetInnerHTML={spanText}></span>
              </button>
            </div>
          )}

          {deleteProfileInfo && (
            <div className={`col-12 col-sm-12 ${style.profile_box}`}>
              <ErrorBlue displayText={deleteProfileInfo} />
            </div>
          )}

          {data?.length
            ? returnActiveFiltersArrProfiles(searchBuyerProfile(data))
                .filter((el, index) => {
                  return index >= 5 * (page - 1) && index < 5 * page;
                })
                .map((profile: BuyerProfile) => {
                  return (
                    <div key={profile.id} className={`col-12 col-sm-12 ${style.profile_box}`}>
                      <SingleProfile profile={profile} />
                    </div>
                  );
                })
            : null}
          {data?.length ? (
            <ProfilePagination
              page={page}
              setPage={setPage}
              toDisplay={returnActiveFiltersArrProfiles(searchBuyerProfile(data)).length}
              perPage={5}
            />
          ) : null}
        </div>
      </section>
      {(() => {
        switch (screen) {
          case BuyerProfileScreen.ADD:
            return <AddBuyerProfile />;

          case BuyerProfileScreen.EDIT:
            return <EditBuyerProfile />;

          default:
            return null;
        }
      })()}
    </>
  );
};

export default BProfile;
