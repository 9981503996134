const EcoTrip = () => {
  return null;
  // <div className={S.ecoTripContainer}>
  //     <img
  //         src={isContrastTheme ? EcologyContrast : Ecology}
  //         alt="Znak Eco Podróż"
  //         className={`${isMobile ? S.ecoTripImageMobile : S.ecoTripImage}`}
  //     />
  //     <div>
  //         <p className={`${S.ecoTripText} ${isMobile ? S.ecoTripText__mobile : ''}`}>
  //             Wybierając PKP IC emitujesz 5kg CO<sub>2</sub>.
  //         </p>
  //         <p className={`${S.ecoTripText_small} ${isMobile ? S.ecoTripText__mobile_small : ''}`}>
  //             To mniej niż samochód o 12kg i samolot o 40kg!
  //         </p>
  //     </div>
  // </div>
};

export default EcoTrip;
