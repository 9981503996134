import {scroller} from 'react-scroll';

import api from 'api/faktury/repository';

import {IPaymentInvoiceData} from 'interfaces/forms';
import {IGenerateInvoiceElement, IGenerateInvoiceResponse} from 'api/faktury/interfaces';
import {IDownloadTicketResponse} from 'api/sprzedaz/interfaces';
import {env} from 'env';
import {isEUCountry} from 'components/common/Invoice/invoiceHelpers';
import {TFunction} from 'i18next';

export const generateInvoiceRequest = async (values: IPaymentInvoiceData, elements: IGenerateInvoiceElement[]) => {
  const isCompany = values.invoiceType === 'company';
  const chcekcPolishNip = values.isNotPolishNIP ? 2 : 1;
  const request = (singleElement: IGenerateInvoiceElement) => {
    return {
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'wystawFakture',
      nabywcaNazwa: isCompany ? values.companyName : `${values.personName} ${values.personSurname}`,
      nabywcaUlica: isCompany ? values.companyStreet : values.personStreet,
      nabywcaKodPocztowy: isCompany ? values.companyZipCode : values.personZipCode,
      nabywcaMiasto: isCompany ? values.companyCity : values.personCity,
      nabywcaNrBudynku: isCompany ? values.companyHouseNumber : values.personHouseNumber,
      nabywcaNrLokalu: isCompany ? values.companyLocalNumber : values.personLocalNumber,
      nabywcaRodzajIdentyfikatora: isCompany ? chcekcPolishNip : 0,
      nabywcaIdentyfikator: isCompany ? values.companyNip : '',
      adresatNazwa: '',
      adresatUlica: '',
      adresatKodPocztowy: '',
      adresatMiasto: '',
      adresatNrBudynku: '',
      adresatNrLokalu: '',
      elementy: [singleElement],
    };
  };

  const resultArray: IGenerateInvoiceResponse[] = [];
  await Promise.allSettled(
    elements.map(async (el) => {
      await api.generateInvoice(request(el)).then((data) => {
        resultArray.push(data);
      });
    }),
  );
  return resultArray;
};

export const generateInvoiceRequestForManyTickets = async (
  values: IPaymentInvoiceData,
  elements: IGenerateInvoiceElement[],
) => {
  const isCompany = values.invoiceType === 'company';
  const request = {
    urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
    metoda: 'wystawFakture',
    nabywcaNazwa: isCompany ? values.companyName : `${values.personName} ${values.personSurname}`,
    nabywcaUlica: isCompany ? values.companyStreet : values.personStreet,
    nabywcaKodPocztowy: isCompany ? values.companyZipCode : values.personZipCode,
    nabywcaMiasto: isCompany ? values.companyCity : values.personCity,
    nabywcaNrBudynku: isCompany ? values.companyHouseNumber : values.personHouseNumber,
    nabywcaNrLokalu: isCompany ? values.companyLocalNumber : values.personLocalNumber,
    nabywcaRodzajIdentyfikatora: isCompany ? (values.isNotPolishNIP ? 2 : 1) : 0,
    nabywcaIdentyfikator: isCompany ? values.companyNip : '',
    adresatNazwa: '',
    adresatUlica: '',
    adresatKodPocztowy: '',
    adresatMiasto: '',
    adresatNrBudynku: '',
    adresatNrLokalu: '',
    elementy: elements,
  };
  await api.generateInvoice(request).then((data) => data);
};

const handleInvoiceErrorScroll = (isMobile: boolean) => {
  scroller.scrollTo('invoiceValidationError', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: isMobile ? -500 : -850,
  });
};

export const isInvoiceFormValid = (
  t: TFunction,
  invoiceValues: IPaymentInvoiceData,
  setInvoiceError: (error: string) => void,
  isMobile: boolean,
): boolean => {
  if (invoiceValues.invoice) {
    if (!invoiceValues.invoiceEmailRules) {
      setInvoiceError(t('26060'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (invoiceValues.invoiceType === 'company' && !invoiceValues.isNotPolishNIP && !invoiceValues.companyNip.length) {
      setInvoiceError(t('26061'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      !invoiceValues.isNotPolishNIP &&
      !!invoiceValues.companyNip.length &&
      (!invoiceValues.companyName.length ||
        !invoiceValues.companyStreet.length ||
        !invoiceValues.companyHouseNumber.length ||
        !invoiceValues.companyZipCode.length ||
        !invoiceValues.companyCity.length ||
        !invoiceValues.companyCountry.length)
    ) {
      setInvoiceError(t('26061'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      invoiceValues.isNotPolishNIP &&
      !invoiceValues.companyCountry.length
    ) {
      setInvoiceError(t('26063'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      invoiceValues.isNotPolishNIP &&
      !!invoiceValues.companyNip.length &&
      isEUCountry(invoiceValues.companyCountry) &&
      !invoiceValues.companyNip.length
    ) {
      setInvoiceError(t('26061'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      invoiceValues.isNotPolishNIP &&
      !!invoiceValues.companyCountry.length &&
      (!invoiceValues.companyName.length ||
        !invoiceValues.companyStreet.length ||
        !invoiceValues.companyHouseNumber.length ||
        !invoiceValues.companyZipCode.length ||
        !invoiceValues.companyCity.length)
    ) {
      setInvoiceError(t('26063'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    if (
      invoiceValues.invoiceType === 'person' &&
      (!invoiceValues.personName.length ||
        !invoiceValues.personSurname.length ||
        !invoiceValues.personStreet.length ||
        !invoiceValues.personHouseNumber.length ||
        !invoiceValues.personZipCode.length ||
        !invoiceValues.personCity.length)
    ) {
      setInvoiceError(t('26063'));
      handleInvoiceErrorScroll(isMobile);
      return false;
    }
    return true;
  }
  return true;
};

export const getTicketsNumbersAndSeries = (
  tickets: (IDownloadTicketResponse | null | undefined)[],
): IGenerateInvoiceElement[] => {
  const data: IGenerateInvoiceElement[] = [];
  tickets?.forEach((list) => {
    if (list) {
      list?.bilety?.map((ticket) =>
        data.push({
          seria: ticket.biletSeria,
          nr: ticket.biletNr,
        }),
      );
    }
  });
  return data;
};
