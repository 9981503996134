import {styled} from '@mui/material';
import {mediaQueries} from 'common/theme/consts';

export const SearchContainer = styled('div')`
  position: relative;
`;

export const SearchIconContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.6rem;
  pointer-events: none;
`;

export const SearchInputStyled = styled('input')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid ${({theme}) => theme.color.border.primary};
  padding: 2.3rem 1.6rem;
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.shadow.tertiary};
  font-size: ${({theme}) => theme.text.size[12]};
  font-weight: ${({theme}) => theme.text.weight[700]};
  line-height: 1.7rem;
  &::placeholder {
    color: ${({theme}) => theme.color.text.disabled};
  }
  @media (${mediaQueries.sm}) {
    line-height: 3.2rem;
    border-radius: 4px;
    padding: 1.6rem;
    font-size: ${({theme}) => theme.text.size[16]};
    font-weight: ${({theme}) => theme.text.weight[600]};
    box-shadow: none;
    &::placeholder {
      color: ${({theme}) => theme.color.text.secondary};
    }
  }
`;
