import {FC, ChangeEvent, FocusEvent, ReactNode} from 'react';
import {useStyledThemeState} from 'common/theme';

import S from './BlueCheckbox.module.scss';

interface Props {
  label?: string;
  name: string;
  id: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onEnterPress?: () => void;
  customElement?: ReactNode;
}

const BlueCheckbox: FC<Props> = ({label, name, id, checked, onChange, onBlur, onEnterPress, customElement}) => {
  const {isContrastTheme} = useStyledThemeState();

  return (
    <div className={`${S.checkboxWrapper} ${isContrastTheme && S.contrast}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(event) => {
          if (onEnterPress && event.key === 'Enter') {
            onEnterPress();
          }
        }}
      />
      <label className={S.contrastLabel} htmlFor={id}>
        {label}
        &nbsp;
        {customElement && customElement}
      </label>
    </div>
  );
};

export default BlueCheckbox;
