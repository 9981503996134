import {ModalContainer} from 'common/components/base/Modal';
import {Text} from 'common/components/base/Text';
import Icon from 'common/components/base/Icon';
import Card from 'components/common/Card/Card';
import ConfirmButton from 'components/common/ConfirmButton';

import {Button, DetailsRow, ModalWrapper} from './TicketDetailsInfoModal.style';

interface DetailRow {
  title: string;
  text: string;
}

interface TicketDetailsInfoModalProps {
  showModal: boolean;
  onXClick: () => void;
  buttonText: string;
  onButtonClick: () => void;
  header?: string;
  details?: DetailRow[];
}

export const TicketDetailsInfoModal = ({
  showModal,
  onXClick,
  buttonText,
  onButtonClick,
  header,
  details = [],
}: TicketDetailsInfoModalProps) => {
  return (
    showModal && (
      <ModalContainer>
        <ModalWrapper>
          <DetailsRow>
            {header && <Text variant="H2">{header}</Text>}
            <Button onClick={onXClick}>
              <Icon icon="X" />
            </Button>
          </DetailsRow>
          {details.map(({title, text}, index) => (
            <Card key={title + index} title={title} variantClass="input">
              <Text variant="H5">{text}</Text>
            </Card>
          ))}
          <ConfirmButton text={buttonText} execute={onButtonClick} />
        </ModalWrapper>
      </ModalContainer>
    )
  );
};
