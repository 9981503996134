import React from 'react';
import {useStyledThemeState, useStyledThemeDispatch} from './useStyledTheme';
import {StyledThemeActions, isFontSizeScale, FontSizeScale} from '../types';
import {mappedFontSizeScales} from '../consts';
import {invert} from 'lodash';

export const useFontScale = () => {
  const {dispatch} = useStyledThemeDispatch();
  const {font} = useStyledThemeState();

  const currentFontScale = React.useMemo(
    () => Number(invert(mappedFontSizeScales)[font]),
    [font],
  ) as unknown as FontSizeScale;

  const setFontByScale = React.useCallback(
    (fontSizeScale: number) => {
      if (isFontSizeScale(fontSizeScale)) {
        dispatch({type: StyledThemeActions.SET_FONT, payload: mappedFontSizeScales[fontSizeScale]});
      } else {
        throw new Error(`${fontSizeScale} is not a valid font scale`);
      }
    },
    [dispatch],
  );

  return {setFontByScale, currentFontScale} as const;
};
