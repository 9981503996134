import {FC} from 'react';
import btnData from './btnsData.json';
import MyProfileHeader from 'components/common/MyProfileHeader';
import UsefulLinkButton from './UsefulLinkButton';

import style from './UsefulLinks.module.scss';
import {useTranslation} from 'react-i18next';
const UsefulLinks: FC = () => {
  const {t} = useTranslation();
  return (
    <div className={`grid col-12 col-sm-12 ${style.links_container}`}>
      <MyProfileHeader text={t('10022')} />
      <div className={`col-8 col-sm-8 ${style.link_box}`}>
        {btnData.map((btn) => {
          return (
            <UsefulLinkButton
              key={btn.id}
              text={t(btn.title)}
              infotxt={t(btn.infotxt)}
              link={btn.link}
              modalNeeded={btn.modal}
              mailTo={btn.mailTo}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UsefulLinks;
