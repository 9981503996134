/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import MaskedInput from 'react-text-mask';
import {isArray} from 'lodash';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

import GUSData from './GUSData';
import BlueCheckbox from '../BlueCheckbox/BlueCheckbox';
import CountriesSearcher from './CountriesSearcher';
import ForeignNipInputs from './ForeignNipInputs';
import NipModal from './NipModal';
import api from 'api/gus/repository';
import apiUser from 'api/user/repository';
import {IUserData} from 'api/user/interfaces';
import {IVerifyNipInGusData} from 'api/gus/interfaces';
import {IPaymentInvoiceData} from 'interfaces/forms';
import {getInvoiceFormFromUserData, initialCompanyInvoiceData, initialPersonInvoiceData} from 'Utils/forms';
import {invoiceCountriesList, InvoiceCountryOption} from 'Utils/invoiceCountriesList';
import {filterCountries} from 'Utils/commonFunctions';
import {
  validateCityName,
  validateCompanyName,
  validateStreetName,
  validateEmptyInput,
  isEUCountry,
} from './invoiceHelpers';

import style from './Invoice.module.scss';
import Warning from 'assets/redWarning.svg';
import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';
import ContrastArrowBottom from 'assets/contrastArrowBottomDesktop.svg';
import MovableLabelInput from 'components/common/MovableLabelInput';
import ConfirmButton from '../ConfirmButton';

interface Props {
  isUserPanel?: boolean;
  values: IPaymentInvoiceData;
  onInputChange: Dispatch<SetStateAction<IPaymentInvoiceData>>;
  basketMode?: boolean;
  basketId?: string;
  onSaveClick?: () => void;
  basketModeInvoiceError?: string;
}

const Invoice: FC<Props> = ({
  values,
  onInputChange,
  // isUserPanel,
  basketMode,
  basketId,
  onSaveClick,
  basketModeInvoiceError,
}) => {
  const {t, i18n} = useTranslation();
  const [verifiedNipData, setVerifiedNipData] = useState<IVerifyNipInGusData>();
  const [verifiedNipError, setVerifiedNipError] = useState<string>('');
  const [nipOptions, setNipOptions] = useState<IVerifyNipInGusData[]>([]);
  const [openNipModal, setOpenNipModal] = useState(false);
  const [searchCountryValue, setSearchCountryValue] = useState('');
  const [filteredCountries, setFilteredCountries] = useState<InvoiceCountryOption[]>([]);
  const [openCountryList, setOpenCountryList] = useState(false);
  const [userData, setUserData] = useState<IUserData>();
  const countryBoxRef = useRef<HTMLDivElement | null>(null);
  const isCompany = values.invoiceType === 'company';
  const countryList = [...invoiceCountriesList].sort((a, b) => a.namePL.localeCompare(b.namePL));
  const isCompanyAddressSaved: boolean = !!values.companyCountry.length;

  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});

  const {isContrastTheme} = useStyledThemeState();

  useEffect(() => {
    if (
      isCompanyAddressSaved &&
      !verifiedNipData &&
      !!values.companyNip.length &&
      !values.isNotPolishNIP &&
      !!values.companyName.length
    ) {
      const nipNumber = parseInt(values.companyNip.replaceAll('-', '').trim());
      !isNaN(nipNumber) &&
        api
          .getAndVerifyNipInGus(nipNumber)
          .then((data) => {
            !data && setVerifiedNipError(t('26001'));
            const values = data?.root.dane;
            if (!isArray(values) && !values?.ErrorCode) {
              values && setVerifiedNipData(values);
            }
            if (isArray(values) && !!values.length) {
              setVerifiedNipData(values[0]);
            }
          })
          .catch((e) => console.log(e));
    }
  }, [values.companyNip]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [countryBoxRef]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutside = (event: any) => {
    if (countryBoxRef.current && !countryBoxRef.current.contains(event.target)) {
      setOpenCountryList(false);
    }
  };

  const handleStreetNameValidation = (inputName: string, inputValue: string) => {
    const error = validateStreetName(inputValue);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[inputName]: removedError, ...rest} = validationErrors;
    setValidationErrors({...rest, ...(error && {[inputName]: error})});
  };

  const handleCityNameValidation = (inputName: string, inputValue: string) => {
    const error = validateCityName(inputValue);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[inputName]: removedError, ...rest} = validationErrors;
    setValidationErrors({...rest, ...(error && {[inputName]: error})});
  };

  const handleEmptyInputValidation = (inputName: string, inputValue: string) => {
    const error = validateEmptyInput(inputValue);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[inputName]: removedError, ...rest} = validationErrors;
    setValidationErrors({...rest, ...(error && {[inputName]: error})});
  };

  const handleCompanyNameValidation = (inputValue: string) => {
    const error = validateCompanyName(inputValue);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {companyName: removedError, ...rest} = validationErrors;
    setValidationErrors({...rest, ...(error && {companyName: error})});
  };

  const handleInvoiceInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value: newValue, type, checked} = e.target;
    const value = type === 'checkbox' ? checked : newValue;
    if (values.invoiceType === 'person' && values.personCountry === t('26002')) {
      name === 'personStreet' && handleStreetNameValidation(name, value as string);
      name === 'personCity' && handleCityNameValidation(name, value as string);
      name === 'personName' && handleEmptyInputValidation(name, value as string);
      name === 'personSurname' && handleEmptyInputValidation(name, value as string);
      name === 'personZipCode' && handleEmptyInputValidation(name, value as string);
      name === 'personHouseNumber' && handleEmptyInputValidation(name, value as string);
    }
    if (values.invoiceType === 'company' && values.companyCountry === t('26002')) {
      name === 'companyStreet' && handleStreetNameValidation(name, value as string);
      name === 'companyCity' && handleCityNameValidation(name, value as string);
      name === 'companyName' && handleCompanyNameValidation(value as string);
      name === 'companyHouseNumber' && handleEmptyInputValidation(name, value as string);
      name === 'companyZipCode' && handleEmptyInputValidation(name, value as string);
    }
    if (values.invoiceType === 'company' && !values.companyCountry.length) {
      name === 'companyCountry' && handleEmptyInputValidation(name, value as string);
    }
    if (values.invoiceType === 'company' && !!values.companyCountry.length) {
      name === 'companyStreet' && handleEmptyInputValidation(name, value as string);
      name === 'companyCity' && handleEmptyInputValidation(name, value as string);
      name === 'companyName' && handleEmptyInputValidation(name, value as string);
      name === 'companyHouseNumber' && handleEmptyInputValidation(name, value as string);
      name === 'companyZipCode' && handleEmptyInputValidation(name, value as string);
    }
    if (values.invoiceType === 'company' && !!values.companyCountry.length && isEUCountry(values.companyCountry)) {
      name === 'companyNip' && handleEmptyInputValidation(name, value as string);
    }
    onInputChange((prev) => ({...prev, [name]: value}));
  };

  const handleWithInvoiceChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target;
    if (checked && !userData) {
      await apiUser.getUserData().then((userData) => {
        if (userData && userData.address) {
          const formData = getInvoiceFormFromUserData(userData.address);
          onInputChange({...formData, [name]: true});
        } else {
          onInputChange((prev) => ({...prev, [name]: true}));
        }
        userData && setUserData(userData);
      });
    }
    if (checked && userData && userData.address) {
      const formData = getInvoiceFormFromUserData(userData.address);
      onInputChange({...formData, [name]: true});
    }
    if (checked && userData && !userData.address) {
      onInputChange((prev) => ({...prev, [name]: true}));
    }
    !checked && onInputChange((prev) => ({...prev, [name]: false}));
  };

  const handleEmailRulesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target;
    const resetInvoiceType = values.invoiceType === 'person' ? initialPersonInvoiceData : resetCompanyInvoiceData();
    onInputChange((prev) => ({...prev, [name]: checked, ...resetInvoiceType, invoiceType: 'person'}));
  };

  const handleInvoiceTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    const resetValues = value === 'person' ? resetCompanyInvoiceData() : initialPersonInvoiceData;
    onInputChange((prev) => ({...prev, [name]: value, ...resetValues}));
    setValidationErrors({});
  };

  const resetCompanyInvoiceData = () => {
    verifiedNipData && setVerifiedNipData(undefined);
    return initialCompanyInvoiceData;
  };

  const handlePolishNipChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target;
    const resetValues = resetCompanyInvoiceData();
    checked && setValidationErrors({});
    setVerifiedNipError('');
    onInputChange((prev) => ({...prev, ...resetValues, [name]: checked}));
  };

  const handleCountryChange = (country: InvoiceCountryOption) => {
    onInputChange((prev) => ({...prev, personCountry: country.namePL}));
    setOpenCountryList(false);
    if (country.namePL !== t('26002')) {
      setValidationErrors({});
    } else {
      const personStreetName = validateStreetName(values.personStreet);
      const personCityName = validateStreetName(values.personCity);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {personStreet: removedStreetError, personCity: removedCityError, ...rest} = validationErrors;
      setValidationErrors({
        ...rest,
        ...(personStreetName && {personStreet: personStreetName}),
        ...(personCityName && {personCity: personCityName}),
      });
    }
  };

  const handleVerifiedNipData = (values: IVerifyNipInGusData) => {
    setVerifiedNipData(values);
    onInputChange((prev) => ({
      ...prev,
      companyName: values.Nazwa,
      companyStreet: values.Ulica,
      companyHouseNumber: `${values.NrNieruchomosci}`,
      companyLocalNumber: `${values.NrLokalu}`,
      companyZipCode: values.KodPocztowy,
      companyCity: values.Miejscowosc,
      companyCountry: t('26002'),
    }));
    !!nipOptions.length && setNipOptions([]);
  };

  const getAndVerityNipInGus = (companyNip: string) => {
    setVerifiedNipError('');
    setVerifiedNipData(undefined);
    if (companyNip.length === 13) {
      const nipNumber = parseInt(companyNip.replaceAll('-', '').trim());
      !isNaN(nipNumber) &&
        api
          .getAndVerifyNipInGus(nipNumber)
          .then((data) => {
            !data && setVerifiedNipError(t('26001'));
            const values = data?.root.dane;
            if (!isArray(values) && !values?.ErrorCode) {
              values && handleVerifiedNipData(values);
            }
            if (isArray(values) && !!values.length) {
              setNipOptions(values);
              setOpenNipModal(true);
            }
            if (!isArray(values) && !!values?.ErrorCode) {
              i18n.language === 'pl' && values.ErrorMessagePl && setVerifiedNipError(values.ErrorMessagePl);
              i18n.language === 'en' && values.ErrorMessageEn && setVerifiedNipError(values.ErrorMessageEn);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    } else {
      setVerifiedNipError(t('26003'));
    }
  };

  const onSearchChange = (value: string) => {
    setSearchCountryValue(value);
    if (value.length > 0) {
      const filtered = filterCountries(value, countryList);
      setFilteredCountries(filtered);
    }
    if (value.length === 0) {
      setFilteredCountries([]);
    }
  };

  const activateNIPEdition = () => {
    setVerifiedNipData(undefined);
    setValidationErrors({});
    onInputChange((prev) => ({
      ...prev,
      companyName: '',
      companyStreet: '',
      companyHouseNumber: '',
      companyLocalNumber: '',
      companyZipCode: '',
      companyCity: '',
      companyCountry: t('26002'),
    }));
  };

  return (
    <>
      <div className={style.invoice_container}>
        {!basketMode && <h3 className={style.invoice_header}>{t('26004')}</h3>}
        {!basketMode && (
          <BlueCheckbox
            name="invoice"
            id="invoice"
            label={t('26005')}
            checked={values.invoice}
            onChange={handleWithInvoiceChange}
          />
        )}
        {values.invoice && (
          <div className={style.invoice_active_section}>
            <BlueCheckbox
              name="invoiceEmailRules"
              id={basketId ? `invoiceEmailRules_${basketId}` : 'invoiceEmailRules'}
              label={t('26006')}
              checked={values.invoiceEmailRules}
              onChange={handleEmailRulesChange}
            />
            {values.invoiceEmailRules && (
              <div className={style.invoice_sub}>
                <h2 className={style.invoice_header}>{t('29248')}</h2>
                <div className={style.invoice_sub_options}>
                  <label htmlFor="invoicePerson" className={style.invoice_input}>
                    <input
                      type="radio"
                      id={basketId ? `invoicePerson_${basketId}` : 'invoicePerson'}
                      name="invoiceType"
                      value="person"
                      checked={values.invoiceType === 'person'}
                      onChange={handleInvoiceTypeChange}
                    />
                    {t('26007')}
                  </label>
                  <label htmlFor="invoiceCompany" className={style.invoice_input}>
                    <input
                      type="radio"
                      id={basketId ? `invoiceCompany_${basketId}` : 'invoiceCompany'}
                      name="invoiceType"
                      value="company"
                      checked={values.invoiceType === 'company'}
                      onChange={handleInvoiceTypeChange}
                    />
                    {t('26008')}
                  </label>
                </div>
                {!isCompany && (
                  <>
                    <div className={`${style.input_box} ${validationErrors?.personName ? style.inputBoxError : ''}`}>
                      <MovableLabelInput
                        t={t}
                        name="personName"
                        type="text"
                        callback={handleInvoiceInputChange}
                        onBlur={handleInvoiceInputChange}
                        labelText="26009"
                        value={values.personName}
                      />
                    </div>
                    <div className={`${style.input_box} ${validationErrors?.personSurname ? style.inputBoxError : ''}`}>
                      <MovableLabelInput
                        t={t}
                        name="personSurname"
                        type="text"
                        callback={handleInvoiceInputChange}
                        onBlur={handleInvoiceInputChange}
                        labelText="26010"
                        value={values.personSurname}
                      />
                    </div>
                    <div className={`${style.input_box} ${validationErrors?.personStreet ? style.inputBoxError : ''}`}>
                      <MovableLabelInput
                        t={t}
                        name="personStreet"
                        type="text"
                        callback={handleInvoiceInputChange}
                        onBlur={handleInvoiceInputChange}
                        labelText="26011"
                        value={values.personStreet}
                      />
                    </div>
                    {validationErrors?.personStreet && validationErrors?.personStreet !== 'Empty' && (
                      <div className={style.validationError}>{validationErrors?.personStreet}</div>
                    )}
                    <div className={`grid ${style.personAddressGrid}`}>
                      <div
                        className={`col-6 col-sm-12 ${style.input_box} ${validationErrors?.personHouseNumber ? style.inputBoxError : ''}`}
                      >
                        <MovableLabelInput
                          t={t}
                          name="personHouseNumber"
                          type="text"
                          callback={handleInvoiceInputChange}
                          onBlur={handleInvoiceInputChange}
                          labelText="26012"
                          value={values.personHouseNumber}
                        />
                      </div>
                      <div className={`col-6 col-sm-12 ${style.input_box}`}>
                        <MovableLabelInput
                          t={t}
                          name="personLocalNumber"
                          type="text"
                          callback={handleInvoiceInputChange}
                          labelText="26013"
                          value={values.personLocalNumber}
                          hideRedStar
                        />
                      </div>
                    </div>
                    <div className={`${style.input_box} ${validationErrors?.personZipCode ? style.inputBoxError : ''}`}>
                      <MovableLabelInput
                        t={t}
                        name="personZipCode"
                        type="text"
                        callback={handleInvoiceInputChange}
                        onBlur={handleInvoiceInputChange}
                        labelText="26014"
                        value={values.personZipCode}
                        component={MaskedInput}
                        componentProps={{
                          mask: values.personCountry === 'Polska' ? [/\d/, /\d/, '-', /\d/, /\d/, /\d/] : false,
                          guide: false,
                          type: 'text',
                          inputMode: 'numeric',
                          id: 'personZipCode',
                          name: 'personZipCode',
                          value: values.personZipCode,
                          onChange: handleInvoiceInputChange,
                          onBlur: handleInvoiceInputChange,
                        }}
                      />
                    </div>
                    <div className={`${style.input_box} ${validationErrors?.personCity ? style.inputBoxError : ''}`}>
                      <MovableLabelInput
                        t={t}
                        name="personCity"
                        type="text"
                        callback={handleInvoiceInputChange}
                        onBlur={handleInvoiceInputChange}
                        labelText="26015"
                        value={values.personCity}
                      />
                    </div>
                    {validationErrors?.personCity && validationErrors?.personCity !== 'Empty' && (
                      <div className={style.validationError}>{validationErrors?.personCity}</div>
                    )}
                    <div className={style.personCountriesBox} ref={countryBoxRef}>
                      <div
                        className={`${style.personSelectCounty} ${openCountryList ? style.personSelectCountyActive : ''}`}
                        aria-label={t('26016')}
                        onClick={() => setOpenCountryList(!openCountryList)}
                      >
                        <select className={`col-12 ${style.custom_select}`} disabled>
                          <option value={values.personCountry}>{values.personCountry}</option>
                        </select>
                        <img src={isContrastTheme ? ContrastArrowBottom : ArrowBottom} alt={t('26017')} />
                      </div>
                      {openCountryList && (
                        <CountriesSearcher
                          searchValue={searchCountryValue}
                          onSearchValueChange={setSearchCountryValue}
                          onSearchChange={onSearchChange}
                          countryList={countryList}
                          filteredCountries={filteredCountries}
                          onFilteredCountriesChange={setFilteredCountries}
                          handleCountryChange={handleCountryChange}
                        />
                      )}
                    </div>
                    <div className={style.requiredMsg}>*{t('26018')}</div>
                  </>
                )}
                {isCompany && (
                  <>
                    <BlueCheckbox
                      name="isNotPolishNIP"
                      id="isNotPolishNIP"
                      label={t('26019')}
                      checked={values.isNotPolishNIP}
                      onChange={handlePolishNipChange}
                    />
                    {!values.isNotPolishNIP && (
                      <div className={style.input_box}>
                        <MaskedInput
                          type="search"
                          name="companyNip"
                          placeholder="NIP"
                          mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                          inputMode="numeric"
                          value={values.companyNip}
                          guide={false}
                          onChange={handleInvoiceInputChange}
                          disabled={!!verifiedNipData}
                        />
                      </div>
                    )}
                    {verifiedNipError && (
                      <p className={style.red_text}>
                        <img src={Warning} alt={t('26020')} />
                        {verifiedNipError}
                      </p>
                    )}
                    {!values.isNotPolishNIP && !verifiedNipData && (
                      <button
                        className={style.load_nip_button}
                        type="button"
                        onClick={() => getAndVerityNipInGus(values.companyNip)}
                      >
                        {t('26021')}
                      </button>
                    )}
                    {!values.isNotPolishNIP && verifiedNipData && (
                      <button className={style.load_nip_button} type="button" onClick={activateNIPEdition}>
                        {t('26022')}
                      </button>
                    )}
                    {verifiedNipData && !values.isNotPolishNIP && (
                      <GUSData
                        values={values}
                        handleInputChange={handleInvoiceInputChange}
                        validationErrors={validationErrors}
                      />
                    )}
                    {values.isNotPolishNIP && (
                      <ForeignNipInputs
                        values={values}
                        onInputChange={onInputChange}
                        handleInputChange={handleInvoiceInputChange}
                        validationErrors={validationErrors}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {openNipModal && !!nipOptions.length && (
        <NipModal
          handleClose={() => setOpenNipModal(false)}
          nipOptions={nipOptions}
          onSelectedNIpOption={handleVerifiedNipData}
        />
      )}
      {!!basketMode && !!values.invoice && !!values.invoiceEmailRules && (
        <p>
          <ConfirmButton text={t('15048')} disabled={!!basketModeInvoiceError} execute={onSaveClick} />
        </p>
      )}
    </>
  );
};

export default Invoice;
