import {ChangeEvent, FC, FocusEvent} from 'react';

import S from './OrangeRadioButton.module.scss';
import BlikIcon from 'assets/blik-icon.svg';
import CardIcon from 'assets/credit-card-icon.svg';
import Przelewy24Icon from 'assets/przelewy24-icon.svg';
import EcoLeafIcon from 'assets/greenLeafLogo.svg';

interface Props {
  label: string;
  name: string;
  id: string;
  checked: boolean;
  value: string;
  isFullWidth?: boolean;
  icon?: 'blik' | 'card' | 'przelewy24';
  icon2?: 'blik' | 'card' | 'przelewy24';
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  withEcoIcon?: boolean;
  disabled?: boolean;
}

const OrangeRadioButton: FC<Props> = ({
  label,
  name,
  id,
  checked,
  value,
  isFullWidth,
  icon,
  icon2,
  onChange,
  onBlur,
  withEcoIcon,
  disabled,
}) => {
  const getIcon = (name: string) => {
    switch (name) {
      case 'blik':
        return BlikIcon;
      case 'card':
        return CardIcon;
      case 'przelewy24':
        return Przelewy24Icon;
      default:
        return '';
    }
  };

  return (
    <label
      htmlFor={id}
      className={`${!disabled ? S.radioBtnContainer : S.radioBtnContainer_disabled} ${isFullWidth && S.fullWidth}`}
    >
      <span className={`${S.radioBtnLabelWrapper} ${isFullWidth && S.fullWidth}`}>
        <input
          className={`${!disabled ? S.radioBtn : S.radioBtn_disabled}`}
          type="radio"
          name={name}
          id={id}
          checked={checked}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <span className={`${!disabled ? S.radioBtnLabel : S.radioBtnLabel_disabled}`}>
          {label}
          {withEcoIcon && <img src={EcoLeafIcon} alt="" />}
        </span>
        {icon && <img src={getIcon(icon)} className={S.radioBtnImage} alt={icon} />}
        {icon2 && <img src={getIcon(icon2)} className={S.radioBtnImage} alt={icon2} />}
      </span>
    </label>
  );
};

export default OrangeRadioButton;
