import {useState} from 'react';
import {usePayment} from 'Utils/PaymentContext';
import apiPayment from 'api/sprzedaz/repository';
import {getTransactionNumbers} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';

import Doggo from 'assets/AdditionalTickets/pet.svg';
import Luggage from 'assets/AdditionalTickets/luggage.svg';
import Bicycle from 'assets/AdditionalTickets/bicycle.svg';
import DoggoContrast from 'assets/AdditionalTickets/petContrast.svg';
import LuggageContrast from 'assets/AdditionalTickets/luggageContrast.svg';
import BicycleContrast from 'assets/AdditionalTickets/bicycleContrast.svg';
import i18n from 'i18n';
const t = i18n.t;

const usePayLaterTicket = () => {
  const {isContrastTheme} = useStyledThemeState();

  const [name, setName] = useState('');
  const [payByBlik, setPayByBlik] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const {setTicketFrom, setTicketTo, setAdditionalTicketsTo, setAdditionalTicketsFrom} = usePayment();

  const getTicket = async () => {
    const unpaidTicket = window.localStorage.payLaterTicket;
    if (unpaidTicket) {
      const parsedTicket = JSON.parse(unpaidTicket);
      const tickArr = getTransactionNumbers(
        parsedTicket.ticketFrom,
        parsedTicket.ticketTo,
        parsedTicket.additionalTicketsFrom,
        parsedTicket.additionalTicketsTo,
        null,
      );

      const status = await apiPayment.getTransactionStatus(tickArr);

      const timeStamp = new Date(parsedTicket.ticketFrom.czasNaOplacenie);

      const checkTime = new Date().getTime() - timeStamp.getTime() > 15 * 1000 * 60;

      if (status?.statusy[0].statusKod === 1 && !checkTime) {
        setTicketFrom(parsedTicket.ticketFrom);
        setTicketTo(parsedTicket.ticketTo);
        setAdditionalTicketsTo(parsedTicket.additionalTicketsTo);
        setAdditionalTicketsFrom(parsedTicket.additionalTicketsFrom);
        setName(parsedTicket.traveler);
      } else {
        window.localStorage.removeItem('payLaterTicket');
        window.location.reload();
      }
    }
  };

  const returnAdditionalTypeTicketData = (type: number) => {
    if (type === 21 || type === 22) {
      return {
        image: isContrastTheme ? DoggoContrast : Doggo,
        nazwa: `${t('29152')} ${type === 22 ? 'p.' : ''}`,
      };
    } else if (type === 23) {
      return {
        image: isContrastTheme ? LuggageContrast : Luggage,
        nazwa: t('19007'),
      };
    } else if (type === 20) {
      return {
        image: isContrastTheme ? BicycleContrast : Bicycle,
        nazwa: t('29151'),
      };
    }
  };

  return {
    name,
    setName,
    payByBlik,
    setPayByBlik,
    showTicketModal,
    setShowTicketModal,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    getTicket,
    returnAdditionalTypeTicketData,
  };
};

export default usePayLaterTicket;
