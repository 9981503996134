import {ErrorBoundary} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

const GlobalErrorBoundary = ({children}: Props) => {
  const {t} = useTranslation();
  const globalErrorHandler = (error: Error, info: {componentStack: string}) => {
    console.error(error);
    console.log(info);
  };

  return (
    <ErrorBoundary
      onError={globalErrorHandler}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fallbackRender={({error, resetErrorBoundary}) => (
        <div role="alert">
          <p>Coś poszło nie tak!</p>
          <pre>{error.message}</pre>
          <button onClick={() => window?.location?.reload()}>{t('29060')}</button>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
