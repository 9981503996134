import {IErrorsList} from '../error-interface';

export enum DocumentType {
  UUTCard = 0, // Legitymacja UUT
  NationalDocumentAutomatic = 1, // Dokument krajowy - pobrany automatycznie z TLC
  NationalDocumentManual = 2, // Dokument krajowy - wpisany ręcznie przez użytkownika
  InternationalDocumentAutomatic = 3, // Dokument międzynarodowy - pobrany automatycznie z ETCD
  InternationalDocumentManual = 4, // Dokument międzynarodowy - wpisany ręcznie przez użytkownika
  ColourCard = 5, // Kolorowa karta
}

export type DocumentTypeValue = (typeof DocumentType)[keyof typeof DocumentType];
export interface ICheckDocumentRequest {
  urzadzenieNr: number;
  metoda: string;
  typDokumentu: DocumentType.UUTCard;
  seriaINumerDokumentu: string;
}

export interface ICheckDocumentResponse extends IErrorsList {
  statusDokumentu: 0 | 1 | 2; // 0 - ważny 1 - nieważny 2- nieznany
}

export interface IDownloadTransportDocumentPayload {
  typDokumentu: DocumentTypeValue;
  seriaDokumentu?: string; // Pole wymagane dla typDokumentu=1-4
  numerDokumentu?: string; // Pole wymagane dla typDokumentu=1-4
  kodDokumentu?: string; // Pole wymagane dla typDokumentu=5
  dataSprzedazy?: string; // format: RRRR-MM-DD
}

export interface IDownloadTransportDocumentFullPayload extends IDownloadTransportDocumentPayload {
  urzadzenieNr: number;
  metoda: 'pobierzDokumentPrzewozowy';
}

export interface IDownloadTransportDocumentResponse extends IErrorsList {
  dokumentyPrzewozowe: IDownloadTransportDocument[];
}

export type NameDocument = 0 | 1; // 0 - nie 1 - tak // Wykorzystywany na potrzeby kolorowych kart
export interface IDownloadTransportDocument {
  dataSprzedazy: string;
  obowiazujeOd: string;
  obowiazujeDo: string;
  numerOferty: number;
  klasa: number;
  liczbaN: number;
  liczbaU: number;
  kodZnizki: number;
  stacjaOdjazdu: number;
  stacjaPrzyjazdu: number;
  odleglosc: number;
  dokumnetImienny: NameDocument;
  podrozny: string; // Imię i nazwisko podróżnego
  seriaDokumentu: string;
  numerDokumentu: string;
  kwota: number;
  ptu: string;
  kwotaPtu: number;
  kategoriaPociagu: string;
}

export enum ChangeTransportOperation { // 0 - Zapisz 1 - Aktualizuj 2 - Usuń
  Save = 0,
  Update = 1,
  Delete = 2,
}

export type ChangeTransportOperationValue = (typeof ChangeTransportOperation)[keyof typeof ChangeTransportOperation];

export interface IChangeTransportDocumentPayload {
  operacja: ChangeTransportOperationValue;
  typDokumentu: DocumentTypeValue;
  seriaDokumentu: string;
  numerDokumentu: string;
  kodDokumentu?: string; // Kod dokumentu wykorzystywany w przypadku, kiedy serii i numeru nie da się wyodrębnić np. bilety DB, OBB // Pole obowiązkowe jeżeli jest nieuzupełniona seria i numer dokumentu
  dataSprzedazy: string;
  obowiazujeOd: string;
  obowiazujeDo: string;
  numerOferty: number;
  klasa: number;
  liczbaN: number;
  liczbaU: number;
  kodZnizki: number;
  stacjaOdjazdu: number;
  stacjaPrzyjazdu?: number;
  odleglosc: number;
  podrozny: string;
}
export interface IDeleteTransportDocumentPayload {
  seriaDokumentu: string;
  numerDokumentu: number;
  dataSprzedazy: string;
  numerOferty: number;
}
interface IBasePayloadData {
  urzadzenieNr: number;
  metoda: 'zmienDokumentPrzewozowy';
}
export interface IChangeTransportDocumentFullPayload extends IChangeTransportDocumentPayload, IBasePayloadData {}
export interface IDeleteTransportDocumentFullPayload extends IDeleteTransportDocumentPayload, IBasePayloadData {}
