import {ComponentProps} from 'react';
import ReactFocusLock from 'react-focus-lock';

import {StyledModal, StyledModalContainer} from './Modal.styles';

type ModalContainerProps = ComponentProps<typeof StyledModal>;

export const ModalContainer = ({children, ...rest}: ModalContainerProps) => {
  return (
    <StyledModalContainer>
      <ReactFocusLock>
        <StyledModal {...rest}>{children}</StyledModal>
      </ReactFocusLock>
    </StyledModalContainer>
  );
};
