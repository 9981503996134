import axios from 'axios';
import {env} from 'env';
import {QueryClient} from '@tanstack/react-query';
import {checkRefreshAndReturnJwt} from 'Utils/auth';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const api = axios.create({
  baseURL: `${env.REACT_APP_MAIN_API_URL}/server/`,
});

api.interceptors.request.use(async (config) => {
  const token = await checkRefreshAndReturnJwt();
  config.headers.Authorization = `Bearer ${token}`;
  config.data.urzadzenieNr = env.REACT_APP_DEVICE_NUMBER;

  return config;
});

api.interceptors.response.use(
  (response) => {
    /* 
        if you want to catch an error from this MARVELOUS api
        you need to check here if there is an 'bledy' property which is an array
        Why? Because all responses come with status code 200...
    */
    return response;
  },
  (error) => {
    if (error.response) {
      // error from api (but status code must be out of the range of 2xx)
    } else if (error.request) {
      // server no response
    } else {
      // server invalid request
    }

    return Promise.reject(error);
  },
);

// public api

export const publicApi = axios.create({
  baseURL: `${env.REACT_APP_MAIN_API_URL}/server/public/endpoint/`,
});

publicApi.interceptors.request.use(async (config) => {
  config.data.urzadzenieNr = env.REACT_APP_DEVICE_NUMBER;

  return config;
});

publicApi.interceptors.response.use(
  (response) => {
    /* 
        if you want to catch an error from this MARVELOUS api
        you need to check here if there is an 'bledy' property which is an array
        Why? Because all responses come with status code 200...
    */
    return response;
  },
  (error) => {
    if (error.response) {
      // error from api (but status code must be out of the range of 2xx)
    } else if (error.request) {
      // server no response
    } else {
      // server invalid request
    }

    return Promise.reject(error);
  },
);
