import {FC, ReactNode} from 'react';
import S from './FullPageContainer.module.scss';

interface Props {
  children: ReactNode;
}

const FullPageContainer: FC<Props> = ({children}) => {
  return <div className={S.fullPageContainer}>{children}</div>;
};

export default FullPageContainer;
