import {IconDefinition} from './Icon.types';

import {ReactComponent as TripThereIcon} from 'assets/tripThereOrangeDesktop.svg';
import {ReactComponent as TripBackIcon} from 'assets/tripBackOrangeDesktop.svg';
import {ReactComponent as TripThereIconContrast} from 'assets/tripThereContrastDesktop.svg';
import {ReactComponent as TripBackIconContrast} from 'assets/tripBackContrastDesktop.svg';
import {ReactComponent as CalendarIcon} from 'assets/calendar.svg';
import {ReactComponent as CalendarIconContrast} from 'assets/calendarContrast.svg';
import {ReactComponent as SpecialCalendar} from 'assets/specialCalendar.svg';
import {ReactComponent as SpecialCalendarContrast} from 'assets/specialCalendarContrast.svg';
import {ReactComponent as Info} from 'assets/infoIcon.svg';
import {ReactComponent as InfoContrast} from 'assets/contrastInfoIcon.svg';
import {ReactComponent as Exclamation} from 'assets/exclamation.svg';
import {ReactComponent as ExclamationContrast} from 'assets/exclamationContrast.svg';
import {ReactComponent as Checked} from 'assets/orangeCheckboxIcon.svg';
import {ReactComponent as CheckedContrast} from 'assets/contrastCheckboxIcon.svg';
import {ReactComponent as OrangeArrowLeft} from 'assets/orangeArrowLeft.svg';
import {ReactComponent as OrangeArrowLeftContrast} from 'assets/contrastArrowLeft.svg';
import {ReactComponent as X} from 'assets/orangeX.svg';
import {ReactComponent as XContrast} from 'assets/contrastX.svg';
import {ReactComponent as chevronLeft} from 'assets/chevronLeft.svg';
import {ReactComponent as chevronLeftContrast} from 'assets/chevronLeftContrast.svg';
import {ReactComponent as Pencil} from 'assets/orangePencil.svg';
import {ReactComponent as PencilContrast} from 'assets/contrastPencil.svg';
import {ReactComponent as Gear} from 'assets/gear.svg';
import {ReactComponent as GearContrast} from 'assets/contrastGear.svg';
import {ReactComponent as Preloader} from 'assets/preloader.svg';
import {ReactComponent as CardArrowIcon} from 'assets/cardArrow.svg';
import {ReactComponent as CardArrowIconContrast} from 'assets/cardArrowContrast.svg';
import {ReactComponent as TicketIcon} from 'assets/ticket.svg';
import {ReactComponent as TicketIconContrast} from 'assets/contrastTicket.svg';
import {ReactComponent as OrangeTicketIcon} from 'assets/orangeTicket.svg';
import {ReactComponent as PassengerIcon} from 'assets/profile.svg';
import {ReactComponent as PassengerIconContrast} from 'assets/profileContrastDesktop2.svg';
import {ReactComponent as ClassIcon} from 'assets/class.svg';
import {ReactComponent as ClassIconContrast} from 'assets/contrastClass.svg';
import {ReactComponent as ShoppingBasket} from 'assets/Basket.svg';
import {ReactComponent as ShoppingBasketContrast} from 'assets/BasketContrast.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/orangeArrowRightBig.svg';
import {ReactComponent as ArrowRightIconContrast} from 'assets/contrastArrowRightBig.svg';
import {ReactComponent as HomeIcon} from 'assets/home.svg';
import {ReactComponent as HomeIconContrast} from 'assets/homeContrast.svg';
import {ReactComponent as TrainOnRails} from 'assets/trainOnRails.svg';
import {ReactComponent as TrainOnRailsContrast} from 'assets/trainOnRailsContrast.svg';
import {ReactComponent as ManOnArmchair} from 'assets/manOnArmchairNavy.svg';
import {ReactComponent as ManOnArmchairContrast} from 'assets/manOnArmchairContrast.svg';
import {ReactComponent as ManOnWheelChair} from 'assets/SeatOptions/oznWheelchair.svg';
import {ReactComponent as ManOnWheelChairContrast} from 'assets/SeatOptions/oznWheelchairContrast.svg';
import {ReactComponent as ShoppingBasketThick} from 'assets/basketThick.svg';
import {ReactComponent as ShoppingBasketThickContrast} from 'assets/basketThickContrast.svg';
import {ReactComponent as CheckboxWithBackground} from 'assets/checkboxWithBackground.svg';
import {ReactComponent as CheckboxWithBackgroundContrast} from 'assets/checkboxWithBackgroundContrast.svg';
import {ReactComponent as UserIcon} from 'assets/userIcon.svg';
import {ReactComponent as UserIconContrast} from 'assets/userIconContrast.svg';
import {ReactComponent as ArrowRightBlue} from 'assets/navyArrowRight.svg';
import {ReactComponent as Clock} from 'assets/clock.svg';
import {ReactComponent as ClockContrast} from 'assets/clockContrast.svg';
import {ReactComponent as TripThereNavyIcon} from 'assets/tripThereMobile.svg';
import {ReactComponent as TripBackNavyIcon} from 'assets/tripBackMobile.svg';
import {ReactComponent as Qr} from 'assets/qr-code-icon.svg';
import {ReactComponent as QrContrast} from 'assets/qr-code-icon-contrast.svg';
import {ReactComponent as Magnifier} from 'assets/magnifier.svg';
import {ReactComponent as MagnifierContrast} from 'assets/magnifierContrast.svg';
import {ReactComponent as Wagon} from 'assets/wagon.svg';
import {ReactComponent as WagonContrast} from 'assets/wagonContrast.svg';

export const ICONS_DEFINITION: IconDefinition = {
  arrow: {
    normal: ArrowRightIcon,
    contrast: ArrowRightIconContrast,
    ariaLabel: 'strzałka w prawo',
  },
  home: {
    normal: HomeIcon,
    contrast: HomeIconContrast,
    ariaLabel: 'ikona domu',
  },
  tripThere: {
    normal: TripThereIcon,
    contrast: TripThereIconContrast,
    ariaLabel: 'strzałka w prawo',
  },
  tripBack: {
    normal: TripBackIcon,
    contrast: TripBackIconContrast,
    ariaLabel: 'strzałka w lewo',
  },
  calendar: {
    normal: CalendarIcon,
    contrast: CalendarIconContrast,
    ariaLabel: 'kalendarz',
  },
  specialCalendar: {
    normal: SpecialCalendar,
    contrast: SpecialCalendarContrast,
    ariaLabel: 'kalendarz',
  },
  exclamation: {
    normal: Exclamation,
    contrast: ExclamationContrast,
    ariaLabel: 'wykrzyknik',
  },
  info: {
    normal: Info,
    contrast: InfoContrast,
    ariaLabel: 'ikona informacji',
  },
  checked: {
    normal: Checked,
    contrast: CheckedContrast,
    ariaLabel: 'ikona zaznaczenia',
  },
  arrowLeft: {
    normal: OrangeArrowLeft,
    contrast: OrangeArrowLeftContrast,
    ariaLabel: 'strzałka w lewo',
  },
  X: {
    normal: X,
    contrast: XContrast,
    ariaLabel: 'ikona zamknięcia',
  },
  chevronLeft: {
    normal: chevronLeft,
    contrast: chevronLeftContrast,
    ariaLabel: 'szewron w lewo',
  },
  pencil: {
    normal: Pencil,
    contrast: PencilContrast,
    ariaLabel: 'ołówek',
  },
  gear: {
    normal: Gear,
    contrast: GearContrast,
    ariaLabel: 'koło zębate',
  },
  preloader: {
    normal: Preloader,
    contrast: Preloader,
    ariaLabel: 'preloader',
  },
  arrowCard: {
    normal: CardArrowIcon,
    contrast: CardArrowIconContrast,
    ariaLabel: 'ikona strzałki w lewo',
  },
  ticketIcon: {
    normal: TicketIcon,
    contrast: TicketIconContrast,
    ariaLabel: 'ikona biletu',
  },
  orangeTicketIcon: {
    normal: OrangeTicketIcon,
    contrast: OrangeTicketIcon,
    ariaLabel: 'ikona biletu',
  },
  passengerIcon: {
    normal: PassengerIcon,
    contrast: PassengerIconContrast,
    ariaLabel: 'ikona profilu',
  },
  classIcon: {
    normal: ClassIcon,
    contrast: ClassIconContrast,
    ariaLabel: 'ikona klasy pociągu',
  },
  shoppingBasket: {
    normal: ShoppingBasket,
    contrast: ShoppingBasketContrast,
    ariaLabel: 'koszyk zakupowy',
  },
  trainOnRails: {
    normal: TrainOnRails,
    contrast: TrainOnRailsContrast,
    ariaLabel: 'ikona pociągu',
  },
  manOnArmchair: {
    normal: ManOnArmchair,
    contrast: ManOnArmchairContrast,
    ariaLabel: 'ikona osoby siedzącej',
  },
  manOnWheelChair: {
    normal: ManOnWheelChair,
    contrast: ManOnWheelChairContrast,
    ariaLabel: 'ikona osoby na wózku inwalidzkim',
  },
  shoppingBasketThick: {
    normal: ShoppingBasketThick,
    contrast: ShoppingBasketThickContrast,
    ariaLabel: 'koszyk zakupowy',
  },
  checkboxWithBackground: {
    normal: CheckboxWithBackground,
    contrast: CheckboxWithBackgroundContrast,
    ariaLabel: 'ikona potwierdzenia',
  },
  userIcon: {
    normal: UserIcon,
    contrast: UserIconContrast,
    ariaLabel: 'ikona użytkownika',
  },
  arrowBlue: {
    normal: ArrowRightBlue,
    contrast: ArrowRightIconContrast,
    ariaLabel: 'strzałka w prawo',
  },
  clock: {
    normal: Clock,
    contrast: ClockContrast,
    ariaLabel: 'zegar',
  },
  tripThereNavy: {
    normal: TripThereNavyIcon,
    contrast: TripThereIconContrast,
    ariaLabel: 'strzałka w prawo',
  },
  tripBackNavy: {
    normal: TripBackNavyIcon,
    contrast: TripBackIconContrast,
    ariaLabel: 'strzałka w lewo',
  },
  QR: {
    normal: Qr,
    contrast: QrContrast,
  },
  magnifier: {
    normal: Magnifier,
    contrast: MagnifierContrast,
    ariaLabel: 'lupa',
  },
  wagon: {
    normal: Wagon,
    contrast: WagonContrast,
    ariaLabel: 'wagon',
  },
};
