import {FC} from 'react';
import ConfirmButton from 'components/common/ConfirmButton';
import {useStyledThemeState} from 'common/theme';

import style from './AccDelete.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import Envelope from 'assets/orangeEnvelope.svg';
import EnvelopeContrast from 'assets/contrastEnvelope.svg';
import {env} from 'env';
import {useTranslation} from 'react-i18next';

interface Props {
  close: () => void;
}

const AccDelete: FC<Props> = ({close}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const success = false;

  return (
    <section className={style.accDelete_container}>
      <div className={style.accDelete_container__box}>
        <header className={style.accDelete_container___box_header}>
          <h2>{success ? t('29013') : t('29014')}</h2>
          <button aria-label={t('29015')} onClick={close}>
            <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
          </button>
        </header>
        {success ? (
          <div className={style.accDelete__success_msg}>
            <img src={isContrastTheme ? EnvelopeContrast : Envelope} alt={t('29016')} />
            <p className={style.text_small}>{t('29017').toString()}</p>
          </div>
        ) : (
          <>
            <div>
              <p className={style.text_small}>{t('29018').toString()}</p>
              <h4 className={style.text_big}>{t('29019').toString()} </h4>
              <p className={style.text_small}>{t('29020', {interpolation: {escapeValue: false}}).toString()}</p>
              <p className={style.text_small}>{t('29021').toString()}</p>
            </div>
            {/* <MovableLabelInput 
                            name='password'
                            type='password'
                            labelText='Hasło'
                            value={pass}
                            callback={(e) => setPass(e.target.value)}
                        /> */}
          </>
        )}
        <div className={style.accDelete__btn_box} style={{marginTop: success ? '40px' : ''}}>
          <form
            name="delete-account"
            action={`${env.REACT_APP_SSO_SERVER}/account/delete`}
            method="post"
            className={style.form}
          >
            <input
              type="hidden"
              name="token"
              value={sessionStorage.eic2Token && JSON.parse(sessionStorage.eic2Token).access_token}
            />
            <ConfirmButton text={t('29050')} />
          </form>
          <ConfirmButton text={success ? t('21061') : t('22006')} execute={close} />
        </div>
      </div>
    </section>
  );
};

export default AccDelete;
