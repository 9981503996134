import {INewTicket} from 'api/sprzedaz/interfaces';
import {Discount} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';

/**
 * Gets tickets and converts them into discounts
 */
export default (adultsTickets: INewTicket[], childTickets: INewTicket[]): Discount[] => {
  const adult = (adultsTickets || []).map((ticket) => {
    return {
      type: 'ADULT',
      code: Number(ticket?.discountCode),
      railwayId: String(ticket?.ticketTypeCode),
    };
  });

  const child = (childTickets || []).map((ticket) => {
    return {
      type: 'CHILD',
      code: Number(ticket?.discountCode),
      railwayId: String(ticket?.ticketTypeCode),
    };
  });

  return [...child, ...adult];
};
