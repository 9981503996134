/* eslint-disable no-extra-boolean-cast */
import {FC, createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from 'react';
import {TFunction} from 'i18next';

import {
  ICancelTicketRequest,
  IGenerateAdditionalFeeResponse,
  IGenerateAdditionalTicketsResponse,
  IGenerateTicketResponse,
  IGenerateTicketSeatResponse,
  IGenerateTicketTripResponse,
} from 'api/sprzedaz/interfaces';

import api from 'api/sprzedaz/repository';

import {formatPrice, getLocationInDiferentLanguage, getTransactionNumbers} from './commonFunctions';
import {useShoppingBasket} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import {PlacePosition, Station} from 'common/api/localData';

export interface ExchangeTicket {
  tnr: string;
  bsr: string;
  bnr: string;
  travelerName: string;
  tickets: string[];
}

type IPaymentContext = {
  ticketFrom: IGenerateTicketResponse | null;
  setTicketFrom: Dispatch<SetStateAction<IGenerateTicketResponse | null>>;
  ticketTo: IGenerateTicketResponse | null;
  setTicketTo: Dispatch<SetStateAction<IGenerateTicketResponse | null>>;
  exchangeTicket: ExchangeTicket | null;
  setExchangeTicket: Dispatch<SetStateAction<ExchangeTicket | null>>;
  additionalTicketsFrom: IGenerateAdditionalTicketsResponse | null;
  setAdditionalTicketsFrom: Dispatch<SetStateAction<IGenerateAdditionalTicketsResponse | null>>;
  additionalTicketsTo: IGenerateAdditionalTicketsResponse | null;
  setAdditionalTicketsTo: Dispatch<SetStateAction<IGenerateAdditionalTicketsResponse | null>>;
  ecoOffsetDiscount: IGenerateAdditionalFeeResponse | null;
  setEcoOffsetDiscount: Dispatch<SetStateAction<IGenerateAdditionalFeeResponse | null>>;
  generateTicketFromErrors: string[];
  setGenerateTicketFromErrors: Dispatch<SetStateAction<string[]>>;
  generateTicketToErrors: string[];
  setGenerateTicketToErrors: Dispatch<SetStateAction<string[]>>;
  generateAddTicketFromErrors: string[];
  setGenerateAddTicketFromErrors: Dispatch<SetStateAction<string[]>>;
  generateAddTicketToErrors: string[];
  setGenerateAddTicketToErrors: Dispatch<SetStateAction<string[]>>;
  getPriceBeforeDiscount: (t: TFunction) => string | null;
  getFinalPriceAsNumber: () => number | null;
  getFinalPrice: (t: TFunction, showZeroPrice?: boolean) => string | null;
  getPTU: (t: TFunction, taxRate: 8 | 23) => string | null;
  getSeatPlaceLabels: (t: TFunction, trips: IGenerateTicketTripResponse[] | undefined) => string[];
  getSeatPlaceLabelStr: (
    t: TFunction,
    wagonNr: number,
    miejsceNr: number,
    usytowanieNazwa: string,
    location: PlacePosition[],
  ) => string;
  resetPaymentData: () => void;
  resetPaymentErrors: () => void;
  hasGuaranteedSeat: boolean;
  setHasGuaranteedSeat: Dispatch<SetStateAction<boolean>>;
  hasAcceptedNoSeat: boolean;
  setHasAcceptedNoSeat: Dispatch<SetStateAction<boolean>>;
  isGuaranteedSeatModalOpen: boolean;
  setIsGuaranteedSeatModalOpen: Dispatch<SetStateAction<boolean>>;
  viaStations: Station[];
  setViaStations: (viaStations: Station[]) => void;
  routeDistance: number | null;
  setRouteDistance: (distance: number | null) => void;
  travelerNameFromReservationTicket: string | null;
  setTravelerNameFromReservationTicket: Dispatch<SetStateAction<string | null>>;
};

const PaymentContext = createContext<IPaymentContext>({
  ticketFrom: null,
  setTicketFrom: () => null,
  ticketTo: null,
  setTicketTo: () => null,
  exchangeTicket: null,
  setExchangeTicket: () => null,
  additionalTicketsFrom: null,
  setAdditionalTicketsFrom: () => null,
  additionalTicketsTo: null,
  setAdditionalTicketsTo: () => null,
  ecoOffsetDiscount: null,
  setEcoOffsetDiscount: () => null,
  generateTicketFromErrors: [],
  setGenerateTicketFromErrors: () => null,
  generateTicketToErrors: [],
  setGenerateTicketToErrors: () => null,
  generateAddTicketFromErrors: [],
  setGenerateAddTicketFromErrors: () => null,
  generateAddTicketToErrors: [],
  setGenerateAddTicketToErrors: () => null,
  getPriceBeforeDiscount: () => null,
  getFinalPriceAsNumber: () => null,
  getFinalPrice: () => null,
  getPTU: () => null,
  getSeatPlaceLabels: () => [],
  getSeatPlaceLabelStr: () => '',
  resetPaymentData: () => null,
  resetPaymentErrors: () => null,
  hasGuaranteedSeat: true,
  setHasGuaranteedSeat: () => false,
  hasAcceptedNoSeat: false,
  setHasAcceptedNoSeat: () => false,
  isGuaranteedSeatModalOpen: false,
  setIsGuaranteedSeatModalOpen: () => false,
  viaStations: [],
  setViaStations: () => {},
  routeDistance: null,
  setRouteDistance: () => {},
  travelerNameFromReservationTicket: null,
  setTravelerNameFromReservationTicket: () => {},
});

export const usePayment = () => {
  return useContext(PaymentContext);
};

interface Props {
  children: ReactNode;
}

const PaymentProvider: FC<Props> = ({children}) => {
  const [ticketFrom, setTicketFrom] = useState<IGenerateTicketResponse | null>(null);
  const [ticketTo, setTicketTo] = useState<IGenerateTicketResponse | null>(null);
  const [exchangeTicket, setExchangeTicket] = useState<ExchangeTicket | null>(null);
  const [hasGuaranteedSeat, setHasGuaranteedSeat] = useState(true);
  const [hasAcceptedNoSeat, setHasAcceptedNoSeat] = useState(false);
  const [isGuaranteedSeatModalOpen, setIsGuaranteedSeatModalOpen] = useState(false);

  const [additionalTicketsFrom, setAdditionalTicketsFrom] = useState<IGenerateAdditionalTicketsResponse | null>(null);
  const [additionalTicketsTo, setAdditionalTicketsTo] = useState<IGenerateAdditionalTicketsResponse | null>(null);

  const [ecoOffsetDiscount, setEcoOffsetDiscount] = useState<IGenerateAdditionalFeeResponse | null>(null);

  const [generateTicketFromErrors, setGenerateTicketFromErrors] = useState<string[]>([]);
  const [generateTicketToErrors, setGenerateTicketToErrors] = useState<string[]>([]);

  const [generateAddTicketFromErrors, setGenerateAddTicketFromErrors] = useState<string[]>([]);
  const [generateAddTicketToErrors, setGenerateAddTicketToErrors] = useState<string[]>([]);

  const [viaStations, setViaStations] = useState<IPaymentContext['viaStations']>([]);
  const [routeDistance, setRouteDistance] = useState<IPaymentContext['routeDistance']>(null);

  const [travelerNameFromReservationTicket, setTravelerNameFromReservationTicket] = useState<string | null>(null);

  const {basket} = useShoppingBasket();

  const getFinalPriceAsNumber = (): number => {
    let final = 0;
    if (ticketFrom) {
      final = ticketFrom?.cenaBilet + ticketFrom?.cenaRowery + ticketFrom?.cenaRezerwacja;
    }
    if (ticketTo) {
      final = final + ticketTo?.cenaBilet + ticketTo?.cenaRowery + ticketTo?.cenaRezerwacja;
    }
    if (additionalTicketsTo) {
      final =
        final +
        additionalTicketsTo?.piesCena +
        additionalTicketsTo?.piesPrzewodnikCena +
        additionalTicketsTo?.bagazCena;
    }
    if (additionalTicketsFrom) {
      final =
        final +
        additionalTicketsFrom?.piesCena +
        additionalTicketsFrom?.piesPrzewodnikCena +
        additionalTicketsFrom?.bagazCena;
    }
    return final;
  };

  const getPriceBeforeDiscount = (t: TFunction): string | null => {
    const final = getFinalPriceAsNumber();
    let base = 0;
    if (ticketFrom) {
      base = ticketFrom?.cenaBazowa + ticketFrom?.cenaRowery + ticketFrom?.cenaRezerwacja;
    }
    if (ticketTo) {
      base = base + ticketTo?.cenaBazowa + ticketTo?.cenaRowery + ticketTo?.cenaRezerwacja;
    }
    if (additionalTicketsTo) {
      base =
        base + additionalTicketsTo?.piesCena + additionalTicketsTo?.piesPrzewodnikCena + additionalTicketsTo?.bagazCena;
    }
    if (additionalTicketsFrom) {
      base =
        base +
        additionalTicketsFrom?.piesCena +
        additionalTicketsFrom?.piesPrzewodnikCena +
        additionalTicketsFrom?.bagazCena;
    }
    return !!final && !!base && base > final ? formatPrice(t, base) : null;
  };

  const getFinalPrice = (t: TFunction, showZeroPrice = false): string | null => {
    const final = getFinalPriceAsNumber();
    return showZeroPrice
      ? formatPrice(t, final, showZeroPrice)
      : !!getFinalPriceAsNumber()
        ? formatPrice(t, final)
        : null;
  };

  const getPTU = (t: TFunction, taxRate: 8 | 23): string | null => {
    if (taxRate === 8) {
      const PTUBase = getFinalPriceAsNumber() - (ticketFrom?.cenaRowery || 0) - (ticketTo?.cenaRowery || 0);
      return !!PTUBase ? formatPrice(t, PTUBase - PTUBase / 1.08) : null;
    } else if (taxRate === 23) {
      const PTUBase = (ticketFrom?.cenaRowery || 0) + (ticketTo?.cenaRowery || 0);
      return !!PTUBase ? formatPrice(t, PTUBase - PTUBase / 1.23) : null;
    } else return null;
  };

  const getSeatPlaceLabels = (t: TFunction, trips: IGenerateTicketTripResponse[] | undefined) => {
    const labels: string[] = [];
    const seats: IGenerateTicketSeatResponse[] = [];

    trips?.forEach((el) => {
      el.miejsca.forEach((el2) => seats.push(el2));
    });

    if (seats && seats?.length > 0) {
      seats.forEach((el) => {
        if (el.wagonNr !== 0 && el.miejsceNr !== 0) {
          return labels.push(`${t('25001')} ${el.wagonNr}, ${t('21002')} ${el.miejsceNr}, ${el.usytowanieNazwa}`);
        } else {
          return labels.push(t('25003'));
        }
      });
    }
    return labels;
  };

  const getSeatPlaceLabelStr = (
    t: TFunction,
    wagonNr: number,
    miejsceNr: number,
    usytowanieNazwa: string,
    location: PlacePosition[],
  ) => {
    const usytuowanieInAllLangs = getLocationInDiferentLanguage(location, usytowanieNazwa);
    if (wagonNr !== 0 && miejsceNr !== 0) {
      return `${t('25001')} ${wagonNr}, ${t('21002')} ${miejsceNr}, ${usytuowanieInAllLangs}`;
    }
    return t('25003');
  };

  const resetPaymentData = () => {
    ticketFrom && setTicketFrom(null);
    ticketTo && setTicketTo(null);
    additionalTicketsFrom && setAdditionalTicketsFrom(null);
    additionalTicketsTo && setAdditionalTicketsTo(null);
    ecoOffsetDiscount && setEcoOffsetDiscount(null);
    sessionStorage.removeItem('payment-deadline');

    if (!basket && ticketFrom) {
      cancelAllTickets();
    }
  };

  const cancelAllTickets = () => {
    const transactions = getTransactionNumbers(
      ticketFrom,
      ticketTo,
      additionalTicketsFrom,
      additionalTicketsTo,
      ecoOffsetDiscount,
    );
    const promises = transactions.map(async (el) => {
      const payload: ICancelTicketRequest = {
        urzadzenieNr: 0,
        metoda: '',
        transakcjaNr: el,
      };
      return await api.cancelTicket(payload);
    });
    Promise.all(promises).catch((e) => console.log(e));
  };

  const resetPaymentErrors = () => {
    generateTicketFromErrors.length && setGenerateTicketFromErrors([]);
    generateTicketToErrors.length && setGenerateTicketToErrors([]);
    generateAddTicketFromErrors.length && setGenerateAddTicketFromErrors([]);
    generateAddTicketToErrors.length && setGenerateAddTicketToErrors([]);
  };

  const value = {
    ticketFrom,
    setTicketFrom,
    ticketTo,
    setTicketTo,
    exchangeTicket,
    setExchangeTicket,
    additionalTicketsFrom,
    setAdditionalTicketsFrom,
    additionalTicketsTo,
    setAdditionalTicketsTo,
    ecoOffsetDiscount,
    setEcoOffsetDiscount,
    generateTicketFromErrors,
    setGenerateTicketFromErrors,
    generateTicketToErrors,
    setGenerateTicketToErrors,
    generateAddTicketFromErrors,
    setGenerateAddTicketFromErrors,
    generateAddTicketToErrors,
    setGenerateAddTicketToErrors,
    getFinalPriceAsNumber,
    getFinalPrice,
    getPTU,
    getPriceBeforeDiscount,
    getSeatPlaceLabels,
    getSeatPlaceLabelStr,
    resetPaymentData,
    resetPaymentErrors,
    hasGuaranteedSeat,
    setHasGuaranteedSeat,
    hasAcceptedNoSeat,
    setHasAcceptedNoSeat,
    isGuaranteedSeatModalOpen,
    setIsGuaranteedSeatModalOpen,
    viaStations,
    setViaStations,
    routeDistance,
    setRouteDistance,
    travelerNameFromReservationTicket,
    setTravelerNameFromReservationTicket,
  };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};

export default PaymentProvider;
