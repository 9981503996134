/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import MyProfileHeader from 'components/common/MyProfileHeader';
import {useStyledThemeState, useToggleTheme, useFontScale} from 'common/theme';

import style from './AppSettings.module.scss';
import Minus from 'assets/orangeMinusMini.svg';
import Plus from 'assets/orangePlusMini.svg';
import MinusContrast from 'assets/contrastMinusMini.svg';
import PlusContrast from 'assets/contrastPlusMini.svg';
import {useTranslation} from 'react-i18next';
interface Props {
  navigateLink?: string;
}

const AppSettings: FC<Props> = ({navigateLink}) => {
  const {t, i18n} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const {toggleTheme} = useToggleTheme();
  const {setFontByScale, currentFontScale} = useFontScale();

  const navigate = useNavigate();

  const handleFontSizeChange = (increase?: boolean) => {
    const scale = increase ? currentFontScale + 1 : currentFontScale - 1;

    if ((!increase && scale >= 1) || (increase && scale <= 3)) {
      setFontByScale(scale);
    }
  };

  return (
    <section className={`grid col-12 ${style.settings_container}`}>
      <MyProfileHeader text={t('10020')} closeAction={() => navigate(navigateLink ?? '/')} />
      <div className={`col-8 col-md-10 col-sm-12 ${style.settings_box} ${style.settings_box__inactive}`}>
        <span>{t('29223')}</span>
        {i18n.language}
        {/* <button className={`${style.settings_orange_txt}`}>Zmień</button> */}
      </div>
      <div className={`col-8 col-md-10 col-sm-12 ${style.settings_box}`} onClick={toggleTheme}>
        <span>{t('29227')}</span>
        {isContrastTheme ? t('29228') : t('29229')}
        <button className={`${style.settings_orange_txt}`}>{t('21003')}</button>
      </div>
      <div className={`col-8 col-md-10 col-sm-12 ${style.settings_fontsize_box}`}>
        {t('29226')}
        <div className={`${style.settings_fontsize_btns}`}>
          <button
            aria-label={t('10007')}
            onClick={() => handleFontSizeChange()}
            disabled={currentFontScale <= 1}
            className={`${currentFontScale > 1 ? '' : style.btn_disabled}`}
          >
            <img src={isContrastTheme ? MinusContrast : Minus} alt="Minus" />
          </button>
          <div>{currentFontScale}</div>
          <button
            aria-label={t('10009')}
            onClick={() => handleFontSizeChange(true)}
            disabled={currentFontScale >= 3}
            className={`${currentFontScale < 3 ? '' : style.btn_disabled}`}
          >
            <img src={isContrastTheme ? PlusContrast : Plus} alt="Plus" />
          </button>
        </div>
      </div>
      <div className="col-8 col-md-10 col-sm-12" style={{height: '400px'}}></div>
    </section>
  );
};

export default AppSettings;
