import {getFullDate} from 'Utils/commonFunctions';
import {calendarWeekData} from 'components/common/Calendar/CalendarData';
import {parseISO, format} from 'date-fns';
import moment from 'moment';

const convertIsoDate = (isoDateString: string) => {
  try {
    const date = parseISO(isoDateString);
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  } catch (error) {
    console.error('Invalid date format', error);
    return '';
  }
};

// to DD.MM.YYYY
const formatDateToEuropean = (dateString: string): string => {
  try {
    const date = parseISO(dateString);
    return format(date, 'dd.MM.yyyy');
  } catch (error) {
    console.error('Invalid date format', error);
    return '';
  }
};

// from YYYY-MM-DD
const getWeekdayFromDate = (dateString: string): string => {
  const date = new Date(dateString);
  const weekdayNumber = (date.getDay() + 6) % 7;
  return calendarWeekData[weekdayNumber];
};

const getDayOfWeekInAccusative = (dateString: string): string => {
  const days = ['niedzielę', 'poniedziałek', 'wtorek', 'środę', 'czwartek', 'piątek', 'sobotę'];
  const date = new Date(dateString);
  return days[date.getDay()];
};
const updateToCurrentTimeIfToday = (isoDateString: string) => {
  const inputMoment = moment(isoDateString);
  const currentMoment = moment();

  if (inputMoment.isSame(currentMoment, 'day')) {
    inputMoment.hour(currentMoment.hour());
    inputMoment.minute(currentMoment.minute());
    inputMoment.second(currentMoment.second());
  }

  return inputMoment.toISOString();
};

const checkDateAndReturnTime = (date: string, shortTimeFormat: boolean = false): string => {
  const providedDate = moment(date);
  const now = moment();

  if (providedDate.isSame(now, 'day')) {
    return moment().format(shortTimeFormat ? 'HH:mm' : 'HH:mm:ss');
  } else return providedDate.startOf('day').format(shortTimeFormat ? 'HH:mm' : 'HH:mm:ss');
};

const getNextTuesdayOrCurrentDateTime = () => {
  const now = moment();
  const dayOfWeek = now.isoWeekday();
  const currentHour = now.hour();

  // Tuesday = 2, Wednesday = 3, Thursday = 4
  if (dayOfWeek === 2 || dayOfWeek === 3 || (dayOfWeek === 4 && currentHour < 15))
    return now.format('YYYY-MM-DD HH:mm:ss');
  else {
    // Find nearest next tuesday
    const daysToAdd = dayOfWeek >= 5 || (dayOfWeek === 4 && currentHour >= 15) ? 9 - dayOfWeek : 2 - dayOfWeek;
    return now.add(daysToAdd, 'days').startOf('day').format('YYYY-MM-DD 00:00:00');
  }
};

const makeStartDateQuarter = () => {
  const actualDate = new Date();
  return actualDate.toISOString();
};

const makeEndDateQuarter = (selectedDate: string) => {
  const date = selectedDate ? moment(selectedDate) : moment();
  const endDate = date.add(3, 'months').subtract(1, 'days');
  return endDate.toISOString();
};

const makeStartDateYear = (preSaleTo?: string) => {
  const preSaleToDate = preSaleTo ? moment(preSaleTo) : moment();
  const currentYear = moment().year();
  //if preSaleTo is in the next year, set it to the start of the year
  if (preSaleToDate.year() > currentYear) {
    return preSaleToDate.startOf('year').toISOString();
  }
  // otherwise, set it to the start of current year
  return moment().toISOString();
};

const makeEndDateYear = (date: string) => {
  const endDate = date ? new Date(date) : new Date();
  endDate.setMonth(11);
  endDate.setDate(31);
  return endDate.toISOString();
};

const makeStartDate = (timePeriod: string, preSaleTo?: string) => {
  if (timePeriod === 'uut') {
    return makeStartDateQuarter();
  }
  return makeStartDateYear(preSaleTo);
};

const showDate = (timePeriod: string, date: string) => {
  if (timePeriod === 'uut') {
    return `${getFullDate(date)} - ${getFullDate(makeEndDateQuarter(date))}`;
  }
  return `${getFullDate(date)} - ${getFullDate(makeEndDateYear(date))}`;
};

const makeYYYYMMDDdate = (preDate: string) => {
  const date = new Date(preDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dateFormatDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const fullDate = `${year}-${month}-${dateFormatDay}`;
  return fullDate;
};

export {
  makeStartDateQuarter,
  makeEndDateQuarter,
  makeStartDateYear,
  makeEndDateYear,
  makeStartDate,
  showDate,
  makeYYYYMMDDdate,
  convertIsoDate,
  getNextTuesdayOrCurrentDateTime,
  getDayOfWeekInAccusative,
  checkDateAndReturnTime,
  updateToCurrentTimeIfToday,
  formatDateToEuropean,
  getWeekdayFromDate,
};
