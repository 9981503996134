import {styled} from '@mui/material';

export const ModalWrapper = styled('div')`
  min-width: 45rem;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  gap: 2.7rem;
`;
export const DetailsRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Button = styled('button')`
  background: none;
  border: none;
  padding: 0.4rem;
  display: flex;
  margin-left: auto;
  cursor: pointer;
`;
