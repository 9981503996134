import React from 'react';
import {useStyledThemeState, useStyledThemeDispatch} from './useStyledTheme';
import {StyledThemeActions} from '../types';

export const useToggleTheme = () => {
  const {isContrastTheme} = useStyledThemeState();
  const {dispatch} = useStyledThemeDispatch();

  const toggleTheme = React.useCallback(() => {
    dispatch({type: StyledThemeActions.SET_THEME, payload: isContrastTheme ? 'default' : 'contrast'});
  }, [dispatch, isContrastTheme]);

  return {toggleTheme} as const;
};
