import {useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from 'react-router-dom';
import {env} from 'env';

import {usePayment} from 'Utils/PaymentContext';
import {checkIfGuest, getTransactionNumbers, returnPaymentDateFormated} from 'Utils/commonFunctions';

import {
  getTicketsNumbersAndSeries,
  generateInvoiceRequest,
  isInvoiceFormValid,
  generateInvoiceRequestForManyTickets,
} from 'components/pages/PurchasePath/PaymentForm/paymentHelpers';

import {IPaymentInvoiceData} from 'interfaces/forms';

import {invoiceFormInitialData} from 'Utils/forms';
import apiPayment from 'api/sprzedaz/repository';
import apiUser from 'api/user/repository';

import {useWindowWidth} from 'Utils/hooks';
import {ICancelTicketRequest} from 'api/sprzedaz/interfaces';
import {IReturnTicketResponse} from '../api/sprzedaz/interfaces';
import {useShoppingBasket} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import {BasketTransaction} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketTypes';
import {IGenerateInvoiceElement} from 'api/faktury/interfaces';
import {myTicketsLocationStates} from 'common/pages/UserProfile/MyTickets';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';
import {isSeasonalCodeOffer} from 'common/utils';

interface TransArr {
  transakcjaNr: number;
  cena: number;
}

const usePaymentMethods = (showQRModal: (e: boolean) => void, setExchangeText?: (e: string) => void) => {
  const {invalidateMyTickets, invalidateMyTicket} = useInvalidateMyTicketsQueries();
  const navigate = useNavigate();
  const {ticketFrom, ticketTo, exchangeTicket, additionalTicketsTo, additionalTicketsFrom, ecoOffsetDiscount} =
    usePayment();
  const {t} = useTranslation();
  const isGuest = useMemo(() => checkIfGuest(), []);

  const {
    basket,
    returnBasketTransactions,
    clearItemsAfterPayment,
    setWaitingForPayment,
    getItemsWithInvoiceData,
    basketEcoOffsetDiscount,
  } = useShoppingBasket();

  const [blik, setBlik] = useState<string>('');
  const [modalText, setModalText] = useState('');
  const [modal, setModal] = useState(false);
  const [specialModal, setSpecialModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const [transNr, setTransNr] = useState(0);
  const [ticketData, setTicketData] = useState({
    series: '',
    number: 0,
  });

  const [invoiceFormData, setInvoiceFormData] = useState<IPaymentInvoiceData>(invoiceFormInitialData);
  const [invoiceError, setInvoiceError] = useState<string>('');

  const isMobile = useWindowWidth() <= 800;

  const getTransactions = () => {
    const arr = [] as TransArr[];
    if (ticketFrom)
      if (typeof ticketFrom.transakcjeNr === 'object') {
        ticketFrom.transakcjeNr.forEach((el) =>
          arr.push({
            transakcjaNr: el.transakcjaNr,
            cena: el.cena,
          }),
        );
      }
    if (ticketTo) {
      if (typeof ticketTo.transakcjeNr === 'object') {
        ticketTo.transakcjeNr.forEach((el) =>
          arr.push({
            transakcjaNr: el.transakcjaNr,
            cena: el.cena,
          }),
        );
      }
    }
    if (additionalTicketsFrom) additionalTicketsFrom.transakcjeNr.forEach((el) => arr.push(el));
    if (additionalTicketsTo) additionalTicketsTo.transakcjeNr.forEach((el) => arr.push(el));
    if (ecoOffsetDiscount) {
      if (typeof ecoOffsetDiscount.transakcjeNr === 'object') {
        ecoOffsetDiscount.transakcjeNr.forEach((el) =>
          arr.push({
            transakcjaNr: el.transakcjaNr,
            cena: el.kwota,
          }),
        );
      }
    }

    return arr;
  };

  const returnPayment = async (paymentId: number, exchangeErr?: string, basketMode?: boolean) => {
    const transactions = !basketMode
      ? getTransactionNumbers(ticketFrom, ticketTo, additionalTicketsFrom, additionalTicketsTo, ecoOffsetDiscount)
      : returnBasketTransactions().map((el) => el.transakcjaNr);
    const cancel = await apiPayment.sendBackPayment({
      transakcjeNr: transactions,
      platnoscNr: paymentId,
    });

    const addText = exchangeErr ? exchangeErr + '<br />' : '';

    if (cancel && cancel.bledy.length > 0 && cancel.bledy[0].kod) {
      setModalText(addText + `<b>${t('24050')}.</b> ${t('24051')}.`);
    } else {
      setModalText(addText + `<b>${t('24015')}.</b> ${t('24016')}.`);
      setModal(true);
      setLoader(false);
    }
  };

  const generateBasketInvoicesRequests = () => {
    const invoiceItems = getItemsWithInvoiceData();

    if (!!invoiceItems && invoiceItems.length > 0) {
      const transactions = [] as BasketTransaction[];

      if (!!invoiceItems && invoiceItems.length > 0) {
        invoiceItems.forEach((it) => {
          if (!!it.ticket && !!it.ticket.transactions && it.ticket.transactions.length > 0 && !it.timeOut) {
            it.ticket.transactions.forEach((el) => transactions.push(el));
          }
        });

        invoiceItems.forEach((add) => {
          if (
            !!add.additionalTickets &&
            !!add.additionalTickets.transactions &&
            add.additionalTickets.transactions.length > 0 &&
            !add.timeOut
          ) {
            add.additionalTickets.transactions.forEach((el) => transactions.push(el));
          }
        });
      }

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!transactions) {
        try {
          const transNumbers = transactions.map((el) => el.transakcjaNr);

          const transRequests = transNumbers.map((el) =>
            apiPayment.downloadTicket({
              urzadzenieNr: 0,
              metoda: '',
              transakcjaNr: el,
            }),
          );

          Promise.all(transRequests).then((tickets) => {
            if (tickets) {
              for (let ticket = 0; ticket < tickets.length; ticket++) {
                const element = tickets[ticket];

                if (!!element && !!element.bilety) {
                  const invoiceData = invoiceItems.find((el) =>
                    el.ticket?.transactions.some((sm) => sm.transakcjaNr === element.bilety[0].transakcjaNr),
                  );

                  if (
                    !!invoiceData &&
                    !!invoiceData.invoiceFormData &&
                    !!invoiceData.invoiceFormData.invoice &&
                    !!invoiceData.invoiceFormData.invoiceEmailRules
                  ) {
                    const numbersAndSeries = [] as IGenerateInvoiceElement[];

                    element.bilety.forEach((ticket) =>
                      numbersAndSeries.push({
                        seria: ticket.biletSeria,
                        nr: ticket.biletNr,
                      }),
                    );
                    generateInvoiceRequestForManyTickets(invoiceData.invoiceFormData, numbersAndSeries);
                  }
                }
              }
            }
          });
        } catch (error) {
          console.log('Błąd - nie udało się wygenerować faktury: ', error);
        }
      }
    }
  };

  const handlePayment = async (paymentId: number, name: string, basketMode?: boolean) => {
    const transactions = !basketMode
      ? getTransactionNumbers(ticketFrom, ticketTo, additionalTicketsFrom, additionalTicketsTo, ecoOffsetDiscount)
      : returnBasketTransactions().map((el) => el.transakcjaNr);

    if (!!basketMode && basket?.items) {
      const basketItemsWithTransactions = basket?.items.map((el) => {
        const ecoOffset = basketEcoOffsetDiscount?.transakcjeNr?.[0]?.transakcjaNr;
        return apiPayment.payTheTicket({
          transakcjeNr: [
            ...(el.ticket?.transactions?.map((el) => el.transakcjaNr) ?? []),
            ...(el.additionalTickets?.transactions?.map((el) => el.transakcjaNr) ?? []),
            ...(ecoOffset ? [ecoOffset] : []),
          ],
          podroznyNazwa: el.buyer ?? name,
          platnoscData: returnPaymentDateFormated(),
          platnoscSposobKod: 16,
          platnoscNr: paymentId,
        });
      });

      if (basketItemsWithTransactions) {
        try {
          Promise.all(basketItemsWithTransactions).then((tickets) => {
            if (!!tickets && !!tickets.some((res) => res && res.bledy && res.bledy.length > 0)) {
              returnPayment(paymentId, undefined, basketMode);
            } else {
              generateBasketInvoicesRequests();
              clearItemsAfterPayment();

              if (ticketFrom) setTransNr(ticketFrom.transakcjaNr);

              setModalText(`<b>${t('25010')}</b>. ${t('24016')}.`);
              setSpecialModal(true);
              showQRModal(true);
              setLoader(false);

              !!basket.waitingForPayment && setWaitingForPayment(false);
            }
          });
        } catch (error) {
          console.log('basket payment error: ', error);
          returnPayment(paymentId, undefined, basketMode);
        }
      }
    } else {
      const ticket = await apiPayment.payTheTicket({
        transakcjeNr: transactions,
        podroznyNazwa: name,
        platnoscData: returnPaymentDateFormated(),
        platnoscSposobKod: 16,
        platnoscNr: paymentId,
      });
      if (ticket) {
        setTicketData({
          series: ticket.bilety[0].biletSeria,
          number: ticket.bilety[0].biletNr,
        });
      }
      if (ticket && ticket.bledy.length && ticketFrom) {
        returnPayment(paymentId);
      } else {
        if (invoiceFormData.invoice) {
          const allTransactionsFrom =
            ticketFrom &&
            (await apiPayment.downloadTicket({
              urzadzenieNr: 0,
              metoda: '',
              transakcjaNr: ticketFrom.transakcjaNr,
            }));
          const allTransactionsTo =
            ticketTo &&
            (await apiPayment.downloadTicket({
              urzadzenieNr: 0,
              metoda: '',
              transakcjaNr: ticketTo.transakcjaNr,
            }));
          Promise.all([allTransactionsFrom, allTransactionsTo]).then((tickets) => {
            if (tickets) {
              generateInvoiceRequest(invoiceFormData, getTicketsNumbersAndSeries(tickets));
              if (!isGuest) {
                window.localStorage.removeItem('invoiceData');
              }
            }
          });
        }
        if (ticketFrom) setTransNr(ticketFrom.transakcjaNr);

        setModalText(`<b>${t('25010')}</b>. ${t('24016')}.`);
        setSpecialModal(true);

        showQRModal(true);
        setLoader(false);

        sessionStorage.removeItem('payment-deadline');
      }
      return ticket;
    }
  };

  const revertTransactions = (paymentId: number, errorExchange: string) => {
    const transactions = getTransactionNumbers(
      ticketFrom,
      ticketTo,
      additionalTicketsFrom,
      additionalTicketsTo,
      ecoOffsetDiscount,
    );
    const promises = transactions.map(async (el) => {
      const payload: ICancelTicketRequest = {
        urzadzenieNr: 0,
        metoda: '',
        transakcjaNr: el,
      };
      return await apiPayment.cancelTicket(payload);
    });
    Promise.all(promises).catch((e) => console.log(e));

    returnPayment(paymentId, t('24055') + errorExchange);
    sessionStorage.removeItem('payment-deadline');
  };

  const checkPaymentStatus = async (paymentId: number, name: string, counter: number, basketMode?: boolean) => {
    const timeStamp = new Date();
    const check = await apiPayment.checkPaymentStatus(paymentId);

    if (!check?.paymentStatus && counter <= 15) {
      const timeCheck = new Date();
      const time = 10000 - (timeCheck.getTime() - timeStamp.getTime());
      setTimeout(
        () => {
          const con = counter + 1;
          checkPaymentStatus(paymentId, name, con, basketMode);
        },
        time > 0 ? time : 0,
      );
    } else if (!check?.paymentStatus && counter > 15) {
      setModalText(`<b>${t('24050')}.</b> ${t('24016')}.`);
      setModal(true);
      setModalText(`<b>${t('24050')}.</b> ${t('24016')}.`);
      setSpecialModal(true);
      setLoader(false);
      !!basket && setWaitingForPayment(true);
    } else {
      switch (check?.paymentStatus) {
        case 'PAID':
          if (exchangeTicket && !!exchangeTicket.tnr && ticketFrom && ticketFrom.transakcjaNr) {
            setExchangeText && setExchangeText('');

            const parsedTrans = parseInt(exchangeTicket.tnr);

            if (!parsedTrans) {
              revertTransactions(paymentId, t('25004'));
              break;
            }

            const paymentResponse = await handlePayment(paymentId, name);
            const oldTicket = await apiPayment.downloadTicket({
              urzadzenieNr: 0,
              metoda: '',
              transakcjaNr: parsedTrans,
            });
            if (oldTicket && oldTicket.bilety && oldTicket.bilety.length > 0) {
              if (paymentResponse && paymentResponse?.bledy.length > 0) return;
              const exchangePromises = oldTicket.bilety.map(async (el) => {
                return await apiPayment.exchangeTicket(el.transakcjaNr, ticketFrom.transakcjaNr);
              });

              await Promise.all(exchangePromises)
                .then((values) => {
                  const res = values as unknown as IReturnTicketResponse[];

                  if (res && res.length > 0 && res.find((x) => x.bledy && x.bledy.length > 0)) {
                    const errors = res.find((x) => x.bledy && x.bledy.length > 0)?.bledy;
                    const errorExchange =
                      errors && errors[0] ? `${errors[0].kod} - ${errors[0].opisy[0].komunikat}` : t('24054');

                    revertTransactions(paymentId, errorExchange);
                  } else {
                    setExchangeText && setExchangeText(t('24057'));
                    invalidateMyTicket(ticketFrom.transakcjaNr, undefined, undefined);
                  }
                })
                .catch((err) => {
                  revertTransactions(paymentId, t('24055') + err);
                });
            }
          } else {
            handlePayment(paymentId, name, basketMode);
          }
          break;
        case 'REJECT':
          setModalText(`<b>${t('24015')}.</b> ${t('24016')}.`);
          setModal(true);
          setLoader(false);
          !!basket && setWaitingForPayment(true);
          break;
        case 'CANCELED':
          if (ticketFrom && !basketMode) {
            returnPayment(paymentId);
            // eslint-disable-next-line no-extra-boolean-cast
          } else if (!!basketMode) {
            returnPayment(paymentId, undefined, basketMode);
          }
          break;
        case 'TO_REJECT':
          returnPayment(paymentId, undefined, basketMode);
          break;
      }
    }
    invalidateMyTickets();
  };

  const paymentWentWrong = () => {
    setModalText(`<b>${t('25014')}</b> ${!isGuest ? t('24016') : ''}.`);
    setSpecialModal(true);
    setModal(true);
    setLoader(false);
    !!basket && setWaitingForPayment(true);
  };

  const registerBasketPayments = async (method: string) => {
    if (!!basket && !!basket.items && basket.items.length > 0) {
      const basketTransactions = returnBasketTransactions();

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!basketTransactions) {
        const timeLimit = basket.timeLimit.replace(' ', 'T');
        const transNumbers = basketTransactions.map((el) => el.transakcjaNr);
        const status = await apiPayment.getTransactionStatus(transNumbers);

        if (status?.statusy[0]?.statusKod) {
          setLoader(true);
          const user = await apiUser.getUserData();

          if (!localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', uuidv4());
          }

          const paymentObj = {
            client_email: user?.contactEmail ? user?.contactEmail : (user?.email as string),
            client_name: `${user?.firstName} ${user?.lastName}`,
            device_uuid: localStorage.getItem('uuid') as string,
            language: i18n.language,
            domestic: true,
            paymentOperator: method,
            number_of_device: env.REACT_APP_DEVICE_NUMBER,
            timeForPayment: timeLimit,
            paymentRegisterRequests: basketTransactions.map((el) => {
              return {
                item_amount: el.cena,
                item_id: el.transakcjaNr,
                item_type: ecoOffsetDiscount?.transakcjeNr?.[0]?.transakcjaNr === el.transakcjaNr ? 1 : 0,
                paymentRegisterP24Request: {
                  p24_methodRefId: null,
                  p24_sdkVersion: '3.5.53-android',
                  p24_method: null,
                  p24_t6_code_blik: blik,
                },
              };
            }),
          };

          try {
            const payment = await apiPayment.registerPayment(paymentObj);

            if (payment?.paymentId && method === 'P24Blik') {
              checkPaymentStatus(payment?.paymentId, `${basket.items[0].buyer}`, 0, true);
            } else if (payment?.paymentRedirect && (method === 'P24' || method === 'P24Przelew')) {
              if (invoiceFormData.invoice) {
                window.localStorage.setItem('invoiceData', JSON.stringify(invoiceFormData));
              }
              window.location.replace(payment?.paymentRedirect as string);
            } else if (payment === undefined) {
              paymentWentWrong();
            }
          } catch {
            paymentWentWrong();
          }
        }
      }
    }
  };

  const registerPayment = async (method: string, name: string) => {
    setInvoiceError('');

    if (isInvoiceFormValid(t, invoiceFormData, setInvoiceError, isMobile)) {
      if (ticketFrom) {
        const tickArr = getTransactionNumbers(
          ticketFrom,
          ticketTo,
          additionalTicketsFrom,
          additionalTicketsTo,
          ecoOffsetDiscount,
        );

        const status = await apiPayment.getTransactionStatus(tickArr);
        if (status?.statusy[0]?.statusKod) {
          setLoader(true);
          const user = await apiUser.getUserData();

          if (!localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', uuidv4());
          }

          const payLater = localStorage.getItem('payLaterTicket');
          const payLaterData = payLater && JSON.parse(payLater);
          const payLaterDeadline = payLaterData?.deadline;
          const payDeadline = sessionStorage.getItem('payment-deadline');

          const paymentObj = {
            client_email: user?.contactEmail ? user?.contactEmail : (user?.email as string),
            client_name: `${name}`,
            device_uuid: localStorage.getItem('uuid') as string,
            language: i18n.language,
            domestic: true,
            paymentOperator: method,
            number_of_device: env.REACT_APP_DEVICE_NUMBER,
            timeForPayment: payDeadline?.slice(0, -6) || payLaterDeadline?.slice(0, -6),
            paymentRegisterRequests: getTransactions().map((el) => {
              return {
                item_amount: el.cena,
                item_id: el.transakcjaNr,
                item_type: ecoOffsetDiscount?.transakcjeNr?.[0]?.transakcjaNr === el.transakcjaNr ? 1 : 0,
                paymentRegisterP24Request: {
                  p24_methodRefId: null,
                  p24_sdkVersion: '3.5.53-android',
                  p24_method: null,
                  p24_t6_code_blik: blik,
                },
              };
            }),
          };
          try {
            const payment = await apiPayment.registerPayment(paymentObj);

            if (invoiceFormData.invoice) {
              window.localStorage.setItem('invoiceData', JSON.stringify(invoiceFormData));
            }
            if (payment?.paymentId && method === 'P24Blik') {
              checkPaymentStatus(payment?.paymentId, name, 0);
            } else if (payment?.paymentRedirect && (method === 'P24' || method === 'P24Przelew')) {
              window.location.replace(payment?.paymentRedirect);
            } else if (payment === undefined) {
              paymentWentWrong();
            }
          } catch {
            paymentWentWrong();
          }
        }
      }
    }
  };

  const payLater = (name: string, isWithNavigate?: boolean) => {
    window.localStorage.removeItem('payLaterTicket');
    const deadline = sessionStorage.getItem('payment-deadline');

    !isGuest &&
      window.localStorage.setItem(
        'payLaterTicket',
        JSON.stringify({
          ticketFrom,
          ticketTo,
          additionalTicketsFrom,
          additionalTicketsTo,
          traveler: name,
          timeStamp: new Date(),
          deadline: deadline ? deadline : null,
        }),
      );
    sessionStorage.removeItem('payment-deadline');
    isWithNavigate &&
      navigate(
        '/profil/mojebilety',
        isSeasonalCodeOffer(ticketFrom?.ofertaKod) ? myTicketsLocationStates.SEASONAL : myTicketsLocationStates.SINGLE,
      );
  };

  return {
    blik,
    setBlik,
    modalText,
    setExchangeText,
    setModalText,
    getTransactions,
    checkPaymentStatus,
    returnPayment,
    isMobile,
    modal,
    setModal,
    loader,
    setLoader,
    invoiceFormData,
    setInvoiceFormData,
    invoiceError,
    setInvoiceError,
    registerBasketPayments,
    registerPayment,
    payLater,
    specialModal,
    setSpecialModal,
    transNr,
    ticketData,
    generateBasketInvoicesRequests,
  };
};

export default usePaymentMethods;
