/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {FC, useEffect, useState} from 'react';
import * as DOMPurify from 'dompurify';

import DiscountToggleDetail from './DiscountToggleDetail';
import {ShopDiscount} from 'common/api/localData';
import {useStyledThemeState} from 'common/theme';
import {ssoUrlBuilderWithPKCEParams} from 'Utils/ssoUrlBuilder';
import S from './SetDiscounts.module.scss';
import {useTranslation} from 'react-i18next';
import {discountListLanguageMapper} from 'Utils/commonFunctions';
import {SHOP_DISCOUNT_CODES} from 'common/consts';
import {ShopDiscountCodeOffer} from 'common/types';
interface Props {
  list: ShopDiscount[];
  selectedDiscount: ShopDiscount | undefined;
  onDiscountChange: (discount: ShopDiscount) => void;
  isUserLoggedIn?: boolean;
  variant?: 'mobileBorder';
}

const DiscountList: FC<Props> = ({list, selectedDiscount, onDiscountChange, isUserLoggedIn, variant}) => {
  const {t, i18n} = useTranslation();
  const [languageNumber, setLanguageNumber] = useState(0);
  const {isContrastTheme} = useStyledThemeState();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const parse = require('html-react-parser');

  const isDisabledDiscount = (code: number): boolean => {
    return false;
    // Uncomment to enable disabled discounts
    // return isCodeRailwayEmployeeRelated(code) && !isUserLoggedIn;
  };

  const handleRedirectToLogin = () => {
    ssoUrlBuilderWithPKCEParams(isContrastTheme).then((ssoUrl) => window.location.replace(ssoUrl));
  };

  useEffect(() => {
    const selectedLang = discountListLanguageMapper(i18n.language);
    setLanguageNumber(selectedLang);
  }, [i18n.language]);
  const getDiscountInfoLabel = (discount: ShopDiscount) => {
    const discountInfo = discount.opisy[languageNumber].informacja.split('<br>')[0].slice(-4).replace('-', '');
    return discountInfo.includes('UUT') ? discountInfo.replace('%', '') : discountInfo;
  };
  const translations: Partial<Readonly<Record<ShopDiscountCodeOffer, string>>> = {
    [SHOP_DISCOUNT_CODES.CHILD_37]: t('13099'),
    [SHOP_DISCOUNT_CODES.CHILD_49]: t('13103'),
  };

  return (
    <>
      {list.map((el) => {
        return (
          <React.Fragment key={el?.kodZakupowy}>
            <div
              className={`${S.singleDiscountBox} ${
                selectedDiscount?.kodZakupowy === el.kodZakupowy ? S.singleDiscountBoxActive : ''
              } ${isDisabledDiscount(el.kodZakupowy) ? S.singleDiscountBoxDisabled : ''} ${variant ? S[variant] : ''}`}
              onClick={() => !isDisabledDiscount(el.kodZakupowy) && onDiscountChange(el)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  e.preventDefault();
                  !isDisabledDiscount(el.kodZakupowy) && onDiscountChange(el);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <div className={S.singleDiscountHeader}>
                <p>
                  <span className="screen-readers">{t('13018')}</span>
                  {el.opisy[languageNumber].nazwa} {translations[el.kodZakupowy] && `- ${translations[el.kodZakupowy]}`}
                </p>
                {!isDisabledDiscount(el.kodZakupowy) && <p className={S.descriptionInfo}>{getDiscountInfoLabel(el)}</p>}
              </div>
              <div className={S.singleDiscountInfo}>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(el.opisy[languageNumber].informacja)}} />
              </div>
              {isDisabledDiscount(el.kodZakupowy) ? (
                <div className={S.disabledDiscountLabel}>
                  {t('13066')}{' '}
                  <button type="button" onClick={handleRedirectToLogin} aria-label={t('13067')}>
                    {t('29120')}
                  </button>
                </div>
              ) : (
                <DiscountToggleDetail
                  description={parse(el.opisy[languageNumber].opis)}
                  ariaLabel={el.opisy[languageNumber].nazwa}
                />
              )}
            </div>
            <div className={S.graySeparatorMobile} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DiscountList;
