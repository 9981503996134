import {styled} from '@mui/material';

export const EmptyInformationContainer = styled('div')`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 27.7rem;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.color.border.primary};
`;
