import Icon from 'common/components/base/Icon';
import {Text} from 'common/components/base/Text';

import {EmptyInformationContainer} from './EmptyInformation.styles';

interface EmptyInformationProps {
  message: string;
}

export const EmptyInformation = ({message}: EmptyInformationProps) => {
  return (
    <EmptyInformationContainer>
      <Icon icon="ticketIcon" variant="huge" />
      <Text variant="H5">{message}</Text>
    </EmptyInformationContainer>
  );
};
