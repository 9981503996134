import {FC} from 'react';

import {TrasaPrzejazdu} from 'api/pociagi/interfaces';
import {getComparableDateFormat} from 'Utils/commonFunctions';

import {TimeType} from './MyTicketsRouteModal.util';

import S from './MyTicketsRouteModal.module.scss';

interface Props {
  routeSection: TrasaPrzejazdu;
  isFirstStation: boolean;
  isEndStation: boolean;
  isNextStation: boolean;
}

const MyTicketsRouteModalStations: FC<Props> = ({routeSection, isFirstStation, isEndStation, isNextStation}) => {
  const currentDate = new Date();

  const getDelay = (scheduledDate: string, actualDate: string) => {
    if (actualDate) {
      return Math.round(
        (getComparableDateFormat(actualDate).getTime() - getComparableDateFormat(scheduledDate).getTime()) / 60000,
      );
    } else return 0;
  };

  const isDelayed = (timeType: TimeType) => {
    if (timeType === 'arrival') {
      return getDelay(routeSection.dataPrzyjazdu, routeSection.dataPrzyjazduRzeczywista) > 0;
    } else {
      return getDelay(routeSection.dataWyjazdu, routeSection.dataWyjazduRzeczywista) > 0;
    }
  };

  const isSectionEnded = (timeType: TimeType) => {
    if (timeType === 'arrival') {
      return (
        currentDate.getTime() >
        getComparableDateFormat(routeSection.dataPrzyjazduRzeczywista || routeSection.dataPrzyjazdu).getTime()
      );
    } else {
      return (
        currentDate.getTime() >
        getComparableDateFormat(routeSection.dataWyjazduRzeczywista || routeSection.dataWyjazdu).getTime()
      );
    }
  };

  const checkIsFirstStation = isFirstStation ? 'departure' : 'arrival';
  const pickFirstStationStyle = isFirstStation ? S.filledInactive : S.filledOnTime;
  const checkIsDelayed = isDelayed(checkIsFirstStation) ? S.filledDelayed : pickFirstStationStyle;

  return (
    <p
      className={`
            ${S.detailsText} 
            ${S.stationName} 
            ${currentDate > getComparableDateFormat(routeSection.dataWyjazduRzeczywista || routeSection.dataWyjazdu) && S.inactive}
            ${isEndStation && currentDate > getComparableDateFormat(routeSection.dataPrzyjazduRzeczywista || routeSection.dataPrzyjazdu) && S.inactive}
        `}
    >
      {routeSection.nazwaStacji}
      <span
        className={`
                ${S.circle} 
                ${!isSectionEnded(checkIsFirstStation) && isNextStation ? checkIsDelayed : S.outlined}
                ${isSectionEnded(checkIsFirstStation) && S.filledInactive}
            `}
      >
        {!isEndStation && <span className={S.dash} />}
      </span>
    </p>
  );
};

export default MyTicketsRouteModalStations;
