import {IPaymentInvoiceData} from 'interfaces/forms';
import api from 'api/user/repository';
import invoiceApi from 'api/faktury/repository';
import {IChangeUserAddressRequest} from 'api/user/interfaces';
import {invoiceCountriesList} from 'Utils/invoiceCountriesList';
import {IDownloadInvoiceRequest} from 'api/faktury/interfaces';
import i18n from 'i18next';

const t = i18n.t;
export const hasInvoiceAllRequiredValues = (invoiceValues: IPaymentInvoiceData): boolean => {
  if (invoiceValues.invoice) {
    if (!invoiceValues.invoiceEmailRules) {
      return false;
    }
    if (invoiceValues.invoiceType === 'company' && !invoiceValues.isNotPolishNIP && !invoiceValues.companyNip.length) {
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      !invoiceValues.isNotPolishNIP &&
      !!invoiceValues.companyNip.length &&
      (!invoiceValues.companyName.length ||
        !invoiceValues.companyStreet.length ||
        !invoiceValues.companyHouseNumber.length ||
        !invoiceValues.companyZipCode.length ||
        !invoiceValues.companyCity.length ||
        !invoiceValues.companyCountry.length)
    ) {
      return false;
    }
    if (invoiceValues.invoiceType === 'company' && !invoiceValues.companyCountry.length) {
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      invoiceValues.isNotPolishNIP &&
      !!invoiceValues.companyCountry.length &&
      isEUCountry(invoiceValues.companyCountry) &&
      !invoiceValues.companyNip.length
    ) {
      return false;
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      invoiceValues.isNotPolishNIP &&
      (!invoiceValues.companyName.length ||
        !invoiceValues.companyStreet.length ||
        !invoiceValues.companyHouseNumber.length ||
        !invoiceValues.companyZipCode.length ||
        !invoiceValues.companyCity.length ||
        !invoiceValues.companyCountry.length)
    ) {
      return false;
    }
    if (
      invoiceValues.invoiceType === 'person' &&
      (!invoiceValues.personName.length ||
        !invoiceValues.personSurname.length ||
        !invoiceValues.personStreet.length ||
        !invoiceValues.personHouseNumber.length ||
        !invoiceValues.personZipCode.length ||
        !invoiceValues.personCity.length)
    ) {
      return false;
    }
    return true;
  }
  return true;
};

export const generateUpdateAddressRequest = async (values: IPaymentInvoiceData, newAddress?: boolean) => {
  const isCompany = values.invoiceType === 'company';
  const country = isCompany ? values.companyCountry : values.personCountry;
  const countryFromList = invoiceCountriesList.find((el) => el.namePL === country);
  const addressTypePart = !values.isNotPolishNIP ? 2 : 3;

  const request: IChangeUserAddressRequest = {
    addressType: !isCompany ? 1 : addressTypePart,
    countryCode: countryFromList ? countryFromList.code : '',
    zipCode: isCompany ? values.companyZipCode : values.personZipCode,
    city: isCompany ? values.companyCity : values.personCity,
    street: isCompany ? values.companyStreet : values.personStreet,
    houseNumber: isCompany ? values.companyHouseNumber : values.personHouseNumber,
    apartmentNumber: isCompany ? values.companyLocalNumber : values.personLocalNumber,
    name: isCompany ? values.companyName : '',
    firstName: isCompany ? '' : values.personName,
    lastName: isCompany ? '' : values.personSurname,
    nip: isCompany ? values.companyNip : '',
    foreignNip: values.isNotPolishNIP,
  };
  if (newAddress) {
    return await api.addUserAddress(request).then((data) => data);
  }
  return await api.editUserAddress(request).then((data) => data);
};

export const handleInvoiceDownload = (invoiceSeries: string, invoiceNr: string) => {
  const request: IDownloadInvoiceRequest = {
    urzadzenieNr: 0,
    metoda: '',
    seria: invoiceSeries,
    nr: invoiceNr,
  };
  const downloadInvoice = async () =>
    await invoiceApi.downloadInvoice(request).then((data) => {
      if (data) {
        const filename = `faktura_${invoiceSeries}_${invoiceNr}.pdf`;
        const blob = new Blob([new Uint8Array(data.obrazFaktury).buffer], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }
    });
  downloadInvoice();
};

export const handleCorrectedNoteDownload = (noteSeries: string, noteNr: string) => {
  const request: IDownloadInvoiceRequest = {
    urzadzenieNr: 0,
    metoda: '',
    seria: noteSeries,
    nr: noteNr,
  };
  const downloadNote = async () =>
    await invoiceApi.downloadNote(request).then((data) => {
      if (data) {
        const filename = `nota_${noteSeries}_${noteNr}.pdf`;
        const blob = new Blob([new Uint8Array(data.obrazNoty).buffer], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }
    });
  downloadNote();
};

export const validateStreetName = (value: string): string | null => {
  const regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃáäčéëöőüž ,"'.)(/-]+$/m;
  if (value.trim().length === 0) {
    return `Empty`;
  }
  if (value.trim().length > 75) {
    return `*Pole nie może zawierać więcej niż 75 znaków`;
  }
  if (regex.test(value) || value.trim() === '') {
    return null;
  }
  return `*Pole zawiera niedozwolone znaki`;
};

export const validateCityName = (value: string): string | null => {
  const regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ ".-]+$/m;
  if (value.trim().length === 0) {
    return `Empty`;
  }
  if (value.trim().length > 35) {
    return `*Pole nie może zawierać więcej niż 35 znaków`;
  }
  if (regex.test(value) || value.trim() === '') {
    return null;
  }
  return `*Pole zawiera niedozwolone znaki`;
};

export const validateCompanyName = (value: string): string | null => {
  if (value.trim().length === 0) {
    return `Empty`;
  }
  if (value.trim().length > 180) {
    return `*Pole nie może zawierać więcej niż 180 znaków`;
  }
  return null;
};

export const validateEmptyInput = (value: string): string | null => {
  if (value.trim().length === 0) {
    return `Empty`;
  }
  return null;
};

export const hasInvoiceValidationErrors = (invoiceValues: IPaymentInvoiceData): boolean => {
  if (invoiceValues.invoice && invoiceValues.invoiceEmailRules) {
    if (invoiceValues.invoiceType === 'company' && invoiceValues.companyCountry === t('26002')) {
      return !(
        !validateStreetName(invoiceValues.companyStreet) &&
        !validateCityName(invoiceValues.companyCity) &&
        !validateCompanyName(invoiceValues.companyName) &&
        !validateEmptyInput(invoiceValues.companyZipCode) &&
        !validateEmptyInput(invoiceValues.companyHouseNumber)
      );
    }
    if (invoiceValues.invoiceType === 'company' && !invoiceValues.companyCountry.length) {
      return !!validateEmptyInput(invoiceValues.companyCountry);
    }
    if (
      invoiceValues.invoiceType === 'company' &&
      !!invoiceValues.companyCountry.length &&
      isEUCountry(invoiceValues.companyCountry)
    ) {
      return !!validateEmptyInput(invoiceValues.companyNip);
    }
    if (invoiceValues.invoiceType === 'company' && !!invoiceValues.companyCountry.length) {
      return !(
        !validateEmptyInput(invoiceValues.companyStreet) &&
        !validateEmptyInput(invoiceValues.companyCity) &&
        !validateEmptyInput(invoiceValues.companyName) &&
        !validateEmptyInput(invoiceValues.companyZipCode) &&
        !validateEmptyInput(invoiceValues.companyHouseNumber)
      );
    }
    if (invoiceValues.invoiceType === 'person' && invoiceValues.personCountry === t('26002')) {
      return !(
        !validateStreetName(invoiceValues.personStreet) &&
        !validateCityName(invoiceValues.personCity) &&
        !validateEmptyInput(invoiceValues.personName) &&
        !validateEmptyInput(invoiceValues.personSurname) &&
        !validateEmptyInput(invoiceValues.personZipCode) &&
        !validateEmptyInput(invoiceValues.personHouseNumber)
      );
    }
  }
  return false;
};

export const isEUCountry = (countryNameInPL: string): boolean => {
  const country = invoiceCountriesList.find((el) => el.namePL === countryNameInPL);
  return country ? country.isEU : false;
};
