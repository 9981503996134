import api from '../axios-middleware';

import {IVerifyNipInGusResponse} from './interfaces';

const methods = {
  getAndVerifyNipInGus: async (nip: number) => {
    return await api.get<IVerifyNipInGusResponse>(`/gus/bir/nip/${nip}`);
  },
};

export default methods;
