import {FC, ChangeEvent, useState} from 'react';

import style from './MovableLabelInput.module.scss';
import Eye from 'assets/passwordIcon.svg';
import EyeDashed from 'assets/passwordShowIcon.svg';
import {TFunction} from 'i18next';

interface Props {
  t: TFunction;
  name: string;
  type: string;
  callback?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  value: string;
  hideRedStar?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regExValidation?: any;
  trimPasteEvent?: boolean;
  component?: React.ElementType;
  componentProps?: Record<string, unknown>;
  icon?: JSX.Element;
  max?: string;
}

const MovableLabelInput: FC<Props> = ({
  t,
  name,
  type,
  callback,
  onBlur,
  labelText,
  value,
  hideRedStar,
  regExValidation,
  trimPasteEvent,
  component,
  componentProps,
  icon,
  max,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);

  if (component) {
    const Component = component;
    return (
      <div className={`${style.login_input_box}`}>
        <Component className={`${value.length > 0 ? style.active_input : style.regular_input}`} {...componentProps} />
        <label htmlFor={name} className={value.length > 0 ? style.active_label : style.regular_label}>
          {t(labelText).toString()}
          {hideRedStar ? null : <span>*</span>}
        </label>
      </div>
    );
  }

  return (
    <div className={`${style.login_input_box}`}>
      <input
        name={name}
        id={name}
        className={`${value.length > 0 ? style.active_input : style.regular_input}`}
        type={toggle ? 'text' : type}
        placeholder=" "
        onKeyDown={(e) => regExValidation && (regExValidation(e.key) ? null : e.preventDefault())}
        onChange={callback}
        onBlur={onBlur}
        onPaste={(e) => {
          if (trimPasteEvent) {
            e.preventDefault();
            const text = e.clipboardData.getData('text');
            const selectElement = document.querySelector(`input[name="${name}"]`) as HTMLInputElement;
            selectElement.value = text.trim();
          }
        }}
        value={value}
        max={max && max}
      />
      <label htmlFor={name} className={value.length > 0 ? style.active_label : style.regular_label}>
        {t(labelText).toString()}
        {hideRedStar ? null : <span>*</span>}
      </label>
      {type === 'password' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex
        <img src={toggle ? EyeDashed : Eye} tabIndex={0} alt="Podgląd hasła" onClick={() => setToggle(!toggle)} />
      )}
      {icon && <div className={style.iconContainer}>{icon}</div>}
    </div>
  );
};

export default MovableLabelInput;
