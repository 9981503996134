import {
  requestTicketTypes,
  requestTicketListTypes,
  ticketTypes,
  ticketKinds,
  ticketSourceCodes,
  ticketTransfers,
  ticketGenderCodes,
} from './consts';

type RequestTicketType = (typeof requestTicketTypes)[keyof typeof requestTicketTypes];
type RequestTicketListType = (typeof requestTicketListTypes)[keyof typeof requestTicketListTypes];
type RequestTicketHistory = 0 | 1;
type RequestTicketDateSort = 0 | 1;

export class MyTicketsRequestParams {
  biletTyp: RequestTicketType;
  listaTyp: RequestTicketListType;
  historia: RequestTicketHistory;
  sortowanie: RequestTicketDateSort;
  wynikowNaStronie: number;
  numerStrony: number;

  constructor(
    biletTyp: RequestTicketType,
    listaTyp: RequestTicketListType,
    historia: RequestTicketHistory,
    sortowanie: RequestTicketDateSort,
    wynikowNaStronie: number,
    numerStrony: number,
  ) {
    this.biletTyp = biletTyp;
    this.listaTyp = listaTyp;
    this.historia = historia;
    this.sortowanie = sortowanie;
    this.wynikowNaStronie = wynikowNaStronie;
    this.numerStrony = numerStrony;
  }
}

export class MyTicketsReservationListParams extends MyTicketsRequestParams {
  biletPodstawowySeria: string;
  biletPodstawowyNr: number;

  constructor(
    biletTyp: RequestTicketType,
    listaTyp: RequestTicketListType,
    historia: RequestTicketHistory,
    sortowanie: RequestTicketDateSort,
    wynikowNaStronie: number,
    numerStrony: number,
    biletPodstawowySeria: string,
    biletPodstawowyNr: number,
  ) {
    super(biletTyp, listaTyp, historia, sortowanie, wynikowNaStronie, numerStrony);
    this.biletPodstawowySeria = biletPodstawowySeria;
    this.biletPodstawowyNr = biletPodstawowyNr;
  }
}

type TicketType = (typeof ticketTypes)[keyof typeof ticketTypes];
// Unclear description in the documentation.
type TicketCanceledType = 0 | 1 | 2 | 3 | 4 | 5;

export interface MyTicketIdentifier {
  transakcjaNr: number;
  biletSeria: string;
  biletNr: number;
  biletPodstawowySeria: string;
  biletPodstawowyNr: number;
  waznoscBiletuOd: string;
  waznoscBiletuDo: string;
  typBiletu: TicketType;
  zwrocony: 0 | 1;
  anulowany: TicketCanceledType;
  czasNaOplacenie: string;
  oplacony: 0 | 1;
  stacjaOd: string[];
  stacjaDo: string[];
  nrPociagu: number[];
  cena: number;
  klasa: 1 | 2;
}

export type TicketKind = (typeof ticketKinds)[keyof typeof ticketKinds];
type TicketSourceCode = (typeof ticketSourceCodes)[keyof typeof ticketSourceCodes];
type TicketTransfer = (typeof ticketTransfers)[keyof typeof ticketTransfers];
type TicketGenderCode = (typeof ticketGenderCodes)[keyof typeof ticketGenderCodes];

export class MyTicketRequestParams {
  transakcjaNr: number | null;
  biletSeria: string | null;
  biletNr: number | null;

  constructor(transakcjaNr: number | null, biletSeria: string | null, biletNr: number | null) {
    this.transakcjaNr = transakcjaNr;
    this.biletSeria = biletSeria;
    this.biletNr = biletNr;
  }
}

export interface MyTicket {
  biletRodzaj: TicketKind;
  transakcjaNr: number;
  biletSeria: string;
  biletNr: number;
  biletZrodloKod: TicketSourceCode;
  sprzedazData: string;
  waznoscBiletuOd: string;
  waznoscBiletuDo: string;
  odcinki: {
    wyjazdData: string;
    przyjazdData: string;
    stacjaOdKod: number;
    stacjaDoKod: number;
    pociagNr: number;
    pociagNazwa: string;
    pociagKategoriaNazwa: string;
    klasa: number;
    kilometry: number;
    miejsca: {
      miejsceTypKod: number;
      // The property below has a typo but that's how it is in the documentation and that's probably what the api returns.
      usytowanieNazwa: string;
      wagonNr: number;
      miejsceNr: number;
      plecKod: TicketGenderCode;
      // The properties below are not in the documentation.
      miejsceTypNazwa: string;
    }[];
    nrReferencyjnyRezerwacji: string;
  }[];
  podrozni: {
    znizkaKod: number;
    spolkaKolejowaKod: number;
    dataUrodzenia: string;
    wspolneMiejsce: 0 | 1;
  }[];
  ofertaKod: number;
  ofertaNazwa: string;
  cenaBilet: number;
  ptuBiletKwota: number;
  ptuBiletProcent: number;
  cenaRezerwacja: number;
  ptuRezerwacjaKwota: number;
  ptuRezerwacjaProcent: number;
  podroznyNazwa: string;
  mozliwaZmianaDanychPodroznego: boolean;
  kod2D: string;
  kodKontrolny: string;
  zwrotData: string;
  sladWeglowy: {
    jezyk: string;
    tresc: string;
  }[];
  zwrotKwota: number;
  fakturaSeria: string;
  fakturaNr: string;
  fakturaKorektaSeria: string;
  fakturaKorektaNr: string;
  notaKorygujacaSeria: string;
  notaKorygujacaNr: string;
  poziomCenowy: string;
  stacjaKoncowa: string;
  transfer1: TicketTransfer;
  transfer2: TicketTransfer;
  transferKomunikat: {
    jezyk: string;
    opis: string;
  }[];
  czasNaOplacenie: string;
  czyMozliwyZwrot: 0 | 1;
  liczbaRowerow: number;
  liczbaBagazy: number;
  liczbaPsow: number;
  cenaRowery: number;
  cenaBagaze: number;
  cenaPsy: number;
  cenaCalkowita: number;
  zwrotRozpoczety: 0 | 1;
  oplataDodatkowa: {
    kod: number;
    kwota: number;
    stawkaPTU: number;
    pyu: number;
  }[];
  // The properties below are not in the documentation.
  platnoscSposobKod: number;
  zwrotOpis: string;
  biletPodstawowySeria: string;
  biletPodstawowyNr: number;
}
