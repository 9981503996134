import React, {FC, useMemo} from 'react';
import S from './Switch.module.scss';

export enum SwitchLabelSide {
  Left = 'left',
  Right = 'right',
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  selected?: boolean;
  setSelected?: (e: boolean) => void;
  children?: React.ReactNode;
  /**
   * @default left
   * @description The side of the label where the children will be placed
   */
  labelSide?: SwitchLabelSide;
}

/**
 * Replaces all spaces with hyphens
 * @param str
 */
const hyphenateTokens = (str: string) => str.replace(/\s/g, '-');

const Switch: FC<Props> = ({
  selected,
  setSelected,
  children,
  className,
  labelSide = SwitchLabelSide.Left,
  id,
  ...props
}) => {
  const inputId = useMemo(() => hyphenateTokens(id || ''), [id]);

  return (
    <label className={`${S.wrapper} ${className}`} htmlFor={inputId}>
      {labelSide === SwitchLabelSide.Left && children}
      <div className={S.switch}>
        <input
          className={S.checkInput}
          type="checkbox"
          checked={selected}
          onChange={() => {
            if (setSelected) setSelected(!selected);
          }}
          id={inputId}
          {...props}
        />
        <span className={`${S.slider} ${S.red}`} id={S.round}></span>
      </div>
      {labelSide === SwitchLabelSide.Right && children}
    </label>
  );
};

export default Switch;
