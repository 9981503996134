import React from 'react';

import {useMyTicketsState} from '../../hooks/useTickets';
import {TicketsListPageLoader} from './TicketsListPageLoader';
import {TICKETS_PER_PAGE} from '../../MyTicketsList.consts';

export const MyTicketsListPageLoader = React.memo(() => {
  const {ticketVariant, isMobileVersion} = useMyTicketsState();

  return (
    <TicketsListPageLoader elementsLength={TICKETS_PER_PAGE} isMobile={isMobileVersion} ticketVariant={ticketVariant} />
  );
});
