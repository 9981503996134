import {useEffect} from 'react';
import {ssoUrlBuilderLoginGuest} from 'Utils/ssoUrlBuilder';
import {useStyledThemeState} from 'common/theme';

const GuestLogin = () => {
  const {isContrastTheme} = useStyledThemeState();

  useEffect(() => {
    const log = async () => window.location.replace(await ssoUrlBuilderLoginGuest(isContrastTheme));
    log();
  }, []);
  return <></>;
};

export default GuestLogin;
